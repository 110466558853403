import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Card } from '@material-ui/core'
import dayjs from 'dayjs'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import { darken } from 'polished'
import { default as React, useState } from 'react'
import { Box, Button, Flex } from 'rebass'
import { styled } from '../../styles/settings/theme'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import useInterval from '../../utils/useInterval'
import DataFetcher from './DataFetcher'
import { currentUserIsAdmin } from '../../utils/admins'
import { MatchMessage } from '../../types/graphql'
import { HIDE_MESSAGE } from '../../types/fragments'
import { FaEyeSlash, FaEye } from 'react-icons/fa'

const GET_MATCH_MESSAGES = gql`
  query UpdateMatchMessages($matchId: ID!, $since: Int) {
    match(id: $matchId) {
      id
      matchMessages(since: $since) {
        id
        hidden
        user {
          displayName
          isAdmin
          id
        }
        body
        createdAt
      }
    }
  }
`
const GET_MATCH_MESSAGES_COUNT = gql`
  query GetMatchMessageCount($matchId: ID!) {
    match(id: $matchId) {
      id
      messagesCount
      hiddenMessagesCount
    }
  }
`

const ADD_MESSAGE = gql`
  mutation addMatchMessage($id: ID!, $body: String!) {
    addMatchMessage(id: $id, body: $body) {
      success
      errors {
        message
      }
    }
  }
`

interface IProps {
  id: string
}

const StyledTextArea = styled.textarea`
  width: 100%;
  color: ${(props) => darken(0.3, props.theme.colors.darkmiddlegray)};
  border: solid 2px ${(props) => props.theme.colors.darkmiddlegray};
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.75rem;
  max-width: 100%;
  min-width: 100%;
  min-height: 6rem;
  outline: none;
`

const MatchMessageBoard: React.FunctionComponent<IProps> = ({ id }) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    hiddenRight: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }
  const { colors } = useTheme()

  const getFormattedDate = (string: string) => {
    var localizedFormat = require('dayjs/plugin/localizedFormat')
    dayjs.extend(localizedFormat)
    return dayjs(string).format('L LT')
  }
  const [stagedMessage, setStagedMessage] = useState('')
  const [pendingNewMessages, setPendingNewMessages] = useState(false)
  const [messageCount, setMessageCount] = useState<number>(0)
  // Dynamic delay
  const [delay, setDelay] = useState<number>(1000)
  const [matchMessages, setMatchMessages] = useState<MatchMessage[]>([])
  // ON/OFF

  const [getMessages, { data, loading: refetchLoading }] = useLazyQuery(GET_MATCH_MESSAGES, {
    variables: {
      matchId: +id,
      since: matchMessages.length,
    },
    fetchPolicy: 'network-only',
  })
  const { data: countData, refetch: countRefetch } = useQuery(GET_MATCH_MESSAGES_COUNT, {
    variables: {
      matchId: +id,
    },
    fetchPolicy: 'network-only',
  })

  const compareDates = (a: string, b: string) => {
    const aDate = new Date(a)
    const bDate = new Date(b)
    return bDate.getTime() - aDate.getTime()
  }

  const [submitMessage, { loading }] = useMutation(ADD_MESSAGE, {
    variables: {
      id: +id,
      body: stagedMessage,
    },
    onCompleted({ data }) {
      setStagedMessage('')
      setDelay(1000)
    },
  })

  const [hideMessage] = useMutation(HIDE_MESSAGE, {
    onCompleted({ data }) {
      setDelay(500)
    },
  })

  // ON/OFF
  useInterval(
    () => {
      // Your custom logic here
      countRefetch()
      delay < 60000 && setDelay(delay + 1000)
    },
    // Delay in milliseconds or null to stop it
    delay,
  )

  if (data && data.match && data.match.matchMessages && pendingNewMessages) {
    setMatchMessages(
      matchMessages.concat(data.match.matchMessages).sort((a, b) => a.createdAt - b.createdAt),
    )
    setPendingNewMessages(false)
  }

  if (countData && countData.match.messagesCount !== messageCount) {
    setMessageCount(countData.match.messagesCount)
    // The only time this number would go down is becasue a message was hidden. In that case we want to throw everything away because we dont know which message was deleted.
    if (countData.match.messagesCount < messageCount) setMatchMessages([])
    if (!refetchLoading && matchMessages.length !== countData.match.messagesCount) {
      setPendingNewMessages(true)
      setDelay(1000)
      getMessages()
    }
  }

  return (
    <Box>
      <Flex
        mb={3}
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
        backgroundColor={colors.whiteSecondary}
      >
        <Flex flexDirection="column" justifyContent="space-between">
          <DataFetcher loading={loading}>
            <Box height={'33%'}>
              <h2>Team Message Board</h2>
            </Box>
            <Box height={'66%'} mt={7} mr={4}>
              <StyledTextArea
                onChange={e => setStagedMessage(e.target.value)}
                value={stagedMessage}
                placeholder="Say something!"
                key={'stagedMessage'}
              />
              <BaseButton onClick={() => submitMessage()}>Send</BaseButton>
            </Box>
          </DataFetcher>
        </Flex>
        <Box maxHeight={'300px'} overflow="auto" width={'100%'} paddingRight="2rem">
          {matchMessages
            .sort((a, b) => compareDates(a.createdAt, b.createdAt))
            .map(message => (
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.5, delay: 0.5 }}
                key={'animation' + message.id}
              >
                <Box mt={'1rem'}>
                  <Card variant="outlined">
                    <Box padding={'.5rem'}>
                      <Box color={message.user.isAdmin ? colors.sand : colors.primarynavy}>
                        <h5>
                          {' '}
                          {message.user.displayName +
                            ' @ ' +
                            getFormattedDate(message.createdAt) +
                            '   '}
                          {message.hidden && <FaEyeSlash />}
                        </h5>
                      </Box>
                      <p> {message.body}</p>
                      {currentUserIsAdmin() && (
                        <Button
                          onClick={() =>
                            hideMessage({
                              variables: {
                                id: +message.id,
                                type: 'Match',
                              },
                            })
                          }
                        >
                          {message.hidden ? (
                            <>
                              Show <FaEye />
                            </>
                          ) : (
                            <>
                              Hide <FaEyeSlash />
                            </>
                          )}
                        </Button>
                      )}
                    </Box>
                  </Card>
                </Box>
              </Animation>
            ))}
        </Box>
      </Flex>
    </Box>
  )
}

export default MatchMessageBoard
