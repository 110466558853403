import React from 'react'
import { Flex, Box, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import HomepageCta from '../../assets/images/background__homepage-cta.jpg'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

const StyledWideContent = styled(WideContent)`
  position: relative;
  height: 500px;
  margin-bottom: -20px;
  z-index: 1;
`

const StyledDivider = styled(Box)`
  position: relative;
  height: 2px;
  margin: auto;
  max-width: calc(100% - 4rem);
  z-index: 8;
  background-color: ${props => props.theme.colors.primarylightblueHighlight};

  ${Mq.md} {
    max-width: calc(100% - 8rem);
    margin: auto;
  }
`

const StyledDividerBlurred = styled(Box)`
  position: relative;
  height: 1rem;
  max-width: calc(100% - 8rem);
  margin: auto;
  z-index: 8;
  background-color: ${props => props.theme.colors.primarylightblueHighlight};
  opacity: 1;
  filter: blur(54px);
`

const StyledWideContainer = styled(WideContainer)`
  z-index: 1;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 500px;

  p {
    max-width: 500px;
    margin: auto;
  }

  h2 {
    font-size: 0.8rem;

    ${Mq.md} {
      font-size: 1rem;
    }
  }
`

const Background = styled(Box)`
  background-image: linear-gradient(180deg, #000000 0%, rgba(3, 51, 89, 0.6) 56.25%, #033359 100%), url('${HomepageCta}');
  height: -webkit-fill-available;
  width: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: -1;
`

const HomePageCTA: React.FC = () => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    width: {
      width: '0%',
      y: 0,
    },
    widthVisible: {
      width: '100%',
      y: 0,
      opacity: 1,
    },
  }

  return (
    <StyledWideContent>
      <StyledWideContainer>
        <Flex width="100%" justifyContent="center" my={8}>
          <Flex flexDirection="column" width={[1, 1, 1, 1 / 2]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4 }}
            >
              <Text textAlign="center" my={2}>
                <h2>ready to #SCHOOLTHECOMPETITION?</h2>
              </Text>
            </Animation>

            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
            >
              <Text textAlign="center" my={2}>
                <p>
                  Your team is waiting for you. Sign up today and join your team for the 2020 - 2021
                  season.
                </p>
              </Text>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Link to={paths.signup()}>
                <Flex>
                  <BaseButton variant="primary" mx="auto" my={3}>
                    sign up today
                  </BaseButton>
                </Flex>
              </Link>
            </Animation>
          </Flex>
        </Flex>
      </StyledWideContainer>
      <Background />
      <Animation
        initial="width"
        animate="widthVisible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 1, delay: 0.4 }}
      >
        <StyledDivider />
      </Animation>
      <StyledDividerBlurred />
    </StyledWideContent>
  )
}

export default HomePageCTA
