import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const SmashRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="Super Smash Bros. Ultimate" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        CSL Super Smash Bros. Ultimate 2020-2021 Circuit Rulebook: <a href="https://drive.google.com/file/d/19BBzpvSWBuF84dunxIWcNjXcYlw1-Qnc/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            CSL-Smash-2020-2021-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY Super Smash Bros. Ultimate RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #smash-help chat.
        </h3>
      </Text>
    </>
  )
}

export default SmashRules
