import gql from 'graphql-tag'
import { useTheme } from 'emotion-theming'
import { Box, Flex } from 'rebass'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { RouteComponentProps, Redirect, Link } from 'react-router-dom'
import { Text } from 'rebass'
import { Dictionary } from 'lodash'
import { Theme, styled, MediaQueries as Mq } from '../../styles/settings'

import {
  useSignInMutation as useSignIn,
  useSignUpMutation as useSignUp,
  UserForAuth,
} from '../../types/graphql'
import { handleResult, errorOn } from '../../utils/results'
import { paths } from '../../utils/Routes'
import { AuthenticationAttributes } from '../../types/fragments'
import { AuthFormContent, AuthFormBox } from '../atoms/AuthFormPieces'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import { updateUserAuth } from '../../utils/formUtils'
import Animation from '../atoms/Animation'
import LoadingSpinner from '../atoms/LoadingSpinner'
import OAuthLoginButton from '../atoms/OAuthLoginButton'
import SectionTitle from '../atoms/SectionTitle'

gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      success
      value {
        ...AuthenticationAttributes
      }
      errors {
        field
        message
      }
    }
  }
  ${AuthenticationAttributes}
`

gql`
  mutation signUp(
    $email: String!
    $password: String!
    $username: String!
    $invitationToken: String
  ) {
    signUp(
      email: $email
      password: $password
      username: $username
      invitationToken: $invitationToken
    ) {
      success
      value {
        ...AuthenticationAttributes
      }
      errors {
        field
        message
      }
    }
  }
  ${AuthenticationAttributes}
`

const StyledFlex = styled(Flex)`
  position: relative;
`

const CustomBox = styled(Box)`
  width: 100%;
  margin: 2rem auto 0;
  border: 2px solid ${props => props.theme.colors.middlegray};
  padding: 2rem;

  ${Mq.md} {
    width: 80%;
  }
`

const DividerGroup = styled(Flex)`
  align-items: center;
  width: 100%;
  margin-top: 3rem;

  h5 {
    min-width: max-content;
    padding-right: 2rem;
  }
`

const Divider = styled(Box)`
  background-color: ${props => props.theme.colors.sand};
  height: 1px;
  width: 100%;
`

const LoginLink = styled(Link)`
  position: absolute;
  right: 30px;
  top: 35px;
`

interface IProps extends RouteComponentProps {
  isSignUp: boolean
  invitationToken?: string
}

const LogIn: React.FC<IProps> = ({ isSignUp, invitationToken, location: { state } }) => {
  const { colors } = useTheme()

  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [formErrors, setFormErrors] = useState<Dictionary<string>>({})
  const [signIn, { loading: signInLoading }] = useSignIn()
  const [signUp, { loading: signUpLoading }] = useSignUp()

  const variables = { email, password }

  const handleOnSuccess = (value: UserForAuth) => {
    updateUserAuth(value)

    window.location.href = value.isIncompleteAccount ? paths.completeProfile() : paths.homepage()
  }

  const handleSignIn = async () => {
    try {
      const { data } = await signIn({ variables })
      handleResult({
        result: data!.signIn,
        onSuccess: value => handleOnSuccess(value),
        onFailure: errors => toast.error(errorOn('base', errors), { containerId: 'temporary' }),
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleSignUp = async () => {
    try {
      const { data } = await signUp({ variables: { ...variables, username, invitationToken } })
      handleResult({
        result: data!.signUp,
        onSuccess: value => handleOnSuccess(value as UserForAuth),
        onFailure: errors => {
          const personalEmailError = errorOn('personalEmail', errors)
          if (personalEmailError) {
            setFormErrors({
              ...errors,
              email: personalEmailError.replace('Personal ', ''),
              personalEmail: '',
            })
          } else {
            setFormErrors(errors)
          }
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    isSignUp ? handleSignUp() : handleSignIn()
  }

  const loading = signInLoading || signUpLoading
  const formErrorMessages = Object.values(formErrors).filter(error => error)
  const formError = !!formErrorMessages.length

  const isButtonDisabled =
    loading ||
    formError ||
    email === '' ||
    password === '' ||
    (isSignUp && passwordConfirmation === '') ||
    (isSignUp && username === '')

  const stateProp = state as any
  if (stateProp && stateProp.message) {
    toast.success(stateProp.message, { containerId: 'temporary' })

    return (
      <Redirect
        to={{
          pathname: '/logIn',
          state: null,
        }}
      />
    )
  }

  const variants = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    fade: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <AuthFormContent onSubmit={handleSubmit}>
      {isSignUp && (
        <LoginLink to={paths.logIn()}>
          <Text color={colors.primarynavy}>
            <h6>Login</h6>
          </Text>
        </LoginLink>
      )}

      <AuthFormBox>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
        >
          <SectionTitle
            text={isSignUp ? 'Register for CSL' : 'Sign in to csl'}
            align="center"
            mb=".75rem"
          />
        </Animation>
        {!isSignUp && (
          <Animation
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
          >
            <Text textAlign="center">
              <p>
                Welcome back! Please select a method
                <br />
                for signing in from the options below.
              </p>
            </Text>
          </Animation>
        )}
        <CustomBox>
          <Animation
            initial="fade"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
          >
            <>
              {!isSignUp && (
                <Flex>
                  <Box width={1 / 2}>
                    <h5>Sign In</h5>
                  </Box>
                  <Box width={1 / 2}>
                    <Text textAlign="right">
                      <Link to={paths.forgotPassword()}>
                        <h5>Forgot password</h5>
                      </Link>
                    </Text>
                  </Box>
                </Flex>
              )}
              <Flex width="auto" mx={-2} mt={6} flexDirection={['column', 'column', 'row', 'row']}>
                {isSignUp && (
                  <Box width={1} margin="auto" px={2}>
                    <InputFieldWithErrors
                      mt={3}
                      mb={4}
                      key="username"
                      type="text"
                      name="username"
                      label="username"
                      placeholder="username"
                      errorMessage={formErrors['username']}
                      value={username}
                      updateSingleField={field => {
                        setUsername(field)
                      }}
                      updateErrors={errors => setFormErrors({ ...formErrors, ...errors })}
                    />
                  </Box>
                )}
                <Box width={1} margin="auto" px={2}>
                  <InputFieldWithErrors
                    mt={3}
                    mb={4}
                    key="email"
                    type="text"
                    name="email"
                    label="email"
                    placeholder="email"
                    value={email}
                    isEmailField={true}
                    updateSingleField={field => {
                      setEmail(field)
                    }}
                    updateErrors={errors => setFormErrors({ ...formErrors, ...errors })}
                    errorMessage={formErrors['email']}
                  />
                </Box>
              </Flex>
              <StyledFlex
                width="auto"
                mt={2}
                mx={-2}
                flexDirection={['column', 'column', 'row', 'row']}
              >
                <Box width={1} margin="auto" px={2}>
                  <InputFieldWithErrors
                    mt={3}
                    mb={4}
                    key="password"
                    type="password"
                    name="password"
                    label="password"
                    placeholder="password"
                    value={password}
                    updateSingleField={field => {
                      setPassword(field)
                    }}
                    isRequired={true}
                    updateErrors={errors => setFormErrors({ ...formErrors, ...errors })}
                    errorMessage={formErrors['password']}
                    otherPassword={passwordConfirmation}
                  />
                </Box>
                {isSignUp && (
                  <Box width={1} margin="auto" px={2}>
                    <InputFieldWithErrors
                      mt={3}
                      mb={4}
                      key="passwordConfirmation"
                      type="password"
                      name="password"
                      label="confirm password"
                      placeholder="confirm password"
                      value={passwordConfirmation}
                      updateSingleField={field => {
                        setPasswordConfirmation(field)
                      }}
                      updateErrors={errors => setFormErrors({ ...formErrors, ...errors })}
                      errorMessage={formErrors['password']}
                      isRequired={true}
                      otherPassword={password}
                    />
                  </Box>
                )}
              </StyledFlex>
              <br />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <StyledFlex width="auto" alignItems="flex-end" flexDirection="column">
                  <Box width="auto">
                    <BaseButton
                      variant={isButtonDisabled ? 'primaryDisabled' : 'primary'}
                      disabled={isButtonDisabled}
                      type="submit"
                    >
                      {isSignUp ? 'Sign Up' : 'Sign In'}
                    </BaseButton>
                  </Box>
                </StyledFlex>
              )}
              {!isSignUp && (
                <>
                  <DividerGroup>
                    <Text textAlign="left" minWidth="max-content">
                      <h5>Other Sign In Options</h5>
                    </Text>
                    <Divider />
                  </DividerGroup>

                  <Flex mt={4}>
                    <Box width={1 / 2} mr={2}>
                      <OAuthLoginButton provider="twitch" backgroundColor={Theme.colors.twitch}>
                        Twitch
                      </OAuthLoginButton>
                    </Box>
                    <Box width={1 / 2} ml={2}>
                      <OAuthLoginButton provider="discord" backgroundColor={Theme.colors.discord}>
                        Discord
                      </OAuthLoginButton>
                    </Box>
                  </Flex>
                </>
              )}
            </>
          </Animation>
        </CustomBox>
      </AuthFormBox>
    </AuthFormContent>
  )
}

export default LogIn
