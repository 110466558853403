import React from 'react'
import { Route, RouteComponentProps, useRouteMatch, Redirect, useHistory } from 'react-router-dom'
import { oc } from 'ts-optchain'
import { camelCase, upperFirst } from 'lodash'
import { Box } from 'rebass'

import { paths } from '../../../utils/Routes'
import { CurrentUserProvider } from '../../../contexts/CurrentUserContext'
import InvitedUserQueryProvider from './InvitedUserQueryProvider'
import BackButton from '../../atoms/BackButton'
import { Anchors } from './enums'

import AboutMe from './AboutMe'
import AddGames from './AddGames'
import AddGamingProfiles from './AddGamingProfiles'
import FinalizeUser from './FinalizeUser'
import SocialProfiles from '../../molecules/SocialProfiles'
import Stepper from '../../atoms/Stepper'
import { Container, FormWrapper } from './ContentWrappers'

interface IURLParams {
  anchor?: string
}

interface IProps extends RouteComponentProps<IURLParams> {}

const CompleteProfile: React.FC<IProps> = ({ match: { params } }) => {
  const match = useRouteMatch()
  const history = useHistory()
  const anchor = oc(params).anchor('')

  const changeAnchor = (anchor: string) => {
    history.push(paths.completeProfile(Anchors[upperFirst(camelCase(anchor))]))
  }

  const sections: { [key: string]: React.ReactElement } = {
    [Anchors.AboutMe]: <AboutMe onFormSubmitted={() => changeAnchor(Anchors.AddGames)} />,
    [Anchors.AddGames]: (
      <AddGames
        onFormSubmitted={() => changeAnchor(Anchors.GamingProfiles)}
        changeAnchor={changeAnchor}
      />
    ),
    [Anchors.GamingProfiles]: (
      <AddGamingProfiles
        onFormSubmitted={() => changeAnchor(Anchors.Subscription)}
        changeAnchor={changeAnchor}
      />
    ),
    [Anchors.Subscription]: (
      <FinalizeUser
        onFormSubmitted={() =>
          (window.location.href = paths.completeProfile(Anchors.SocialProfiles))
        }
        changeAnchor={changeAnchor}
      />
    ),
    [Anchors.SocialProfiles]: (
      <SocialProfiles
        onFormSubmitted={() => history.push(paths.myUniversity())}
        onSkip={() => history.push(paths.myUniversity())}
        submitButtonText="add social links"
      />
    ),
  }

  const validRedirect = anchor === Anchors.AboutMe || ['PUSH', 'POP'].includes(history.action)

  return anchor === '' || !validRedirect ? (
    <Redirect to={paths.completeProfile(Anchors.AboutMe)} />
  ) : (
    <CurrentUserProvider>
      <InvitedUserQueryProvider>
        <Container>
          {anchor !== Anchors.AboutMe && anchor !== Anchors.SocialProfiles && (
            <BackButton onClick={() => history.goBack()} />
          )}

          <FormWrapper>
            <Route
              path={`${match.path}/:anchor`}
              render={({ match }) => sections[match.params.anchor]}
            />
          </FormWrapper>
          <Box width="75%" mx="auto" my={8}>
            <Stepper
              totalSteps={Object.values(Anchors).length}
              currentStep={Object.values(Anchors).indexOf(anchor) + 1}
            />
          </Box>
        </Container>
      </InvitedUserQueryProvider>
    </CurrentUserProvider>
  )
}

export default CompleteProfile
