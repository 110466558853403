import React from 'react'
import gql from 'graphql-tag'
import { IoLogoTwitch } from 'react-icons/io'
import { FaDiscord } from 'react-icons/fa'
import { darken } from 'polished'
import { styled } from '../../styles/settings/theme'

import { useOauthLoginUrlLazyQuery } from '../../types/graphql'
import { BaseButton } from './Buttons'
import Hidden from '../atoms/Hidden'

gql`
  query oauthLoginUrl($provider: String!) {
    oauthLoginUrl(provider: $provider)
  }
`

interface IOAuthButton {
  backgroundColor?: any
}

const OAuthButton = styled(BaseButton)<IOAuthButton>`
  min-width: 100%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.backgroundColor};
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 1rem;
    margin-top: -4px;
  }

  &:hover {
    background-color: ${props => darken(0.1, props.backgroundColor)};
  }
`

interface IOAuthLoginButton {
  provider: string
  backgroundColor?: any
}

const OAuthLoginButton: React.FC<IOAuthLoginButton> = ({ provider, backgroundColor, ...props }) => {
  const [oauthLoginUrl] = useOauthLoginUrlLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      window.location.href = data.oauthLoginUrl
    },
  })

  const redirectToOAuthLogin = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    oauthLoginUrl({
      variables: { provider },
    })
  }

  return (
    <OAuthButton backgroundColor={backgroundColor} onClick={redirectToOAuthLogin} {...props}>
      <Hidden smDown>
        {provider === 'discord' ? (
          <FaDiscord size={22} />
        ) : provider === 'twitch' ? (
          <IoLogoTwitch size={22} />
        ) : null}
      </Hidden>
      {props.children}
    </OAuthButton>
  )
}

export default OAuthLoginButton
