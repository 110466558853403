import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { Flex, Box } from 'rebass'
import { useHistory } from 'react-router-dom'

import { paths } from '../../../utils/Routes'
import { handleResult } from '../../../utils/results'
import { SiteSwitch, collegeSite } from '../../../utils/sites'
import {
  useVerfiyNewUserMutation,
  useVerfiyNewEsportsUserMutation,
  VerfiyNewUserMutation,
} from '../../../types/graphql'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import UserAboutSection from '../../molecules/UserAboutSection/UserAboutSection'
import UniversityFields from '../../molecules/UserAboutSection/UniversityFields'
import VarsityFields from '../../molecules/VarsityFields'
import MarketingEmailsOptIn from '../../molecules/MarketingEmailsOptIn'

gql`
  mutation VerfiyNewEsportsUser($name: String!, $discordUsername: String!, $gender: String) {
    verifyNewUser(name: $name, discordUsername: $discordUsername, gender: $gender) {
      success
      errors {
        field
        message
      }
    }
  }
`

gql`
  mutation VerfiyNewUser(
    $name: String!
    $email: String!
    $universityId: ID!
    $universityMajorId: ID
    $graduationYear: Int!
    $discordUsername: String!
    $gender: String
  ) {
    verifyNewUser(
      name: $name
      email: $email
      universityId: $universityId
      universityMajorId: $universityMajorId
      graduationYear: $graduationYear
      discordUsername: $discordUsername
      gender: $gender
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface IAboutSectionProps {
  onFormSubmitted: () => void
}

const AboutMe: React.FC<IAboutSectionProps> = ({ onFormSubmitted }) => {
  const history = useHistory()
  const {
    isInVarsityProgram,
    varsityProgramUrl,
    setIsInVarsityProgram,
    setVarsityProgramUrl,
    marketingEmailsCd,
    setMarketingEmailsCd,
    setCurrentUserContextLoading,
    setCurrentUserFormErrors,
    fullName,
    email,
    universityId,
    universityMajorId,
    graduationYear,
    discordUsername,
    gender,
  } = useContext(CurrentUserContext)
  const useVerfiyNewUser = collegeSite ? useVerfiyNewUserMutation : useVerfiyNewEsportsUserMutation

  const [verifyNewUser, { loading }] = useVerfiyNewUser({
    variables: {
      name: fullName,
      email,
      universityId,
      universityMajorId,
      graduationYear: Number(graduationYear),
      discordUsername,
      gender,
    },
    onError: error => {
      console.log(error)
    },
    onCompleted: (data: VerfiyNewUserMutation) => {
      handleResult({
        result: data.verifyNewUser,
        onSuccess: () => {
          setCurrentUserContextLoading(false)
          onFormSubmitted()
        },
        onFailure: errors => {
          setCurrentUserContextLoading(false)
          setCurrentUserFormErrors(errors)
        },
      })
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Flex>
      <UserAboutSection
        headerText="complete your profile"
        submitButtonText="continue"
        cancelButtonText="skip profile setup"
        onCancel={() => history.push(paths.profile())}
        onSubmit={verifyNewUser}
      >
        <SiteSwitch
          esports={null}
          college={
            <Box>
              <UniversityFields isNewUser />
              <VarsityFields
                mb={6}
                isInVarsityProgram={isInVarsityProgram}
                varsityProgramUrl={varsityProgramUrl}
                onChangeIsInVarsityProgram={isInVarsityProgram =>
                  setIsInVarsityProgram(isInVarsityProgram)
                }
                onChangeProgramUrl={varsityProgramUrl => setVarsityProgramUrl(varsityProgramUrl)}
              />
            </Box>
          }
        />
        <MarketingEmailsOptIn
          mb={6}
          marketingEmailsCd={marketingEmailsCd}
          setMarketingEmailsCd={setMarketingEmailsCd}
        />
      </UserAboutSection>
    </Flex>
  )
}

export default AboutMe
