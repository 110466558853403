import React from 'react'
import { Text } from 'rebass'
import {
  MainHeader,
  SubHeader,
  SectionHeader,
  Paragraph
} from '../atoms/RulesTypography'

const OwRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="Overwatch" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        Lone Star College Overwatch Intramurals Rulebook (Fall 2020): <a href="https://drive.google.com/file/d/1Mp8SolYULk4hd_aEj34qebSt-Y1LwqIX/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Lone-Star-College-Intramurals-Overwatch-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY Overwatch RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #overwatch-help chat.
        </h3>
      </Text>
    </>
  )
}

export default OwRules
