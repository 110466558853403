import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const CsgoRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="COUNTERSTRIKE: GLOBAL OFFENSIVE" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        CSL CS:GO 2020-2021 Season Rulebook: <a href="https://drive.google.com/file/d/1tmaYKcyazgF_8uvtYk4xE8jiZ4r9iCjb/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          CSL-CSGO-2020-2021-Rules.pdf
          </a><br />
          Lone Star College CS:GO Intramurals Rulebook (Fall 2020): <a href="https://drive.google.com/file/d/1oVyHB1S9OxxmDP3jRS-garpcVogznMpv/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Lone-Star-College-Intramurals-CSGO-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY CSGO RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #CSGO-help chat.
        </h3>
      </Text>
    </>
  )
}

export default CsgoRules
