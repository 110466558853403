import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Text } from 'rebass'

import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { useGetTopTeamsQuery } from '../../types/graphql'
import { paths } from '../../utils/Routes'
import { SiteSwitch, siteSwitcher } from '../../utils/sites'
import Animation from '../atoms/Animation'
import Card from '../atoms/Card'
import Rank from '../atoms/Rank'
import Table from '../atoms/Table'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'
import DataFetcher from './DataFetcher'
import HomeRankingsAlert from './HomeRankingsAlert'
import OpenTournaments from './OpenTournaments'
import RankedUniversities from './RankedUniversities'

gql`
  query getTopTeams {
    topTeams {
      rank
      team {
        id
        name
        selectedSeasonStats {
          losses
          wins
          id
        }
      }
    }
  }
`

const StyledWideContent = styled(WideContent)`
  background: ${props => props.theme.colors.black};
  padding: 4rem 0 0;

  ${Mq.md} {
    padding: 2rem 0 0;
  }
`

const HomeRankings: React.FC = () => {
  const { colors } = useTheme()

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const {
    data: topTeamsData,
    loading: topTeamsLoading,
    error: topTeamsError,
  } = useGetTopTeamsQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <StyledWideContent>
      <WideContainer>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
        >
          <Box
            sx={{
              display: 'grid',
              gridGap: [8, 8, 8, 5],
              gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
            }}
            pb={[5]}
          >
            <div>
              <Text mb={4} color={colors.white}>
                <h2>
                  <SiteSwitch esports="Open Tournaments" college="Top Universities" />
                </h2>
              </Text>

              <Card notch={true} notchSize={16}>
                <SiteSwitch esports={<OpenTournaments />} college={<RankedUniversities />} />
              </Card>
            </div>

            <div>
              <Text mb={4} color={colors.white}>
                <h2>Top Teams</h2>
              </Text>

              <Card notch={true} notchSize={16}>
                <DataFetcher loading={topTeamsLoading} error={topTeamsError}>
                  {topTeamsData &&
                    (topTeamsData.topTeams.length === 0 ? (
                      <HomeRankingsAlert
                        title="Teams are not yet ranked"
                        description="Check back soon to view the full list of ranked teams."
                        ctaText={siteSwitcher({
                          college: 'View this upcoming season',
                          esports: '',
                        })}
                        ctaLink={siteSwitcher({ college: paths.leagues(), esports: '' })}
                      />
                    ) : (
                      <Table headerBackgroundColor="darkgray">
                        <thead>
                          <tr>
                            <th>
                              <Text color={colors.white}>
                                <h6>Rank</h6>
                              </Text>
                            </th>
                            <th>
                              <Text color={colors.white}>
                                <h6>Team name</h6>
                              </Text>
                            </th>
                            <th>
                              <Text color={colors.white}>
                                <h6>Season record</h6>
                              </Text>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {topTeamsData.topTeams.map(topTeam => (
                            <tr key={topTeam.team.id}>
                              <td>
                                <Rank rank={topTeam.rank} textColor={colors.darkgray} />
                              </td>
                              <td>
                                <Link to={paths.teamDetail(topTeam.team.id)}>
                                  <h6>{topTeam.team.name}</h6>
                                </Link>
                              </td>
                              <td>
                                <Text color={colors.darkgray}>
                                  <h6>
                                    {(topTeam.team.selectedSeasonStats &&
                                      topTeam.team.selectedSeasonStats.wins) ||
                                      0}{' '}
                                    -{' '}
                                    {(topTeam.team.selectedSeasonStats &&
                                      topTeam.team.selectedSeasonStats.losses) ||
                                      0}
                                  </h6>
                                </Text>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ))}
                </DataFetcher>
              </Card>
            </div>
          </Box>
        </Animation>
      </WideContainer>
    </StyledWideContent>
  )
}

export default HomeRankings
