import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { usePagination, useTable } from 'react-table'
import { Box, Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'
import { useQuery } from '@apollo/react-hooks'

import { paths } from '../../utils/Routes'
import { University } from '../../types/graphql'
import HeroImage from '../../assets/images/hero__universities.jpg'
import { BaseButton } from '../atoms/Buttons'
import { CustomInput } from '../atoms/FormPieces'
import Animation from '../atoms/Animation'
import Card from '../atoms/Card'
import Content from '../atoms/Content'
import DataFetcher from '../organisms/DataFetcher'
import HeroContainer from '../atoms/HeroContainer'
import Paginator from '../molecules/Paginator'
import Table from '../atoms/Table'

const GET_UNIVERSITIES = gql`
  query getUniversities {
    universities {
      id
      name
      state
    }
  }
`

interface IProps {
  universities: University[]
}

const StyledInput = styled(CustomInput)`
  background-color: ${props => props.theme.colors.white};
`
const UniversitiesContent: React.FunctionComponent<IProps> = ({ universities }) => {
  const { colors } = useTheme()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState(universities)

  const columns = useMemo(
    () => [
      {
        Header: 'University',
        accessor: 'name',
        Cell: ({
          cell: { value },
          row: { original },
        }: {
          cell: { value: string }
          row: { original: University }
        }) => <Link to={paths.university(original.id)}>{value}</Link>,
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        accessor: 'id',
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <Link to={paths.university(value)}>
            <BaseButton variant="secondary">View University</BaseButton>
          </Link>
        ),
      },
    ],
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: useMemo(() => searchResults, [searchResults]),
      initialState: { pageIndex: 0, pageSize: 30 },
    },
    usePagination,
  )

  const debouncedSetSearchResults = useCallback(debounce(setSearchResults, 500), [])

  useEffect(() => {
    debouncedSetSearchResults(
      universities.filter(university =>
        university.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    )
  }, [universities, searchQuery, debouncedSetSearchResults])

  useEffect(() => {
    gotoPage(0)
  }, [searchResults, gotoPage])

  const onPageChanged = (_event: React.ChangeEvent<unknown>, page: number) => {
    gotoPage(page - 1)
  }

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <HeroContainer height="18rem" heroImage={HeroImage} tint={'3,51,89,0.6'}>
        <Flex alignItems="flex-end" height="100%" pt={5}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4 }}
          >
            <Text color="white">
              <h1>Universities</h1>
            </Text>
          </Animation>
        </Flex>
      </HeroContainer>

      <Content>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Box mb={3}>
            <h2>Search for a university</h2>
          </Box>
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
        >
          <StyledInput
            mb={7}
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder="Search university"
          />
        </Animation>

        <Box mb={3}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
          >
            <h2>
              Universities{' '}
              <Text color={colors.darkgray} as="span">
                ({searchResults.length})
              </Text>
            </h2>
          </Animation>
        </Box>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
        >
          <Card notch={true} pb={5}>
            <Table headerBackgroundColor="middlegray" {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        <h6>{column.render('Header')}</h6>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            <h6>{cell.render('Cell')}</h6>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            <Flex justifyContent="center" mt={5} px={2}>
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4 }}
              >
                <Paginator page={pageIndex + 1} count={pageCount} onChange={onPageChanged} />
              </Animation>
            </Flex>
          </Card>
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Card mt={6} p={5} notch={true}>
            <Text textAlign="center">
              <Text color={colors.primarynavy} mb={4}>
                <h6>NOT SEEING YOUR UNIVERSITY? REQUEST TO ADD YOUR SCHOOL TODAY</h6>
              </Text>

              <a href="mailto:support@cslesports.gg">
                <BaseButton variant="secondary">Add your school</BaseButton>
              </a>
            </Text>
          </Card>
        </Animation>
      </Content>
    </>
  )
}

const Universities: React.FunctionComponent = () => {
  const { data, error, loading } = useQuery(GET_UNIVERSITIES, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <DataFetcher error={error} loading={loading}>
      {data && data.universities && <UniversitiesContent universities={data.universities} />}
    </DataFetcher>
  )
}

export default Universities
