import React from 'react'
import {
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const MtgaRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="Magic: The Gathering Arena" />
      <SectionHeader text="Not playable for 2020-2021 Season" />
    </>
  )
}

export default MtgaRules
