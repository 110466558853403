/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useTheme } from 'emotion-theming'
import { Link } from 'react-router-dom'
import { Flex, Box, Text, Link as ExternalLink } from 'rebass'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import HyperX from '../../assets/images/sponsor_logos/sponsor__hyperx--white.png'
import MMs from '../../assets/images/sponsor_logos/mms-logo.png'
import MaddenHero from '../../assets/images/hero__esports--madden.jpg'
import NbaSponsor from '../../assets/images/sponsor_logos/powerzup-filter.png'
import MaddenSponsor from '../../assets/images/sponsor_logos/powerzup-filter.png'
import NbaHero from '../../assets/images/hero__esports--nba.jpg'
import GfHero from '../../assets/images/hero__esports--gf.jpg'
import DreamHackHero from '../../assets/images/DreamHack-Hero.jpg'
import NaceHero from '../../assets/images/NS-Hero.jpg'
import NewsletterHero from '../../assets/images/hero__esports--newsletter.jpg'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import WideContainer from '../atoms/WideContainer'
import HeroCarousel from '../molecules/HeroCarousel'
import Iframe from 'react-iframe'

interface IHeroProps {
  borderColor?: any
  heroImage?: string
  sponsorImage?: string
}

const StyledBox = styled(Box)`
  h1 {
    max-width: 600px;
    margin-bottom: 2rem;
    ${Mq.md} {
      margin-bottom: 4rem;
    }
  }
  p {
    max-width: 480px;
  }
`

const StyledWideContainer = styled(WideContainer)`
  display: flex;
  align-items: center;
  min-height: 32rem;
`

const SponsorBox = styled(Box)`
  text-align: center;
  padding-left: 4rem;
  ${Mq.md} {
    padding-left: 0;
  }
  img {
    margin: 0 auto;
    max-height: 200px;
  }
`

const SmallSponsor = styled(Box)`
  max-width: 120px;
  margin: 2rem auto 0;
`

const StyledWideContent = styled(Flex)<IHeroProps>`
  background-image: linear-gradient(black,black), url('${props => props.heroImage}');
  box-shadow: 0 3000px rgba(0, 0, 0, 0.6) inset;
  background-size: cover;
  background-blend-mode: saturation;
  background-repeat: no-repeat;
  background-position: center bottom;

  ${Mq.md} {
    background-position: top;
    background-size: cover;
  }
`

const HomePageCarousel: React.FC<IHeroProps> = () => {
  const { colors } = useTheme()
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <HeroCarousel>
      <StyledWideContent heroImage={DreamHackHero}>
        <StyledWideContainer>
          <StyledBox width={[1, 1, 1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.2 }}
            >
              <Text color={colors.primarylightblue} mb={2}>
                <h5>Dream Big and Go BEYOND</h5>
              </Text>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Text color={colors.white}>
                <h1>Valorant Collegiate Open</h1>
              </Text>
            </Animation>
            <Flex alignItems="center">
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
              >
                <Link to={paths.tournaments()}>
                  <BaseButton variant="primary">Register Today</BaseButton>
                </Link>
              </Animation>
            </Flex>
          </StyledBox>
        </StyledWideContainer>
      </StyledWideContent>

      <StyledWideContent heroImage={NaceHero}>
        <StyledWideContainer>
          <StyledBox width={[1, 1, 1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.2 }}
            >
              <Text color={colors.primarylightblue} mb={2}>
                {/* <h5>Introducing CSL High School</h5> */}
              </Text>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Text color={colors.white}>
                <h1>Introducing NACE Starleague</h1>
              </Text>
            </Animation>
            <Flex alignItems="center">
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
              >
                <ExternalLink href="https://cslesports.com/nace-starleague/" rel="noopener noreferrer">
                  <BaseButton variant="primary">More Information</BaseButton>
                </ExternalLink>
              </Animation>
            </Flex>
          </StyledBox>
        </StyledWideContainer>
      </StyledWideContent>
    </HeroCarousel>
  )
}

export default HomePageCarousel
