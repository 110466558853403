import _ from 'lodash'

export function validateEmail(email: string) {
  //todo remove the regex stripping all +s
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.replace(/\+/g, ''))) {
    return true
  }
  return false
}

export function validateName(name: string) {
  if (/^[a-z ,.'-]+$/i.test(name)) {
    return true
  }
  return false
}

export function validateDiscord(discordName: string) {
  if (/^([a-zA-Z0-9]|\W)[^@#:```]*#\d{4,}$/.test(discordName)) {
    return true
  }
  return false
}

export function validatePasswordConfirmation(password: string, passwordConfirmation: string) {
  return password === passwordConfirmation
}

export function currentUserIsLoggedIn() {
  return !_.isEmpty(localStorage.getItem('csl_access-token'))
}

export function currentUsername() {
  return localStorage.getItem('csl_username')
}

export function accountIsIncomplete() {
  return sessionStorage.getItem('is_incomplete_account')
}

export default {
  validateEmail,
  validateName,
  validateDiscord,
  validatePasswordConfirmation,
  currentUserIsLoggedIn,
  currentUsername,
  accountIsIncomplete,
}
