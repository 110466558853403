import React, { Fragment } from 'react'
import { ArrowLeft } from 'react-feather'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import { MediaQueries as Mq, styled, Theme } from '../../styles/settings/theme'
import { CheckmarkIcon } from '../../assets/icons/checkmark-icon'

import { Game } from '../../types/graphql'
import { matchIsBye, teamScore } from '../../utils/matchUtils'
import { OpenLeagueLogoAlt, StarLeagueLogoAlt } from '../../assets/icons/league-icons'
import { paths } from '../../utils/Routes'
import HeroImage from '../../assets/images/hero__team-details.jpg'
import HeroContainer from '../atoms/HeroContainer'

interface IProps {
  matchId: string
  sportId: string
  tournamentId: string
  tournamentName: string
  homeTeamId: string | null
  homeTeamName: string | null
  homeTeamWins: number
  homeTeamLosses: number
  homeTeamMembersCount: number | null
  homeTeamCheckedIn: boolean | undefined
  awayTeamId: string | null
  awayTeamName: string | null
  awayTeamWins: number
  awayTeamLosses: number
  awayTeamMembersCount: number | null
  awayTeamCheckedIn: boolean | undefined
  games: Game[]
  settled: boolean
  isBye?: boolean | undefined
}

interface ITeamDetailsProps {
  anyGamesReported: boolean
  scoreColor: 'white' | 'green' | 'red'
  nameColor: 'white' | 'green'
  score: number
  name: string | null
  wins: number
  id: string | null
  losses: number
  memberCount: number | null
  isBye?: boolean | undefined
  checkedIn: boolean | undefined
}

interface LeagueProps {
  logoPadding: string
}

const BackContainer = styled(Flex)`
  display: inline-flex;
`

const LeagueLogoContainer = styled(Flex)<LeagueProps>`
  display: block;
  background: #fff;
  width: 50px;
  margin: 0 auto 20px;
  padding: ${props => props.logoPadding};
  height: auto;
  clip-path: polygon(18% 0%, 82% 0%, 100% 62%, 50% 100%, 0% 62%);
`

const TeamContainer = styled(Box)`
  position: relative;
  flex: 1;
  align-self: flex-end;
`

const TeamName = styled.h2`
  cursor: pointer;
  font-size: ${Theme.fontSizes[0]};
  ${Mq.md} {
    font-size: ${Theme.fontSizes[1]};
  }
`

const Team2Container = styled(TeamContainer)`
  text-align: right;
`

const ScoreText = styled(Text)`
  font-family: DrukWide;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  ${Mq.md} {
    font-size: 50px;
    line-height: 65px;
  }
`
const CenterContainer = styled(Box)`
  text-align: center;
  margin-top: 0px;
  position: relative;
  ${Mq.md} {
    margin-top: 0.25rem;
  }
`

const VersusContainer = styled(Box)`
  border: 3px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 45px;
  text-align: center;
  position: absolute;
  bottom: 0;

  ${Mq.md} {
    width: 76px;
    height: 76px;
    line-height: 76px;
    position: relative;
  }
`

const VersusText = styled.h2`
  font-size: ${props => props.theme.fontSizes[0]};

  ${Mq.md} {
    font-size: ${props => props.theme.fontSizes[1]};
  }
`

const TeamDetails = ({
  anyGamesReported,
  scoreColor,
  nameColor,
  score,
  name,
  wins,
  id,
  losses,
  memberCount,
  isBye,
  checkedIn
}: ITeamDetailsProps) => {

  return (
    <Box>
      {anyGamesReported ? <ScoreText color={scoreColor}>{score}</ScoreText> : null}

      {(name && id) || (!name && isBye) ? (
        <Fragment>
          {/*
          // @ts-ignore */}
          <Link to={`${id ? paths.teamDetail(id) : '#'}`}>
            <Text color={nameColor} mb={3}>
              <TeamName>
                {name === null && isBye ? 'BYE' : name && name} {name && `(${wins}-${losses})`}
              </TeamName>
            </Text>
          </Link>
        </Fragment>
      ) : null}

      {memberCount ? (
        <Text color="white">
          <h5>
            {memberCount} members {checkedIn ? <CheckmarkIcon /> : ''}
            {/* | TODO: CONFERENCE */}
          </h5>
        </Text>
      ) : null}
    </Box>
  )
}

const nameColor = (byeMatch: boolean, name: string | null): 'white' | 'green' =>
  byeMatch && name ? 'green' : 'white'

const scoreColor = (
  teamScore: number,
  otherTeamScore: number,
  settled: boolean,
): 'white' | 'green' | 'red' => (!settled ? 'white' : teamScore > otherTeamScore ? 'green' : 'red')

const MatchDetailHero: React.FunctionComponent<IProps> = ({
  matchId,
  sportId,
  tournamentId,
  tournamentName,
  homeTeamId,
  homeTeamName,
  homeTeamLosses,
  homeTeamWins,
  homeTeamCheckedIn,
  awayTeamLosses,
  awayTeamWins,
  homeTeamMembersCount,
  awayTeamId,
  awayTeamName,
  awayTeamMembersCount,
  awayTeamCheckedIn,
  games,
  settled,
  isBye
}: IProps) => {
  const anyGamesReported = games.some(game => game.winner)

  const homeTeamScore = homeTeamId ? teamScore(homeTeamId, games) : 0
  const awayTeamScore = awayTeamId ? teamScore(awayTeamId, games) : 0
  return (
    <>
      <HeroContainer height="18rem" heroImage={HeroImage} tint={'0,0,0, 0.6'}>
        <Flex justifyContent="space-between" flex="1">
          <Box>
            {tournamentId && (
              <Link to={paths.tournament(tournamentId)}>
                <BackContainer>
                  <ArrowLeft color={Theme.colors.white} size="20" />

                  <Text color="white" ml={3}>
                    <h6>View Bracket</h6>
                  </Text>
                </BackContainer>
              </Link>
            )}
          </Box>
        </Flex>

        <Flex height={['100%', '100%', '100%', 'auto']} pb={[4, 4, 0]}>
          <TeamContainer>
            <TeamDetails
              anyGamesReported={anyGamesReported}
              scoreColor={scoreColor(homeTeamScore, awayTeamScore, settled)}
              nameColor={nameColor(matchIsBye(settled, games), homeTeamName)}
              score={homeTeamScore}
              id={homeTeamId}
              name={homeTeamName}
              wins={homeTeamWins}
              losses={homeTeamLosses}
              memberCount={homeTeamMembersCount}
              isBye={isBye}
              checkedIn={homeTeamCheckedIn}
            />
          </TeamContainer>

          <CenterContainer flex="0 0 auto">
            {tournamentName.toLowerCase().includes('star') ? (
              <LeagueLogoContainer logoPadding="4px 7px 7px">
                <StarLeagueLogoAlt />
              </LeagueLogoContainer>
            ) : (
              <LeagueLogoContainer logoPadding="2px 7px 7px">
                <OpenLeagueLogoAlt />
              </LeagueLogoContainer>
            )}
            <Text color="white">
              <h6>Best of {games.length}</h6>
            </Text>

            <VersusContainer mt={4} mx={'auto'}>
              <Text color="white">
                <VersusText>VS</VersusText>
              </Text>
            </VersusContainer>
          </CenterContainer>

          <Team2Container>
            <TeamDetails
              anyGamesReported={anyGamesReported}
              scoreColor={scoreColor(awayTeamScore, homeTeamScore, settled)}
              nameColor={nameColor(matchIsBye(settled, games), homeTeamName)}
              score={awayTeamScore}
              name={awayTeamName}
              id={awayTeamId}
              wins={awayTeamWins}
              losses={awayTeamLosses}
              memberCount={awayTeamMembersCount}
              isBye={isBye}
              checkedIn={awayTeamCheckedIn}
            />
          </Team2Container>
        </Flex>
      </HeroContainer>
    </>
  )
}

export default MatchDetailHero
