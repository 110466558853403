import { useFormik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Text } from 'rebass'
import { object, string } from 'yup'

import { useUpsertUniversityInfoMutation } from '../../types/graphql'
import { handleResult } from '../../utils/results'
import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import DataFetcher from '../organisms/DataFetcher'

gql`
  mutation UpsertUniversityInfo(
    $id: ID
    $name: String!
    $abbreviation: String
    $state: String
    $domains: [String!]
  ) {
    upsertUniversity(
      id: $id
      name: $name
      abbreviation: $abbreviation
      state: $state
      domains: $domains
    ) {
      success
      value {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

interface IProps {
  name?: string
  universityId?: string
  abbreviation?: string
  state?: string
  domains?: string
}

const AdminUniversityInfo: React.FC<IProps> = ({
  name = '',
  universityId,
  abbreviation = '',
  state = '',
  domains = '',
}) => {
  const history = useHistory()

  const [updateBasicInfo, { loading: updateLoading }] = useUpsertUniversityInfoMutation({
    onError: (error) => {
      toast.error(error.message, { containerId: 'temporary' })
    },
    onCompleted: (data) => {
      handleResult({
        result: data.upsertUniversity,
        onSuccess: (value) => {
          history.push(paths.manageUniversity(value.id))
          toast.success('Your university has been saved', { containerId: 'temporary' })
        },
        onFailure: (errors) => {
          Object.keys(errors).forEach((errorKey) => {
            toast.error(errors[errorKey], { containerId: 'temporary' })
          })
        },
      })
    },
  })

  const formik = useFormik({
    initialValues: { name, abbreviation, state, domains },
    validationSchema: object().shape({
      name: string().required(),
      abbreviation: string(),
      state: string(),
      domains: string(),
    }),
    onSubmit: (values) => {
      debugger
      updateBasicInfo({
        variables: {
          name: values['name'],
          abbreviation: values['abbreviation'],
          state: values['state'],
          domains: values['domains'].replace(/\s+/g, '').split(','),
          id: universityId,
        },
      })
    },
  })

  // const getDomainFromIndex = (idx: number) => {
  //   debugger
  //   return _domains[idx]
  // }

  // const changeDomain = (index: number, value: string) => {
  //   let tempDomains = _domains
  //   tempDomains[index] = value
  //   setDomains(tempDomains)
  // }

  return (
    <DataFetcher loading={updateLoading}>
      <Box>
        <Text mb={3}>
          <h3>University Details</h3>
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <Box bg="white" p={7}>
            <Box width={'70%'} minWidth={'10rem'}>
              <InputFieldWithErrors
                mb={5}
                type="text"
                name="name"
                label="University name"
                placeholder="University name"
                value={formik.values.name}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.name}
              />
              <InputFieldWithErrors
                mb={5}
                type="text"
                name="abbreviation"
                label="Abbreviation"
                placeholder="Abbreviation"
                value={formik.values.abbreviation}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.abbreviation}
              />
              <InputFieldWithErrors
                type="text"
                name="state"
                label="state"
                placeholder="state"
                value={formik.values.state}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.state}
              />
            </Box>

            <Box width={'70%'} minWidth={'10rem'} mt={'20px'}>
              <h4>Email Domains</h4>
              <p>Seperated by , and don't include the @</p>
              {/* {React.Children.toArray(
                _domains.map((domain, idx) => (
                  <InputFieldWithErrors
                    id={domain}
                    type="text"
                    name="domains"
                    label={'Domain #' + (idx + 1)}
                    placeholder="domain.com"
                    value={getDomainFromIndex(idx)}
                    customOnChange={(e) => changeDomain(idx, e.target.value)}
                    // errorMessage={}
                    mt={'20px'}
                  />
                )),
              )} */}
              <InputFieldWithErrors
                type="text"
                name="domains"
                label="domains"
                placeholder="domains"
                value={formik.values.domains}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.domains}
              />
            </Box>
          </Box>

          <BaseButton variant="primary" mt={4} type="submit">
            Save University
          </BaseButton>
        </form>
      </Box>
    </DataFetcher>
  )
}

export default AdminUniversityInfo
