import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'

import { Anchors } from './enums'
import { handleResult } from '../../../utils/results'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import {
  allFieldsMutationArguments,
  allFieldsMutationVariables,
} from '../../../utils/gamingProfilesUtils'
import GamingProfiles from '../../molecules/GamingProfiles/GamingProfiles'

const VERIFY_NEW_USER = gql(String.raw`
  mutation verifyNewUserWithGames(
    $name: String!
    $email: String!
    $universityId: ID!
    $universityMajorId: ID
    $graduationYear: Int!
    $discordUsername: String!
    ${allFieldsMutationArguments}
  ) {
    verifyNewUser(
      name: $name
      email: $email
      universityId: $universityId
      universityMajorId: $universityMajorId
      graduationYear: $graduationYear
      discordUsername: $discordUsername
      ${allFieldsMutationVariables}
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`)

interface IAddGamingProfilesProps {
  onFormSubmitted: () => void
  changeAnchor: (anchor: string) => void
}

const AddGamingProfiles: React.FC<IAddGamingProfilesProps> = ({
  onFormSubmitted,
  changeAnchor,
}) => {
  const {
    setCurrentUserContextLoading,
    setCurrentUserFormErrors,
    sportClickHandler,
    fullName,
    email,
    universityId,
    universityMajorId,
    graduationYear,
    discordUsername,
    gender,
    sportFields,
    selectedSports,
  } = useContext(CurrentUserContext)

  const [verifyNewUser, { loading }] = useMutation(VERIFY_NEW_USER, {
    variables: {
      name: fullName,
      email,
      universityId,
      universityMajorId,
      graduationYear: Number(graduationYear),
      discordUsername,
      gender,
      ...sportFields,
    },
    onError: error => {
      console.log(error)
    },
    onCompleted: data => {
      handleResult({
        result: data.verifyNewUser,
        onSuccess: () => {
          setCurrentUserContextLoading(false)
          onFormSubmitted()
        },
        onFailure: errors => {
          setCurrentUserContextLoading(false)
          setCurrentUserFormErrors(errors)
        },
      })
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (isEmpty(selectedSports)) {
      changeAnchor(Anchors.AddGames)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSports])

  return (
    <GamingProfiles
      headerText="Your Gaming Profiles"
      subheaderText="Add in your gamer id/tags for the previously selected games so your teammates can easily find you."
      submitButtonText="add gaming profiles"
      onFormSubmitted={verifyNewUser}
      removeSport={sportClickHandler}
      sports={selectedSports}
    />
  )
}

export default AddGamingProfiles
