import React from 'react'
import { Link } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { Box, Flex, FlexProps, BoxProps, Link as ExternalLink } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import TwitchLogo from '../../assets/images/sponsor_logos/sponsor__twitch--white.png'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import CopyrightBar from '../atoms/CopyrightBar'
import FooterLogo from '../atoms/FooterLogo'
import FooterSocialIcons from '../molecules/FooterSocialIcons'
import ModalContainer from '../atoms/ModalContainer'
import Video from '../atoms/Video'
import WideContent from '../atoms/WideContent'

const StyledFlex = styled(Flex)<FlexProps>`
  height: auto;
  display: block;
  z-index: 9;
  max-width: 1600px;
  margin: auto;
  position: relative;
  a p {
    color: ${props => props.theme.colors.white};
    transition: ${props => props.theme.transitions.fast};

    &:hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }

  ${Mq.md} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: top;
    padding: 4rem;
    color: ${props => props.theme.colors.white};

    .active {
      color: ${props => props.theme.colors.primaryblue};
    }
  }
`

const StyledWideContent = styled(WideContent)`
  display: flex;
  background: linear-gradient(180deg, #033359 0%, #011a40 100%);

  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    0% 100%,
    0% 0%
  );
`

const StyledSponsors = styled(Box)<BoxProps>`
  display: flex;
`

const StyledUl = styled.ul`
  li {
    color: ${props => props.theme.colors.white};
    margin-bottom: 0.5rem;

    a {
      color: ${props => props.theme.colors.white};
      transition: ${props => props.theme.transitions.fast};

      &:hover {
        color: ${props => props.theme.colors.primarylightblue};
      }
    }
  }
  li:first-child {
    margin-bottom: 1.75rem;
  }

  h5 {
    margin-top: 2rem;
  }
  ${Mq.md} {
    h5 {
      margin-top: 0px;
    }
  }
`
//TODO: add links to li elements
const Footer: React.FC = () => {
  const [showVideoModal, hideVideoModal] = useModal(() => (
    <ModalContainer hideModal={hideVideoModal} noPadding>
      <Video />
    </ModalContainer>
  ))

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <StyledWideContent>
        <StyledFlex py={[8, 8, 4, 4]} px={[6, 6, 0, 0]} justifyContent="space-between">
          <Box mr={4}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Box width={'8rem'} mb={6}>
                <FooterLogo variant="light" />
              </Box>
            </Animation>
          </Box>
          <Box mr={4}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              {/* TODO: Add real links to the footer once determined what we will be using, add links using routes not slash */}
              <StyledUl>
                <li>
                  <h5>Resources</h5>
                </li>
                <li>
                  <Link to={paths.newsAndAnnouncements()}>
                    <p>News & Announcements</p>
                  </Link>
                </li>
                <li>
                  <ExternalLink
                    href="https://www.indeedjobs.com/csl-esports"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Careers</p>
                  </ExternalLink>
                </li>
                <li>
                  <Link to="#" onClick={() => showVideoModal()}>
                    <p>About us</p>
                  </Link>
                </li>
              </StyledUl>
            </Animation>
          </Box>
          <Box mr={4}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              {/* TODO: Add real links to the footer once determined what we will be using, add links using routes not slash */}
              <StyledUl>
                <li>
                  <h5>Help</h5>
                </li>
                <li>
                  <Link to={paths.contact()}>
                    <p>Contact Us</p>
                  </Link>
                </li>
                <li>
                  <Link to={paths.support()}>
                    <p>Support</p>
                  </Link>
                </li>
                <li>
                  <Link to={paths.rules('csgo')}>
                    <p>Rules</p>
                  </Link>
                </li>
              </StyledUl>
            </Animation>
          </Box>
          <Box width={1 / 3}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
            >
              <StyledUl>
                <li>
                  <h5>get connected</h5>
                </li>
                <li>
                  <p>CSL is powered by you and our amazing sponsors.</p>
                </li>
              </StyledUl>
              <StyledSponsors mx={-4} mt={6}>
                <Box px={4}>
                  <ExternalLink
                    href="https://www.twitch.tv/p/students/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TwitchLogo} alt="Twitch Logo" width="80px" height="auto" />
                  </ExternalLink>
                </Box>
              </StyledSponsors>
            </Animation>
          </Box>

          <FooterSocialIcons position="absolute" />
        </StyledFlex>
      </StyledWideContent>
      <CopyrightBar />
    </>
  )
}
export default Footer
