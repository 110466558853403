import React from 'react'

import { paths } from '../../../utils/Routes'
import { SiteSwitch } from '../../../utils/sites'
import NavList from './NavList'

interface IMainMenuProps {
  flexDirection?: string
}

const MainMenu: React.FC<IMainMenuProps> = ({ flexDirection }) => {
  const collegeListItems = [
    { path: paths.leagues() },
    { path: paths.tournaments() },
    { path: paths.universities() },
    { path: paths.rules('lol'), label: 'rules' },
    { path: paths.newsAndAnnouncements() },
  ]
  const esportsListItems = [
    { path: paths.tournaments() },
    { path: paths.aboutUs(), label: 'about us' },
    { path: paths.rules('lol'), label: 'rules' },
    { path: paths.newsAndAnnouncements() },
  ]
  return (
    <SiteSwitch
      college={<NavList listItems={collegeListItems} flexDirection={flexDirection || 'row'} />}
      esports={
        <NavList listItems={esportsListItems} flexDirection={flexDirection || 'row'} mainMenu />
      }
    />
  )
}

export default MainMenu
