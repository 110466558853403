import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'

import { Anchors } from './enums'
import { handleResult } from '../../../utils/results'
import { updateUserAuth } from '../../../utils/formUtils'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import {
  allFieldsMutationArguments,
  allFieldsMutationVariables,
} from '../../../utils/gamingProfilesUtils'
import UserSubscription from '../../molecules/UserSubscription'
import {
  UserForAuth,
  useRescindPendingInviteMutation as useRescindPendingInvite,
  useCreateRosterEntryMutation as createRosterEntryMutation,
} from '../../../types/graphql'

const CREATE_NEW_USER = gql(String.raw`
  mutation createNewUser(
    $name: String!
    $email: String!
    $universityId: ID!
    $universityMajorId: ID
    $graduationYear: Int!
    $discordUsername: String!
    $gender: String
    ${allFieldsMutationArguments}
  ) {
    createNewUser(
      name: $name
      email: $email
      universityId: $universityId
      universityMajorId: $universityMajorId
      graduationYear: $graduationYear
      discordUsername: $discordUsername
      gender: $gender
      ${allFieldsMutationVariables}
    ) {
      success
      value {
        accessToken
        username
        client
        expiry
        uid
        id
        isIncompleteAccount
      }
      errors {
        field
        message
      }
    }
  }
`)

interface IFinalizedUserProps {
  onFormSubmitted: () => void
  changeAnchor: (anchor: string) => void
}

const FinalizedUser: React.FC<IFinalizedUserProps> = ({ onFormSubmitted, changeAnchor }) => {
  const {
    setCurrentUserContextLoading,
    setPaidForPremium,
    fullName,
    email,
    universityId,
    universityMajorId,
    graduationYear,
    discordUsername,
    gender,
    sportFields,
    acceptedInvitations,
    declinedInvitations,
    paidForPremium,
  } = useContext(CurrentUserContext)

  const [removePendingInvite, { loading: removePendingInviteLoading }] = useRescindPendingInvite({
    onError(error) {
      console.log(error)
    },
    onCompleted() {
      onFormSubmitted()
    },
  })

  const [createRosterEntry, { loading: createRosterEntryLoading }] = createRosterEntryMutation({
    onError(error) {
      console.log(error)
    },
    onCompleted() {
      onFormSubmitted()
    },
  })

  const [createNewUser, { loading: createNewUserLoading }] = useMutation(CREATE_NEW_USER, {
    variables: {
      name: fullName,
      email,
      universityId,
      universityMajorId,
      graduationYear: Number(graduationYear),
      discordUsername,
      gender,
      paidForPremium,
      ...sportFields,
    },
    onError: error => {
      console.log(error)
    },
    onCompleted: data => {
      handleResult({
        result: data.createNewUser,
        onSuccess: value => {
          updateUserAuth(value as UserForAuth)
          if (acceptedInvitations.length || declinedInvitations.length) {
            acceptedInvitations.forEach(invitation =>
              createRosterEntry({
                variables: {
                  teamPlayerInviteId: invitation.id,
                },
              }),
            )
            declinedInvitations.forEach(invitation =>
              removePendingInvite({
                variables: {
                  id: invitation.id,
                },
              }),
            )
          } else {
            onFormSubmitted()
          }
          setCurrentUserContextLoading(false)
        },
        onFailure: errors => {
          setCurrentUserContextLoading(false)
          console.log(errors)
        },
      })
    },
  })

  const loading = removePendingInviteLoading || createRosterEntryLoading || createNewUserLoading

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (isEmpty(sportFields)) {
    changeAnchor(Anchors.GamingProfiles)
  }

  return (
    <UserSubscription
      onSelectAccountType={accountType => {
        setPaidForPremium(!!accountType)
        createNewUser()
      }}
    />
  )
}

export default FinalizedUser
