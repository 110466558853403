import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from 'rebass'

import { useGetTopUniversitiesQuery } from '../../types/graphql'
import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'
import Rank from '../atoms/Rank'
import Table from '../atoms/Table'
import DataFetcher from './DataFetcher'
import HomeRankingsAlert from './HomeRankingsAlert'

gql`
  query getTopUniversities {
    topUniversities {
      rank
      university {
        id
        name
        state
      }
    }
  }
`

const RankedUniversities: React.FC = () => {
  const { colors } = useTheme()

  const {
    data: topUniversitiesData,
    loading: topUniversitiesLoading,
    error: topUniversitiesError,
  } = useGetTopUniversitiesQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <DataFetcher loading={topUniversitiesLoading} error={topUniversitiesError}>
      {topUniversitiesData &&
        (topUniversitiesData.topUniversities.length === 0 ? (
          <HomeRankingsAlert
            title="Season Has Not Begun"
            description="You have just enough time to join before the 2020-2021 season starts. Sign up now, and
                    find your team today."
            ctaText="Create account"
            ctaLink={paths.signup()}
          />
        ) : (
          <>
            <Table headerBackgroundColor="darkgray" lastRowBorder={true}>
              <thead>
                <tr>
                  <th>
                    <Text color={colors.white}>
                      <h6>Rank</h6>
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.white}>
                      <h6>University</h6>
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.white}>
                      <h6>State</h6>
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {topUniversitiesData.topUniversities.map(topUniversity => (
                  <tr key={topUniversity.university.id}>
                    <td>
                      <Rank rank={topUniversity.rank} textColor={colors.darkgray} />
                    </td>
                    <td>
                      <Link to={paths.university(topUniversity.university.id)}>
                        <h6>{topUniversity.university.name}</h6>
                      </Link>
                    </td>
                    <td>
                      <Text color={colors.darkgray}>
                        <h6>{topUniversity.university.state || '-'}</h6>
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Flex justifyContent="center" py={6}>
              <Link to={paths.universities()}>
                <BaseButton variant="secondary">View All Universities</BaseButton>
              </Link>
            </Flex>
          </>
        ))}
    </DataFetcher>
  )
}

export default RankedUniversities
