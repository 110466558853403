import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const MaddenRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="Madden NFL 21" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        CSL Madden NFL 21 2020-2021 Season Rulebook: Coming Soon
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY Madden NFL 21 RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #madden-help chat.
        </h3>
      </Text>
    </>
  )
}

export default MaddenRules
