import gql from 'graphql-tag'
import React from 'react'
import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import {
  MatchCarouselMatchInfoFragment as Match,
  MatchCarouselTournamentCheckinInfoFragment as Tournament,
} from '../../types/graphql'
import Carousel from '../atoms/Carousel'
import { MatchCard } from './MatchCarouselPieces/MatchCard'
import { TournamentCard } from './MatchCarouselPieces/TournamentCard'

interface ICarouselProps {
  children?: any
  className?: any
  onClick?: any
}

gql`
  fragment MatchCarouselMatchInfo on Match {
    id
    sport
    scheduledDate
    rescheduleDate
    currentUserCheckedIn
    checkinAvailable
    currentUserIsTeamMember
    currentUserCanReport
    homeTeamCheckedIn
    awayTeamCheckedIn
    homeTeam {
      ...MatchCarouselTeamInfo
    }
    awayTeam {
      ...MatchCarouselTeamInfo
    }
  }
`
gql`
  fragment MatchCarouselTournamentCheckinInfo on Tournament {
    id
    sport
    firstMatchDate
    name
    currentUserCheckedIn
    currentUserCanCheckIn
  }
`
gql`
  fragment MatchCarouselTeamInfo on Team {
    id
    name
    currentUserOnTeam
  }
`

interface IMatchCarouselProps {
  matches: Match[]
  tournaments: Tournament[]
}

const StyledBox = styled(Box)<BoxProps>`
  width: auto;
  margin-left: -8px;
  margin-right: -8px;
`

const MatchCarousel: React.FC<IMatchCarouselProps> = ({ matches, tournaments }) => {
  return (
    <StyledBox>
      <Carousel>
        {tournaments &&
          tournaments.map(tournament => (
            <TournamentCard
              data-index={tournament.id}
              key={tournament.id}
              tournament={tournament}
            />
          ))}
        {matches &&
          matches.map(match => <MatchCard data-index={match.id} key={match.id} match={match} />)}
      </Carousel>
    </StyledBox>
  )
}

export default MatchCarousel
