import { useTheme } from 'emotion-theming'
import React from 'react'
import { GiCrossedSwords } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import { Flex, Text } from 'rebass'

import Alert from '../atoms/Alert'
import { BaseButton } from '../atoms/Buttons'

interface IProps {
  title: string
  description: string
  ctaLink: string
  ctaText: string
}

const HomeRankingsAlert: React.FC<IProps> = ({ title, description, ctaLink, ctaText }) => {
  const { colors } = useTheme()

  return (
    <Alert py={6} px={[3, 3, 8, 8]}>
      <GiCrossedSwords size={38} color={colors.sand} />
      <Text my={5} color="darkgray">
        <h6>{title}</h6>
      </Text>
      <Text mb={5} textAlign="center">
        <p>{description}</p>
      </Text>

      {ctaLink && ctaText && (
        <Flex flexDirection="column" alignItems="center">
          <Link to={ctaLink}>
            <BaseButton variant="secondary" mx="auto">
              {ctaText}
            </BaseButton>
          </Link>
        </Flex>
      )}
    </Alert>
  )
}

export default HomeRankingsAlert
