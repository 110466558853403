import { css } from '@emotion/core'

export const fonts = css`
  @font-face {
    font-family: 'Campton Book';
    src: url('/fonts/Campton-Book.woff2') format('woff2'),
      url('/fonts/Campton-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'DrukWide';
    src: url('/fonts/DrukWide-Bold.otf');
    font-weight: bold;
  }

  @font-face {
    font-family: 'DrukWide';
    src: url('/fonts/DrukWide-Medium.otf');
    font-weight: 500;
  }
  @font-face {
    font-family: 'DrukText';
    src: url('/fonts/DrukText-Bold.otf');
    font-weight: bold;
  }

  @font-face {
    font-family: 'DrukText';
    src: url('/fonts/DrukText-Medium.otf');
    font-weight: 500;
  }
`
