import { Dictionary } from 'lodash'
import { darken } from 'polished'
import React, { ReactNode } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Box, BoxProps, Text, TextProps } from 'rebass'

import { styled } from '../../styles/settings/theme'
import { SetState } from '../../types/aliases'
import {
  validateDiscord,
  validateEmail,
  validateName,
  validatePasswordConfirmation,
} from '../../utils/accountUtils'
import { spaceify } from '../../utils/strings'
import { CustomInput, FieldLabel, TypeaheadWrapper } from '../atoms/FormPieces'
import { CustomSelect } from '../atoms/Select'

export interface IOptionType {
  id: string
  label: string
  name?: string
}

export enum FieldTypes {
  Text = 'text',
  Typeahead = 'typeahead',
  Select = 'select',
}

interface ITypeaheadProps {
  selected: IOptionType[]
  options: IOptionType[]
}

interface IInputFieldWithErrorsProps extends BoxProps {
  type: string
  errorMessage?: string
  label: string
  placeholder: string
  name: string
  updateField?: (field: Dictionary<string>) => void
  value?: string | number
  children?: ReactNode
  maxLength?: number
  placeholderActive?: boolean
  fieldSubText?: string | React.ReactNode
  disabled?: boolean
  isRequired?: boolean
  updateErrors?: (errors: { [name: string]: string }) => void
  isEmailField?: boolean
  isDiscordField?: boolean
  isNameField?: boolean
  otherPassword?: string
  typeaheadProps?: ITypeaheadProps
  updateSingleField?: (field: string) => void | SetState<string>
  updateTypeaheadField?: (selected: IOptionType[]) => void
  min?: number
  customOnChange?: (e: React.ChangeEvent<any>) => void
}

interface IInputBoxProps extends BoxProps {
  error?: boolean
}

interface IFieldSubTextProps {
  error: boolean
}

export const InputBox = styled(Box)<IInputBoxProps>`
  position: relative;

  input,
  select {
    border-color: ${props =>
      props.error ? props.theme.colors.red : props.theme.colors.darkmiddlegray};
    outline-color: ${props => (props.error ? props.theme.colors.red : 'initial')};

    &:focus {
      border-color: ${props =>
        props.error ? props.theme.colors.red : props.theme.colors.darkgray};
      outline: none;
    }
  }
`

export const ErrorMessage = styled(Text)<TextProps>`
  position: absolute;
  left: 2px;
  color: ${props => props.theme.colors.red};
`

export const FieldSubText = styled(Text)<IFieldSubTextProps>`
  font-size: 10px;
  color: ${props => (props.error ? 'red' : darken(0.3, props.theme.colors.darkmiddlegray))};
  position: absolute;
  font-family: ${props => props.theme.fonts.body};
  right: 0;
  bottom: -1rem;
  text-transform: lowercase;

  ::first-letter {
    text-transform: uppercase;
  }
`

export const InputFieldWithErrors: React.FC<IInputFieldWithErrorsProps> = ({
  type,
  errorMessage,
  label,
  placeholder,
  placeholderActive,
  name,
  value,
  updateField,
  children,
  maxLength,
  fieldSubText,
  disabled,
  isRequired,
  updateErrors,
  isEmailField,
  isDiscordField,
  otherPassword,
  typeaheadProps,
  updateSingleField,
  updateTypeaheadField,
  isNameField,
  min,
  customOnChange,
  ...boxProps
}) => {
  const onChange =
    customOnChange ||
    ((event: React.SyntheticEvent) => {
      const { value } = event.target as HTMLInputElement

      if (errorMessage && updateErrors) {
        updateErrors({
          [name]: '',
        })
      }

      updateField && updateField({ [name]: value })
      updateSingleField && updateSingleField(value)
      onBlur(event)
    })

  const onBlur = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement
    if (isRequired && !value && updateErrors) {
      updateErrors({
        [name]: `${label} is required.`,
      })
    }

    if (value && isEmailField && updateErrors && !validateEmail(value)) {
      updateErrors({
        [name]: 'Not a valid email address.',
      })
    }
    if (value && isDiscordField && updateErrors && !validateDiscord(value)) {
      updateErrors({
        [name]: 'Not a valid Discord Username.',
      })
    }
    if (value && isNameField && updateErrors && !validateName(value)) {
      updateErrors({
        [name]: `${label} format is invalid`,
      })
    }

    if (
      value &&
      otherPassword &&
      updateErrors &&
      !validatePasswordConfirmation(value, otherPassword)
    ) {
      updateErrors({
        [name]: 'Passwords do not match.',
      })
    }
  }

  const onTypeaheadBlur = (event: Event) => {
    const inputValue = (event.target as HTMLInputElement).value
    if (updateErrors) {
      if (isRequired && !inputValue) {
        updateErrors({
          [name]: `${label} is required.`,
        })
      } else if (inputValue && !value) {
        updateErrors({
          [name]: `Please select a valid ${label} from the list.`,
        })
      }
    }
  }

  const onTypeaheadChange = (selected: IOptionType[]) => {
    if (updateErrors && errorMessage) {
      updateErrors({
        [name]: '',
      })
    }

    updateTypeaheadField && updateTypeaheadField(selected)
  }

  const showLabel = errorMessage || !!value || label !== placeholder

  return (
    <InputBox
      error={!!errorMessage}
      mb={boxProps.mb}
      ml={boxProps.ml}
      mt={boxProps.mt}
      width={boxProps.width}
    >
      {type === FieldTypes.Select ? (
        <CustomSelect
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholderActive={value === ''}
          disabled={disabled}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {children}
        </CustomSelect>
      ) : type === FieldTypes.Typeahead && typeaheadProps ? (
        <TypeaheadWrapper disabled={disabled}>
          <Typeahead
            id={name}
            placeholder={placeholder}
            inputProps={{
              name,
            }}
            onChange={onTypeaheadChange}
            onBlur={onTypeaheadBlur}
            selected={typeaheadProps.selected}
            options={typeaheadProps.options}
          />
        </TypeaheadWrapper>
      ) : (
        <CustomInput
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disabled}
          min={min}
        />
      )}

      {showLabel && <FieldLabel disabled={disabled} label={label} color={errorMessage && 'red'} />}
      {(fieldSubText || errorMessage) && (
        <FieldSubText error={!!errorMessage}>
          {(errorMessage && spaceify(errorMessage)) || fieldSubText}
        </FieldSubText>
      )}
    </InputBox>
  )
}
