import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const Dota2Rules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="Dota 2" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        CSL Dota 2 2020-2021 Season Rulebook: <a href="https://drive.google.com/file/d/1i411eCBhgqYro9HiO8gkF2mixh_A_cwb/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          CSL-DOTA2-2020-2021-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY DOTA2 RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #dota2-help chat.
        </h3>
      </Text>
    </>
  )
}

export default Dota2Rules
