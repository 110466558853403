import gql from 'graphql-tag'
import React, { useContext, useEffect, ReactNode } from 'react'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { useGetInvitedUserQuery as getInvitedUser } from '../../../types/graphql'
import { CurrentUserUniversity, CurrentUserPendingInvitations } from '../../../types/fragments'

import DataFetcher from '../../organisms/DataFetcher'

gql`
  query getInvitedUser {
    invitedUser {
      email
      university {
        ...CurrentUser_University
      }
      pendingTeamInvitations {
        ...CurrentUser_PendingInvitations
      }
    }
  }
  ${CurrentUserUniversity}
  ${CurrentUserPendingInvitations}
`

interface IProps {
  children?: ReactNode
}

const InvitedUserQueryProvider: React.FC<IProps> = ({ children }) => {
  const {
    currentUserContextLoading,
    setIsInvitedUser,
    setEmail,
    setUniversity,
    setUniversityId,
    setPendingInvitations,
    setCurrentUserContextLoading,
  } = useContext(CurrentUserContext)
  const { error, loading } = getInvitedUser({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data.invitedUser) {
        setIsInvitedUser(true)
        setEmail(data.invitedUser.email)
        if (data.invitedUser.university) {
          setUniversity(data.invitedUser.university)
          setUniversityId(data.invitedUser.university.id)
        }
        data.invitedUser.pendingTeamInvitations &&
          setPendingInvitations(data.invitedUser.pendingTeamInvitations)
      }
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <DataFetcher loading={currentUserContextLoading} error={error}>
      {children}
    </DataFetcher>
  )
}

export default InvitedUserQueryProvider
