import React from 'react'
import dayjs from 'dayjs'
import { Text, Flex } from 'rebass'
import {
  CenterBracketContent,
  SideBox,
  CenterBox,
  TopRow,
  VS,
  Thirds,
  Gray,
  Boxes,
  Dash,
  SideBoxLeftJoiner,
  SideBoxRightJoiner,
  SideContainer,
  Circle,
} from '../atoms/CenterBracketPieces'
import BracketText from '../atoms/BracketText'
import {
  useTeamIdFromSeat,
  useMatchSettled,
  useMatchScheduledDate,
} from '../../utils/bracketDataUtils'

interface CenterBracketProps {
  lower?: boolean
  numTeams: number
  isDoubleElim?: boolean
  seats?: Array<number | null>
  winnerFinals: number
  grandFinals: number
  loserSemis: number
  loserFinals: number
}
interface CenterBracketContentProps {
  lower?: boolean
  numTeams?: number
  isDoubleElim?: boolean
}

const CenterBracket: React.FunctionComponent<CenterBracketProps> = ({
  lower,
  isDoubleElim,
  numTeams,
  winnerFinals,
  grandFinals,
  loserSemis,
  loserFinals,
}) => {
  const team1 = useTeamIdFromSeat(lower ? loserFinals : grandFinals, true)
  const team2 = useTeamIdFromSeat(lower ? loserFinals : grandFinals, false)

  const scheduledDate = useMatchScheduledDate(lower ? loserFinals : grandFinals)
  const remainingHours = dayjs(scheduledDate).diff(dayjs(), 'hour')
  const settled = useMatchSettled(lower ? loserFinals : grandFinals)

  let status = settled
    ? 'completed'
    : team1 && team2 && remainingHours > 0
    ? remainingHours > 24
      ? 'upcoming'
      : 'pending'
    : 'tbd'

  return (
    <CenterBracketContent lower={lower} numTeams={numTeams} isDoubleElim={isDoubleElim}>
      <Gray>
        <Dash lower={lower} />
      </Gray>
      <Boxes lower={lower}>
        <SideContainer flexDirection="column" mr={lower ? '6%' : '3%'}>
          {!lower && <SideBoxLeftJoiner numTeams={numTeams} isDoubleElim={isDoubleElim} />}
          <SideBox lower={lower} side="left" isDoubleElim={isDoubleElim}>
            <Flex flexDirection="column" width="100%" justifyContent="space-between">
              <TopRow height={'1.6rem'}>
                <Flex pb={1}>
                  <Text color="darkmiddlegray" mr={2}></Text>
                  <Text color="black">
                    <h6>{isDoubleElim ? 'winners finals' : 'semifinals'}</h6>
                  </Text>
                </Flex>
              </TopRow>
              <BracketText seat={winnerFinals} center={true} homeTeam={true} />
              <BracketText seat={winnerFinals} center={true} />
            </Flex>
          </SideBox>
          {!lower && (
            <SideBoxLeftJoiner bottomHalf={true} isDoubleElim={isDoubleElim} numTeams={numTeams} />
          )}
        </SideContainer>

        <CenterBox flexDirection="column">
          <TopRow
            height={'2.15rem'}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Flex pb={2}>
              <Text color="darkmiddlegray" mr={2}>
                {/* match number */}
              </Text>
              <Text color="black">
                <h4>{lower ? 'losers finals' : isDoubleElim ? 'grand finals' : 'finals'}</h4>
              </Text>
            </Flex>
            {!lower && (
              <Flex pb={2}>
                <Circle bg={status === 'completed' ? 'green' : 'yellow'} mt={'1px'} mr={2} />
                <Text color="darkgray">
                  {status === 'completed' ? (
                    <h6>match completed</h6>
                  ) : status === 'upcoming' ? (
                    <h6>match upcoming</h6>
                  ) : (
                    <h6>match upcoming</h6>
                  )}
                </Text>
              </Flex>
            )}
          </TopRow>

          <Thirds>
            <BracketText
              seat={lower ? loserFinals : grandFinals}
              center={true}
              homeTeam={true}
              finals={true}
              precedingSeat={winnerFinals}
            />

            <Flex justifyContent="center" alignItems="center">
              <VS justifyContent="center" alignItems="center" mt={1} mb={1}>
                <Text color="orange" ml={'1px'} mt={'2px'}>
                  <h6>VS</h6>
                </Text>
              </VS>
            </Flex>

            <BracketText
              seat={lower ? loserFinals : grandFinals}
              center={true}
              finals={true}
              alignRight={true}
            />
          </Thirds>
        </CenterBox>
        <SideContainer flexDirection="column" ml={lower ? '6%' : '3%'} justifyContent="flex-end">
          <SideBox lower={lower} side="right" isDoubleElim={isDoubleElim}>
            <Flex flexDirection="column" width="100%" justifyContent="space-between">
              <TopRow height={'1.6rem'}>
                <Flex pb={1}>
                  <Text color="darkmiddlegray" mr={2}></Text>
                  <Text color="black">
                    <h6>
                      {isDoubleElim
                        ? lower
                          ? 'losers semi finals'
                          : 'losers finals'
                        : 'semifinals'}
                    </h6>
                  </Text>
                </Flex>
              </TopRow>

              <BracketText
                seat={lower ? loserSemis : loserFinals}
                center={true}
                homeTeam={true}
              ></BracketText>

              <BracketText seat={lower ? loserSemis : loserFinals} center={true}></BracketText>
            </Flex>
          </SideBox>
          {<SideBoxRightJoiner isDoubleElim={isDoubleElim} numTeams={numTeams} />}
        </SideContainer>
      </Boxes>
    </CenterBracketContent>
  )
}

export default CenterBracket
