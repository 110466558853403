import { useTheme } from 'emotion-theming'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React, { useLayoutEffect, useRef, useState } from 'react'
import Particles from 'react-particles-js'
import { Link } from 'react-router-dom'
import { Box, Flex, FlexProps, Text } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import Card from '../atoms/Card'
import csgo from '../../assets/images/sportAvatar__csgo.jpg'
import dota2 from '../../assets/images/sportAvatar__dota2.jpg'
import fortnite from '../../assets/images/sportAvatar__fortnite.jpg'
import gears from '../../assets/images/sportAvatar__gears.jpg'
import LayerOne from '../../assets/images/background__platform--1.png'
import LayerThree from '../../assets/images/background__platform--3.png'
import lol from '../../assets/images/sportAvatar__lol.jpg'
import madden from '../../assets/images/sportAvatar__madden.jpg'
import nba2k from '../../assets/images/sportAvatar__nba2k.jpg'
import overwatch from '../../assets/images/sportAvatar__overwatch.jpg'
import rl from '../../assets/images/sportAvatar__rl.jpg'
import sfv from '../../assets/images/sportAvatar__sfv.jpg'
import smash from '../../assets/images/sportAvatar__smash.jpg'
import valorant from '../../assets/images/sportAvatar__valorant.jpg'

interface IProps {
  image?: string
  width?: string
  height?: string
  transition?: string
}

const GamePic = styled(Card)<IProps>`
  width: 100%;
  height: 18rem;
  padding-top: 14rem;
  background-image: ${props => props.theme.gradients.primarynavyFade}, url('${props =>
  props.image}');
  opacity: 1;
  transition: ${props => props.theme.transitions.fast};
  background-size: cover;
  background-position: center;

  ${Mq.md} {
    width: auto;
    height: 19.5rem;
    padding-top: 15rem;
    cursor: pointer;

    H5 {
      transition: ${props => props.theme.transitions.fast};
        }
    &:hover H5 {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }
`

const LargeFlex = styled(Flex)<FlexProps>`
  display: grid;
  grid-column-gap: 0rem;
  grid-template-columns: 1fr 1fr;

  ${Mq.md} {
    display: flex;
  }
`

const SmallGamePic = styled(Card)<IProps>`
width: 9rem;
height: 9rem;
background-image: ${props => props.theme.gradients.primarynavyFade}, url('${props => props.image}');
opacity: 1;
cursor: pointer;
background-size: cover;
background-position: center;

  H5 {
    transition: ${props => props.theme.transitions.fast};
  }
  &:hover H5 {
    color: ${props => props.theme.colors.primarylightblue};
  }

  ${Mq.md} {
    width: 100%;
  }
`

const ScrollContainer = styled(Flex)`
  overflow-x: scroll;
  width: 110%;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${Mq.md} {
    width: auto;
  }
`

const PlatformSection = styled(Box)`
  display: block;
  width: 100%;
  z-index: 8;
  margin: auto;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #ffffff 0%, #c8ebf9 100%);

  h2.cta {
    max-width: 300px;
    margin: auto;
  }
`

const ComponentContainer = styled(Box)`
  display: block;
  padding: 0 0 150px;
  margin: auto;
  background: linear-gradient(180deg, #ffffff 0%, #c8ebf9 100%);

  ${Mq.md} {
    padding: 0 0 300px;
  }
`

const BackgroundLayerOne = styled(Box)<IParallaxImage>`
  display: block;
  background-image: url('${LayerOne}');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 11rem;
  position: absolute;
  bottom: 2rem;
  z-index: 9;

  ${Mq.md} {
    height: 500px;
    background-size: contain;
    bottom: 0;
  }

  @media screen and (min-width: 900px) and (max-width: 1120px) {
    bottom: -10vw;
  }

  @media screen and (min-width: 1450px) {
    background-size: cover;
    bottom: 2rem;
  }
`

const BackgroundLayerThree = styled(Box)<IParallaxImage>`
  display: block;
  background-image: url('${LayerThree}');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 290px;
  position: absolute;
  bottom: 0px;
  z-index: 1;

  ${Mq.md} {
    height: 792px;
    background-size: cover;
  }
`

const GradientOverlay = styled(Box)`
  height: 120px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 58.8%);
  position: absolute;
  bottom: -1px;
  width: 100%;
  z-index: 99;

  ${Mq.md} {
    height: 240px;
  }
`

const RadialOverlay = styled(Box)`
  height: 200px;
  background: radial-gradient(47.04% 47.04% at 50% 68.88%, #ffffff 0%, rgba(200, 235, 249, 0) 100%);
  position: absolute;
  bottom: 90px;
  width: 100%;
  z-index: 3;

  ${Mq.md} {
    height: 320px;
  }
`

const GamesList = styled(Box)`
  padding-top: 3rem;
  width: auto;
  z-index: 7;
  position: relative;
  max-width: 1600px;
  margin: auto;
  padding: 0 1rem;

  ${Mq.md} {
    padding: 0 4rem;
  }
`

const CtaFlex = styled(Flex)`
  position: relative;
  z-index: 99;
`

const StyledParticles = styled(Particles)`
  height: 400px;
  position: absolute;
  bottom: 270px;
  width: 100%;
  z-index: 1;
`

interface IParallaxImage {
  src?: string
  style?: object
}

export const IntersectionContext = React.createContext({ inView: true })

const ParallaxImageTwo: React.FC<IParallaxImage> = ({ src, ...style }) => {
  const [elementTop, setElementTop] = useState(0)
  const ref = useRef<HTMLDivElement | null>(null)
  const { scrollY } = useViewportScroll()

  const y = useTransform(scrollY, [elementTop, elementTop + 10], [-1, 0], {
    clamp: false,
  })

  useLayoutEffect(() => {
    const element = ref.current
    setElementTop(element!.offsetTop)
  }, [ref])

  return (
    <div ref={ref} className="image-container">
      <motion.div className="overlay" style={{ ...style, y }}>
        <BackgroundLayerThree />
      </motion.div>
    </div>
  )
}

const HomePageGames: React.FC<IProps> = ({ transition }) => {
  const { colors } = useTheme()
  const largePics1 = [
    { name: 'cs:go', image: csgo },
    { name: 'dota2', image: dota2 },
  ]
  const largePics2 = [
    { name: 'rocket league', image: rl },
    { name: 'street fighter v', image: sfv },
  ]
  const smallPics = [
    { name: 'league of legends', image: lol },
    { name: 'overwatch', image: overwatch },
    { name: 'fortnite', image: fortnite },
    { name: 'madden', image: madden },
    { name: 'super smash bros', image: smash },
    { name: 'nba 2k', image: nba2k },
    { name: 'valorant', image: valorant },
    { name: 'gears', image: gears },
  ]

  const animations = {
    hidden: {
      opacity: 0,
      y: '-1.5rem',
    },
    hiddenLeft: {
      opacity: 0,
      x: '-1.5rem',
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }

  return (
    <PlatformSection>
      <ComponentContainer>
        <GamesList>
          <Animation
            initial="hiddenLeft"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4 }}
          >
            <Text mb={4}>
              <h2>our games</h2>
            </Text>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <LargeFlex width="auto" mx={[-2]} mb={4}>
              {largePics1.map(game => (
                <Box key={game.name} width={1} className="grid-item" px={2} mb={4}>
                  <Link to={paths.signup()}>
                    <GamePic notch={true} notchSize={16} image={game.image}>
                      <Text textAlign="center" color={colors.white}>
                        <h5>{game.name}</h5>
                      </Text>
                    </GamePic>
                  </Link>
                </Box>
              ))}

              {largePics2.map(game => (
                <Box key={game.name} width={1} px={2} mb={4}>
                  <Link to={paths.signup()}>
                    <GamePic notch={true} notchSize={16} image={game.image}>
                      <Text textAlign="center" color={colors.white}>
                        <h5>{game.name}</h5>
                      </Text>
                    </GamePic>
                  </Link>
                </Box>
              ))}
            </LargeFlex>
          </Animation>

          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <ScrollContainer mx={[0, 0, 0, -2]} mb={4}>
              <Flex width="100%" flex={['1 0 auto', '1 0 auto', 'none']}>
                {smallPics.map((game, i) => (
                  <Box
                    flex={['1 0 auto', '1 0 auto', '1 0 auto', 'auto']}
                    width={['auto', 'auto', 'auto', '100%']}
                    key={i}
                    px={2}
                    mb={4}
                  >
                    <Link to={paths.signup()} target="_top">
                      <SmallGamePic notch={true} notchSize={16} image={game.image}>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          width={['100%', '100%', 'auto']}
                          height="100%"
                        >
                          <Text textAlign="center" color={colors.white}>
                            <h5>{game.name}</h5>
                          </Text>
                        </Flex>
                      </SmallGamePic>
                    </Link>
                  </Box>
                ))}
              </Flex>
            </ScrollContainer>
          </Animation>
        </GamesList>

        <CtaFlex justifyContent="center" my={8}>
          <Flex flexDirection="column" width={[1, 1, 1, 1 / 3]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              <Text textAlign="center" my={2}>
                <h2 className="cta">your new team is calling</h2>
              </Text>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
            >
              <Link to={paths.signup()}>
                <Flex>
                  <BaseButton variant="navy" mx="auto" my={3}>
                    sign up today
                  </BaseButton>
                </Flex>
              </Link>
            </Animation>
          </Flex>
        </CtaFlex>
        <StyledParticles
          params={{
            particles: {
              number: {
                value: 400,
                density: {
                  enable: true,
                },
              },
              size: {
                value: 4,
                random: true,
                anim: {
                  speed: 5,
                  size_min: 0.4,
                },
              },
              line_linked: {
                enable: false,
              },
              move: {
                random: true,
                speed: 2,
                direction: 'top',
                out_mode: 'out',
              },
            },
          }}
        />
      </ComponentContainer>
      <GradientOverlay />

      <BackgroundLayerOne />
      <RadialOverlay />
      <ParallaxImageTwo />
    </PlatformSection>
  )
}

export default HomePageGames
