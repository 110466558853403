import React from 'react'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'
import NewsBrief from '../organisms/NewsBrief'

const StyledWideContent = styled(WideContent)`
  background: ${props => props.theme.colors.black};
  padding: 6rem 0 0rem;
  margin-top: -2rem;

  ${Mq.md} {
    margin-top: -5rem;
    padding: 6rem 0 2rem;
  }
`

const NewsWrapper = styled(WideContent)`
  background: ${props => props.theme.colors.black};
  h2 {
    color: ${props => props.theme.colors.white};
  }

  h6 {
    transition: ${props => props.theme.transitions.fast};

    &:hover {
      color: ${props => props.theme.colors.primarynavy};
    }
  }
  h6.view-all {
    min-width: max-content;
    transition: ${props => props.theme.transitions.fast};

    &:hover {
      color: ${props => props.theme.colors.secondarylightblue};
    }
  }
`

const HomePageNews: React.FC = () => {
  return (
    <StyledWideContent>
      <WideContainer>
        <NewsWrapper>
          <NewsBrief />
        </NewsWrapper>
      </WideContainer>
    </StyledWideContent>
  )
}

export default HomePageNews
