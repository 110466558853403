import React from 'react'
import { Flex, FlexProps, Text } from 'rebass'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import { CheckBox } from '../atoms/FormPieces'
import { useTheme } from 'emotion-theming'

interface IVarsityFieldsProps extends FlexProps {
  varsityProgramUrl: string
  isInVarsityProgram: boolean
  onChangeIsInVarsityProgram: (isInVarsityProgram: boolean) => void
  onChangeProgramUrl: (varsityProgramUrl: string) => void
}

const VarsityFields: React.FC<IVarsityFieldsProps> = ({
  varsityProgramUrl,
  isInVarsityProgram,
  onChangeIsInVarsityProgram,
  onChangeProgramUrl,
  ...flexProps
}) => {
  const { colors } = useTheme()
  return (
    <Flex mt={flexProps.mt} mb={flexProps.mb} justifyContent="space-between">
      <Flex flexDirection="column" width={['100%', '100%', '80%']}>
        <Text mb={5} color={colors.primarynavy}>
          <h5>Are you part of an esports varsity program at your school?</h5>
        </Text>
        <Flex>
          <Flex>
            <CheckBox
              name="isInVarsityProgram"
              type="checkbox"
              checked={isInVarsityProgram}
              onChange={() => onChangeIsInVarsityProgram(true)}
            />
            <Text ml={3} color={colors.primarynavy}>
              <h5>Yes</h5>
            </Text>
          </Flex>
          <Flex ml={5}>
            <CheckBox
              name="isInVarsityProgram"
              type="checkbox"
              checked={!isInVarsityProgram}
              onChange={() => onChangeIsInVarsityProgram(false)}
            />
            <Text ml={3} color={colors.primarynavy}>
              <h5>No</h5>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isInVarsityProgram && (
        <InputFieldWithErrors
          width="48%"
          type="text"
          name="varsityProgramUrl"
          label="varsity program website (optional)"
          placeholder="add a url to your official page"
          value={varsityProgramUrl}
          updateField={field => onChangeProgramUrl(field.varsityProgramUrl as string)}
        />
      )}
    </Flex>
  )
}

export default VarsityFields
