import React from 'react'

export const CheckmarkIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2929 1.29292L3.99992 7.58592L1.70692 5.29292C1.51832 5.11076 1.26571 5.00997 1.00352 5.01224C0.741321 5.01452 0.490508 5.11969 0.3051 5.3051C0.119692 5.49051 0.0145233 5.74132 0.0122448 6.00352C0.00996641 6.26571 0.110761 6.51832 0.292919 6.70692L3.29292 9.70692C3.48045 9.89439 3.73475 9.99971 3.99992 9.99971C4.26508 9.99971 4.51939 9.89439 4.70692 9.70692L11.7069 2.70692C11.8891 2.51832 11.9899 2.26571 11.9876 2.00352C11.9853 1.74132 11.8801 1.49051 11.6947 1.3051C11.5093 1.11969 11.2585 1.01452 10.9963 1.01224C10.7341 1.00997 10.4815 1.11076 10.2929 1.29292Z"
        fill="#1FB5F7"
      />
    </svg>
  )
}
