import { useTheme } from 'emotion-theming'
import React from 'react'
import { GiCrossedSwords } from 'react-icons/gi'
import { AiFillCrown } from 'react-icons/ai'
import { FiCrosshair } from 'react-icons/fi'
import { Flex, Link, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { Team } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import Alert from '../atoms/Alert'
import Card from '../atoms/Card'
import League from '../atoms/League'
import SportLogo from '../atoms/SportLogo'
import Table from '../atoms/Table'

interface IProps {
  teams: Team[]
  currentUserTeamIds: string[]
}

interface UniversityDetailTeam extends Team {
  isCurrentUserTeam?: boolean
}

const StyledText = styled.text`
  max-width: 20rem;
  min-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`

const StyledFlex = styled(Flex)`
  max-width: 20rem;
  min-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  h6.teamAlert {
    padding-left: 0.5rem;
  }
`

const UniversityDetailTeams: React.FC<IProps> = ({ teams, currentUserTeamIds }) => {
  const { colors } = useTheme()

  const orderedTeams: UniversityDetailTeam[] = teams.reduce(
    (ordered: UniversityDetailTeam[], team: UniversityDetailTeam) => {
      if (currentUserTeamIds.includes(team.id)) {
        ordered.unshift({ ...team, isCurrentUserTeam: true })
      } else {
        ordered.push(team)
      }
      return ordered
    },
    [],
  )

  if (orderedTeams.length === 0) {
    return (
      <Card notch={true} notchSize={16} mt={['1rem', '1rem', 0]} py={[6]} px={[4]}>
        <Alert borderColor={colors.sand} mx={['auto']}>
          <GiCrossedSwords size={38} color={colors.sand} />
          <Text my={5} color="darkgray">
            <h6>No Active Teams!</h6>
          </Text>
          <Text mb={5}>
            <p>
              Looks like this university does not have any active teams for the selected games.
              {/* Create a team below so you can start competing! */}
            </p>
          </Text>

          {/* <BaseButton variant="secondary">Create a Team</BaseButton> */}
        </Alert>
      </Card>
    )
  }

  return (
    <Table headerBackgroundColor="middlegray">
      <thead>
        <tr>
          <th>
            <Text color={colors.transparent}>Logo</Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>Team</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>Conference</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>League</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>Record</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.transparent}>Link</Text>
          </th>
        </tr>
      </thead>

      <tbody>
        {orderedTeams.map(team => (
          <tr key={team.id}>
            <td>{team.sportSlug && <SportLogo width="1.5625rem" sport={team.sportSlug} />}</td>
            <td>
              <Flex>
                <StyledText color={colors.secondarynavy} width="20rem">
                  <h6>{team.name}</h6>
                  <Text ml={2} color={team.isCurrentUserTeam ? colors.purple : colors.orange}>
                    {team.isCurrentUserTeam || team.isRecruiting ? (
                      <StyledFlex alignItems="center" ml={2}>
                        {team.isCurrentUserTeam ? (
                          <>
                            <AiFillCrown size={18} color={colors.purple} />
                            <h6 className="teamAlert" style={{ marginLeft: '0,5rem' }}>
                              {team.isCurrentUserTeam ? 'My Team' : 'Recruiting'}
                            </h6>
                          </>
                        ) : (
                          <>
                            <FiCrosshair size={18} color={colors.orange} />
                            <h6 className="teamAlert" style={{ marginLeft: '0,5rem' }}>
                              {team.isCurrentUserTeam ? 'My Team' : 'Recruiting'}
                            </h6>
                          </>
                        )}
                      </StyledFlex>
                    ) : null}
                  </Text>
                </StyledText>
              </Flex>
            </td>
            <td>
              <Text color={colors.darkgray}>
                <h6>{team.selectedSeasonConferenceName}</h6>
              </Text>
            </td>

            <td>
              {team.selectedSeason && team.selectedSeason.divisionName && (
                <League
                  league={team.selectedSeason && team.selectedSeason.divisionName}
                  color={colors.secondarynavy}
                />
              )}
            </td>

            <td>
              {team.selectedSeasonStats && (
                <Text color={colors.darkgray}>
                  <h6>
                    {team.selectedSeasonStats.wins}-{team.selectedSeasonStats.losses}
                  </h6>
                </Text>
              )}
            </td>

            <td>
              <Link href={paths.teamDetail(team.id)}>
                <BaseButton variant="secondary">View Roster</BaseButton>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default UniversityDetailTeams
