import React, { useState } from 'react'
import { Flex, FlexProps, Text, Box } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import { CheckmarkIcon } from '../../assets/icons/checkmark-icon'
import DataFetcher from '../organisms/DataFetcher'
import SectionTitle from '../atoms/SectionTitle'

// -------------------------------------------------------

// NOTE: Everything commented out in this component is related to stripe and premium subscriptions.
// Once we get the green light to resume premium subscriptions, we should do the following:

// TODO:
// -uncomment everything
// -delete any code that is marked for deletion (will say "DELETE")
// -add back imports for useEffect and BoxProps
// -reorganize import statements

// -------------------------------------------------------

// import { useQuery } from '@apollo/react-hooks'
// import gql from 'graphql-tag'
// import { Theme } from '../../styles/settings'
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
// import { CheckCircle } from 'react-feather'
// import { darken } from 'polished'
// import LoadingSpinner from '../atoms/LoadingSpinner'
// import { InputBox, FieldLabel } from '../atoms/FormPieces'
// import SubscriptionPrice from '../atoms/SubscriptionPrice'

// const GET_PAYMENT_INTENT = gql`
//   query paymentIntent {
//     paymentIntent {
//       clientSecret
//       amount
//       receiptEmail
//     }
//   }
// `

interface OptionContainerProps extends FlexProps {
  selected: boolean
  disabled?: boolean
  expanded?: boolean
}

const OptionContainer = styled(Flex)<OptionContainerProps>`
  border: 2px solid
    ${props => (props.selected ? props.theme.colors.sand : props.theme.colors.middlegray)};
  padding: 2rem;
  flex-direction: column;
  max-width: 4526px;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

// interface CardFormContainerProps extends BoxProps {
//   error: boolean
// }

// const CardFormContainer = styled(Box)<CardFormContainerProps>`
//   border: 2px solid
//     ${props => (props.error ? props.theme.colors.red : props.theme.colors.darkmiddlegray)};
//   padding: 0.55rem 0.75rem 0.45rem;
// `

// const PaymentConfirmation = styled(Flex)`
//   border: 2px solid ${props => props.theme.colors.green};
//   max-width: 21rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto;
//   padding: 10px 0;
// `

// const CARD_OPTIONS = {
//   style: {
//     base: {
//       iconColor: Theme.colors.primarylightblue,
//       color: Theme.colors.primarynavy,
//       fontFamily: Theme.fonts.condensed,
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': { color: Theme.colors.primarynavy },
//       '::placeholder': {
//         color: darken(0.3, Theme.colors.darkmiddlegray),
//         fontFamily: Theme.fonts.condensed,
//       },
//     },
//     invalid: {
//       iconColor: Theme.colors.red,
//       color: Theme.colors.red,
//     },
//   },
// }

interface UserSubscriptionProps {
  onSelectAccountType: (accountType: number) => void
  updateLoading?: (loading: boolean) => void
  parentLoading?: boolean
  isUpdate?: boolean
}

const UserSubscription: React.FunctionComponent<UserSubscriptionProps> = ({
  onSelectAccountType,
  updateLoading,
  parentLoading,
  isUpdate,
}) => {
  const [selectedOption, setSelectedOption] = useState(1)
  // const [subscriptionPrice, setSubscriptionPrice] = useState(0)
  // const stripe = useStripe()
  // const elements = useElements()
  // const [error, setError] = useState(null as any)
  // const [cardComplete, setCardComplete] = useState(false)
  // const [processing, setProcessing] = useState(false)
  // const [billingEmail, setBillingEmail] = useState('')
  // const [clientSecret, setClientSecret] = useState('')
  // const [paymentSuccess, setPaymentSuccess] = useState(false)

  // const { loading: paymentIntentLoading, error: paymentIntentLoadingError } = useQuery(
  //   GET_PAYMENT_INTENT,
  //   {
  //     fetchPolicy: 'cache-and-network',
  //     onCompleted: data => {
  //       setSubscriptionPrice(data.paymentIntent.amount)
  //       setBillingEmail(data.paymentIntent.email)
  //       setClientSecret(data.paymentIntent.clientSecret)
  //     },
  //   },
  // )

  // const handleSubmit = async (event: any) => {
  //   event.preventDefault()
  //   if (!stripe || !elements) {
  //     return
  //   }

  //   const card = elements.getElement(CardElement)

  //   if (!card) {
  //     return
  //   }

  //   if (error) {
  //     card.focus()
  //     return
  //   }

  //   if (cardComplete) {
  //     setProcessing(true)
  //   }

  //   const result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: card,
  //       billing_details: {
  //         email: billingEmail,
  //       },
  //     },
  //   })

  //   if (result.error) {
  //     setError(result.error)
  //   } else {
  //     setPaymentSuccess(true)
  //     onSelectAccountType(1)
  //   }

  //   setProcessing(false)
  // }

  // useEffect(() => {
  //   updateLoading && updateLoading(paymentIntentLoading || !stripe || !elements)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentIntentLoading, stripe, elements])

  const lineItem = (text: string) => {
    return (
      <Flex alignItems="flex-start" mt={3}>
        <Box mr={3} pt={1}>
          <CheckmarkIcon />
        </Box>
        <Text>
          <p>{text}</p>
        </Text>
      </Flex>
    )
  }

  return (
    // <DataFetcher
    //   loading={!paymentSuccess && (paymentIntentLoading || parentLoading || !stripe || !elements)}
    //   error={paymentIntentLoadingError}
    //   height="100%"
    // >
    <DataFetcher loading={!!parentLoading} error={undefined} height="100%">
      <Flex flexDirection="column" width="100%" px={[2, 2, 7]}>
        <SectionTitle text="select account type" align="center" mb="5" />
        <OptionContainer
          mt={5}
          mb={3}
          selected={selectedOption === 0}
          onClick={() => setSelectedOption(0)}
          // disabled={processing || paymentSuccess}

          // DELETE disabled={true} when the above is uncommented
          disabled={true}
        >
          <Flex justifyContent="space-between">
            <Text color="primarynavy">
              <h5>Basic Account</h5>
            </Text>
            {/* <Text color="darkgray">
              <h5>free</h5>
            </Text> */}
          </Flex>
          {lineItem('For casual and semi-competitive skill levels')}
          {lineItem('Access to free tournaments')}
        </OptionContainer>
        <Flex flexDirection="column">
          <OptionContainer
            selected={selectedOption === 1}
            onClick={() => setSelectedOption(1)}
            // disabled={processing || paymentSuccess}
          >
            <Flex justifyContent="space-between">
              <Text color="primarynavy">
                <h5>Premium Account</h5>
              </Text>
              {/* <Flex alignItems="center">
                <SubscriptionPrice fontFamily="condensed" price={subscriptionPrice} />
                <Text ml={2} color="darkgray">
                  <h5>per season</h5>
                </Text>
              </Flex> */}
            </Flex>
            {lineItem('Compete at any skill level (casual, semi-competitive, & Star League)')}
            {lineItem('Access to free tournaments')}
            {lineItem('Open Division: Recreational matches')}
            {lineItem('Challenger Division: Semi-competitive matches')}
            {lineItem('Star League: Top level of competition')}
            {lineItem('One team, representing each school')}
            {lineItem('Thousands of dollars in scholarships and prizing')}
            {lineItem('Features in CSL weekly broadcast, channels, and content')}
            {/* <InputBox mt={6} mb={2}>
              {paymentSuccess ? (
                <PaymentConfirmation>
                  <CheckCircle size={38} color={Theme.colors.green} />
                  <Text color="darkgray" ml={7}>
                    <h6>Payment Successful</h6>
                  </Text>
                </PaymentConfirmation>
              ) : (
                <CardFormContainer error={error}>
                  <CardElement
                    options={CARD_OPTIONS}
                    onChange={e => {
                      setError(e.error)
                      setCardComplete(e.complete)
                    }}
                  />
                </CardFormContainer>
              )}
              {error && <FieldLabel color="red" label={error.message} />}
            </InputBox> */}
          </OptionContainer>
          {/* <Flex mt={5}>
            {processing || parentLoading ? (
              <LoadingSpinner />
            ) : !paymentSuccess ? (
              <BaseButton
                variant="secondary"
                onClick={selectedOption === 0 ? () => onSelectAccountType(0) : handleSubmit}
                mx="auto"
              >
                {selectedOption === 1 ? `Pay $${subscriptionPrice} and Continue` : 'continue'}
              </BaseButton>
            ) : null}
          </Flex> */}

          {/* DELETE below Flex and BaseButton once the above is uncommented */}
          <Flex mt={5}>
            <BaseButton variant="secondary" onClick={() => onSelectAccountType(1)} mx="auto">
              Continue
            </BaseButton>
          </Flex>
        </Flex>
      </Flex>
    </DataFetcher>
  )
}

export default UserSubscription
