import gql from 'graphql-tag'
import { oc } from 'ts-optchain'
import React, { useState, useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { UniversityHubQuery } from '../../types/fragments'
import DataFetcher from '../organisms/DataFetcher'
import UniversityHub from '../organisms/UniversityHub'
import { useConstants } from '../../hooks/constants'
import { useMyUniversityDetailPageLazyQuery } from '../../types/graphql'

gql`
  query myUniversityDetailPage($selectedYear: String) {
    myUniversity(selectedYear: $selectedYear) {
      ...UniversityHubQuery
    }
  }
  ${UniversityHubQuery}
`
interface IURLParams {
  universityId?: string
  page?: string
  pageSize?: string
}
const MyUniversityDetail: React.FC<RouteComponentProps<IURLParams>> = () => {
  const constants = useConstants()
  const currentSeasonYear = oc(constants).currentSeasonYear('')
  const [year, setYear] = useState('')

  const [universityQuery, { data, error, loading }] = useMyUniversityDetailPageLazyQuery({
    variables: { selectedYear: year },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (currentSeasonYear && !year) {
      setYear(currentSeasonYear)
    }
  }, [year, currentSeasonYear])

  useEffect(() => {
    if (!year) {
      return
    }
    universityQuery()
  }, [year, universityQuery])

  if (data && !data.myUniversity) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <DataFetcher error={error} loading={loading || (!data && !error)}>
      {data && data.myUniversity && (
        <UniversityHub university={data.myUniversity} setYear={setYear} year={year} />
      )}
    </DataFetcher>
  )
}

export default MyUniversityDetail
