import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO8601 datetime */
  DateTime: any;
  Upload: any;
};

export type Query = {
   __typename?: 'Query';
  /** Collection of application constants */
  constants: Constants;
  /** Get current user */
  currentUser?: Maybe<CurrentUser>;
  /** Get a list of divisions with accompanying seasons */
  divisions: Array<Division>;
  homepageRankingTournaments: Array<Tournament>;
  /** Gets invitation information if current user is new, invited user */
  invitedUser?: Maybe<InvitedUser>;
  /** Get info about a match by ID */
  match?: Maybe<Match>;
  /** Get a current user's notifications */
  myNotifications?: Maybe<Array<Notification>>;
  /** Get info about the current User's University */
  myUniversity?: Maybe<University>;
  /** Get a list of Nba Teams */
  nbaTeams: NbaTeam;
  /** Get a news article by slug */
  newsArticle?: Maybe<NewsArticle>;
  /** Get the last three news articles */
  newsBrief?: Maybe<Array<NewsArticle>>;
  /** Get a news post or announcement (i.e. NewsArticle) by id */
  newsOrAnnouncement: NewsOrAnnouncementPayload;
  /** Get a list of news posts or announcements (i.e. NewsArticles) */
  newsOrAnnouncements: NewsOrAnnouncementsPayload;
  /** Get a list of Nhl Teams */
  nhlTeams: NhlTeam;
  /** Get an OAuth login URL */
  oauthLoginUrl: Scalars['String'];
  /** Get Stripe Payment Intent object */
  paymentIntent: PaymentIntent;
  /** Get a list of forfeits pending review */
  pendingForfeitsConnection: ForfeitConnection;
  /** Get a list of platforms */
  platforms: Array<Platform>;
  /** Get a list of played sports */
  playedSports: Array<Sport>;
  /** Get info about a Season by ID */
  season?: Maybe<Season>;
  /** Get a list of player characteristic options for a single sport or group of sports. */
  sportPlayerCharacteristics: SportPlayerCharacteristics;
  /** Get Season Info in Tournament Admin */
  sportSeasons: Array<Season>;
  /** Get info about user by ID */
  team?: Maybe<Team>;
  /** Get info about the last N teams */
  teams: Array<Team>;
  /** Get top ranked teams */
  topTeams: Array<TopTeam>;
  /** Get top ranked universities */
  topUniversities: Array<TopUniversity>;
  /** Get info about tournament by ID */
  tournament?: Maybe<Tournament>;
  /** Get filterable list of tournaments */
  tournamentsConnection: TournamentConnection;
  /** Get a list of universities */
  universities: Array<University>;
  /** Get info about a university by ID */
  university?: Maybe<University>;
  /** Get a list of university majors */
  universityMajors: Array<UniversityMajor>;
  /** Get info about user by ID */
  user?: Maybe<User>;
  /** Get current user for dashboard */
  userDashboard?: Maybe<User>;
  /** Query user by email/personal email */
  userSearch: Array<User>;
  /** Get user settings information by current_user or ID if admin */
  userSettings?: Maybe<User>;
};


export type QueryDivisionsArgs = {
  seasonYears?: Maybe<Array<Scalars['String']>>;
};


export type QueryMatchArgs = {
  id: Scalars['ID'];
};


export type QueryMyUniversityArgs = {
  selectedYear?: Maybe<Scalars['String']>;
};


export type QueryNewsArticleArgs = {
  slug: Scalars['String'];
};


export type QueryNewsOrAnnouncementArgs = {
  id: Scalars['ID'];
};


export type QueryNewsOrAnnouncementsArgs = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  type: NewsArticleTypes;
  sort?: Maybe<NewsArticlesSortOrders>;
  categories?: Maybe<Array<PostCategories>>;
  sports?: Maybe<Array<SportSlugs>>;
};


export type QueryOauthLoginUrlArgs = {
  provider: Scalars['String'];
};


export type QueryPendingForfeitsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sports?: Maybe<Array<Scalars['String']>>;
};


export type QuerySeasonArgs = {
  id: Scalars['ID'];
};


export type QuerySportPlayerCharacteristicsArgs = {
  lolChampions?: Maybe<Scalars['Boolean']>;
  dota2Heroes?: Maybe<Scalars['Boolean']>;
  sfvFighters?: Maybe<Scalars['Boolean']>;
  owHeroes?: Maybe<Scalars['Boolean']>;
  mtgaColors?: Maybe<Scalars['Boolean']>;
  nbaTeams?: Maybe<Scalars['Boolean']>;
  hsClasses?: Maybe<Scalars['Boolean']>;
  sc2Races?: Maybe<Scalars['Boolean']>;
  nhlTeams?: Maybe<Scalars['Boolean']>;
  maddenTeams?: Maybe<Scalars['Boolean']>;
  valorantAgents?: Maybe<Scalars['Boolean']>;
  smashCharacters?: Maybe<Scalars['Boolean']>;
};


export type QuerySportSeasonsArgs = {
  sport: Scalars['String'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamsArgs = {
  number?: Maybe<Scalars['Int']>;
  withPendingImages?: Maybe<Scalars['Boolean']>;
};


export type QueryTournamentArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sports?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['String']>;
};


export type QueryUniversityArgs = {
  id: Scalars['ID'];
  selectedYear?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserSearchArgs = {
  email: Scalars['String'];
};


export type QueryUserSettingsArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type NewsArticle = {
   __typename?: 'NewsArticle';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  featured: Scalars['Boolean'];
  featuredImage: Scalars['String'];
  id: Scalars['ID'];
  newsCategory: NewsCategory;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  sport?: Maybe<SportSlugs>;
  summary: Scalars['String'];
  title: Scalars['String'];
  type: NewsArticleTypes;
};

export enum NewsArticleTypes {
  /** News */
  News = 'News',
  /** Announcement */
  Announcement = 'Announcement'
}

export type NewsCategory = {
   __typename?: 'NewsCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum SportSlugs {
  /** valorant */
  Valorant = 'valorant',
  /** gears */
  Gears = 'gears',
  /** mtga */
  Mtga = 'mtga',
  /** sfv */
  Sfv = 'sfv',
  /** nba */
  Nba = 'nba',
  /** nhl */
  Nhl = 'nhl',
  /** fortnite */
  Fortnite = 'fortnite',
  /** madden */
  Madden = 'madden',
  /** ow */
  Ow = 'ow',
  /** rl */
  Rl = 'rl',
  /** lol */
  Lol = 'lol',
  /** sc2 */
  Sc2 = 'sc2',
  /** dota2 */
  Dota2 = 'dota2',
  /** csgo */
  Csgo = 'csgo',
  /** hs */
  Hs = 'hs',
  /** smash */
  Smash = 'smash',
  /** all sports */
  AllSports = 'all_sports'
}

export type Match = {
   __typename?: 'Match';
  awayTeam?: Maybe<Team>;
  awayTeamCheckedIn?: Maybe<Scalars['Boolean']>;
  checkinAvailable: Scalars['Boolean'];
  currentUserCanApproveReschedule: Scalars['Boolean'];
  currentUserCanReport: Scalars['Boolean'];
  currentUserCheckedIn: Scalars['Boolean'];
  currentUserIsCoordinator: Scalars['Boolean'];
  currentUserIsTeamMember: Scalars['Boolean'];
  forfeit?: Maybe<Forfeit>;
  games?: Maybe<Array<Game>>;
  hiddenMessagesCount: Scalars['Int'];
  homeTeam?: Maybe<Team>;
  homeTeamCheckedIn?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isBye: Scalars['Boolean'];
  maps?: Maybe<Array<Map>>;
  matchMessages: Array<MatchMessage>;
  messagesCount: Scalars['Int'];
  playerStats?: Maybe<Array<UserSeasonStats>>;
  rescheduleDate?: Maybe<Scalars['DateTime']>;
  rescheduleRequestingTeam?: Maybe<Team>;
  round?: Maybe<Round>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  season?: Maybe<Season>;
  settled?: Maybe<Scalars['Boolean']>;
  settledMemo?: Maybe<Scalars['Boolean']>;
  sport?: Maybe<Scalars['String']>;
  teamEnrollmentOne?: Maybe<TeamEnrollment>;
  teamEnrollmentTwo?: Maybe<TeamEnrollment>;
  tournament?: Maybe<Tournament>;
  winner?: Maybe<Team>;
};


export type MatchMatchMessagesArgs = {
  since?: Maybe<Scalars['Int']>;
};

export type Team = {
   __typename?: 'Team';
  activePlayers?: Maybe<Array<User>>;
  activePlayersCount: Scalars['Int'];
  activeRosterEntries?: Maybe<Array<RosterEntry>>;
  approvedBannerImage?: Maybe<TeamImage>;
  approvedLogoImage?: Maybe<TeamImage>;
  checkedIn?: Maybe<Scalars['Boolean']>;
  conferenceIds: Array<Scalars['Int']>;
  coordinator?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  currentSeason?: Maybe<Season>;
  currentSeasonEligible: Scalars['Int'];
  currentUserApplicationPending: Scalars['Boolean'];
  currentUserIsCoordinator: Scalars['Boolean'];
  currentUserIsEligible: Scalars['Boolean'];
  currentUserOnTeam: Scalars['Boolean'];
  division?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRecruiting: Scalars['Boolean'];
  messagesCount: Scalars['Int'];
  name: Scalars['String'];
  overallLosses: Scalars['Int'];
  overallWins: Scalars['Int'];
  pendingBannerImage?: Maybe<TeamImage>;
  pendingInvitations: Array<PendingInvitation>;
  pendingLogoImage?: Maybe<TeamImage>;
  pendingRosterEntries: Array<RosterEntry>;
  players?: Maybe<Array<User>>;
  rosterEntries?: Maybe<Array<RosterEntry>>;
  seasonRegistries: Array<SeasonRegistry>;
  seasonYear?: Maybe<Array<Scalars['String']>>;
  selectedSeason?: Maybe<Season>;
  selectedSeasonConferenceName?: Maybe<Scalars['String']>;
  selectedSeasonMatches?: Maybe<Array<Match>>;
  selectedSeasonPlayerStats?: Maybe<Array<UserSeasonStats>>;
  selectedSeasonRank?: Maybe<Scalars['Int']>;
  selectedSeasonStats?: Maybe<TeamSeasonStats>;
  settledMatches: Array<Match>;
  sportSlug: Scalars['String'];
  teamAwards: Array<Award>;
  teamEnrollments: Array<TeamEnrollment>;
  teamMessages: Array<TeamMessage>;
  tournamentRegistries: Array<TournamentRegistry>;
  tournamentsToCheckinTo: Array<Tournament>;
  truncatedName?: Maybe<Scalars['String']>;
  twitchUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  university?: Maybe<University>;
  unreportedMatches?: Maybe<Array<Match>>;
  upcomingMatches?: Maybe<Array<Match>>;
};


export type TeamTeamMessagesArgs = {
  since?: Maybe<Scalars['Int']>;
};

export type TeamImage = {
   __typename?: 'TeamImage';
  dataUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type User = {
   __typename?: 'User';
  activeRosterEntries?: Maybe<Array<RosterEntry>>;
  activeTeams: Array<Team>;
  characterImageName?: Maybe<Scalars['String']>;
  characterName?: Maybe<Scalars['String']>;
  checkedInForShowdown: Scalars['Boolean'];
  checkins?: Maybe<Array<Checkin>>;
  csgoEseaProfileUrl?: Maybe<Scalars['String']>;
  csgoSteamId?: Maybe<Scalars['String']>;
  dashboardMatchHistory?: Maybe<Array<Match>>;
  discordId?: Maybe<Scalars['String']>;
  discordUsername?: Maybe<Scalars['String']>;
  dota2HeroImageName?: Maybe<Scalars['String']>;
  dota2HeroName?: Maybe<Scalars['String']>;
  dota2SteamId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  facebookUsername?: Maybe<Scalars['String']>;
  fortniteEpicUsername?: Maybe<Scalars['String']>;
  freeAgencies?: Maybe<Array<FreeAgency>>;
  gearsXboxLiveId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  graduationYear?: Maybe<Scalars['Int']>;
  hsBattletag?: Maybe<Scalars['String']>;
  hsPreferredClass?: Maybe<Scalars['String']>;
  hsPreferredClassCd?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameName?: Maybe<Scalars['String']>;
  isConfirmed: Scalars['Boolean'];
  isPremiumUser?: Maybe<Scalars['Boolean']>;
  lolAverageStats?: Maybe<LolGamePlayerStats>;
  lolChampionName?: Maybe<Scalars['String']>;
  lolGamePlayerStats?: Maybe<Array<LolGamePlayerStats>>;
  lolRiotImageName?: Maybe<Scalars['String']>;
  lolSummonerName?: Maybe<Scalars['String']>;
  lolSummonerTier?: Maybe<Scalars['String']>;
  maddenPreferredTeam?: Maybe<Scalars['String']>;
  maddenPreferredTeamCd?: Maybe<Scalars['String']>;
  maddenPsnId?: Maybe<Scalars['String']>;
  maddenXboxLiveId?: Maybe<Scalars['String']>;
  mtgaPreferredColor?: Maybe<Scalars['String']>;
  mtgaPreferredColorCd?: Maybe<Scalars['String']>;
  mtgaUsername?: Maybe<Scalars['String']>;
  myStatesTeams?: Maybe<Array<Team>>;
  name?: Maybe<Scalars['String']>;
  nbaPreferredTeam?: Maybe<Scalars['String']>;
  nbaPreferredTeamCd?: Maybe<Scalars['String']>;
  nbaPsnId?: Maybe<Scalars['String']>;
  nbaXboxLiveId?: Maybe<Scalars['String']>;
  nhlPreferredTeam?: Maybe<Scalars['String']>;
  nhlPreferredTeamCd?: Maybe<Scalars['String']>;
  nhlPsnId?: Maybe<Scalars['String']>;
  nhlXboxLiveId?: Maybe<Scalars['String']>;
  owBattleNetAccountLink?: Maybe<Scalars['String']>;
  owPreferredHero?: Maybe<Scalars['String']>;
  owPreferredHeroCd?: Maybe<Scalars['String']>;
  pendingApplications?: Maybe<Array<RosterEntry>>;
  pendingTeamInvitations: Array<PendingInvitation>;
  personalEmail?: Maybe<Scalars['String']>;
  playedSports?: Maybe<Array<Sport>>;
  playingSport?: Maybe<Scalars['String']>;
  reportedMatches: Array<Match>;
  rlEpicId?: Maybe<Scalars['String']>;
  rlPsnId?: Maybe<Scalars['String']>;
  rlSteamId?: Maybe<Scalars['String']>;
  rlXboxLiveId?: Maybe<Scalars['String']>;
  sc2Battletag?: Maybe<Scalars['String']>;
  sc2CharacterName?: Maybe<Scalars['String']>;
  sc2PreferredRace?: Maybe<Scalars['String']>;
  sc2PreferredRaceCd?: Maybe<Scalars['String']>;
  selectedSeasonStats?: Maybe<UserSeasonStats>;
  sfvFid?: Maybe<Scalars['String']>;
  sfvPreferredFighter?: Maybe<Scalars['String']>;
  sfvPreferredFighterCd?: Maybe<Scalars['String']>;
  showdownTournamentId?: Maybe<Scalars['String']>;
  smashGamerTag?: Maybe<Scalars['String']>;
  stateTeamApplications?: Maybe<Array<RosterEntry>>;
  stateTeams?: Maybe<Array<Team>>;
  subscriptionEndDate?: Maybe<Scalars['String']>;
  teams: Array<Team>;
  tournamentsToCheckinTo?: Maybe<Array<Tournament>>;
  twitchUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  university: University;
  universityMajor?: Maybe<UniversityMajor>;
  unreportedMatches?: Maybe<Array<Match>>;
  upcomingMatches?: Maybe<Array<Match>>;
  userSeasonStats?: Maybe<Array<UserSeasonStats>>;
  username?: Maybe<Scalars['String']>;
  valorantPreferredChampion?: Maybe<Scalars['String']>;
  valorantPreferredChampionCd?: Maybe<Scalars['String']>;
  valorantRiotAccount?: Maybe<Scalars['String']>;
  vgPreferredHero?: Maybe<Scalars['String']>;
};

export type University = {
   __typename?: 'University';
  abbreviation?: Maybe<Scalars['String']>;
  activeUsers: Array<User>;
  currentUserCanCreateTeam?: Maybe<Scalars['Boolean']>;
  currentUserPlayedSports: Array<Scalars['String']>;
  currentUserTeamIds: Array<Scalars['ID']>;
  domains?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  lastSeasonTeams: Array<Team>;
  name: Scalars['String'];
  newTeams: Array<Team>;
  numberOfAwards: Scalars['Int'];
  seasonTeams: Array<Team>;
  state?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Team>>;
};

export type RosterEntry = {
   __typename?: 'RosterEntry';
  accepted?: Maybe<Scalars['Boolean']>;
  gameRole?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameName?: Maybe<Scalars['String']>;
  player: User;
  roleCd?: Maybe<Scalars['String']>;
  staffRoleCd?: Maybe<Scalars['String']>;
  team: Team;
};

export type LolGamePlayerStats = {
   __typename?: 'LolGamePlayerStats';
  assists?: Maybe<Scalars['Int']>;
  creepsPerMin?: Maybe<Scalars['Float']>;
  deaths?: Maybe<Scalars['Int']>;
  goldPerMin?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  kills?: Maybe<Scalars['Int']>;
  lolLineupEntryId?: Maybe<Scalars['Int']>;
  playerId?: Maybe<Scalars['Int']>;
  totalDamageDealtToChampions?: Maybe<Scalars['Int']>;
};

export type FreeAgency = {
   __typename?: 'FreeAgency';
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<Scalars['String']>;
  sportSlug?: Maybe<Scalars['String']>;
};

export type Sport = {
   __typename?: 'Sport';
  slug?: Maybe<Scalars['String']>;
};

export type Checkin = {
   __typename?: 'Checkin';
  id?: Maybe<Scalars['ID']>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  tournament?: Maybe<Tournament>;
  user?: Maybe<User>;
};

export type Tournament = {
   __typename?: 'Tournament';
  activeRosterEntries?: Maybe<Array<RosterEntry>>;
  address?: Maybe<Scalars['String']>;
  adminTournamentTeams?: Maybe<Array<Team>>;
  banner?: Maybe<Scalars['String']>;
  bracketData?: Maybe<Array<BracketData>>;
  brackets?: Maybe<Array<Tournament>>;
  checkedInTeams?: Maybe<Array<Team>>;
  checkinLength?: Maybe<Scalars['Int']>;
  checkins?: Maybe<Array<Checkin>>;
  containerId?: Maybe<Scalars['ID']>;
  currentUserCanCheckIn: Scalars['Boolean'];
  currentUserCanRegister?: Maybe<Scalars['Boolean']>;
  currentUserCheckedIn?: Maybe<Scalars['Boolean']>;
  currentUserNumberOfEligibleTeams: Scalars['Int'];
  decided: Scalars['Boolean'];
  defaultSeriesLength?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  desiredBrackets: Scalars['Int'];
  /** Get list of up to 100 matching teams */
  eligibleTeams: Array<Team>;
  firstMatchDate?: Maybe<Scalars['DateTime']>;
  grandFinals?: Maybe<Array<BracketData>>;
  id: Scalars['ID'];
  isPremium?: Maybe<Scalars['Boolean']>;
  lowerBracketData?: Maybe<Array<TournamentMatch>>;
  matches?: Maybe<Array<Match>>;
  minPlayers?: Maybe<Scalars['Int']>;
  minTeamSize?: Maybe<Scalars['Int']>;
  multibracketCapable: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  numberOfTeams?: Maybe<Scalars['Int']>;
  platform?: Maybe<Platform>;
  playerStats?: Maybe<Array<UserSeasonStats>>;
  players?: Maybe<Array<User>>;
  published: Scalars['Boolean'];
  registrationCloseDate?: Maybe<Scalars['DateTime']>;
  registrationOpenDate?: Maybe<Scalars['DateTime']>;
  roundIntervals?: Maybe<Array<RoundInterval>>;
  rounds?: Maybe<Array<Round>>;
  season?: Maybe<Season>;
  seatTree?: Maybe<Array<SeatTree>>;
  sponsorImage?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  startingSeats?: Maybe<Array<Scalars['Int']>>;
  style?: Maybe<Scalars['String']>;
  styleChoice?: Maybe<BracketTypes>;
  teamSeeds?: Maybe<Array<TeamSeed>>;
  teamStats?: Maybe<Array<TeamSeasonStats>>;
  teams?: Maybe<Array<Team>>;
  tournamentRegistries?: Maybe<Array<TournamentRegistry>>;
  type?: Maybe<Scalars['String']>;
  typeChoice?: Maybe<TournamentTypes>;
  upperBracketData?: Maybe<Array<BracketData>>;
};


export type TournamentEligibleTeamsArgs = {
  name?: Maybe<Scalars['String']>;
};

export type RoundInterval = {
   __typename?: 'RoundInterval';
  id?: Maybe<Scalars['ID']>;
  matches?: Maybe<Array<Match>>;
  scheduledDate: Scalars['DateTime'];
  tournament?: Maybe<Tournament>;
};


export type Round = {
   __typename?: 'Round';
  id: Scalars['ID'];
  loserToName: Scalars['String'];
  matchPrototype?: Maybe<MatchPrototype>;
  matches?: Maybe<Array<Match>>;
  roundInterval?: Maybe<RoundInterval>;
  tournament?: Maybe<Tournament>;
  tournamentBracketOrder?: Maybe<Scalars['Int']>;
  tournamentRoundName?: Maybe<Scalars['String']>;
  winner?: Maybe<Team>;
  winnerToName: Scalars['String'];
};

export type MatchPrototype = {
   __typename?: 'MatchPrototype';
  gameCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type Season = {
   __typename?: 'Season';
  adminSeasonTeams?: Maybe<Array<Team>>;
  banner?: Maybe<Scalars['String']>;
  conferences: Array<Conference>;
  division?: Maybe<Division>;
  division1: Scalars['Boolean'];
  divisionName?: Maybe<Scalars['String']>;
  divisionNumber: Scalars['Int'];
  /** Get list of up to 100 matching teams */
  eligibleTeams: Array<Team>;
  enrolledTeams: Array<Team>;
  firstMatchDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  isPremium: Scalars['Boolean'];
  matchPrototype?: Maybe<MatchPrototype>;
  maximumTeamSize?: Maybe<Scalars['Int']>;
  minimumTeamSize?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  participantsCount: Scalars['Int'];
  priceCents?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  region?: Maybe<Scalars['String']>;
  regionChoice?: Maybe<SeasonRegions>;
  registeredTeams: Array<Team>;
  registrationCloseDate?: Maybe<Scalars['DateTime']>;
  registrationOpenDate?: Maybe<Scalars['DateTime']>;
  registrationStatus: LeagueRegistrationStatuses;
  rosterLockDate?: Maybe<Scalars['DateTime']>;
  roundIntervals?: Maybe<Array<RoundInterval>>;
  scheduleForWeek?: Maybe<RoundInterval>;
  scheduleType?: Maybe<Scalars['String']>;
  scheduleTypeChoice?: Maybe<SeasonSchedules>;
  seasonEndDate?: Maybe<Scalars['DateTime']>;
  seasonYear?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  sport?: Maybe<Sport>;
  standingsList: Array<SeasonStandings>;
  teamSeasonStats: Array<TeamSeasonStats>;
  teams?: Maybe<Array<Team>>;
  teamsCount: Scalars['Int'];
  tournaments?: Maybe<Array<Tournament>>;
};


export type SeasonEligibleTeamsArgs = {
  name?: Maybe<Scalars['String']>;
};


export type SeasonScheduleForWeekArgs = {
  weekNumber: Scalars['Int'];
};

export type Conference = {
   __typename?: 'Conference';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  season?: Maybe<Season>;
  teams?: Maybe<Array<Team>>;
};

export type SeasonStandings = {
   __typename?: 'SeasonStandings';
  gameLosses?: Maybe<Scalars['Int']>;
  gameWins?: Maybe<Scalars['Int']>;
  matchLosses?: Maybe<Scalars['Int']>;
  matchWins?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['ID']>;
};

export enum LeagueRegistrationStatuses {
  /** unstarted */
  Unstarted = 'unstarted',
  /** enrolling */
  Enrolling = 'enrolling',
  /** draft */
  Draft = 'draft',
  /** populating */
  Populating = 'populating',
  /** active */
  Active = 'active',
  /** ended */
  Ended = 'ended'
}

export enum SeasonSchedules {
  /** round_robin */
  RoundRobin = 'round_robin',
  /** double_round_robin */
  DoubleRoundRobin = 'double_round_robin'
}

export enum SeasonRegions {
  /** north_america */
  NorthAmerica = 'north_america',
  /** europe */
  Europe = 'europe'
}

export type Division = {
   __typename?: 'Division';
  bannerUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  seasons: Array<Season>;
};

export type TeamSeasonStats = {
   __typename?: 'TeamSeasonStats';
  id: Scalars['ID'];
  losses?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  season: Season;
  team: Team;
  teamId: Scalars['Int'];
  wins?: Maybe<Scalars['Int']>;
};

export type Platform = {
   __typename?: 'Platform';
  id: Scalars['ID'];
  title: Scalars['String'];
  uid: Scalars['String'];
};

export type TeamSeed = {
   __typename?: 'TeamSeed';
  seed?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export enum BracketTypes {
  /** single_elim */
  SingleElim = 'single_elim',
  /** double_elim */
  DoubleElim = 'double_elim'
}

export enum TournamentTypes {
  /** playoff */
  Playoff = 'playoff',
  /** normal */
  Normal = 'normal',
  /** showdown */
  Showdown = 'showdown'
}

export type TournamentRegistry = {
   __typename?: 'TournamentRegistry';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  seed?: Maybe<Scalars['Int']>;
  team?: Maybe<Team>;
  tournament: Tournament;
};

export type BracketData = {
   __typename?: 'BracketData';
  matchId?: Maybe<Scalars['String']>;
  scheduledDate?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  settled?: Maybe<Scalars['String']>;
  team1Id?: Maybe<Scalars['String']>;
  team1Wins?: Maybe<Scalars['String']>;
  team2Id?: Maybe<Scalars['String']>;
  team2Wins?: Maybe<Scalars['String']>;
};

export type TournamentMatch = {
   __typename?: 'TournamentMatch';
  id?: Maybe<Scalars['String']>;
  matchNumber?: Maybe<Scalars['String']>;
  team1Id?: Maybe<Scalars['String']>;
  team1Name?: Maybe<Scalars['String']>;
  team1Seed?: Maybe<Scalars['String']>;
  team1Wins?: Maybe<Scalars['String']>;
  team2Id?: Maybe<Scalars['String']>;
  team2Name?: Maybe<Scalars['String']>;
  team2Seed?: Maybe<Scalars['String']>;
  team2Wins?: Maybe<Scalars['String']>;
};

export type SeatTree = {
   __typename?: 'SeatTree';
  loserTo?: Maybe<Scalars['Int']>;
  seat?: Maybe<Scalars['Int']>;
  winnerTo?: Maybe<Scalars['Int']>;
};

export type UserSeasonStats = {
   __typename?: 'UserSeasonStats';
  id: Scalars['ID'];
  losses?: Maybe<Scalars['Int']>;
  player: User;
  season: Season;
  user: User;
  wins?: Maybe<Scalars['Int']>;
};

export type UniversityMajor = {
   __typename?: 'UniversityMajor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PendingInvitation = {
   __typename?: 'PendingInvitation';
  gameRole?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  player: User;
  playerPlaysSport: Scalars['Boolean'];
  team: Team;
};

export type Award = {
   __typename?: 'Award';
  award: Scalars['Int'];
  id: Scalars['ID'];
  teamId: Scalars['Int'];
  tournamentId: Scalars['Int'];
};

export type TeamMessage = {
   __typename?: 'TeamMessage';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  team: Team;
  user: UserForComment;
};

export type UserForComment = {
   __typename?: 'UserForComment';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
};

export type SeasonRegistry = {
   __typename?: 'SeasonRegistry';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  season: Season;
  team: Team;
};

export type TeamEnrollment = {
   __typename?: 'TeamEnrollment';
  id?: Maybe<Scalars['ID']>;
  season?: Maybe<Season>;
  team?: Maybe<Team>;
};

export type Game = {
   __typename?: 'Game';
  id: Scalars['ID'];
  loser?: Maybe<Lineup>;
  map?: Maybe<Map>;
  proofScreenshotUrl?: Maybe<Scalars['String']>;
  riotTournamentCodeData?: Maybe<RiotTournamentCodeData>;
  winner?: Maybe<Lineup>;
};

export type Lineup = {
   __typename?: 'Lineup';
  game?: Maybe<Game>;
  id: Scalars['ID'];
  lineupEntries?: Maybe<Array<LineupEntry>>;
  players?: Maybe<Array<User>>;
  score?: Maybe<Scalars['Int']>;
  team?: Maybe<Team>;
};

export type LineupEntry = {
   __typename?: 'LineupEntry';
  characterImageName?: Maybe<Scalars['String']>;
  characterName?: Maybe<Scalars['String']>;
  deckListCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kills?: Maybe<Scalars['Int']>;
  lolGamePlayerStats?: Maybe<LolGamePlayerStats>;
  match: Match;
  player?: Maybe<User>;
  role?: Maybe<Scalars['String']>;
  selectedColor?: Maybe<Scalars['String']>;
  smashRemainingStocks?: Maybe<Scalars['Int']>;
};

export type Map = {
   __typename?: 'Map';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RiotTournamentCodeData = {
   __typename?: 'RiotTournamentCodeData';
  gameId: Scalars['Int'];
  id: Scalars['ID'];
  tournamentCode: Scalars['String'];
};

export type Forfeit = {
   __typename?: 'Forfeit';
  accepted?: Maybe<Scalars['Boolean']>;
  explanation: Scalars['String'];
  id: Scalars['ID'];
  match: Match;
  opposingTeamName: Scalars['ID'];
  proofScreenshotUrl: Scalars['String'];
  rejectedMessage?: Maybe<Scalars['String']>;
  requestingTeamName: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type MatchMessage = {
   __typename?: 'MatchMessage';
  Match: Team;
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  user: UserForComment;
};

export type CurrentUser = {
   __typename?: 'CurrentUser';
  coordinatingTeams: Array<Team>;
  emailForVerification?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  isIncompleteAccount: Scalars['Boolean'];
  unreportedMatches?: Maybe<Array<Match>>;
  upcomingMatches?: Maybe<Array<Match>>;
  username?: Maybe<Scalars['String']>;
};

export type InvitedUser = {
   __typename?: 'InvitedUser';
  email: Scalars['String'];
  pendingTeamInvitations?: Maybe<Array<PendingInvitation>>;
  university?: Maybe<University>;
};

export type SportPlayerCharacteristics = {
   __typename?: 'SportPlayerCharacteristics';
  dota2Heroes?: Maybe<Dota2Heroes>;
  hsClasses?: Maybe<HearthstoneCharacterClasses>;
  lolChampions?: Maybe<LolChampions>;
  maddenTeams?: Maybe<MaddenTeams>;
  mtgaColors?: Maybe<MtgaColors>;
  nbaTeams?: Maybe<NbaTeams>;
  nhlTeams?: Maybe<NhlTeams>;
  owHeroes?: Maybe<OverwatchHeroes>;
  sc2Races?: Maybe<Starcraft2Races>;
  sfvFighters?: Maybe<SfvFighters>;
  smashCharacters?: Maybe<SmashCharacters>;
  valorantAgents?: Maybe<ValorantAgent>;
};

export type LolChampions = {
   __typename?: 'LolChampions';
  options: Array<Scalars['String']>;
};

export type Dota2Heroes = {
   __typename?: 'Dota2Heroes';
  options: Array<Scalars['String']>;
};

export type SfvFighters = {
   __typename?: 'SfvFighters';
  options: Array<Scalars['String']>;
};

export type OverwatchHeroes = {
   __typename?: 'OverwatchHeroes';
  options: Array<Scalars['String']>;
};

export type MtgaColors = {
   __typename?: 'MtgaColors';
  options: Array<Scalars['String']>;
};

export type NbaTeams = {
   __typename?: 'NbaTeams';
  options: Array<Scalars['String']>;
};

export type HearthstoneCharacterClasses = {
   __typename?: 'HearthstoneCharacterClasses';
  options: Array<Scalars['String']>;
};

export type Starcraft2Races = {
   __typename?: 'Starcraft2Races';
  options: Array<Scalars['String']>;
};

export type NhlTeams = {
   __typename?: 'NhlTeams';
  options: Array<Scalars['String']>;
};

export type MaddenTeams = {
   __typename?: 'MaddenTeams';
  options: Array<Scalars['String']>;
};

export type ValorantAgent = {
   __typename?: 'ValorantAgent';
  options: Array<Scalars['String']>;
};

export type SmashCharacters = {
   __typename?: 'SmashCharacters';
  options: Array<Scalars['String']>;
};

export type NhlTeam = {
   __typename?: 'NhlTeam';
  options: Array<Scalars['String']>;
};

export type NbaTeam = {
   __typename?: 'NbaTeam';
  options: Array<Scalars['String']>;
};

/** The connection type for Tournament. */
export type TournamentConnection = {
   __typename?: 'TournamentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TournamentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Tournament>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Information about pagination in a connection. */
export type PageInfo = {
   __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type TournamentEdge = {
   __typename?: 'TournamentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Tournament>;
};

export type PaymentIntent = {
   __typename?: 'PaymentIntent';
  amount: Scalars['Int'];
  clientSecret: Scalars['String'];
  receiptEmail: Scalars['String'];
};

export type Constants = {
   __typename?: 'Constants';
  currentSeasonYear: Scalars['String'];
  postCategories: Array<PostCategories>;
  postTypes: Array<NewsArticleTypes>;
  sportSlugs: Array<SportSlugs>;
  teamStaffRoles: Array<TeamStaffRoles>;
};

export enum PostCategories {
  /** CSL News */
  CslNews = 'csl_news',
  /** Tournaments */
  Tournaments = 'tournaments',
  /** Features */
  Features = 'features',
  /** Games */
  Games = 'games'
}

export enum TeamStaffRoles {
  /** none */
  None = 'none',
  /** coach */
  Coach = 'coach',
  /** analyst_manager */
  AnalystManager = 'analyst_manager',
  /** team_captain */
  TeamCaptain = 'team_captain'
}

export type NewsOrAnnouncementPayload = {
   __typename?: 'NewsOrAnnouncementPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<NewsArticle>;
};

export type InputError = {
   __typename?: 'InputError';
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Notification = {
   __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
};

export type TopTeam = {
   __typename?: 'TopTeam';
  id: Scalars['ID'];
  rank: Scalars['Int'];
  team: Team;
};

export type TopUniversity = {
   __typename?: 'TopUniversity';
  id: Scalars['ID'];
  rank: Scalars['Int'];
  university: University;
};

export type NewsOrAnnouncementsPayload = {
   __typename?: 'NewsOrAnnouncementsPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<NewsOrAnnouncementsPaginatedPayload>;
};

export type NewsOrAnnouncementsPaginatedPayload = {
   __typename?: 'NewsOrAnnouncementsPaginatedPayload';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  items: Array<NewsArticle>;
  totalPages: Scalars['Int'];
};

export enum NewsArticlesSortOrders {
  /** most_recent */
  MostRecent = 'most_recent'
}

/** The connection type for Forfeit. */
export type ForfeitConnection = {
   __typename?: 'ForfeitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ForfeitEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Forfeit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ForfeitEdge = {
   __typename?: 'ForfeitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Forfeit>;
};

export type Mutation = {
   __typename?: 'Mutation';
  addMatchMessage: AddMatchMessagePayload;
  addTeamMessage: AddTeamMessagePayload;
  addTournamentMessage: AddTournamentMessagePayload;
  applyForTeam: ApplyForTeamPayload;
  approveMatchReschedule: ApproveMatchReschedulePayload;
  approveTeamImages: ApproveTeamImagesPayload;
  autoReportLolGame: AutoReportLolGamePayload;
  changePassword: ChangePasswordPayload;
  confirmByToken: ConfirmByTokenPayload;
  createNewCheckin: CreateNewCheckinPayload;
  createNewMatchCheckin: CreateNewMatchCheckinPayload;
  createNewUser: CreateNewUserPayload;
  createRosterEntry: CreateRosterEntryPayload;
  deleteMatchCheckin: DeleteMatchCheckinPayload;
  deleteTeamApplication: DeleteTeamApplicationPayload;
  deleteTeamPlayerInvitation: DeleteTeamPlayerInvitationPayload;
  deleteTournamentCheckin: DeleteTournamentCheckinPayload;
  deleteTournamentRegistry: DeleteTournamentRegistryPayload;
  editSeasonBasicInfo: EditSeasonBasicInfoPayload;
  editTeam: EditTeamPayload;
  editTournamentBasicInfo: EditTournamentBasicInfoPayload;
  editTournamentIrl: EditTournamentIrlPayload;
  editTournamentLogistics: EditTournamentLogisticsPayload;
  editTournamentRoundPrototypes: EditTournamentRoundPrototypesPayload;
  editTournamentTeams: EditTournamentTeamsPayload;
  hideMessage: HideMessagePayload;
  inviteUserToPlatform: InviteUserToPlatformPayload;
  inviteUserToTeam: InviteUserToTeamPayload;
  markNotificationsRead: MarkNotificationsReadPayload;
  oauthSignIn: UserForAuth;
  populateSeason: PopulateSeasonPayload;
  populateTournament: PopulateTournamentPayload;
  registerTeamForSeason: RegisterTeamForSeasonPayload;
  registerTeamForTournament: RegisterTeamForTournamentPayload;
  reportCsgoGame: ReportCsgoGamePayload;
  reportDota2Game: ReportDota2GamePayload;
  reportForfeit: ReportForfeitPayload;
  reportFortniteGame: ReportFortniteGamePayload;
  reportLolGame: ReportLolGamePayload;
  reportMaddenGame: ReportMaddenGamePayload;
  reportMtgaGame: ReportMtgaGamePayload;
  reportNbaGame: ReportNbaGamePayload;
  reportNhlGame: ReportNhlGamePayload;
  reportOwGame: ReportOwGamePayload;
  reportRlGame: ReportRlGamePayload;
  reportSfvGame: ReportSfvGamePayload;
  reportSmashGame: ReportSmashGamePayload;
  reportValorantGame: ReportValorantGamePayload;
  requestMatchReschedule: RequestMatchReschedulePayload;
  rescheduleMatch: RescheduleMatchPayload;
  resetPassword: ResetPasswordPayload;
  reviewForfeit: ReviewForfeitPayload;
  sendEmailConfirmation: SendEmailConfirmationPayload;
  sendPasswordReset: SendPasswordResetPayload;
  signIn: SignInPayload;
  signOut: SignOutPayload;
  signUp: SignUpPayload;
  updateRosterEntry: UpdateRosterEntryPayload;
  updateUser: UpdateUserPayload;
  upsertConferences: UpsertConferencesPayload;
  upsertNewsOrAnnouncement: UpsertNewsOrAnnouncementPayload;
  upsertTeam: UpsertTeamPayload;
  upsertUniversity: UpsertUniversityPayload;
  verifyNewUser: VerifyNewUserPayload;
};


export type MutationAddMatchMessageArgs = {
  id: Scalars['ID'];
  body: Scalars['String'];
};


export type MutationAddTeamMessageArgs = {
  id: Scalars['ID'];
  body: Scalars['String'];
};


export type MutationAddTournamentMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationApplyForTeamArgs = {
  id: Scalars['ID'];
  gameRole?: Maybe<Scalars['String']>;
};


export type MutationApproveMatchRescheduleArgs = {
  matchId: Scalars['ID'];
  approve: Scalars['Boolean'];
};


export type MutationApproveTeamImagesArgs = {
  id: Scalars['ID'];
  approveBanner?: Maybe<Scalars['Boolean']>;
  approveLogo?: Maybe<Scalars['Boolean']>;
};


export type MutationAutoReportLolGameArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationConfirmByTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateNewCheckinArgs = {
  tournamentId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationCreateNewMatchCheckinArgs = {
  matchId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationCreateNewUserArgs = {
  id?: Maybe<Scalars['ID']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['ID']>;
  gender?: Maybe<Scalars['String']>;
  universityMajorId?: Maybe<Scalars['ID']>;
  graduationYear?: Maybe<Scalars['Int']>;
  paidForPremium?: Maybe<Scalars['Boolean']>;
  discordUsername: Scalars['String'];
  twitterUsername?: Maybe<Scalars['String']>;
  twitchUsername?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  isInVarsityProgram?: Maybe<Scalars['Boolean']>;
  varsityProgramUrl?: Maybe<Scalars['String']>;
  marketingEmailsCd?: Maybe<Scalars['Int']>;
  gearsXboxLiveId?: Maybe<Scalars['String']>;
  mtgaUsername?: Maybe<Scalars['String']>;
  mtgaPreferredColorCd?: Maybe<Scalars['String']>;
  sfvFid?: Maybe<Scalars['String']>;
  sfvPreferredFighterCd?: Maybe<Scalars['String']>;
  nbaXboxLiveId?: Maybe<Scalars['String']>;
  nbaPsnId?: Maybe<Scalars['String']>;
  nbaPreferredTeamCd?: Maybe<Scalars['String']>;
  maddenXboxLiveId?: Maybe<Scalars['String']>;
  maddenPsnId?: Maybe<Scalars['String']>;
  maddenPreferredTeamCd?: Maybe<Scalars['String']>;
  owPreferredHeroCd?: Maybe<Scalars['String']>;
  owBattleNetAccountLink?: Maybe<Scalars['String']>;
  nhlXboxLiveId?: Maybe<Scalars['String']>;
  nhlPsnId?: Maybe<Scalars['String']>;
  nhlPreferredTeamCd?: Maybe<Scalars['String']>;
  vgPlayerName?: Maybe<Scalars['String']>;
  vgPreferredHeroCd?: Maybe<Scalars['String']>;
  sc2Battletag?: Maybe<Scalars['String']>;
  sc2CharacterName?: Maybe<Scalars['String']>;
  sc2PreferredRaceCd?: Maybe<Scalars['String']>;
  lolSummonerName?: Maybe<Scalars['String']>;
  lolChampionName?: Maybe<Scalars['String']>;
  dota2SteamId?: Maybe<Scalars['String']>;
  dota2HeroName?: Maybe<Scalars['String']>;
  hsBattletag?: Maybe<Scalars['String']>;
  hsPreferredClassCd?: Maybe<Scalars['String']>;
  rlXboxLiveId?: Maybe<Scalars['String']>;
  rlPsnId?: Maybe<Scalars['String']>;
  rlSteamId?: Maybe<Scalars['String']>;
  rlEpicId?: Maybe<Scalars['String']>;
  fortniteEpicUsername?: Maybe<Scalars['String']>;
  smashGamerTag?: Maybe<Scalars['String']>;
  quakePreferredChampionId?: Maybe<Scalars['String']>;
  quakeUsername?: Maybe<Scalars['String']>;
  csgoSteamId?: Maybe<Scalars['String']>;
  csgoEseaProfileUrl?: Maybe<Scalars['String']>;
  valorantRiotAccount?: Maybe<Scalars['String']>;
  valorantPreferredChampionCd?: Maybe<Scalars['String']>;
};


export type MutationCreateRosterEntryArgs = {
  teamPlayerInviteId: Scalars['ID'];
};


export type MutationDeleteMatchCheckinArgs = {
  matchId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationDeleteTeamApplicationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeamPlayerInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTournamentCheckinArgs = {
  tournamentId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationDeleteTournamentRegistryArgs = {
  id: Scalars['ID'];
};


export type MutationEditSeasonBasicInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  sport: Scalars['String'];
  name: Scalars['String'];
  seasonYear: Scalars['String'];
  firstMatchDate: Scalars['String'];
  registrationOpenDate: Scalars['String'];
  registrationCloseDate: Scalars['String'];
  rosterLockDate?: Maybe<Scalars['String']>;
  seasonEndDate: Scalars['String'];
  divisionName: Scalars['String'];
  banner?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  scheduleTypeChoice: SeasonSchedules;
  regionChoice: SeasonRegions;
  minimumTeamSize?: Maybe<Scalars['Int']>;
  maximumTeamSize?: Maybe<Scalars['Int']>;
  defaultSeriesLength: Scalars['Int'];
  isPremiere: Scalars['Boolean'];
  isPremium: Scalars['Boolean'];
  published: Scalars['Boolean'];
  price: Scalars['Int'];
};


export type MutationEditTeamArgs = {
  id: Scalars['ID'];
  isRecruiting?: Maybe<Scalars['Boolean']>;
};


export type MutationEditTournamentBasicInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  sport?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstMatchDate?: Maybe<Scalars['String']>;
  registrationOpenDate?: Maybe<Scalars['String']>;
  registrationCloseDate?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  checkinLength?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['ID']>;
};


export type MutationEditTournamentIrlArgs = {
  id: Scalars['ID'];
  address: Scalars['String'];
  state: Scalars['String'];
  onsiteEntryCode: Scalars['String'];
};


export type MutationEditTournamentLogisticsArgs = {
  defaultSeriesLength: Scalars['Int'];
  feedsIntoId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  minTeamSize: Scalars['Int'];
  numberOfTeams: Scalars['Int'];
  priceCents?: Maybe<Scalars['Int']>;
  multibracketCapable: Scalars['Boolean'];
  desiredBrackets: Scalars['Int'];
  seasonId?: Maybe<Scalars['ID']>;
  styleChoice: BracketTypes;
  typeChoice: TournamentTypes;
  roundPrototypes?: Maybe<Array<RoundMatchPrototypeInput>>;
};


export type MutationEditTournamentRoundPrototypesArgs = {
  roundPrototypes: Array<RoundMatchPrototypeInput>;
  tournamentId: Scalars['ID'];
};


export type MutationEditTournamentTeamsArgs = {
  tournamentId: Scalars['ID'];
  teamRegistriesMap: Array<TeamTournamentRegistryMap>;
};


export type MutationHideMessageArgs = {
  id: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationInviteUserToPlatformArgs = {
  email: Scalars['String'];
};


export type MutationInviteUserToTeamArgs = {
  email: Scalars['String'];
  teamId: Scalars['ID'];
  gameRole?: Maybe<Scalars['String']>;
};


export type MutationMarkNotificationsReadArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationOauthSignInArgs = {
  provider: OAuthProviderEnum;
  code: Scalars['String'];
  state: Scalars['String'];
};


export type MutationPopulateSeasonArgs = {
  seasonId: Scalars['ID'];
};


export type MutationPopulateTournamentArgs = {
  tournamentId: Scalars['ID'];
};


export type MutationRegisterTeamForSeasonArgs = {
  seasonId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationRegisterTeamForTournamentArgs = {
  tournamentId?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};


export type MutationReportCsgoGameArgs = {
  gameId: Scalars['ID'];
  mapId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportDota2GameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportForfeitArgs = {
  matchId: Scalars['ID'];
  proofScreenshot: Scalars['Upload'];
  explanation: Scalars['String'];
  requestingTeamId?: Maybe<Scalars['ID']>;
};


export type MutationReportFortniteGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportLolGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportMaddenGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportMtgaGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportNbaGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportNhlGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportOwGameArgs = {
  gameId: Scalars['ID'];
  mapId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportRlGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportSfvGameArgs = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportSmashGameArgs = {
  gameId: Scalars['ID'];
  mapId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationReportValorantGameArgs = {
  gameId: Scalars['ID'];
  mapId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type MutationRequestMatchRescheduleArgs = {
  matchId: Scalars['ID'];
  rescheduleDate: Scalars['DateTime'];
};


export type MutationRescheduleMatchArgs = {
  matchId: Scalars['ID'];
  rescheduleDate: Scalars['DateTime'];
};


export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationReviewForfeitArgs = {
  forfeitId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  rejectedMessage?: Maybe<Scalars['String']>;
};


export type MutationSendPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignUpArgs = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
};


export type MutationUpdateRosterEntryArgs = {
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  gameRole?: Maybe<Scalars['String']>;
  staffRole?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
  kicked?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  id?: Maybe<Scalars['ID']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['ID']>;
  gender?: Maybe<Scalars['String']>;
  universityMajorId?: Maybe<Scalars['ID']>;
  graduationYear?: Maybe<Scalars['Int']>;
  paidForPremium?: Maybe<Scalars['Boolean']>;
  discordUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  twitchUsername?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  isInVarsityProgram?: Maybe<Scalars['Boolean']>;
  varsityProgramUrl?: Maybe<Scalars['String']>;
  marketingEmailsCd?: Maybe<Scalars['Int']>;
  gearsXboxLiveId?: Maybe<Scalars['String']>;
  mtgaUsername?: Maybe<Scalars['String']>;
  mtgaPreferredColorCd?: Maybe<Scalars['String']>;
  sfvFid?: Maybe<Scalars['String']>;
  sfvPreferredFighterCd?: Maybe<Scalars['String']>;
  nbaXboxLiveId?: Maybe<Scalars['String']>;
  nbaPsnId?: Maybe<Scalars['String']>;
  nbaPreferredTeamCd?: Maybe<Scalars['String']>;
  maddenXboxLiveId?: Maybe<Scalars['String']>;
  maddenPsnId?: Maybe<Scalars['String']>;
  maddenPreferredTeamCd?: Maybe<Scalars['String']>;
  owPreferredHeroCd?: Maybe<Scalars['String']>;
  owBattleNetAccountLink?: Maybe<Scalars['String']>;
  nhlXboxLiveId?: Maybe<Scalars['String']>;
  nhlPsnId?: Maybe<Scalars['String']>;
  nhlPreferredTeamCd?: Maybe<Scalars['String']>;
  vgPlayerName?: Maybe<Scalars['String']>;
  vgPreferredHeroCd?: Maybe<Scalars['String']>;
  sc2Battletag?: Maybe<Scalars['String']>;
  sc2CharacterName?: Maybe<Scalars['String']>;
  sc2PreferredRaceCd?: Maybe<Scalars['String']>;
  lolSummonerName?: Maybe<Scalars['String']>;
  lolChampionName?: Maybe<Scalars['String']>;
  dota2SteamId?: Maybe<Scalars['String']>;
  dota2HeroName?: Maybe<Scalars['String']>;
  hsBattletag?: Maybe<Scalars['String']>;
  hsPreferredClassCd?: Maybe<Scalars['String']>;
  rlXboxLiveId?: Maybe<Scalars['String']>;
  rlPsnId?: Maybe<Scalars['String']>;
  rlSteamId?: Maybe<Scalars['String']>;
  rlEpicId?: Maybe<Scalars['String']>;
  fortniteEpicUsername?: Maybe<Scalars['String']>;
  smashGamerTag?: Maybe<Scalars['String']>;
  quakePreferredChampionId?: Maybe<Scalars['String']>;
  quakeUsername?: Maybe<Scalars['String']>;
  csgoSteamId?: Maybe<Scalars['String']>;
  csgoEseaProfileUrl?: Maybe<Scalars['String']>;
  valorantRiotAccount?: Maybe<Scalars['String']>;
  valorantPreferredChampionCd?: Maybe<Scalars['String']>;
};


export type MutationUpsertConferencesArgs = {
  seasonId: Scalars['ID'];
  conferences?: Maybe<Array<ConferenceAttributes>>;
  registeredTeams: Array<Scalars['ID']>;
};


export type MutationUpsertNewsOrAnnouncementArgs = {
  type: NewsArticleTypes;
  title: Scalars['String'];
  body: Scalars['String'];
  summary: Scalars['String'];
  category?: Maybe<PostCategories>;
  sport?: Maybe<SportSlugs>;
  featuredImage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  featured?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertTeamArgs = {
  name?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  pendingLogo?: Maybe<Scalars['String']>;
  pendingBanner?: Maybe<Scalars['String']>;
  approvedLogo?: Maybe<Scalars['String']>;
  approvedBanner?: Maybe<Scalars['String']>;
  coordinatorRole?: Maybe<Scalars['String']>;
  coordinatorId?: Maybe<Scalars['ID']>;
  facebookUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  twitchUsername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpsertUniversityArgs = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  domains?: Maybe<Array<Scalars['String']>>;
};


export type MutationVerifyNewUserArgs = {
  id?: Maybe<Scalars['ID']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['ID']>;
  gender?: Maybe<Scalars['String']>;
  universityMajorId?: Maybe<Scalars['ID']>;
  graduationYear?: Maybe<Scalars['Int']>;
  paidForPremium?: Maybe<Scalars['Boolean']>;
  discordUsername: Scalars['String'];
  twitterUsername?: Maybe<Scalars['String']>;
  twitchUsername?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  isInVarsityProgram?: Maybe<Scalars['Boolean']>;
  varsityProgramUrl?: Maybe<Scalars['String']>;
  marketingEmailsCd?: Maybe<Scalars['Int']>;
  gearsXboxLiveId?: Maybe<Scalars['String']>;
  mtgaUsername?: Maybe<Scalars['String']>;
  mtgaPreferredColorCd?: Maybe<Scalars['String']>;
  sfvFid?: Maybe<Scalars['String']>;
  sfvPreferredFighterCd?: Maybe<Scalars['String']>;
  nbaXboxLiveId?: Maybe<Scalars['String']>;
  nbaPsnId?: Maybe<Scalars['String']>;
  nbaPreferredTeamCd?: Maybe<Scalars['String']>;
  maddenXboxLiveId?: Maybe<Scalars['String']>;
  maddenPsnId?: Maybe<Scalars['String']>;
  maddenPreferredTeamCd?: Maybe<Scalars['String']>;
  owPreferredHeroCd?: Maybe<Scalars['String']>;
  owBattleNetAccountLink?: Maybe<Scalars['String']>;
  nhlXboxLiveId?: Maybe<Scalars['String']>;
  nhlPsnId?: Maybe<Scalars['String']>;
  nhlPreferredTeamCd?: Maybe<Scalars['String']>;
  vgPlayerName?: Maybe<Scalars['String']>;
  vgPreferredHeroCd?: Maybe<Scalars['String']>;
  sc2Battletag?: Maybe<Scalars['String']>;
  sc2CharacterName?: Maybe<Scalars['String']>;
  sc2PreferredRaceCd?: Maybe<Scalars['String']>;
  lolSummonerName?: Maybe<Scalars['String']>;
  lolChampionName?: Maybe<Scalars['String']>;
  dota2SteamId?: Maybe<Scalars['String']>;
  dota2HeroName?: Maybe<Scalars['String']>;
  hsBattletag?: Maybe<Scalars['String']>;
  hsPreferredClassCd?: Maybe<Scalars['String']>;
  rlXboxLiveId?: Maybe<Scalars['String']>;
  rlPsnId?: Maybe<Scalars['String']>;
  rlSteamId?: Maybe<Scalars['String']>;
  rlEpicId?: Maybe<Scalars['String']>;
  fortniteEpicUsername?: Maybe<Scalars['String']>;
  smashGamerTag?: Maybe<Scalars['String']>;
  quakePreferredChampionId?: Maybe<Scalars['String']>;
  quakeUsername?: Maybe<Scalars['String']>;
  csgoSteamId?: Maybe<Scalars['String']>;
  csgoEseaProfileUrl?: Maybe<Scalars['String']>;
  valorantRiotAccount?: Maybe<Scalars['String']>;
  valorantPreferredChampionCd?: Maybe<Scalars['String']>;
};

export type SignInPayload = {
   __typename?: 'SignInPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<UserForAuth>;
};

export type UserForAuth = {
   __typename?: 'UserForAuth';
  accessToken: Scalars['String'];
  client: Scalars['String'];
  expiry: Scalars['String'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  isIncompleteAccount: Scalars['Boolean'];
  teamIds: Array<Scalars['String']>;
  uid: Scalars['String'];
  username: Scalars['String'];
};

/** OAuth Providers */
export enum OAuthProviderEnum {
  /** Twitch.tv */
  Twitch = 'twitch',
  /** Discord.com */
  Discord = 'discord'
}

/** Autogenerated return type of SignOut */
export type SignOutPayload = {
   __typename?: 'SignOutPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type SignUpPayload = {
   __typename?: 'SignUpPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<UserForAuth>;
};

/** Autogenerated return type of ReportCsgoGame */
export type ReportCsgoGamePayload = {
   __typename?: 'ReportCsgoGamePayload';
  match?: Maybe<Match>;
};

export type UserReportedGame = {
  score: Scalars['Int'];
  userSubmittedLineup: Array<UserSubmittedLineup>;
};

export type UserSubmittedLineup = {
  playerId: Scalars['String'];
  roleCd?: Maybe<Scalars['String']>;
  deckListCode?: Maybe<Scalars['String']>;
  dota2Hero?: Maybe<Scalars['String']>;
  lolChampion?: Maybe<Scalars['String']>;
  selectedFighterCd?: Maybe<Scalars['String']>;
  valorantChampionCd?: Maybe<Scalars['String']>;
  kills?: Maybe<Scalars['Int']>;
  teamCd?: Maybe<Scalars['String']>;
  smashStocksRemaining?: Maybe<Scalars['Int']>;
  smashCharacter?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReportLolGame */
export type ReportLolGamePayload = {
   __typename?: 'ReportLolGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of AutoReportLolGame */
export type AutoReportLolGamePayload = {
   __typename?: 'AutoReportLolGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportMtgaGame */
export type ReportMtgaGamePayload = {
   __typename?: 'ReportMtgaGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportDota2Game */
export type ReportDota2GamePayload = {
   __typename?: 'ReportDota2GamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportSfvGame */
export type ReportSfvGamePayload = {
   __typename?: 'ReportSfvGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportRlGame */
export type ReportRlGamePayload = {
   __typename?: 'ReportRlGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportFortniteGame */
export type ReportFortniteGamePayload = {
   __typename?: 'ReportFortniteGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportValorantGame */
export type ReportValorantGamePayload = {
   __typename?: 'ReportValorantGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportNhlGame */
export type ReportNhlGamePayload = {
   __typename?: 'ReportNhlGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportNbaGame */
export type ReportNbaGamePayload = {
   __typename?: 'ReportNbaGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportOwGame */
export type ReportOwGamePayload = {
   __typename?: 'ReportOwGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportMaddenGame */
export type ReportMaddenGamePayload = {
   __typename?: 'ReportMaddenGamePayload';
  match?: Maybe<Match>;
};

/** Autogenerated return type of ReportSmashGame */
export type ReportSmashGamePayload = {
   __typename?: 'ReportSmashGamePayload';
  match?: Maybe<Match>;
};

export type UpdateRosterEntryPayload = {
   __typename?: 'UpdateRosterEntryPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type InviteUserToTeamPayload = {
   __typename?: 'InviteUserToTeamPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type InviteUserToPlatformPayload = {
   __typename?: 'InviteUserToPlatformPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type CreateRosterEntryPayload = {
   __typename?: 'CreateRosterEntryPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type ApplyForTeamPayload = {
   __typename?: 'ApplyForTeamPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DeleteTeamPlayerInvitationPayload = {
   __typename?: 'DeleteTeamPlayerInvitationPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DeleteTeamApplicationPayload = {
   __typename?: 'DeleteTeamApplicationPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type EditTeamPayload = {
   __typename?: 'EditTeamPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EditTournamentBasicInfo */
export type EditTournamentBasicInfoPayload = {
   __typename?: 'EditTournamentBasicInfoPayload';
  errors?: Maybe<Array<InputError>>;
  id?: Maybe<Scalars['ID']>;
  tournament?: Maybe<Tournament>;
};

export type EditTournamentLogisticsPayload = {
   __typename?: 'EditTournamentLogisticsPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Tournament>;
};

export type RoundMatchPrototypeInput = {
  roundId: Scalars['ID'];
  numberOfGames: Scalars['Int'];
};

/** Autogenerated return type of EditTournamentIrl */
export type EditTournamentIrlPayload = {
   __typename?: 'EditTournamentIrlPayload';
  errors?: Maybe<Array<InputError>>;
  id?: Maybe<Scalars['ID']>;
};

export type EditTournamentTeamsPayload = {
   __typename?: 'EditTournamentTeamsPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Tournament>;
};

export type TeamTournamentRegistryMap = {
  teamId: Scalars['ID'];
  seed?: Maybe<Scalars['Int']>;
};

export type EditTournamentRoundPrototypesPayload = {
   __typename?: 'EditTournamentRoundPrototypesPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Tournament>;
};

export type PopulateTournamentPayload = {
   __typename?: 'PopulateTournamentPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Tournament>;
};

/** Autogenerated return type of EditSeasonBasicInfo */
export type EditSeasonBasicInfoPayload = {
   __typename?: 'EditSeasonBasicInfoPayload';
  errors?: Maybe<Array<InputError>>;
  id?: Maybe<Scalars['ID']>;
  season?: Maybe<Season>;
};

/** Autogenerated return type of UpsertConferences */
export type UpsertConferencesPayload = {
   __typename?: 'UpsertConferencesPayload';
  errors?: Maybe<Array<InputError>>;
  id?: Maybe<Scalars['ID']>;
  season?: Maybe<Season>;
};

/** Attributes for creating or updating a conference */
export type ConferenceAttributes = {
  seasonId: Scalars['ID'];
  teamIds?: Maybe<Array<Scalars['ID']>>;
  conferenceName: Scalars['String'];
};

export type PopulateSeasonPayload = {
   __typename?: 'PopulateSeasonPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ReportForfeit */
export type ReportForfeitPayload = {
   __typename?: 'ReportForfeitPayload';
  errors: Array<Scalars['String']>;
};


/** Autogenerated return type of ReviewForfeit */
export type ReviewForfeitPayload = {
   __typename?: 'ReviewForfeitPayload';
  errors: Array<Scalars['String']>;
};

export type SendPasswordResetPayload = {
   __typename?: 'SendPasswordResetPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordPayload = {
   __typename?: 'ResetPasswordPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type UpsertTeamPayload = {
   __typename?: 'UpsertTeamPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Team>;
};

export type VerifyNewUserPayload = {
   __typename?: 'VerifyNewUserPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type CreateNewUserPayload = {
   __typename?: 'CreateNewUserPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<UserForAuth>;
};

/** Autogenerated return type of SendEmailConfirmation */
export type SendEmailConfirmationPayload = {
   __typename?: 'SendEmailConfirmationPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ConfirmByToken */
export type ConfirmByTokenPayload = {
   __typename?: 'ConfirmByTokenPayload';
  universityId?: Maybe<Scalars['ID']>;
};

export type UpdateUserPayload = {
   __typename?: 'UpdateUserPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<UserForAuth>;
};

/** Autogenerated return type of ChangePassword */
export type ChangePasswordPayload = {
   __typename?: 'ChangePasswordPayload';
  errors: Array<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpsertUniversityPayload = {
   __typename?: 'UpsertUniversityPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<University>;
};

export type CreateNewCheckinPayload = {
   __typename?: 'CreateNewCheckinPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type CreateNewMatchCheckinPayload = {
   __typename?: 'CreateNewMatchCheckinPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type RegisterTeamForTournamentPayload = {
   __typename?: 'RegisterTeamForTournamentPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type RegisterTeamForSeasonPayload = {
   __typename?: 'RegisterTeamForSeasonPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DeleteTournamentRegistryPayload = {
   __typename?: 'DeleteTournamentRegistryPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DeleteTournamentCheckinPayload = {
   __typename?: 'DeleteTournamentCheckinPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DeleteMatchCheckinPayload = {
   __typename?: 'DeleteMatchCheckinPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type UpsertNewsOrAnnouncementPayload = {
   __typename?: 'UpsertNewsOrAnnouncementPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<NewsArticle>;
};

export type ApproveTeamImagesPayload = {
   __typename?: 'ApproveTeamImagesPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Team>;
};

export type MarkNotificationsReadPayload = {
   __typename?: 'MarkNotificationsReadPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Array<Notification>>;
};

export type AddTournamentMessagePayload = {
   __typename?: 'AddTournamentMessagePayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type RequestMatchReschedulePayload = {
   __typename?: 'RequestMatchReschedulePayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Match>;
};

export type ApproveMatchReschedulePayload = {
   __typename?: 'ApproveMatchReschedulePayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Match>;
};

export type RescheduleMatchPayload = {
   __typename?: 'RescheduleMatchPayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Match>;
};

export type AddTeamMessagePayload = {
   __typename?: 'AddTeamMessagePayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type AddMatchMessagePayload = {
   __typename?: 'AddMatchMessagePayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type HideMessagePayload = {
   __typename?: 'HideMessagePayload';
  errors?: Maybe<Array<InputError>>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type OauthLoginUrlQueryVariables = {
  provider: Scalars['String'];
};


export type OauthLoginUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'oauthLoginUrl'>
);

export type ChangeAccountSettingsFieldMutationVariables = {
  personalEmail?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type ChangeAccountSettingsFieldMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & Pick<UpdateUserPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'UserForAuth' }
      & AuthenticationAttributesFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type ChangePasswordMutationVariables = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'ChangePasswordPayload' }
    & Pick<ChangePasswordPayload, 'success' | 'errors'>
  ) }
);

export type ConfirmByTokenMutationVariables = {
  token: Scalars['String'];
};


export type ConfirmByTokenMutation = (
  { __typename?: 'Mutation' }
  & { confirmByToken: (
    { __typename?: 'ConfirmByTokenPayload' }
    & Pick<ConfirmByTokenPayload, 'universityId'>
  ) }
);

export type UpdateUserApplicationMutationVariables = {
  id: Scalars['ID'];
  accepted?: Maybe<Scalars['Boolean']>;
};


export type UpdateUserApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateRosterEntry: (
    { __typename?: 'UpdateRosterEntryPayload' }
    & Pick<UpdateRosterEntryPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type RescindPendingInviteMutationVariables = {
  id: Scalars['ID'];
};


export type RescindPendingInviteMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeamPlayerInvitation: (
    { __typename?: 'DeleteTeamPlayerInvitationPayload' }
    & Pick<DeleteTeamPlayerInvitationPayload, 'success'>
  ) }
);

export type SendEmailConfirmationMutationVariables = {};


export type SendEmailConfirmationMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailConfirmation: (
    { __typename?: 'SendEmailConfirmationPayload' }
    & Pick<SendEmailConfirmationPayload, 'success'>
  ) }
);

export type UpdateSocialProfilesMutationVariables = {
  twitchUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type UpdateSocialProfilesMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & Pick<UpdateUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type ToggleTeamRecruitingStatusMutationVariables = {
  id: Scalars['ID'];
  isRecruiting: Scalars['Boolean'];
};


export type ToggleTeamRecruitingStatusMutation = (
  { __typename?: 'Mutation' }
  & { editTeam: (
    { __typename?: 'EditTeamPayload' }
    & Pick<EditTeamPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type AddTournamentMessageMutationVariables = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type AddTournamentMessageMutation = (
  { __typename?: 'Mutation' }
  & { addTournamentMessage: (
    { __typename?: 'AddTournamentMessagePayload' }
    & Pick<AddTournamentMessagePayload, 'success'>
  ) }
);

export type UniversitiesQueryVariables = {};


export type UniversitiesQuery = (
  { __typename?: 'Query' }
  & { universities: Array<(
    { __typename?: 'University' }
    & Pick<University, 'id' | 'name'>
  )> }
);

export type UniversityMajorsQueryVariables = {};


export type UniversityMajorsQuery = (
  { __typename?: 'Query' }
  & { universityMajors: Array<(
    { __typename?: 'UniversityMajor' }
    & Pick<UniversityMajor, 'id' | 'name'>
  )> }
);

export type Upsert_Season_InfoMutationVariables = {
  banner?: Maybe<Scalars['String']>;
  defaultSeriesLength: Scalars['Int'];
  divisionName: Scalars['String'];
  firstMatchDate: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  isPremiere: Scalars['Boolean'];
  isPremium: Scalars['Boolean'];
  maximumTeamSize?: Maybe<Scalars['Int']>;
  minimumTeamSize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  regionChoice: SeasonRegions;
  registrationCloseDate: Scalars['String'];
  registrationOpenDate: Scalars['String'];
  rosterLockDate?: Maybe<Scalars['String']>;
  scheduleTypeChoice: SeasonSchedules;
  seasonEndDate: Scalars['String'];
  seasonYear: Scalars['String'];
  sponsorImage?: Maybe<Scalars['String']>;
  sport: Scalars['String'];
  published: Scalars['Boolean'];
};


export type Upsert_Season_InfoMutation = (
  { __typename?: 'Mutation' }
  & { editSeasonBasicInfo: (
    { __typename?: 'EditSeasonBasicInfoPayload' }
    & { season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id'>
      & AdminSeasonInfoQueryFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type PopulateSeasonMutationVariables = {
  seasonId: Scalars['ID'];
};


export type PopulateSeasonMutation = (
  { __typename?: 'Mutation' }
  & { populateSeason: (
    { __typename?: 'PopulateSeasonPayload' }
    & Pick<PopulateSeasonPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type SeasonAdminQueryVariables = {
  id: Scalars['ID'];
};


export type SeasonAdminQuery = (
  { __typename?: 'Query' }
  & { season?: Maybe<(
    { __typename?: 'Season' }
    & AdminSeasonInfoQueryFragment
    & AdminSeasonTeamsQueryFragment
  )> }
);

export type UpsertConferencesMutationVariables = {
  seasonId: Scalars['ID'];
  conferences?: Maybe<Array<ConferenceAttributes>>;
  registeredTeams: Array<Scalars['ID']>;
};


export type UpsertConferencesMutation = (
  { __typename?: 'Mutation' }
  & { upsertConferences: (
    { __typename?: 'UpsertConferencesPayload' }
    & { season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'isEditable'>
      & AdminSeasonTeamsQueryFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type EligibleTeamInfoSeasonFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'createdAt'>
  & { university?: Maybe<(
    { __typename?: 'University' }
    & Pick<University, 'name' | 'id' | 'abbreviation' | 'state'>
  )> }
);

export type AdminSeasonEligibleTeamsQueryVariables = {
  seasonId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type AdminSeasonEligibleTeamsQuery = (
  { __typename?: 'Query' }
  & { season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id'>
    & { eligibleTeams: Array<(
      { __typename?: 'Team' }
      & EligibleTeamInfoSeasonFragment
    )> }
  )> }
);

export type AdminRegisterTeamForSeasonMutationVariables = {
  teamId: Scalars['ID'];
  seasonId: Scalars['ID'];
};


export type AdminRegisterTeamForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { registerTeamForSeason: (
    { __typename?: 'RegisterTeamForSeasonPayload' }
    & Pick<RegisterTeamForSeasonPayload, 'success' | 'value'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type Upsert_Tournament_InfoMutationVariables = {
  id?: Maybe<Scalars['ID']>;
  sport: Scalars['String'];
  name: Scalars['String'];
  firstMatchDate: Scalars['String'];
  registrationCloseDate: Scalars['String'];
  registrationOpenDate: Scalars['String'];
  banner?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  checkinLength?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['ID']>;
};


export type Upsert_Tournament_InfoMutation = (
  { __typename?: 'Mutation' }
  & { editTournamentBasicInfo: (
    { __typename?: 'EditTournamentBasicInfoPayload' }
    & Pick<EditTournamentBasicInfoPayload, 'id'>
    & { tournament?: Maybe<(
      { __typename?: 'Tournament' }
      & { adminTournamentTeams?: Maybe<Array<(
        { __typename?: 'Team' }
        & AdminTournamentTeamInfoFragment
      )>> }
      & AdminTournamentInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type PopulateTournamentMutationVariables = {
  tournamentId: Scalars['ID'];
};


export type PopulateTournamentMutation = (
  { __typename?: 'Mutation' }
  & { populateTournament: (
    { __typename?: 'PopulateTournamentPayload' }
    & { value?: Maybe<(
      { __typename?: 'Tournament' }
      & { adminTournamentTeams?: Maybe<Array<(
        { __typename?: 'Team' }
        & AdminTournamentTeamInfoFragment
      )>> }
      & AdminTournamentInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type GetPlatformsQueryVariables = {};


export type GetPlatformsQuery = (
  { __typename?: 'Query' }
  & { platforms: Array<(
    { __typename?: 'Platform' }
    & Pick<Platform, 'id' | 'uid' | 'title'>
  )> }
);

export type Edit_Tournament_IrlMutationVariables = {
  id: Scalars['ID'];
  address: Scalars['String'];
  state: Scalars['String'];
  onsiteEntryCode: Scalars['String'];
};


export type Edit_Tournament_IrlMutation = (
  { __typename?: 'Mutation' }
  & { editTournamentIrl: (
    { __typename?: 'EditTournamentIrlPayload' }
    & Pick<EditTournamentIrlPayload, 'id'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type EditTournamentLogisticsMutationVariables = {
  defaultSeriesLength: Scalars['Int'];
  feedsIntoId: Scalars['ID'];
  id: Scalars['ID'];
  minTeamSize: Scalars['Int'];
  numberOfTeams: Scalars['Int'];
  priceCents: Scalars['Int'];
  seasonId: Scalars['ID'];
  styleChoice: BracketTypes;
  typeChoice: TournamentTypes;
  multibracketCapable: Scalars['Boolean'];
  desiredBrackets: Scalars['Int'];
};


export type EditTournamentLogisticsMutation = (
  { __typename?: 'Mutation' }
  & { editTournamentLogistics: (
    { __typename?: 'EditTournamentLogisticsPayload' }
    & { value?: Maybe<(
      { __typename?: 'Tournament' }
      & { adminTournamentTeams?: Maybe<Array<(
        { __typename?: 'Team' }
        & AdminTournamentTeamInfoFragment
      )>> }
      & AdminTournamentInfoFragment
    )> }
  ) }
);

export type EditTournamentRoundPrototypesMutationVariables = {
  tournamentId: Scalars['ID'];
  roundPrototypes: Array<RoundMatchPrototypeInput>;
};


export type EditTournamentRoundPrototypesMutation = (
  { __typename?: 'Mutation' }
  & { editTournamentRoundPrototypes: (
    { __typename?: 'EditTournamentRoundPrototypesPayload' }
    & { value?: Maybe<(
      { __typename?: 'Tournament' }
      & { adminTournamentTeams?: Maybe<Array<(
        { __typename?: 'Team' }
        & AdminTournamentTeamInfoFragment
      )>> }
      & AdminTournamentInfoFragment
    )> }
  ) }
);

export type SportSeasonsInfoFragmentFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'id' | 'divisionName' | 'seasonYear'>
  & { tournaments?: Maybe<Array<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'name'>
  )>> }
);

export type GetSeasonsQueryVariables = {
  sport: Scalars['String'];
};


export type GetSeasonsQuery = (
  { __typename?: 'Query' }
  & { sportSeasons: Array<(
    { __typename?: 'Season' }
    & SportSeasonsInfoFragmentFragment
  )> }
);

export type EditTournamentTeamsMutationVariables = {
  tournamentId: Scalars['ID'];
  teamRegistriesMap: Array<TeamTournamentRegistryMap>;
};


export type EditTournamentTeamsMutation = (
  { __typename?: 'Mutation' }
  & { editTournamentTeams: (
    { __typename?: 'EditTournamentTeamsPayload' }
    & { value?: Maybe<(
      { __typename?: 'Tournament' }
      & { adminTournamentTeams?: Maybe<Array<(
        { __typename?: 'Team' }
        & AdminTournamentTeamInfoFragment
      )>> }
      & AdminTournamentInfoFragment
    )> }
  ) }
);

export type EligibleTeamInfoTournamentFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'selectedSeasonConferenceName'>
  & { selectedSeasonStats?: Maybe<(
    { __typename?: 'TeamSeasonStats' }
    & Pick<TeamSeasonStats, 'wins' | 'losses'>
  )>, university?: Maybe<(
    { __typename?: 'University' }
    & Pick<University, 'id' | 'abbreviation' | 'name' | 'state'>
  )>, coordinator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type AdminTournnamentEligibleTeamsQueryVariables = {
  tournamentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type AdminTournnamentEligibleTeamsQuery = (
  { __typename?: 'Query' }
  & { tournament?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
    & { eligibleTeams: Array<(
      { __typename?: 'Team' }
      & EligibleTeamInfoTournamentFragment
    )> }
  )> }
);

export type AdminRegisterTeamForTournamentMutationVariables = {
  teamId: Scalars['ID'];
  tournamentId: Scalars['ID'];
};


export type AdminRegisterTeamForTournamentMutation = (
  { __typename?: 'Mutation' }
  & { registerTeamForTournament: (
    { __typename?: 'RegisterTeamForTournamentPayload' }
    & Pick<RegisterTeamForTournamentPayload, 'value'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UpsertUniversityInfoMutationVariables = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  domains?: Maybe<Array<Scalars['String']>>;
};


export type UpsertUniversityInfoMutation = (
  { __typename?: 'Mutation' }
  & { upsertUniversity: (
    { __typename?: 'UpsertUniversityPayload' }
    & Pick<UpsertUniversityPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'University' }
      & Pick<University, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type CreateTeamMutationVariables = {
  name: Scalars['String'];
  sport: Scalars['String'];
  coordinatorRole: Scalars['String'];
};


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { upsertTeam: (
    { __typename?: 'UpsertTeamPayload' }
    & Pick<UpsertTeamPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type DashboardMatchHistoryInfoFragment = (
  { __typename?: 'Match' }
  & Pick<Match, 'id' | 'scheduledDate'>
  & { homeTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  )>, awayTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  )>, games?: Maybe<Array<(
    { __typename?: 'Game' }
    & Pick<Game, 'id'>
    & { winner?: Maybe<(
      { __typename?: 'Lineup' }
      & Pick<Lineup, 'id'>
      & { team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
      )> }
    )> }
  )>> }
);

export type ReportForfeitMutationVariables = {
  matchId: Scalars['ID'];
  explanation: Scalars['String'];
  proofScreenshot: Scalars['Upload'];
  requestingTeamId?: Maybe<Scalars['ID']>;
};


export type ReportForfeitMutation = (
  { __typename?: 'Mutation' }
  & { reportForfeit: (
    { __typename?: 'ReportForfeitPayload' }
    & Pick<ReportForfeitPayload, 'errors'>
  ) }
);

export type SendPasswordResetMutationVariables = {
  email: Scalars['String'];
};


export type SendPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordReset: (
    { __typename?: 'SendPasswordResetPayload' }
    & Pick<SendPasswordResetPayload, 'success'>
  ) }
);

export type Report_Csgo_GameMutationVariables = {
  gameId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  mapId: Scalars['ID'];
  evidenceScreenshot: Scalars['String'];
};


export type Report_Csgo_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportCsgoGame: (
    { __typename?: 'ReportCsgoGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type Dota2HeroesQueryVariables = {};


export type Dota2HeroesQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { dota2Heroes?: Maybe<(
      { __typename?: 'Dota2Heroes' }
      & Pick<Dota2Heroes, 'options'>
    )> }
  ) }
);

export type Report_Dota2_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Dota2_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportDota2Game: (
    { __typename?: 'ReportDota2GamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type Report_Fortnite_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Fortnite_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportFortniteGame: (
    { __typename?: 'ReportFortniteGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type LolChampionsQueryVariables = {};


export type LolChampionsQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { lolChampions?: Maybe<(
      { __typename?: 'LolChampions' }
      & Pick<LolChampions, 'options'>
    )> }
  ) }
);

export type Report_Lol_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Lol_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportLolGame: (
    { __typename?: 'ReportLolGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type ReportMaddenGameMutationVariables = {
  gameId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  winningTeamId: Scalars['ID'];
  evidenceScreenshot: Scalars['String'];
};


export type ReportMaddenGameMutation = (
  { __typename?: 'Mutation' }
  & { reportMaddenGame: (
    { __typename?: 'ReportMaddenGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type Report_Mtga_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Mtga_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportMtgaGame: (
    { __typename?: 'ReportMtgaGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type NbaTeamsQueryVariables = {};


export type NbaTeamsQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { nbaTeams?: Maybe<(
      { __typename?: 'NbaTeams' }
      & Pick<NbaTeams, 'options'>
    )> }
  ) }
);

export type Report_Nba_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Nba_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportNbaGame: (
    { __typename?: 'ReportNbaGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type NhlTeamsQueryVariables = {};


export type NhlTeamsQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { nhlTeams?: Maybe<(
      { __typename?: 'NhlTeams' }
      & Pick<NhlTeams, 'options'>
    )> }
  ) }
);

export type Report_Nhl_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Nhl_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportNhlGame: (
    { __typename?: 'ReportNhlGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type OverwatchHeroesQueryVariables = {};


export type OverwatchHeroesQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { owHeroes?: Maybe<(
      { __typename?: 'OverwatchHeroes' }
      & Pick<OverwatchHeroes, 'options'>
    )> }
  ) }
);

export type ReportOwGameMutationVariables = {
  gameId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  winningTeamId: Scalars['ID'];
  mapId: Scalars['ID'];
  evidenceScreenshot: Scalars['String'];
};


export type ReportOwGameMutation = (
  { __typename?: 'Mutation' }
  & { reportOwGame: (
    { __typename?: 'ReportOwGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type Report_Rl_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Rl_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportRlGame: (
    { __typename?: 'ReportRlGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type SfvFightersQueryVariables = {};


export type SfvFightersQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { sfvFighters?: Maybe<(
      { __typename?: 'SfvFighters' }
      & Pick<SfvFighters, 'options'>
    )> }
  ) }
);

export type Report_Sfv_GameMutationVariables = {
  gameId: Scalars['ID'];
  winningTeamId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  evidenceScreenshot: Scalars['String'];
};


export type Report_Sfv_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportSfvGame: (
    { __typename?: 'ReportSfvGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type SmashCharactersQueryVariables = {};


export type SmashCharactersQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { smashCharacters?: Maybe<(
      { __typename?: 'SmashCharacters' }
      & Pick<SmashCharacters, 'options'>
    )> }
  ) }
);

export type ReportSmashGameMutationVariables = {
  gameId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  winningTeamId: Scalars['ID'];
  mapId: Scalars['ID'];
  evidenceScreenshot: Scalars['String'];
};


export type ReportSmashGameMutation = (
  { __typename?: 'Mutation' }
  & { reportSmashGame: (
    { __typename?: 'ReportSmashGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type ValorantAgentsQueryVariables = {};


export type ValorantAgentsQuery = (
  { __typename?: 'Query' }
  & { sportPlayerCharacteristics: (
    { __typename?: 'SportPlayerCharacteristics' }
    & { valorantAgents?: Maybe<(
      { __typename?: 'ValorantAgent' }
      & Pick<ValorantAgent, 'options'>
    )> }
  ) }
);

export type Report_Valorant_GameMutationVariables = {
  gameId: Scalars['ID'];
  homeTeam: UserReportedGame;
  awayTeam: UserReportedGame;
  mapId: Scalars['ID'];
  evidenceScreenshot: Scalars['String'];
};


export type Report_Valorant_GameMutation = (
  { __typename?: 'Mutation' }
  & { reportValorantGame: (
    { __typename?: 'ReportValorantGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type GetTopTeamsQueryVariables = {};


export type GetTopTeamsQuery = (
  { __typename?: 'Query' }
  & { topTeams: Array<(
    { __typename?: 'TopTeam' }
    & Pick<TopTeam, 'rank'>
    & { team: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { selectedSeasonStats?: Maybe<(
        { __typename?: 'TeamSeasonStats' }
        & Pick<TeamSeasonStats, 'losses' | 'wins' | 'id'>
      )> }
    ) }
  )> }
);

export type InviteUserToTeamMutationVariables = {
  email: Scalars['String'];
  teamId: Scalars['ID'];
  gameRole?: Maybe<Scalars['String']>;
};


export type InviteUserToTeamMutation = (
  { __typename?: 'Mutation' }
  & { inviteUserToTeam: (
    { __typename?: 'InviteUserToTeamPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type InviteUserToPlatformMutationVariables = {
  email: Scalars['String'];
};


export type InviteUserToPlatformMutation = (
  { __typename?: 'Mutation' }
  & { inviteUserToPlatform: (
    { __typename?: 'InviteUserToPlatformPayload' }
    & Pick<InviteUserToPlatformPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type SignInMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn: (
    { __typename?: 'SignInPayload' }
    & Pick<SignInPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'UserForAuth' }
      & AuthenticationAttributesFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type SignUpMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
};


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp: (
    { __typename?: 'SignUpPayload' }
    & Pick<SignUpPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'UserForAuth' }
      & AuthenticationAttributesFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type SignOutMutationVariables = {};


export type SignOutMutation = (
  { __typename?: 'Mutation' }
  & { signOut: (
    { __typename?: 'SignOutPayload' }
    & Pick<SignOutPayload, 'success'>
  ) }
);

export type MatchCarouselMatchInfoFragment = (
  { __typename?: 'Match' }
  & Pick<Match, 'id' | 'sport' | 'scheduledDate' | 'rescheduleDate' | 'currentUserCheckedIn' | 'checkinAvailable' | 'currentUserIsTeamMember' | 'currentUserCanReport' | 'homeTeamCheckedIn' | 'awayTeamCheckedIn'>
  & { homeTeam?: Maybe<(
    { __typename?: 'Team' }
    & MatchCarouselTeamInfoFragment
  )>, awayTeam?: Maybe<(
    { __typename?: 'Team' }
    & MatchCarouselTeamInfoFragment
  )> }
);

export type MatchCarouselTournamentCheckinInfoFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'id' | 'sport' | 'firstMatchDate' | 'name' | 'currentUserCheckedIn' | 'currentUserCanCheckIn'>
);

export type MatchCarouselTeamInfoFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'currentUserOnTeam'>
);

export type CheckinTeamForMatchMutationVariables = {
  teamId: Scalars['ID'];
  matchId: Scalars['ID'];
};


export type CheckinTeamForMatchMutation = (
  { __typename?: 'Mutation' }
  & { createNewMatchCheckin: (
    { __typename?: 'CreateNewMatchCheckinPayload' }
    & Pick<CreateNewMatchCheckinPayload, 'success' | 'value'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UncheckinTeamFromMatchMutationVariables = {
  teamId: Scalars['ID'];
  matchId: Scalars['ID'];
};


export type UncheckinTeamFromMatchMutation = (
  { __typename?: 'Mutation' }
  & { deleteMatchCheckin: (
    { __typename?: 'DeleteMatchCheckinPayload' }
    & Pick<DeleteMatchCheckinPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type AutoReportLolGameMutationVariables = {
  id: Scalars['ID'];
};


export type AutoReportLolGameMutation = (
  { __typename?: 'Mutation' }
  & { autoReportLolGame: (
    { __typename?: 'AutoReportLolGamePayload' }
    & { match?: Maybe<(
      { __typename?: 'Match' }
      & Pick<Match, 'id' | 'settled'>
    )> }
  ) }
);

export type UpdateMatchMessagesQueryVariables = {
  matchId: Scalars['ID'];
  since?: Maybe<Scalars['Int']>;
};


export type UpdateMatchMessagesQuery = (
  { __typename?: 'Query' }
  & { match?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, 'id'>
    & { matchMessages: Array<(
      { __typename?: 'MatchMessage' }
      & Pick<MatchMessage, 'id' | 'hidden' | 'body' | 'createdAt'>
      & { user: (
        { __typename?: 'UserForComment' }
        & Pick<UserForComment, 'displayName' | 'isAdmin' | 'id'>
      ) }
    )> }
  )> }
);

export type GetMatchMessageCountQueryVariables = {
  matchId: Scalars['ID'];
};


export type GetMatchMessageCountQuery = (
  { __typename?: 'Query' }
  & { match?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'messagesCount' | 'hiddenMessagesCount'>
  )> }
);

export type AddMatchMessageMutationVariables = {
  id: Scalars['ID'];
  body: Scalars['String'];
};


export type AddMatchMessageMutation = (
  { __typename?: 'Mutation' }
  & { addMatchMessage: (
    { __typename?: 'AddMatchMessagePayload' }
    & Pick<AddMatchMessagePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'message'>
    )>> }
  ) }
);

export type MyTeamInfoFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'selectedSeasonConferenceName' | 'isRecruiting' | 'sportSlug'>
  & { selectedSeason?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'divisionName' | 'seasonYear'>
  )>, selectedSeasonStats?: Maybe<(
    { __typename?: 'TeamSeasonStats' }
    & Pick<TeamSeasonStats, 'losses' | 'wins' | 'id'>
  )> }
);

export type CheckAuthQueryVariables = {};


export type CheckAuthQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'id' | 'username' | 'isAdmin' | 'isIncompleteAccount' | 'emailForVerification'>
  )> }
);

export type NewsBrief_NewsArticleFragment = (
  { __typename?: 'NewsArticle' }
  & Pick<NewsArticle, 'id' | 'createdAt' | 'title' | 'featuredImage' | 'slug'>
  & { newsCategory: (
    { __typename?: 'NewsCategory' }
    & Pick<NewsCategory, 'id' | 'name'>
  ) }
);

export type NewsBriefQueryVariables = {};


export type NewsBriefQuery = (
  { __typename?: 'Query' }
  & { newsBrief?: Maybe<Array<(
    { __typename?: 'NewsArticle' }
    & NewsBrief_NewsArticleFragment
  )>> }
);

export type MyNotificationsQueryVariables = {};


export type MyNotificationsQuery = (
  { __typename?: 'Query' }
  & { myNotifications?: Maybe<Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'description' | 'title' | 'read' | 'createdAt'>
  )>> }
);

export type MarkNotificationsReadMutationVariables = {
  ids: Array<Scalars['ID']>;
};


export type MarkNotificationsReadMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationsRead: (
    { __typename?: 'MarkNotificationsReadPayload' }
    & Pick<MarkNotificationsReadPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type OauthSignInMutationVariables = {
  code: Scalars['String'];
  provider: OAuthProviderEnum;
  state: Scalars['String'];
};


export type OauthSignInMutation = (
  { __typename?: 'Mutation' }
  & { oauthSignIn: (
    { __typename?: 'UserForAuth' }
    & AuthenticationAttributesFragment
  ) }
);

export type HomepageRankingTournamentsQueryVariables = {};


export type HomepageRankingTournamentsQuery = (
  { __typename?: 'Query' }
  & { homepageRankingTournaments: Array<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'name' | 'firstMatchDate' | 'style' | 'published' | 'registrationCloseDate' | 'registrationOpenDate' | 'decided'>
    & { platform?: Maybe<(
      { __typename?: 'Platform' }
      & Pick<Platform, 'id' | 'title'>
    )> }
  )> }
);

export type GetTopUniversitiesQueryVariables = {};


export type GetTopUniversitiesQuery = (
  { __typename?: 'Query' }
  & { topUniversities: Array<(
    { __typename?: 'TopUniversity' }
    & Pick<TopUniversity, 'rank'>
    & { university: (
      { __typename?: 'University' }
      & Pick<University, 'id' | 'name' | 'state'>
    ) }
  )> }
);

export type RequestMatchRescheduleMutationVariables = {
  matchId: Scalars['ID'];
  rescheduleDate: Scalars['DateTime'];
};


export type RequestMatchRescheduleMutation = (
  { __typename?: 'Mutation' }
  & { requestMatchReschedule: (
    { __typename?: 'RequestMatchReschedulePayload' }
    & Pick<RequestMatchReschedulePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>>, value?: Maybe<(
      { __typename?: 'Match' }
      & MatchDetailAttributesFragment
    )> }
  ) }
);

export type RescheduleMatchMutationVariables = {
  matchId: Scalars['ID'];
  rescheduleDate: Scalars['DateTime'];
};


export type RescheduleMatchMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleMatch: (
    { __typename?: 'RescheduleMatchPayload' }
    & Pick<RescheduleMatchPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>>, value?: Maybe<(
      { __typename?: 'Match' }
      & MatchDetailAttributesFragment
    )> }
  ) }
);

export type ApproveMatchRescheduleMutationVariables = {
  matchId: Scalars['ID'];
  approve: Scalars['Boolean'];
};


export type ApproveMatchRescheduleMutation = (
  { __typename?: 'Mutation' }
  & { approveMatchReschedule: (
    { __typename?: 'ApproveMatchReschedulePayload' }
    & Pick<ApproveMatchReschedulePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>>, value?: Maybe<(
      { __typename?: 'Match' }
      & MatchDetailAttributesFragment
    )> }
  ) }
);

export type ResetPasswordMutationVariables = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type ApplyForTeamMutationVariables = {
  id: Scalars['ID'];
  gameRole?: Maybe<Scalars['String']>;
};


export type ApplyForTeamMutation = (
  { __typename?: 'Mutation' }
  & { applyForTeam: (
    { __typename?: 'ApplyForTeamPayload' }
    & Pick<ApplyForTeamPayload, 'success'>
  ) }
);

export type TeamDetailPageQueryVariables = {
  id: Scalars['ID'];
};


export type TeamDetailPageQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & TeamDetailQueryFragment
    & TeamPendingInvitationsQueryFragment
    & TeamRosterEntriesQueryFragment
    & TeamSeasonInfoQueryFragment
    & TeamSettledMatchesQueryFragment
    & UpcomingMatchesQueryFragment
    & UnreportedMatchesQueryFragment
    & TournamentsToCheckinToQueryFragment
    & TeamMessageBoardFragment
  )> }
);

export type UpdateTeamMessagesQueryVariables = {
  teamId: Scalars['ID'];
  since?: Maybe<Scalars['Int']>;
};


export type UpdateTeamMessagesQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
    & { teamMessages: Array<(
      { __typename?: 'TeamMessage' }
      & Pick<TeamMessage, 'id' | 'body' | 'createdAt'>
      & { user: (
        { __typename?: 'UserForComment' }
        & Pick<UserForComment, 'displayName' | 'isAdmin' | 'id'>
      ) }
    )> }
  )> }
);

export type GetTeamMessageCountQueryVariables = {
  teamId: Scalars['ID'];
};


export type GetTeamMessageCountQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'messagesCount'>
  )> }
);

export type AddTeamMessageMutationVariables = {
  id: Scalars['ID'];
  body: Scalars['String'];
};


export type AddTeamMessageMutation = (
  { __typename?: 'Mutation' }
  & { addTeamMessage: (
    { __typename?: 'AddTeamMessagePayload' }
    & Pick<AddTeamMessagePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'message'>
    )>> }
  ) }
);

export type TournamentAdminQueryVariables = {
  id: Scalars['ID'];
};


export type TournamentAdminQuery = (
  { __typename?: 'Query' }
  & { tournament?: Maybe<(
    { __typename?: 'Tournament' }
    & { adminTournamentTeams?: Maybe<Array<(
      { __typename?: 'Team' }
      & AdminTournamentTeamInfoFragment
    )>>, checkedInTeams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )>> }
    & AdminTournamentInfoFragment
  )> }
);

export type CheckinTeamForTournamentMutationVariables = {
  teamId: Scalars['ID'];
  tournamentId: Scalars['ID'];
};


export type CheckinTeamForTournamentMutation = (
  { __typename?: 'Mutation' }
  & { createNewCheckin: (
    { __typename?: 'CreateNewCheckinPayload' }
    & Pick<CreateNewCheckinPayload, 'success' | 'value'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UncheckinTeamFromTournamentMutationVariables = {
  teamId: Scalars['ID'];
  tournamentId: Scalars['ID'];
};


export type UncheckinTeamFromTournamentMutation = (
  { __typename?: 'Mutation' }
  & { deleteTournamentCheckin: (
    { __typename?: 'DeleteTournamentCheckinPayload' }
    & Pick<DeleteTournamentCheckinPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type TournamentDetailRegModalQueryVariables = {};


export type TournamentDetailRegModalQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentUser' }
    & TournamentDetail_CurrentUserFragment
  )> }
);

export type RegisterTeamForTournamentMutationVariables = {
  teamId: Scalars['ID'];
  tournamentId: Scalars['ID'];
};


export type RegisterTeamForTournamentMutation = (
  { __typename?: 'Mutation' }
  & { registerTeamForTournament: (
    { __typename?: 'RegisterTeamForTournamentPayload' }
    & Pick<RegisterTeamForTournamentPayload, 'success' | 'value'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UnregisterTeamFromTournamentMutationVariables = {
  id: Scalars['ID'];
};


export type UnregisterTeamFromTournamentMutation = (
  { __typename?: 'Mutation' }
  & { deleteTournamentRegistry: (
    { __typename?: 'DeleteTournamentRegistryPayload' }
    & Pick<DeleteTournamentRegistryPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type ReviewForfeitMutationVariables = {
  forfeitId: Scalars['ID'];
  rejectedMessage?: Maybe<Scalars['String']>;
  accepted: Scalars['Boolean'];
};


export type ReviewForfeitMutation = (
  { __typename?: 'Mutation' }
  & { reviewForfeit: (
    { __typename?: 'ReviewForfeitPayload' }
    & Pick<ReviewForfeitPayload, 'errors'>
  ) }
);

export type UserAccountSettingsQueryVariables = {
  id?: Maybe<Scalars['ID']>;
};


export type UserAccountSettingsQuery = (
  { __typename?: 'Query' }
  & { userSettings?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'subscriptionEndDate' | 'personalEmail' | 'isPremiumUser'>
  )> }
);

export type VerfiyNewEsportsUserMutationVariables = {
  name: Scalars['String'];
  discordUsername: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
};


export type VerfiyNewEsportsUserMutation = (
  { __typename?: 'Mutation' }
  & { verifyNewUser: (
    { __typename?: 'VerifyNewUserPayload' }
    & Pick<VerifyNewUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type VerfiyNewUserMutationVariables = {
  name: Scalars['String'];
  email: Scalars['String'];
  universityId: Scalars['ID'];
  universityMajorId?: Maybe<Scalars['ID']>;
  graduationYear: Scalars['Int'];
  discordUsername: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
};


export type VerfiyNewUserMutation = (
  { __typename?: 'Mutation' }
  & { verifyNewUser: (
    { __typename?: 'VerifyNewUserPayload' }
    & Pick<VerifyNewUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type GetInvitedUserQueryVariables = {};


export type GetInvitedUserQuery = (
  { __typename?: 'Query' }
  & { invitedUser?: Maybe<(
    { __typename?: 'InvitedUser' }
    & Pick<InvitedUser, 'email'>
    & { university?: Maybe<(
      { __typename?: 'University' }
      & CurrentUser_UniversityFragment
    )>, pendingTeamInvitations?: Maybe<Array<(
      { __typename?: 'PendingInvitation' }
      & CurrentUser_PendingInvitationsFragment
    )>> }
  )> }
);

export type PendingForfeitsQueryVariables = {
  sports?: Maybe<Array<Scalars['String']>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type PendingForfeitsQuery = (
  { __typename?: 'Query' }
  & { pendingForfeitsConnection: (
    { __typename?: 'ForfeitConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ForfeitEdge' }
      & Pick<ForfeitEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Forfeit' }
        & Pick<Forfeit, 'id' | 'requestingTeamName' | 'opposingTeamName' | 'explanation' | 'proofScreenshotUrl' | 'updatedAt'>
        & { match: (
          { __typename?: 'Match' }
          & Pick<Match, 'id' | 'sport'>
        ) }
      )> }
    )>>> }
  ) }
);

export type LeagueDetail_TeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'sportSlug'>
  & { seasonRegistries: Array<(
    { __typename?: 'SeasonRegistry' }
    & Pick<SeasonRegistry, 'id'>
    & { season: (
      { __typename?: 'Season' }
      & Pick<Season, 'id'>
    ) }
  )>, teamEnrollments: Array<(
    { __typename?: 'TeamEnrollment' }
    & Pick<TeamEnrollment, 'id'>
    & { season?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id'>
    )> }
  )> }
);

export type LeagueDetail_RegisteredTeamUniversityFragment = (
  { __typename?: 'University' }
  & Pick<University, 'id' | 'name'>
);

export type LeagueDetail_RegisteredTeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'conferenceIds'>
  & { university?: Maybe<(
    { __typename?: 'University' }
    & LeagueDetail_RegisteredTeamUniversityFragment
  )> }
);

export type LeagueDetail_CurrentUserFragment = (
  { __typename?: 'CurrentUser' }
  & Pick<CurrentUser, 'id'>
  & { coordinatingTeams: Array<(
    { __typename?: 'Team' }
    & LeagueDetail_TeamFragment
  )> }
);

export type LeagueDetail_ConferenceFragment = (
  { __typename?: 'Conference' }
  & Pick<Conference, 'id' | 'name'>
);

export type LeagueDetail_SeasonFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'id' | 'name' | 'teamsCount' | 'participantsCount' | 'registrationStatus' | 'banner' | 'sponsorImage' | 'firstMatchDate' | 'seasonEndDate' | 'seasonYear'>
  & { teamSeasonStats: Array<(
    { __typename?: 'TeamSeasonStats' }
    & Pick<TeamSeasonStats, 'teamId' | 'wins' | 'losses' | 'rank'>
  )>, conferences: Array<(
    { __typename?: 'Conference' }
    & LeagueDetail_ConferenceFragment
  )>, division?: Maybe<(
    { __typename?: 'Division' }
    & Pick<Division, 'logoUrl'>
  )>, sport?: Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'slug'>
  )>, registeredTeams: Array<(
    { __typename?: 'Team' }
    & LeagueDetail_RegisteredTeamFragment
  )>, enrolledTeams: Array<(
    { __typename?: 'Team' }
    & LeagueDetail_RegisteredTeamFragment
  )> }
);

export type LeagueDetailQueryVariables = {
  id: Scalars['ID'];
};


export type LeagueDetailQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentUser' }
    & LeagueDetail_CurrentUserFragment
  )>, season?: Maybe<(
    { __typename?: 'Season' }
    & LeagueDetail_SeasonFragment
  )> }
);

export type LeagueDetailRegisterTeamForSeasonMutationVariables = {
  teamId: Scalars['ID'];
  seasonId: Scalars['ID'];
};


export type LeagueDetailRegisterTeamForSeasonMutation = (
  { __typename?: 'Mutation' }
  & { registerTeamForSeason: (
    { __typename?: 'RegisterTeamForSeasonPayload' }
    & Pick<RegisterTeamForSeasonPayload, 'success' | 'value'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type SeasonScheduleModalGamesFragment = (
  { __typename?: 'Game' }
  & Pick<Game, 'id'>
  & { winner?: Maybe<(
    { __typename?: 'Lineup' }
    & Pick<Lineup, 'id'>
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )> }
  )> }
);

export type SeasonScheduleModalMatchFragment = (
  { __typename?: 'Match' }
  & Pick<Match, 'id' | 'settledMemo' | 'scheduledDate'>
  & { teamEnrollmentOne?: Maybe<(
    { __typename?: 'TeamEnrollment' }
    & Pick<TeamEnrollment, 'id'>
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )> }
  )>, teamEnrollmentTwo?: Maybe<(
    { __typename?: 'TeamEnrollment' }
    & Pick<TeamEnrollment, 'id'>
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )> }
  )>, games?: Maybe<Array<(
    { __typename?: 'Game' }
    & SeasonScheduleModalGamesFragment
  )>> }
);

export type SeasonSchedulesModalQueryVariables = {
  id: Scalars['ID'];
  weekNumber: Scalars['Int'];
};


export type SeasonSchedulesModalQuery = (
  { __typename?: 'Query' }
  & { season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id'>
    & { roundIntervals?: Maybe<Array<(
      { __typename?: 'RoundInterval' }
      & Pick<RoundInterval, 'scheduledDate' | 'id'>
    )>>, scheduleForWeek?: Maybe<(
      { __typename?: 'RoundInterval' }
      & Pick<RoundInterval, 'id'>
      & { matches?: Maybe<Array<(
        { __typename?: 'Match' }
        & SeasonScheduleModalMatchFragment
      )>> }
    )> }
  )> }
);

export type Leagues_SeasonFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'id' | 'name' | 'seasonYear' | 'registrationStatus'>
  & { sport?: Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'slug'>
  )> }
);

export type Leagues_DivisionFragment = (
  { __typename?: 'Division' }
  & Pick<Division, 'id' | 'name' | 'position' | 'bannerUrl' | 'logoUrl'>
  & { seasons: Array<(
    { __typename?: 'Season' }
    & Leagues_SeasonFragment
  )> }
);

export type LeaguesDivisionsQueryVariables = {
  seasonYears?: Maybe<Array<Scalars['String']>>;
};


export type LeaguesDivisionsQuery = (
  { __typename?: 'Query' }
  & { divisions: Array<(
    { __typename?: 'Division' }
    & Leagues_DivisionFragment
  )> }
);

export type ManageNewsAndAnnouncements_NewsOrAnnouncementFragment = (
  { __typename?: 'NewsArticle' }
  & Pick<NewsArticle, 'id' | 'type' | 'title' | 'body' | 'summary' | 'sport' | 'featuredImage' | 'featured' | 'position' | 'published'>
  & { newsCategory: (
    { __typename?: 'NewsCategory' }
    & Pick<NewsCategory, 'id' | 'name'>
  ) }
);

export type NewsOrAnnouncementQueryVariables = {
  id: Scalars['ID'];
};


export type NewsOrAnnouncementQuery = (
  { __typename?: 'Query' }
  & { newsOrAnnouncement: (
    { __typename?: 'NewsOrAnnouncementPayload' }
    & Pick<NewsOrAnnouncementPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'NewsArticle' }
      & ManageNewsAndAnnouncements_NewsOrAnnouncementFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type SetNewCoordinatorMutationVariables = {
  id: Scalars['ID'];
  coordinatorId: Scalars['ID'];
};


export type SetNewCoordinatorMutation = (
  { __typename?: 'Mutation' }
  & { upsertTeam: (
    { __typename?: 'UpsertTeamPayload' }
    & Pick<UpsertTeamPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UpdateRosterEntryMutationVariables = {
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  gameRole?: Maybe<Scalars['String']>;
  staffRole?: Maybe<Scalars['String']>;
  kicked?: Maybe<Scalars['Boolean']>;
};


export type UpdateRosterEntryMutation = (
  { __typename?: 'Mutation' }
  & { updateRosterEntry: (
    { __typename?: 'UpdateRosterEntryPayload' }
    & Pick<UpdateRosterEntryPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UpdateTeamMutationVariables = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pendingLogo?: Maybe<Scalars['String']>;
  approvedLogo?: Maybe<Scalars['String']>;
  pendingBanner?: Maybe<Scalars['String']>;
  approvedBanner?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  twitchUsername?: Maybe<Scalars['String']>;
};


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { upsertTeam: (
    { __typename?: 'UpsertTeamPayload' }
    & Pick<UpsertTeamPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type GetUniversityAdminQueryVariables = {
  id: Scalars['ID'];
};


export type GetUniversityAdminQuery = (
  { __typename?: 'Query' }
  & { university?: Maybe<(
    { __typename?: 'University' }
    & Pick<University, 'id' | 'name' | 'abbreviation' | 'state' | 'domains'>
  )> }
);

export type MatchDetailPageQueryVariables = {
  id: Scalars['ID'];
};


export type MatchDetailPageQuery = (
  { __typename?: 'Query' }
  & { match?: Maybe<(
    { __typename?: 'Match' }
    & MatchDetailAttributesFragment
  )> }
);

export type GetTeamsWithPendingImagesQueryVariables = {};


export type GetTeamsWithPendingImagesQuery = (
  { __typename?: 'Query' }
  & { teams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { coordinator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
    )>, pendingLogoImage?: Maybe<(
      { __typename?: 'TeamImage' }
      & Pick<TeamImage, 'name' | 'dataUrl'>
    )>, pendingBannerImage?: Maybe<(
      { __typename?: 'TeamImage' }
      & Pick<TeamImage, 'name' | 'dataUrl'>
    )> }
  )> }
);

export type ApproveTeamImagesMutationVariables = {
  id: Scalars['ID'];
  approveBanner?: Maybe<Scalars['Boolean']>;
  approveLogo?: Maybe<Scalars['Boolean']>;
};


export type ApproveTeamImagesMutation = (
  { __typename?: 'Mutation' }
  & { approveTeamImages: (
    { __typename?: 'ApproveTeamImagesPayload' }
    & Pick<ApproveTeamImagesPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type MyUniversityDetailPageQueryVariables = {
  selectedYear?: Maybe<Scalars['String']>;
};


export type MyUniversityDetailPageQuery = (
  { __typename?: 'Query' }
  & { myUniversity?: Maybe<(
    { __typename?: 'University' }
    & UniversityHubQueryFragment
  )> }
);

export type NewsAndAnnouncements_NewsArticleFragment = (
  { __typename?: 'NewsArticle' }
  & Pick<NewsArticle, 'id' | 'title' | 'sport' | 'summary' | 'body' | 'position' | 'featured' | 'featuredImage' | 'type' | 'createdAt' | 'slug' | 'published'>
  & { newsCategory: (
    { __typename?: 'NewsCategory' }
    & Pick<NewsCategory, 'id' | 'name'>
  ) }
);

export type NewsAndAnnouncements_PaginatedFragment = (
  { __typename?: 'NewsOrAnnouncementsPaginatedPayload' }
  & Pick<NewsOrAnnouncementsPaginatedPayload, 'totalPages' | 'currentPage' | 'count'>
  & { items: Array<(
    { __typename?: 'NewsArticle' }
    & NewsAndAnnouncements_NewsArticleFragment
  )> }
);

export type NewsOrAnnouncementsQueryVariables = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  type: NewsArticleTypes;
  sort?: Maybe<NewsArticlesSortOrders>;
  sports?: Maybe<Array<SportSlugs>>;
  categories?: Maybe<Array<PostCategories>>;
};


export type NewsOrAnnouncementsQuery = (
  { __typename?: 'Query' }
  & { newsOrAnnouncements: (
    { __typename?: 'NewsOrAnnouncementsPayload' }
    & Pick<NewsOrAnnouncementsPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'NewsOrAnnouncementsPaginatedPayload' }
      & NewsAndAnnouncements_PaginatedFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type UpsertNewsOrAnnouncementMutationVariables = {
  type: NewsArticleTypes;
  title: Scalars['String'];
  body: Scalars['String'];
  summary: Scalars['String'];
  category?: Maybe<PostCategories>;
  sport?: Maybe<SportSlugs>;
  featuredImage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  featured?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};


export type UpsertNewsOrAnnouncementMutation = (
  { __typename?: 'Mutation' }
  & { upsertNewsOrAnnouncement: (
    { __typename?: 'UpsertNewsOrAnnouncementPayload' }
    & Pick<UpsertNewsOrAnnouncementPayload, 'success'>
    & { value?: Maybe<(
      { __typename?: 'NewsArticle' }
      & Pick<NewsArticle, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type NewsArticleDetailQueryVariables = {
  slug: Scalars['String'];
};


export type NewsArticleDetailQuery = (
  { __typename?: 'Query' }
  & { newsArticle?: Maybe<(
    { __typename?: 'NewsArticle' }
    & Pick<NewsArticle, 'id' | 'createdAt' | 'title' | 'summary' | 'body' | 'featuredImage' | 'featured' | 'position' | 'type' | 'slug'>
    & { newsCategory: (
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id' | 'name'>
    ) }
  )> }
);

export type PlayerProfileQueryVariables = {
  id: Scalars['ID'];
};


export type PlayerProfileQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'username' | 'discordId' | 'discordUsername' | 'facebookUsername' | 'twitterUsername' | 'twitchUsername' | 'lolSummonerTier' | 'lolChampionName' | 'lolRiotImageName' | 'lolSummonerName' | 'dota2HeroImageName' | 'dota2SteamId' | 'sc2PreferredRace' | 'hsPreferredClass' | 'vgPreferredHero' | 'nhlPreferredTeam' | 'owPreferredHero' | 'maddenPreferredTeam' | 'nbaPreferredTeam' | 'sfvPreferredFighter' | 'sfvFid' | 'mtgaPreferredColor' | 'csgoEseaProfileUrl' | 'csgoSteamId' | 'rlSteamId' | 'valorantRiotAccount' | 'valorantPreferredChampion'>
    & { university: (
      { __typename?: 'University' }
      & Pick<University, 'id' | 'name'>
    ), lolAverageStats?: Maybe<(
      { __typename?: 'LolGamePlayerStats' }
      & Pick<LolGamePlayerStats, 'id' | 'kills' | 'deaths' | 'assists' | 'totalDamageDealtToChampions' | 'goldPerMin' | 'creepsPerMin'>
    )>, playedSports?: Maybe<Array<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'slug'>
    )>>, activeRosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'id' | 'inGameName' | 'gameRole'>
      & { player: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { selectedSeasonStats?: Maybe<(
          { __typename?: 'UserSeasonStats' }
          & Pick<UserSeasonStats, 'id' | 'wins' | 'losses'>
        )> }
      ), team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'createdAt' | 'sportSlug' | 'seasonYear' | 'selectedSeasonConferenceName' | 'division' | 'selectedSeasonRank'>
        & { teamAwards: Array<(
          { __typename?: 'Award' }
          & Pick<Award, 'id'>
        )>, selectedSeason?: Maybe<(
          { __typename?: 'Season' }
          & Pick<Season, 'id' | 'divisionName'>
        )>, selectedSeasonStats?: Maybe<(
          { __typename?: 'TeamSeasonStats' }
          & Pick<TeamSeasonStats, 'id' | 'wins' | 'losses'>
        )>, selectedSeasonMatches?: Maybe<Array<(
          { __typename?: 'Match' }
          & Pick<Match, 'id' | 'scheduledDate' | 'settled'>
          & { homeTeam?: Maybe<(
            { __typename?: 'Team' }
            & Pick<Team, 'id' | 'name'>
          )>, awayTeam?: Maybe<(
            { __typename?: 'Team' }
            & Pick<Team, 'id' | 'name'>
          )>, games?: Maybe<Array<(
            { __typename?: 'Game' }
            & Pick<Game, 'id'>
            & { winner?: Maybe<(
              { __typename?: 'Lineup' }
              & Pick<Lineup, 'id'>
              & { team?: Maybe<(
                { __typename?: 'Team' }
                & Pick<Team, 'id'>
              )> }
            )> }
          )>> }
        )>> }
      ) }
    )>>, upcomingMatches?: Maybe<Array<(
      { __typename?: 'Match' }
      & MatchCarouselMatchInfoFragment
    )>>, tournamentsToCheckinTo?: Maybe<Array<(
      { __typename?: 'Tournament' }
      & MatchCarouselTournamentCheckinInfoFragment
    )>>, unreportedMatches?: Maybe<Array<(
      { __typename?: 'Match' }
      & MatchCarouselMatchInfoFragment
    )>> }
    & PlayerProfileMatchHistoryQueryFragment
  )> }
);

export type UpdateUserAboutFieldsMutationVariables = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['ID']>;
  universityMajorId?: Maybe<Scalars['ID']>;
  graduationYear?: Maybe<Scalars['Int']>;
  discordUsername?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
};


export type UpdateUserAboutFieldsMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & Pick<UpdateUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type GetCurrentUserTeamInformationQueryVariables = {
  id?: Maybe<Scalars['ID']>;
};


export type GetCurrentUserTeamInformationQuery = (
  { __typename?: 'Query' }
  & { userSettings?: Maybe<(
    { __typename?: 'User' }
    & { university: (
      { __typename?: 'University' }
      & Pick<University, 'id'>
    ), pendingTeamInvitations: Array<(
      { __typename?: 'PendingInvitation' }
      & Pick<PendingInvitation, 'id' | 'gameRole' | 'playerPlaysSport'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'sportSlug' | 'overallWins' | 'overallLosses'>
      ) }
    )>, pendingApplications?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'id' | 'gameRole'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'sportSlug' | 'overallWins' | 'overallLosses'>
      ) }
    )>>, activeRosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'id' | 'gameRole'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'sportSlug' | 'overallWins' | 'overallLosses' | 'currentUserIsCoordinator'>
      ) }
    )>> }
  )> }
);

export type CreateRosterEntryMutationVariables = {
  teamPlayerInviteId: Scalars['ID'];
};


export type CreateRosterEntryMutation = (
  { __typename?: 'Mutation' }
  & { createRosterEntry: (
    { __typename?: 'CreateRosterEntryPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type DeleteTeamApplicationMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteTeamApplicationMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeamApplication: (
    { __typename?: 'DeleteTeamApplicationPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'field' | 'message'>
    )>> }
  ) }
);

export type TournamentDetailQueryVariables = {
  id: Scalars['ID'];
};


export type TournamentDetailQuery = (
  { __typename?: 'Query' }
  & { tournament?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'name' | 'style' | 'sport' | 'numberOfTeams' | 'firstMatchDate' | 'description' | 'address' | 'banner' | 'sponsorImage' | 'registrationOpenDate' | 'registrationCloseDate' | 'checkinLength' | 'currentUserNumberOfEligibleTeams' | 'containerId'>
    & { brackets?: Maybe<Array<(
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id'>
    )>>, checkins?: Maybe<Array<(
      { __typename?: 'Checkin' }
      & Pick<Checkin, 'teamId'>
    )>>, activeRosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'id' | 'gameRole' | 'inGameName'>
      & { player: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'discordId'>
      ) }
    )>>, playerStats?: Maybe<Array<(
      { __typename?: 'UserSeasonStats' }
      & Pick<UserSeasonStats, 'id' | 'wins' | 'losses'>
      & { player: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )>>, teamStats?: Maybe<Array<(
      { __typename?: 'TeamSeasonStats' }
      & Pick<TeamSeasonStats, 'id' | 'wins' | 'losses'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
      ) }
    )>>, teamSeeds?: Maybe<Array<(
      { __typename?: 'TeamSeed' }
      & Pick<TeamSeed, 'teamId' | 'seed'>
    )>>, teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'currentUserOnTeam' | 'truncatedName'>
      & { coordinator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username'>
      )>, university?: Maybe<(
        { __typename?: 'University' }
        & Pick<University, 'id' | 'abbreviation'>
      )> }
    )>>, grandFinals?: Maybe<Array<(
      { __typename?: 'BracketData' }
      & Pick<BracketData, 'seat'>
    )>>, bracketData?: Maybe<Array<(
      { __typename?: 'BracketData' }
      & Pick<BracketData, 'team1Id' | 'team1Wins' | 'settled' | 'matchId' | 'team2Id' | 'team2Wins' | 'seat' | 'scheduledDate'>
    )>>, seatTree?: Maybe<Array<(
      { __typename?: 'SeatTree' }
      & Pick<SeatTree, 'seat' | 'winnerTo' | 'loserTo'>
    )>> }
  )> }
);

export type PlayoffsListQueryVariables = {
  featuredTournamentId: Scalars['ID'];
};


export type PlayoffsListQuery = (
  { __typename?: 'Query' }
  & { playedSports: Array<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'slug'>
  )>, tournament?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'name' | 'sport' | 'firstMatchDate' | 'numberOfTeams' | 'style' | 'registrationCloseDate' | 'registrationOpenDate'>
  )> }
);

export type TournamentsQueryVariables = {
  sports?: Maybe<Array<Scalars['String']>>;
  state: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type TournamentsQuery = (
  { __typename?: 'Query' }
  & { tournamentsConnection: (
    { __typename?: 'TournamentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'TournamentEdge' }
      & Pick<TournamentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Tournament' }
        & Pick<Tournament, 'id' | 'name' | 'sport' | 'banner' | 'firstMatchDate' | 'numberOfTeams' | 'style' | 'registrationCloseDate' | 'registrationOpenDate' | 'decided'>
        & { platform?: Maybe<(
          { __typename?: 'Platform' }
          & Pick<Platform, 'id' | 'title'>
        )> }
      )> }
    )>>> }
  ) }
);

export type GetUniversitiesQueryVariables = {};


export type GetUniversitiesQuery = (
  { __typename?: 'Query' }
  & { universities: Array<(
    { __typename?: 'University' }
    & Pick<University, 'id' | 'name' | 'state'>
  )> }
);

export type UniversityDetailPageQueryVariables = {
  id: Scalars['ID'];
  selectedYear?: Maybe<Scalars['String']>;
};


export type UniversityDetailPageQuery = (
  { __typename?: 'Query' }
  & { university?: Maybe<(
    { __typename?: 'University' }
    & UniversityHubQueryFragment
  )> }
);

export type DashboardQueryVariables = {};


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { userDashboard?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { activeTeams: Array<(
      { __typename?: 'Team' }
      & MyTeamInfoFragment
    )>, upcomingMatches?: Maybe<Array<(
      { __typename?: 'Match' }
      & MatchCarouselMatchInfoFragment
    )>>, tournamentsToCheckinTo?: Maybe<Array<(
      { __typename?: 'Tournament' }
      & MatchCarouselTournamentCheckinInfoFragment
    )>>, unreportedMatches?: Maybe<Array<(
      { __typename?: 'Match' }
      & MatchCarouselMatchInfoFragment
    )>>, dashboardMatchHistory?: Maybe<Array<(
      { __typename?: 'Match' }
      & DashboardMatchHistoryInfoFragment
    )>> }
  )> }
);

export type User_SearchFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'personalEmail' | 'username' | 'discordUsername'>
);

export type UserSearchQueryVariables = {
  email: Scalars['String'];
};


export type UserSearchQuery = (
  { __typename?: 'Query' }
  & { userSearch: Array<(
    { __typename?: 'User' }
    & User_SearchFragment
  )> }
);

export type ConstantsQueryVariables = {};


export type ConstantsQuery = (
  { __typename?: 'Query' }
  & { constants: (
    { __typename?: 'Constants' }
    & Pick<Constants, 'sportSlugs' | 'postCategories' | 'teamStaffRoles' | 'currentSeasonYear'>
  ) }
);

export type DivisionsAttrsQueryVariables = {
  seasonYears?: Maybe<Array<Scalars['String']>>;
};


export type DivisionsAttrsQuery = (
  { __typename?: 'Query' }
  & { divisions: Array<(
    { __typename?: 'Division' }
    & Pick<Division, 'name' | 'position' | 'bannerUrl' | 'logoUrl'>
  )> }
);

export type AdminSeasonInfoQueryFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'banner' | 'division1' | 'divisionName' | 'divisionNumber' | 'firstMatchDate' | 'id' | 'isEditable' | 'isPremium' | 'maximumTeamSize' | 'minimumTeamSize' | 'name' | 'priceCents' | 'published' | 'regionChoice' | 'registrationCloseDate' | 'registrationOpenDate' | 'rosterLockDate' | 'scheduleTypeChoice' | 'seasonEndDate' | 'seasonYear' | 'sponsorImage'>
  & { matchPrototype?: Maybe<(
    { __typename?: 'MatchPrototype' }
    & Pick<MatchPrototype, 'id' | 'gameCount'>
  )>, sport?: Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'slug'>
  )> }
);

export type AdminMatchPrototypeInfoFragment = (
  { __typename?: 'MatchPrototype' }
  & Pick<MatchPrototype, 'id' | 'gameCount'>
);

export type AdminTournamentRoundInfoFragment = (
  { __typename?: 'Round' }
  & Pick<Round, 'id' | 'tournamentRoundName' | 'tournamentBracketOrder'>
  & { matchPrototype?: Maybe<(
    { __typename?: 'MatchPrototype' }
    & AdminMatchPrototypeInfoFragment
  )> }
);

export type AdminTournamentRegistryInfoFragment = (
  { __typename?: 'TournamentRegistry' }
  & Pick<TournamentRegistry, 'id' | 'seed'>
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )> }
);

export type AdminTournamentInfoFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'name' | 'description' | 'desiredBrackets' | 'multibracketCapable' | 'containerId' | 'id' | 'firstMatchDate' | 'registrationCloseDate' | 'registrationOpenDate' | 'banner' | 'sponsorImage' | 'published' | 'minTeamSize' | 'defaultSeriesLength' | 'typeChoice' | 'numberOfTeams' | 'styleChoice' | 'sport' | 'checkinLength' | 'isPremium'>
  & { brackets?: Maybe<Array<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id'>
  )>>, tournamentRegistries?: Maybe<Array<(
    { __typename?: 'TournamentRegistry' }
    & AdminTournamentRegistryInfoFragment
  )>>, season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id'>
  )>, platform?: Maybe<(
    { __typename?: 'Platform' }
    & Pick<Platform, 'id' | 'uid' | 'title'>
  )>, rounds?: Maybe<Array<(
    { __typename?: 'Round' }
    & AdminTournamentRoundInfoFragment
  )>> }
);

export type AdminTournamentTeamInfoFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'name' | 'id' | 'activePlayersCount'>
  & { university?: Maybe<(
    { __typename?: 'University' }
    & Pick<University, 'abbreviation' | 'name' | 'id' | 'state'>
  )> }
);

export type AdminSeasonTeamInfoFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'activePlayersCount'>
  & { university?: Maybe<(
    { __typename?: 'University' }
    & Pick<University, 'id' | 'abbreviation' | 'name' | 'state'>
  )> }
);

export type AdminSeasonConferencesFragment = (
  { __typename?: 'Conference' }
  & Pick<Conference, 'id' | 'name'>
  & { teams?: Maybe<Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )>> }
);

export type AdminSeasonTeamsQueryFragment = (
  { __typename?: 'Season' }
  & { adminSeasonTeams?: Maybe<Array<(
    { __typename?: 'Team' }
    & AdminSeasonTeamInfoFragment
  )>>, conferences: Array<(
    { __typename?: 'Conference' }
    & AdminSeasonConferencesFragment
  )> }
);

export type UniversityHubQueryFragment = (
  { __typename?: 'University' }
  & Pick<University, 'id' | 'name' | 'currentUserCanCreateTeam' | 'currentUserPlayedSports' | 'currentUserTeamIds' | 'numberOfAwards'>
  & { activeUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, seasonTeams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'selectedSeasonConferenceName' | 'isRecruiting' | 'sportSlug'>
    & { selectedSeason?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'divisionName' | 'seasonYear'>
    )>, selectedSeasonStats?: Maybe<(
      { __typename?: 'TeamSeasonStats' }
      & Pick<TeamSeasonStats, 'losses' | 'wins' | 'id'>
    )> }
  )>, newTeams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'selectedSeasonConferenceName' | 'isRecruiting' | 'sportSlug'>
    & { selectedSeason?: Maybe<(
      { __typename?: 'Season' }
      & Pick<Season, 'id' | 'divisionName' | 'seasonYear'>
    )>, selectedSeasonStats?: Maybe<(
      { __typename?: 'TeamSeasonStats' }
      & Pick<TeamSeasonStats, 'losses' | 'wins' | 'id'>
    )> }
  )> }
);

export type TeamDetailQueryFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'sportSlug' | 'currentUserIsCoordinator' | 'currentUserIsEligible' | 'currentUserOnTeam' | 'currentUserApplicationPending' | 'selectedSeasonConferenceName' | 'selectedSeasonRank' | 'currentSeasonEligible' | 'isRecruiting' | 'facebookUsername' | 'twitterUsername' | 'twitchUsername'>
  & { pendingLogoImage?: Maybe<(
    { __typename?: 'TeamImage' }
    & Pick<TeamImage, 'name' | 'dataUrl'>
  )>, approvedLogoImage?: Maybe<(
    { __typename?: 'TeamImage' }
    & Pick<TeamImage, 'name' | 'dataUrl'>
  )>, pendingBannerImage?: Maybe<(
    { __typename?: 'TeamImage' }
    & Pick<TeamImage, 'name' | 'dataUrl'>
  )>, approvedBannerImage?: Maybe<(
    { __typename?: 'TeamImage' }
    & Pick<TeamImage, 'name' | 'dataUrl'>
  )>, coordinator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'discordId'>
  )>, university?: Maybe<(
    { __typename?: 'University' }
    & Pick<University, 'id' | 'abbreviation' | 'name'>
  )>, teamAwards: Array<(
    { __typename?: 'Award' }
    & Pick<Award, 'id'>
  )> }
);

export type TeamPendingInvitationsQueryFragment = (
  { __typename?: 'Team' }
  & { pendingInvitations: Array<(
    { __typename?: 'PendingInvitation' }
    & Pick<PendingInvitation, 'id' | 'gameRole'>
    & { player: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'username' | 'discordUsername'>
    ) }
  )> }
);

export type TeamRosterEntriesQueryFragment = (
  { __typename?: 'Team' }
  & { activeRosterEntries?: Maybe<Array<(
    { __typename?: 'RosterEntry' }
    & Pick<RosterEntry, 'id' | 'inGameName' | 'accepted' | 'roleCd' | 'gameRole' | 'staffRoleCd'>
    & { player: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'discordId' | 'characterImageName'>
      & { university: (
        { __typename?: 'University' }
        & Pick<University, 'name' | 'id'>
      ) }
    ) }
  )>>, pendingRosterEntries: Array<(
    { __typename?: 'RosterEntry' }
    & Pick<RosterEntry, 'id' | 'gameRole'>
    & { player: (
      { __typename?: 'User' }
      & Pick<User, 'username' | 'discordUsername'>
    ) }
  )> }
);

export type TeamSeasonInfoQueryFragment = (
  { __typename?: 'Team' }
  & { selectedSeasonPlayerStats?: Maybe<Array<(
    { __typename?: 'UserSeasonStats' }
    & Pick<UserSeasonStats, 'id' | 'wins' | 'losses'>
    & { player: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>>, selectedSeasonStats?: Maybe<(
    { __typename?: 'TeamSeasonStats' }
    & Pick<TeamSeasonStats, 'id' | 'wins' | 'losses'>
  )>, selectedSeason?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id' | 'divisionName'>
  )> }
);

export type TeamSettledMatchesQueryFragment = (
  { __typename?: 'Team' }
  & { settledMatches: Array<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'scheduledDate'>
    & { homeTeam?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, awayTeam?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, games?: Maybe<Array<(
      { __typename?: 'Game' }
      & Pick<Game, 'id'>
      & { winner?: Maybe<(
        { __typename?: 'Lineup' }
        & Pick<Lineup, 'id'>
        & { team?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id'>
        )> }
      )> }
    )>> }
  )> }
);

export type UpcomingMatchesQueryFragment = (
  { __typename?: 'Team' }
  & { upcomingMatches?: Maybe<Array<(
    { __typename?: 'Match' }
    & MatchCarouselMatchInfoFragment
  )>> }
);

export type UnreportedMatchesQueryFragment = (
  { __typename?: 'Team' }
  & { unreportedMatches?: Maybe<Array<(
    { __typename?: 'Match' }
    & MatchCarouselMatchInfoFragment
  )>> }
);

export type TournamentsToCheckinToQueryFragment = (
  { __typename?: 'Team' }
  & { tournamentsToCheckinTo: Array<(
    { __typename?: 'Tournament' }
    & MatchCarouselTournamentCheckinInfoFragment
  )> }
);

export type TournamentDetail_TeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'sportSlug'>
  & { tournamentRegistries: Array<(
    { __typename?: 'TournamentRegistry' }
    & Pick<TournamentRegistry, 'id'>
    & { tournament: (
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id'>
    ) }
  )> }
);

export type TeamMessageBoardFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'currentUserOnTeam'>
  & { teamMessages: Array<(
    { __typename?: 'TeamMessage' }
    & Pick<TeamMessage, 'id' | 'hidden' | 'body' | 'createdAt'>
    & { user: (
      { __typename?: 'UserForComment' }
      & Pick<UserForComment, 'displayName' | 'isAdmin' | 'id'>
    ) }
  )> }
);

export type TeamMessageBoardUpdatesFragment = (
  { __typename?: 'Team' }
  & { teamMessages: Array<(
    { __typename?: 'TeamMessage' }
    & Pick<TeamMessage, 'id' | 'hidden' | 'body' | 'createdAt'>
    & { user: (
      { __typename?: 'UserForComment' }
      & Pick<UserForComment, 'displayName' | 'isAdmin' | 'id'>
    ) }
  )> }
);

export type TournamentDetail_CurrentUserFragment = (
  { __typename?: 'CurrentUser' }
  & Pick<CurrentUser, 'id'>
  & { coordinatingTeams: Array<(
    { __typename?: 'Team' }
    & TournamentDetail_TeamFragment
  )> }
);

export type CurrentUser_UniversityFragment = (
  { __typename?: 'University' }
  & Pick<University, 'id' | 'name'>
);

export type PendingInvitation_TeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'sportSlug' | 'overallWins' | 'overallLosses'>
);

export type CurrentUser_PendingInvitationsFragment = (
  { __typename?: 'PendingInvitation' }
  & Pick<PendingInvitation, 'id' | 'gameRole'>
  & { team: (
    { __typename?: 'Team' }
    & PendingInvitation_TeamFragment
  ) }
);

export type AuthenticationAttributesFragment = (
  { __typename?: 'UserForAuth' }
  & Pick<UserForAuth, 'accessToken' | 'username' | 'client' | 'expiry' | 'uid' | 'id' | 'isIncompleteAccount' | 'isAdmin' | 'teamIds'>
);

export type PlayerProfileMatchHistoryQueryFragment = (
  { __typename?: 'User' }
  & { reportedMatches: Array<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'scheduledDate'>
    & { homeTeam?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, awayTeam?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, games?: Maybe<Array<(
      { __typename?: 'Game' }
      & Pick<Game, 'id'>
      & { winner?: Maybe<(
        { __typename?: 'Lineup' }
        & Pick<Lineup, 'id'>
        & { team?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id'>
        )> }
      )> }
    )>> }
  )> }
);

export type MatchDetailAttributesFragment = (
  { __typename?: 'Match' }
  & Pick<Match, 'id' | 'sport' | 'currentUserCanReport' | 'currentUserCanApproveReschedule' | 'currentUserIsCoordinator' | 'currentUserIsTeamMember' | 'scheduledDate' | 'rescheduleDate' | 'homeTeamCheckedIn' | 'awayTeamCheckedIn' | 'settled' | 'isBye'>
  & { rescheduleRequestingTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )>, forfeit?: Maybe<(
    { __typename?: 'Forfeit' }
    & Pick<Forfeit, 'id' | 'requestingTeamName' | 'opposingTeamName' | 'accepted' | 'explanation' | 'rejectedMessage' | 'proofScreenshotUrl'>
  )>, playerStats?: Maybe<Array<(
    { __typename?: 'UserSeasonStats' }
    & Pick<UserSeasonStats, 'id' | 'wins' | 'losses'>
    & { player: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>>, maps?: Maybe<Array<(
    { __typename?: 'Map' }
    & Pick<Map, 'id' | 'name'>
  )>>, tournament?: Maybe<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'sport' | 'type' | 'name'>
  )>, awayTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'truncatedName' | 'sportSlug'>
    & { selectedSeasonStats?: Maybe<(
      { __typename?: 'TeamSeasonStats' }
      & Pick<TeamSeasonStats, 'id' | 'wins' | 'losses'>
    )>, coordinator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, activeRosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'id'>
    )>>, rosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'gameRole' | 'id' | 'inGameName' | 'roleCd'>
      & { player: (
        { __typename?: 'User' }
        & Pick<User, 'csgoEseaProfileUrl' | 'id' | 'discordId' | 'discordUsername' | 'username' | 'characterName' | 'characterImageName'>
      ) }
    )>> }
  )>, homeTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'truncatedName' | 'sportSlug'>
    & { selectedSeasonStats?: Maybe<(
      { __typename?: 'TeamSeasonStats' }
      & Pick<TeamSeasonStats, 'id' | 'wins' | 'losses'>
    )>, coordinator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, activeRosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'id'>
    )>>, rosterEntries?: Maybe<Array<(
      { __typename?: 'RosterEntry' }
      & Pick<RosterEntry, 'gameRole' | 'id' | 'inGameName' | 'roleCd'>
      & { player: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'csgoEseaProfileUrl' | 'discordUsername' | 'discordId' | 'username' | 'characterName' | 'characterImageName'>
      ) }
    )>> }
  )>, games?: Maybe<Array<(
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'proofScreenshotUrl'>
    & { map?: Maybe<(
      { __typename?: 'Map' }
      & Pick<Map, 'id' | 'name'>
    )>, riotTournamentCodeData?: Maybe<(
      { __typename?: 'RiotTournamentCodeData' }
      & Pick<RiotTournamentCodeData, 'tournamentCode'>
    )>, winner?: Maybe<(
      { __typename?: 'Lineup' }
      & Pick<Lineup, 'score' | 'id'>
      & { lineupEntries?: Maybe<Array<(
        { __typename?: 'LineupEntry' }
        & Pick<LineupEntry, 'id' | 'deckListCode' | 'selectedColor' | 'role' | 'characterName' | 'characterImageName' | 'kills' | 'smashRemainingStocks'>
        & { player?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )>, lolGamePlayerStats?: Maybe<(
          { __typename?: 'LolGamePlayerStats' }
          & Pick<LolGamePlayerStats, 'kills' | 'deaths' | 'assists'>
        )> }
      )>>, players?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'truncatedName'>
      )> }
    )>, loser?: Maybe<(
      { __typename?: 'Lineup' }
      & Pick<Lineup, 'score' | 'id'>
      & { lineupEntries?: Maybe<Array<(
        { __typename?: 'LineupEntry' }
        & Pick<LineupEntry, 'id' | 'deckListCode' | 'selectedColor' | 'role' | 'characterName' | 'characterImageName' | 'kills' | 'smashRemainingStocks'>
        & { player?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )>, lolGamePlayerStats?: Maybe<(
          { __typename?: 'LolGamePlayerStats' }
          & Pick<LolGamePlayerStats, 'kills' | 'deaths' | 'assists'>
        )> }
      )>>, players?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
      )> }
    )> }
  )>> }
);

export type HideMatchMessageMutationVariables = {
  id: Scalars['ID'];
  type: Scalars['String'];
};


export type HideMatchMessageMutation = (
  { __typename?: 'Mutation' }
  & { hideMessage: (
    { __typename?: 'HideMessagePayload' }
    & Pick<HideMessagePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'message'>
    )>> }
  ) }
);

export const EligibleTeamInfoSeasonFragmentDoc = gql`
    fragment eligibleTeamInfoSeason on Team {
  id
  name
  createdAt
  university {
    name
    id
    abbreviation
    state
  }
}
    `;
export const SportSeasonsInfoFragmentFragmentDoc = gql`
    fragment sportSeasonsInfoFragment on Season {
  id
  divisionName
  seasonYear
  tournaments {
    id
    name
  }
}
    `;
export const EligibleTeamInfoTournamentFragmentDoc = gql`
    fragment eligibleTeamInfoTournament on Team {
  id
  name
  selectedSeasonStats {
    wins
    losses
  }
  university {
    id
    abbreviation
    name
    state
  }
  selectedSeasonConferenceName
  coordinator {
    id
    username
  }
}
    `;
export const DashboardMatchHistoryInfoFragmentDoc = gql`
    fragment DashboardMatchHistoryInfo on Match {
  id
  scheduledDate
  homeTeam {
    id
    name
  }
  awayTeam {
    id
    name
  }
  games {
    id
    winner {
      id
      team {
        id
      }
    }
  }
}
    `;
export const MyTeamInfoFragmentDoc = gql`
    fragment MyTeamInfo on Team {
  id
  name
  selectedSeasonConferenceName
  isRecruiting
  selectedSeason {
    id
    divisionName
    seasonYear
  }
  selectedSeasonStats {
    losses
    wins
    id
  }
  sportSlug
}
    `;
export const NewsBrief_NewsArticleFragmentDoc = gql`
    fragment NewsBrief_NewsArticle on NewsArticle {
  id
  createdAt
  title
  featuredImage
  newsCategory {
    id
    name
  }
  slug
}
    `;
export const LeagueDetail_TeamFragmentDoc = gql`
    fragment LeagueDetail_Team on Team {
  id
  name
  sportSlug
  seasonRegistries {
    id
    season {
      id
    }
  }
  teamEnrollments {
    id
    season {
      id
    }
  }
}
    `;
export const LeagueDetail_CurrentUserFragmentDoc = gql`
    fragment LeagueDetail_CurrentUser on CurrentUser {
  id
  coordinatingTeams {
    ...LeagueDetail_Team
  }
}
    ${LeagueDetail_TeamFragmentDoc}`;
export const LeagueDetail_ConferenceFragmentDoc = gql`
    fragment LeagueDetail_Conference on Conference {
  id
  name
}
    `;
export const LeagueDetail_RegisteredTeamUniversityFragmentDoc = gql`
    fragment LeagueDetail_RegisteredTeamUniversity on University {
  id
  name
}
    `;
export const LeagueDetail_RegisteredTeamFragmentDoc = gql`
    fragment LeagueDetail_RegisteredTeam on Team {
  id
  name
  conferenceIds
  university {
    ...LeagueDetail_RegisteredTeamUniversity
  }
}
    ${LeagueDetail_RegisteredTeamUniversityFragmentDoc}`;
export const LeagueDetail_SeasonFragmentDoc = gql`
    fragment LeagueDetail_Season on Season {
  id
  name
  teamsCount
  participantsCount
  registrationStatus
  banner
  sponsorImage
  firstMatchDate
  seasonEndDate
  seasonYear
  teamSeasonStats {
    teamId
    wins
    losses
    rank
  }
  conferences {
    ...LeagueDetail_Conference
  }
  division {
    logoUrl
  }
  sport {
    slug
  }
  registeredTeams {
    ...LeagueDetail_RegisteredTeam
  }
  enrolledTeams {
    ...LeagueDetail_RegisteredTeam
  }
}
    ${LeagueDetail_ConferenceFragmentDoc}
${LeagueDetail_RegisteredTeamFragmentDoc}`;
export const SeasonScheduleModalGamesFragmentDoc = gql`
    fragment SeasonScheduleModalGames on Game {
  id
  winner {
    id
    team {
      id
    }
  }
}
    `;
export const SeasonScheduleModalMatchFragmentDoc = gql`
    fragment SeasonScheduleModalMatch on Match {
  id
  settledMemo
  scheduledDate
  teamEnrollmentOne {
    id
    team {
      id
      name
    }
  }
  teamEnrollmentTwo {
    id
    team {
      id
      name
    }
  }
  games {
    ...SeasonScheduleModalGames
  }
}
    ${SeasonScheduleModalGamesFragmentDoc}`;
export const Leagues_SeasonFragmentDoc = gql`
    fragment Leagues_Season on Season {
  id
  name
  seasonYear
  registrationStatus
  sport {
    slug
  }
}
    `;
export const Leagues_DivisionFragmentDoc = gql`
    fragment Leagues_Division on Division {
  id
  name
  position
  bannerUrl
  logoUrl
  seasons {
    ...Leagues_Season
  }
}
    ${Leagues_SeasonFragmentDoc}`;
export const ManageNewsAndAnnouncements_NewsOrAnnouncementFragmentDoc = gql`
    fragment ManageNewsAndAnnouncements_NewsOrAnnouncement on NewsArticle {
  id
  type
  newsCategory {
    id
    name
  }
  title
  body
  summary
  sport
  featuredImage
  featured
  position
  published
}
    `;
export const NewsAndAnnouncements_NewsArticleFragmentDoc = gql`
    fragment NewsAndAnnouncements_NewsArticle on NewsArticle {
  id
  title
  sport
  summary
  body
  position
  featured
  featuredImage
  type
  createdAt
  slug
  published
  newsCategory {
    id
    name
  }
}
    `;
export const NewsAndAnnouncements_PaginatedFragmentDoc = gql`
    fragment NewsAndAnnouncements_Paginated on NewsOrAnnouncementsPaginatedPayload {
  items {
    ...NewsAndAnnouncements_NewsArticle
  }
  totalPages
  currentPage
  count
}
    ${NewsAndAnnouncements_NewsArticleFragmentDoc}`;
export const User_SearchFragmentDoc = gql`
    fragment User_Search on User {
  id
  name
  email
  personalEmail
  username
  discordUsername
}
    `;
export const AdminSeasonInfoQueryFragmentDoc = gql`
    fragment AdminSeasonInfoQuery on Season {
  banner
  division1
  divisionName
  divisionNumber
  firstMatchDate
  id
  id
  isEditable
  isPremium
  maximumTeamSize
  minimumTeamSize
  name
  priceCents
  published
  regionChoice
  registrationCloseDate
  registrationOpenDate
  rosterLockDate
  scheduleTypeChoice
  seasonEndDate
  seasonYear
  sponsorImage
  matchPrototype {
    id
    gameCount
  }
  sport {
    slug
  }
}
    `;
export const AdminTournamentRegistryInfoFragmentDoc = gql`
    fragment AdminTournamentRegistryInfo on TournamentRegistry {
  team {
    id
  }
  id
  seed
}
    `;
export const AdminMatchPrototypeInfoFragmentDoc = gql`
    fragment AdminMatchPrototypeInfo on MatchPrototype {
  id
  gameCount
}
    `;
export const AdminTournamentRoundInfoFragmentDoc = gql`
    fragment AdminTournamentRoundInfo on Round {
  id
  tournamentRoundName
  tournamentBracketOrder
  matchPrototype {
    ...AdminMatchPrototypeInfo
  }
}
    ${AdminMatchPrototypeInfoFragmentDoc}`;
export const AdminTournamentInfoFragmentDoc = gql`
    fragment AdminTournamentInfo on Tournament {
  name
  description
  brackets {
    id
  }
  desiredBrackets
  multibracketCapable
  containerId
  id
  firstMatchDate
  registrationCloseDate
  registrationOpenDate
  banner
  sponsorImage
  published
  minTeamSize
  defaultSeriesLength
  typeChoice
  numberOfTeams
  tournamentRegistries {
    ...AdminTournamentRegistryInfo
  }
  season {
    id
  }
  platform {
    id
    uid
    title
  }
  styleChoice
  sport
  checkinLength
  isPremium
  rounds {
    ...AdminTournamentRoundInfo
  }
}
    ${AdminTournamentRegistryInfoFragmentDoc}
${AdminTournamentRoundInfoFragmentDoc}`;
export const AdminTournamentTeamInfoFragmentDoc = gql`
    fragment AdminTournamentTeamInfo on Team {
  name
  id
  activePlayersCount
  university {
    abbreviation
    name
    id
    state
  }
}
    `;
export const AdminSeasonTeamInfoFragmentDoc = gql`
    fragment AdminSeasonTeamInfo on Team {
  id
  name
  activePlayersCount
  university {
    id
    abbreviation
    name
    state
  }
}
    `;
export const AdminSeasonConferencesFragmentDoc = gql`
    fragment AdminSeasonConferences on Conference {
  id
  name
  teams {
    id
  }
}
    `;
export const AdminSeasonTeamsQueryFragmentDoc = gql`
    fragment AdminSeasonTeamsQuery on Season {
  adminSeasonTeams {
    ...AdminSeasonTeamInfo
  }
  conferences {
    ...AdminSeasonConferences
  }
}
    ${AdminSeasonTeamInfoFragmentDoc}
${AdminSeasonConferencesFragmentDoc}`;
export const UniversityHubQueryFragmentDoc = gql`
    fragment UniversityHubQuery on University {
  id
  name
  currentUserCanCreateTeam
  currentUserPlayedSports
  currentUserTeamIds
  numberOfAwards
  activeUsers {
    id
  }
  seasonTeams {
    id
    name
    selectedSeasonConferenceName
    isRecruiting
    selectedSeason {
      id
      divisionName
      seasonYear
    }
    selectedSeasonStats {
      losses
      wins
      id
    }
    sportSlug
  }
  newTeams {
    id
    name
    selectedSeasonConferenceName
    isRecruiting
    selectedSeason {
      id
      divisionName
      seasonYear
    }
    selectedSeasonStats {
      losses
      wins
      id
    }
    sportSlug
  }
}
    `;
export const TeamDetailQueryFragmentDoc = gql`
    fragment TeamDetailQuery on Team {
  id
  name
  sportSlug
  currentUserIsCoordinator
  currentUserIsEligible
  currentUserOnTeam
  currentUserApplicationPending
  selectedSeasonConferenceName
  selectedSeasonRank
  currentSeasonEligible
  isRecruiting
  facebookUsername
  twitterUsername
  twitchUsername
  pendingLogoImage {
    name
    dataUrl
  }
  approvedLogoImage {
    name
    dataUrl
  }
  pendingBannerImage {
    name
    dataUrl
  }
  approvedBannerImage {
    name
    dataUrl
  }
  coordinator {
    id
    username
    discordId
  }
  university {
    id
    abbreviation
    name
  }
  teamAwards {
    id
  }
}
    `;
export const TeamPendingInvitationsQueryFragmentDoc = gql`
    fragment TeamPendingInvitationsQuery on Team {
  pendingInvitations {
    id
    player {
      id
      email
      username
      discordUsername
    }
    gameRole
  }
}
    `;
export const TeamRosterEntriesQueryFragmentDoc = gql`
    fragment TeamRosterEntriesQuery on Team {
  activeRosterEntries {
    id
    inGameName
    accepted
    player {
      id
      username
      discordId
      characterImageName
      university {
        name
        id
      }
    }
    roleCd
    gameRole
    staffRoleCd
  }
  pendingRosterEntries {
    id
    player {
      username
      discordUsername
    }
    gameRole
  }
}
    `;
export const TeamSeasonInfoQueryFragmentDoc = gql`
    fragment TeamSeasonInfoQuery on Team {
  selectedSeasonPlayerStats {
    id
    wins
    losses
    player {
      id
    }
  }
  selectedSeasonStats {
    id
    wins
    losses
  }
  selectedSeason {
    id
    divisionName
  }
}
    `;
export const TeamSettledMatchesQueryFragmentDoc = gql`
    fragment TeamSettledMatchesQuery on Team {
  settledMatches {
    id
    scheduledDate
    homeTeam {
      id
      name
    }
    awayTeam {
      id
      name
    }
    games {
      id
      winner {
        id
        team {
          id
        }
      }
    }
  }
}
    `;
export const MatchCarouselTeamInfoFragmentDoc = gql`
    fragment MatchCarouselTeamInfo on Team {
  id
  name
  currentUserOnTeam
}
    `;
export const MatchCarouselMatchInfoFragmentDoc = gql`
    fragment MatchCarouselMatchInfo on Match {
  id
  sport
  scheduledDate
  rescheduleDate
  currentUserCheckedIn
  checkinAvailable
  currentUserIsTeamMember
  currentUserCanReport
  homeTeamCheckedIn
  awayTeamCheckedIn
  homeTeam {
    ...MatchCarouselTeamInfo
  }
  awayTeam {
    ...MatchCarouselTeamInfo
  }
}
    ${MatchCarouselTeamInfoFragmentDoc}`;
export const UpcomingMatchesQueryFragmentDoc = gql`
    fragment UpcomingMatchesQuery on Team {
  upcomingMatches {
    ...MatchCarouselMatchInfo
  }
}
    ${MatchCarouselMatchInfoFragmentDoc}`;
export const UnreportedMatchesQueryFragmentDoc = gql`
    fragment UnreportedMatchesQuery on Team {
  unreportedMatches {
    ...MatchCarouselMatchInfo
  }
}
    ${MatchCarouselMatchInfoFragmentDoc}`;
export const MatchCarouselTournamentCheckinInfoFragmentDoc = gql`
    fragment MatchCarouselTournamentCheckinInfo on Tournament {
  id
  sport
  firstMatchDate
  name
  currentUserCheckedIn
  currentUserCanCheckIn
}
    `;
export const TournamentsToCheckinToQueryFragmentDoc = gql`
    fragment TournamentsToCheckinToQuery on Team {
  tournamentsToCheckinTo {
    ...MatchCarouselTournamentCheckinInfo
  }
}
    ${MatchCarouselTournamentCheckinInfoFragmentDoc}`;
export const TeamMessageBoardFragmentDoc = gql`
    fragment TeamMessageBoard on Team {
  currentUserOnTeam
  teamMessages {
    id
    hidden
    user {
      displayName
      isAdmin
      id
    }
    body
    createdAt
  }
}
    `;
export const TeamMessageBoardUpdatesFragmentDoc = gql`
    fragment TeamMessageBoardUpdates on Team {
  teamMessages(since: $since) {
    id
    hidden
    user {
      displayName
      isAdmin
      id
    }
    body
    createdAt
  }
}
    `;
export const TournamentDetail_TeamFragmentDoc = gql`
    fragment TournamentDetail_Team on Team {
  id
  name
  sportSlug
  tournamentRegistries {
    id
    tournament {
      id
    }
  }
}
    `;
export const TournamentDetail_CurrentUserFragmentDoc = gql`
    fragment TournamentDetail_CurrentUser on CurrentUser {
  id
  coordinatingTeams {
    ...TournamentDetail_Team
  }
}
    ${TournamentDetail_TeamFragmentDoc}`;
export const CurrentUser_UniversityFragmentDoc = gql`
    fragment CurrentUser_University on University {
  id
  name
}
    `;
export const PendingInvitation_TeamFragmentDoc = gql`
    fragment PendingInvitation_Team on Team {
  id
  name
  sportSlug
  overallWins
  overallLosses
}
    `;
export const CurrentUser_PendingInvitationsFragmentDoc = gql`
    fragment CurrentUser_PendingInvitations on PendingInvitation {
  id
  gameRole
  team {
    ...PendingInvitation_Team
  }
}
    ${PendingInvitation_TeamFragmentDoc}`;
export const AuthenticationAttributesFragmentDoc = gql`
    fragment AuthenticationAttributes on UserForAuth {
  accessToken
  username
  client
  expiry
  uid
  id
  isIncompleteAccount
  isAdmin
  teamIds
}
    `;
export const PlayerProfileMatchHistoryQueryFragmentDoc = gql`
    fragment PlayerProfileMatchHistoryQuery on User {
  reportedMatches {
    id
    scheduledDate
    homeTeam {
      id
      name
    }
    awayTeam {
      id
      name
    }
    games {
      id
      winner {
        id
        team {
          id
        }
      }
    }
  }
}
    `;
export const MatchDetailAttributesFragmentDoc = gql`
    fragment MatchDetailAttributes on Match {
  id
  sport
  currentUserCanReport
  currentUserCanApproveReschedule
  currentUserIsCoordinator
  currentUserIsTeamMember
  scheduledDate
  rescheduleDate
  homeTeamCheckedIn
  awayTeamCheckedIn
  rescheduleRequestingTeam {
    id
  }
  settled
  forfeit {
    id
    requestingTeamName
    opposingTeamName
    accepted
    explanation
    rejectedMessage
    proofScreenshotUrl
  }
  isBye
  playerStats {
    id
    wins
    losses
    player {
      id
    }
  }
  maps {
    id
    name
  }
  tournament {
    id
    sport
    type
    name
  }
  awayTeam {
    id
    name
    truncatedName
    sportSlug
    selectedSeasonStats {
      id
      wins
      losses
    }
    coordinator {
      id
    }
    activeRosterEntries {
      id
    }
    rosterEntries {
      gameRole
      id
      inGameName
      roleCd
      player {
        csgoEseaProfileUrl
        id
        discordId
        discordUsername
        username
        characterName
        characterImageName
      }
    }
  }
  homeTeam {
    id
    name
    truncatedName
    sportSlug
    selectedSeasonStats {
      id
      wins
      losses
    }
    coordinator {
      id
    }
    activeRosterEntries {
      id
    }
    rosterEntries {
      gameRole
      id
      inGameName
      roleCd
      player {
        id
        csgoEseaProfileUrl
        discordUsername
        discordId
        username
        characterName
        characterImageName
      }
    }
  }
  games {
    id
    map {
      id
      name
    }
    riotTournamentCodeData {
      tournamentCode
    }
    proofScreenshotUrl
    winner {
      score
      id
      lineupEntries {
        id
        player {
          id
        }
        deckListCode
        selectedColor
        role
        characterName
        characterImageName
        kills
        lolGamePlayerStats {
          kills
          deaths
          assists
        }
        smashRemainingStocks
      }
      players {
        id
      }
      team {
        id
        truncatedName
      }
    }
    loser {
      score
      id
      lineupEntries {
        id
        player {
          id
        }
        deckListCode
        selectedColor
        role
        characterName
        characterImageName
        kills
        lolGamePlayerStats {
          kills
          deaths
          assists
        }
        smashRemainingStocks
      }
      players {
        id
      }
      team {
        id
      }
    }
  }
}
    `;
export const OauthLoginUrlDocument = gql`
    query oauthLoginUrl($provider: String!) {
  oauthLoginUrl(provider: $provider)
}
    `;
export type OauthLoginUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OauthLoginUrlQuery, OauthLoginUrlQueryVariables>, 'query'> & ({ variables: OauthLoginUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OauthLoginUrlComponent = (props: OauthLoginUrlComponentProps) => (
      <ApolloReactComponents.Query<OauthLoginUrlQuery, OauthLoginUrlQueryVariables> query={OauthLoginUrlDocument} {...props} />
    );
    
export type OauthLoginUrlProps<TChildProps = {}> = ApolloReactHoc.DataProps<OauthLoginUrlQuery, OauthLoginUrlQueryVariables> & TChildProps;
export function withOauthLoginUrl<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OauthLoginUrlQuery,
  OauthLoginUrlQueryVariables,
  OauthLoginUrlProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, OauthLoginUrlQuery, OauthLoginUrlQueryVariables, OauthLoginUrlProps<TChildProps>>(OauthLoginUrlDocument, {
      alias: 'oauthLoginUrl',
      ...operationOptions
    });
};

/**
 * __useOauthLoginUrlQuery__
 *
 * To run a query within a React component, call `useOauthLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOauthLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOauthLoginUrlQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useOauthLoginUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OauthLoginUrlQuery, OauthLoginUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<OauthLoginUrlQuery, OauthLoginUrlQueryVariables>(OauthLoginUrlDocument, baseOptions);
      }
export function useOauthLoginUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OauthLoginUrlQuery, OauthLoginUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OauthLoginUrlQuery, OauthLoginUrlQueryVariables>(OauthLoginUrlDocument, baseOptions);
        }
export type OauthLoginUrlQueryHookResult = ReturnType<typeof useOauthLoginUrlQuery>;
export type OauthLoginUrlLazyQueryHookResult = ReturnType<typeof useOauthLoginUrlLazyQuery>;
export type OauthLoginUrlQueryResult = ApolloReactCommon.QueryResult<OauthLoginUrlQuery, OauthLoginUrlQueryVariables>;
export const ChangeAccountSettingsFieldDocument = gql`
    mutation ChangeAccountSettingsField($personalEmail: String, $username: String, $id: ID) {
  updateUser(personalEmail: $personalEmail, username: $username, id: $id) {
    success
    value {
      ...AuthenticationAttributes
    }
    errors {
      field
      message
    }
  }
}
    ${AuthenticationAttributesFragmentDoc}`;
export type ChangeAccountSettingsFieldMutationFn = ApolloReactCommon.MutationFunction<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables>;
export type ChangeAccountSettingsFieldComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables>, 'mutation'>;

    export const ChangeAccountSettingsFieldComponent = (props: ChangeAccountSettingsFieldComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables> mutation={ChangeAccountSettingsFieldDocument} {...props} />
    );
    
export type ChangeAccountSettingsFieldProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables> & TChildProps;
export function withChangeAccountSettingsField<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeAccountSettingsFieldMutation,
  ChangeAccountSettingsFieldMutationVariables,
  ChangeAccountSettingsFieldProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables, ChangeAccountSettingsFieldProps<TChildProps>>(ChangeAccountSettingsFieldDocument, {
      alias: 'changeAccountSettingsField',
      ...operationOptions
    });
};

/**
 * __useChangeAccountSettingsFieldMutation__
 *
 * To run a mutation, you first call `useChangeAccountSettingsFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccountSettingsFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccountSettingsFieldMutation, { data, loading, error }] = useChangeAccountSettingsFieldMutation({
 *   variables: {
 *      personalEmail: // value for 'personalEmail'
 *      username: // value for 'username'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeAccountSettingsFieldMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables>(ChangeAccountSettingsFieldDocument, baseOptions);
      }
export type ChangeAccountSettingsFieldMutationHookResult = ReturnType<typeof useChangeAccountSettingsFieldMutation>;
export type ChangeAccountSettingsFieldMutationResult = ApolloReactCommon.MutationResult<ChangeAccountSettingsFieldMutation>;
export type ChangeAccountSettingsFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAccountSettingsFieldMutation, ChangeAccountSettingsFieldMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    success
    errors
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    
export type ChangePasswordProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ChangePasswordMutation, ChangePasswordMutationVariables> & TChildProps;
export function withChangePassword<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  ChangePasswordProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ChangePasswordMutation, ChangePasswordMutationVariables, ChangePasswordProps<TChildProps>>(ChangePasswordDocument, {
      alias: 'changePassword',
      ...operationOptions
    });
};

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ConfirmByTokenDocument = gql`
    mutation confirmByToken($token: String!) {
  confirmByToken(token: $token) {
    universityId
  }
}
    `;
export type ConfirmByTokenMutationFn = ApolloReactCommon.MutationFunction<ConfirmByTokenMutation, ConfirmByTokenMutationVariables>;
export type ConfirmByTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmByTokenMutation, ConfirmByTokenMutationVariables>, 'mutation'>;

    export const ConfirmByTokenComponent = (props: ConfirmByTokenComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmByTokenMutation, ConfirmByTokenMutationVariables> mutation={ConfirmByTokenDocument} {...props} />
    );
    
export type ConfirmByTokenProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ConfirmByTokenMutation, ConfirmByTokenMutationVariables> & TChildProps;
export function withConfirmByToken<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConfirmByTokenMutation,
  ConfirmByTokenMutationVariables,
  ConfirmByTokenProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ConfirmByTokenMutation, ConfirmByTokenMutationVariables, ConfirmByTokenProps<TChildProps>>(ConfirmByTokenDocument, {
      alias: 'confirmByToken',
      ...operationOptions
    });
};

/**
 * __useConfirmByTokenMutation__
 *
 * To run a mutation, you first call `useConfirmByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmByTokenMutation, { data, loading, error }] = useConfirmByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmByTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmByTokenMutation, ConfirmByTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmByTokenMutation, ConfirmByTokenMutationVariables>(ConfirmByTokenDocument, baseOptions);
      }
export type ConfirmByTokenMutationHookResult = ReturnType<typeof useConfirmByTokenMutation>;
export type ConfirmByTokenMutationResult = ApolloReactCommon.MutationResult<ConfirmByTokenMutation>;
export type ConfirmByTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmByTokenMutation, ConfirmByTokenMutationVariables>;
export const UpdateUserApplicationDocument = gql`
    mutation updateUserApplication($id: ID!, $accepted: Boolean) {
  updateRosterEntry(id: $id, accepted: $accepted) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateUserApplicationMutationFn = ApolloReactCommon.MutationFunction<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>;
export type UpdateUserApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>, 'mutation'>;

    export const UpdateUserApplicationComponent = (props: UpdateUserApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables> mutation={UpdateUserApplicationDocument} {...props} />
    );
    
export type UpdateUserApplicationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables> & TChildProps;
export function withUpdateUserApplication<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserApplicationMutation,
  UpdateUserApplicationMutationVariables,
  UpdateUserApplicationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables, UpdateUserApplicationProps<TChildProps>>(UpdateUserApplicationDocument, {
      alias: 'updateUserApplication',
      ...operationOptions
    });
};

/**
 * __useUpdateUserApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateUserApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApplicationMutation, { data, loading, error }] = useUpdateUserApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function useUpdateUserApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>(UpdateUserApplicationDocument, baseOptions);
      }
export type UpdateUserApplicationMutationHookResult = ReturnType<typeof useUpdateUserApplicationMutation>;
export type UpdateUserApplicationMutationResult = ApolloReactCommon.MutationResult<UpdateUserApplicationMutation>;
export type UpdateUserApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserApplicationMutation, UpdateUserApplicationMutationVariables>;
export const RescindPendingInviteDocument = gql`
    mutation rescindPendingInvite($id: ID!) {
  deleteTeamPlayerInvitation(id: $id) {
    success
  }
}
    `;
export type RescindPendingInviteMutationFn = ApolloReactCommon.MutationFunction<RescindPendingInviteMutation, RescindPendingInviteMutationVariables>;
export type RescindPendingInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RescindPendingInviteMutation, RescindPendingInviteMutationVariables>, 'mutation'>;

    export const RescindPendingInviteComponent = (props: RescindPendingInviteComponentProps) => (
      <ApolloReactComponents.Mutation<RescindPendingInviteMutation, RescindPendingInviteMutationVariables> mutation={RescindPendingInviteDocument} {...props} />
    );
    
export type RescindPendingInviteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RescindPendingInviteMutation, RescindPendingInviteMutationVariables> & TChildProps;
export function withRescindPendingInvite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RescindPendingInviteMutation,
  RescindPendingInviteMutationVariables,
  RescindPendingInviteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RescindPendingInviteMutation, RescindPendingInviteMutationVariables, RescindPendingInviteProps<TChildProps>>(RescindPendingInviteDocument, {
      alias: 'rescindPendingInvite',
      ...operationOptions
    });
};

/**
 * __useRescindPendingInviteMutation__
 *
 * To run a mutation, you first call `useRescindPendingInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescindPendingInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescindPendingInviteMutation, { data, loading, error }] = useRescindPendingInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRescindPendingInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RescindPendingInviteMutation, RescindPendingInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<RescindPendingInviteMutation, RescindPendingInviteMutationVariables>(RescindPendingInviteDocument, baseOptions);
      }
export type RescindPendingInviteMutationHookResult = ReturnType<typeof useRescindPendingInviteMutation>;
export type RescindPendingInviteMutationResult = ApolloReactCommon.MutationResult<RescindPendingInviteMutation>;
export type RescindPendingInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<RescindPendingInviteMutation, RescindPendingInviteMutationVariables>;
export const SendEmailConfirmationDocument = gql`
    mutation sendEmailConfirmation {
  sendEmailConfirmation {
    success
  }
}
    `;
export type SendEmailConfirmationMutationFn = ApolloReactCommon.MutationFunction<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables>;
export type SendEmailConfirmationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables>, 'mutation'>;

    export const SendEmailConfirmationComponent = (props: SendEmailConfirmationComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables> mutation={SendEmailConfirmationDocument} {...props} />
    );
    
export type SendEmailConfirmationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables> & TChildProps;
export function withSendEmailConfirmation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailConfirmationMutation,
  SendEmailConfirmationMutationVariables,
  SendEmailConfirmationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables, SendEmailConfirmationProps<TChildProps>>(SendEmailConfirmationDocument, {
      alias: 'sendEmailConfirmation',
      ...operationOptions
    });
};

/**
 * __useSendEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useSendEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailConfirmationMutation, { data, loading, error }] = useSendEmailConfirmationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendEmailConfirmationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables>(SendEmailConfirmationDocument, baseOptions);
      }
export type SendEmailConfirmationMutationHookResult = ReturnType<typeof useSendEmailConfirmationMutation>;
export type SendEmailConfirmationMutationResult = ApolloReactCommon.MutationResult<SendEmailConfirmationMutation>;
export type SendEmailConfirmationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailConfirmationMutation, SendEmailConfirmationMutationVariables>;
export const UpdateSocialProfilesDocument = gql`
    mutation updateSocialProfiles($twitchUsername: String, $twitterUsername: String, $facebookUsername: String, $id: ID) {
  updateUser(twitchUsername: $twitchUsername, twitterUsername: $twitterUsername, facebookUsername: $facebookUsername, id: $id) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateSocialProfilesMutationFn = ApolloReactCommon.MutationFunction<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables>;
export type UpdateSocialProfilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables>, 'mutation'>;

    export const UpdateSocialProfilesComponent = (props: UpdateSocialProfilesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables> mutation={UpdateSocialProfilesDocument} {...props} />
    );
    
export type UpdateSocialProfilesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables> & TChildProps;
export function withUpdateSocialProfiles<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSocialProfilesMutation,
  UpdateSocialProfilesMutationVariables,
  UpdateSocialProfilesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables, UpdateSocialProfilesProps<TChildProps>>(UpdateSocialProfilesDocument, {
      alias: 'updateSocialProfiles',
      ...operationOptions
    });
};

/**
 * __useUpdateSocialProfilesMutation__
 *
 * To run a mutation, you first call `useUpdateSocialProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialProfilesMutation, { data, loading, error }] = useUpdateSocialProfilesMutation({
 *   variables: {
 *      twitchUsername: // value for 'twitchUsername'
 *      twitterUsername: // value for 'twitterUsername'
 *      facebookUsername: // value for 'facebookUsername'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSocialProfilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables>(UpdateSocialProfilesDocument, baseOptions);
      }
export type UpdateSocialProfilesMutationHookResult = ReturnType<typeof useUpdateSocialProfilesMutation>;
export type UpdateSocialProfilesMutationResult = ApolloReactCommon.MutationResult<UpdateSocialProfilesMutation>;
export type UpdateSocialProfilesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSocialProfilesMutation, UpdateSocialProfilesMutationVariables>;
export const ToggleTeamRecruitingStatusDocument = gql`
    mutation toggleTeamRecruitingStatus($id: ID!, $isRecruiting: Boolean!) {
  editTeam(id: $id, isRecruiting: $isRecruiting) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type ToggleTeamRecruitingStatusMutationFn = ApolloReactCommon.MutationFunction<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables>;
export type ToggleTeamRecruitingStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables>, 'mutation'>;

    export const ToggleTeamRecruitingStatusComponent = (props: ToggleTeamRecruitingStatusComponentProps) => (
      <ApolloReactComponents.Mutation<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables> mutation={ToggleTeamRecruitingStatusDocument} {...props} />
    );
    
export type ToggleTeamRecruitingStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables> & TChildProps;
export function withToggleTeamRecruitingStatus<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ToggleTeamRecruitingStatusMutation,
  ToggleTeamRecruitingStatusMutationVariables,
  ToggleTeamRecruitingStatusProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables, ToggleTeamRecruitingStatusProps<TChildProps>>(ToggleTeamRecruitingStatusDocument, {
      alias: 'toggleTeamRecruitingStatus',
      ...operationOptions
    });
};

/**
 * __useToggleTeamRecruitingStatusMutation__
 *
 * To run a mutation, you first call `useToggleTeamRecruitingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTeamRecruitingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTeamRecruitingStatusMutation, { data, loading, error }] = useToggleTeamRecruitingStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isRecruiting: // value for 'isRecruiting'
 *   },
 * });
 */
export function useToggleTeamRecruitingStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables>(ToggleTeamRecruitingStatusDocument, baseOptions);
      }
export type ToggleTeamRecruitingStatusMutationHookResult = ReturnType<typeof useToggleTeamRecruitingStatusMutation>;
export type ToggleTeamRecruitingStatusMutationResult = ApolloReactCommon.MutationResult<ToggleTeamRecruitingStatusMutation>;
export type ToggleTeamRecruitingStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleTeamRecruitingStatusMutation, ToggleTeamRecruitingStatusMutationVariables>;
export const AddTournamentMessageDocument = gql`
    mutation AddTournamentMessage($id: ID!, $message: String!) {
  addTournamentMessage(id: $id, message: $message) {
    success
  }
}
    `;
export type AddTournamentMessageMutationFn = ApolloReactCommon.MutationFunction<AddTournamentMessageMutation, AddTournamentMessageMutationVariables>;
export type AddTournamentMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddTournamentMessageMutation, AddTournamentMessageMutationVariables>, 'mutation'>;

    export const AddTournamentMessageComponent = (props: AddTournamentMessageComponentProps) => (
      <ApolloReactComponents.Mutation<AddTournamentMessageMutation, AddTournamentMessageMutationVariables> mutation={AddTournamentMessageDocument} {...props} />
    );
    
export type AddTournamentMessageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddTournamentMessageMutation, AddTournamentMessageMutationVariables> & TChildProps;
export function withAddTournamentMessage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddTournamentMessageMutation,
  AddTournamentMessageMutationVariables,
  AddTournamentMessageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddTournamentMessageMutation, AddTournamentMessageMutationVariables, AddTournamentMessageProps<TChildProps>>(AddTournamentMessageDocument, {
      alias: 'addTournamentMessage',
      ...operationOptions
    });
};

/**
 * __useAddTournamentMessageMutation__
 *
 * To run a mutation, you first call `useAddTournamentMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTournamentMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTournamentMessageMutation, { data, loading, error }] = useAddTournamentMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddTournamentMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTournamentMessageMutation, AddTournamentMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTournamentMessageMutation, AddTournamentMessageMutationVariables>(AddTournamentMessageDocument, baseOptions);
      }
export type AddTournamentMessageMutationHookResult = ReturnType<typeof useAddTournamentMessageMutation>;
export type AddTournamentMessageMutationResult = ApolloReactCommon.MutationResult<AddTournamentMessageMutation>;
export type AddTournamentMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTournamentMessageMutation, AddTournamentMessageMutationVariables>;
export const UniversitiesDocument = gql`
    query Universities {
  universities {
    id
    name
  }
}
    `;
export type UniversitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UniversitiesQuery, UniversitiesQueryVariables>, 'query'>;

    export const UniversitiesComponent = (props: UniversitiesComponentProps) => (
      <ApolloReactComponents.Query<UniversitiesQuery, UniversitiesQueryVariables> query={UniversitiesDocument} {...props} />
    );
    
export type UniversitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<UniversitiesQuery, UniversitiesQueryVariables> & TChildProps;
export function withUniversities<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UniversitiesQuery,
  UniversitiesQueryVariables,
  UniversitiesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UniversitiesQuery, UniversitiesQueryVariables, UniversitiesProps<TChildProps>>(UniversitiesDocument, {
      alias: 'universities',
      ...operationOptions
    });
};

/**
 * __useUniversitiesQuery__
 *
 * To run a query within a React component, call `useUniversitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniversitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniversitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUniversitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniversitiesQuery, UniversitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<UniversitiesQuery, UniversitiesQueryVariables>(UniversitiesDocument, baseOptions);
      }
export function useUniversitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniversitiesQuery, UniversitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniversitiesQuery, UniversitiesQueryVariables>(UniversitiesDocument, baseOptions);
        }
export type UniversitiesQueryHookResult = ReturnType<typeof useUniversitiesQuery>;
export type UniversitiesLazyQueryHookResult = ReturnType<typeof useUniversitiesLazyQuery>;
export type UniversitiesQueryResult = ApolloReactCommon.QueryResult<UniversitiesQuery, UniversitiesQueryVariables>;
export const UniversityMajorsDocument = gql`
    query UniversityMajors {
  universityMajors {
    id
    name
  }
}
    `;
export type UniversityMajorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UniversityMajorsQuery, UniversityMajorsQueryVariables>, 'query'>;

    export const UniversityMajorsComponent = (props: UniversityMajorsComponentProps) => (
      <ApolloReactComponents.Query<UniversityMajorsQuery, UniversityMajorsQueryVariables> query={UniversityMajorsDocument} {...props} />
    );
    
export type UniversityMajorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<UniversityMajorsQuery, UniversityMajorsQueryVariables> & TChildProps;
export function withUniversityMajors<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UniversityMajorsQuery,
  UniversityMajorsQueryVariables,
  UniversityMajorsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UniversityMajorsQuery, UniversityMajorsQueryVariables, UniversityMajorsProps<TChildProps>>(UniversityMajorsDocument, {
      alias: 'universityMajors',
      ...operationOptions
    });
};

/**
 * __useUniversityMajorsQuery__
 *
 * To run a query within a React component, call `useUniversityMajorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniversityMajorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniversityMajorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUniversityMajorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniversityMajorsQuery, UniversityMajorsQueryVariables>) {
        return ApolloReactHooks.useQuery<UniversityMajorsQuery, UniversityMajorsQueryVariables>(UniversityMajorsDocument, baseOptions);
      }
export function useUniversityMajorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniversityMajorsQuery, UniversityMajorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniversityMajorsQuery, UniversityMajorsQueryVariables>(UniversityMajorsDocument, baseOptions);
        }
export type UniversityMajorsQueryHookResult = ReturnType<typeof useUniversityMajorsQuery>;
export type UniversityMajorsLazyQueryHookResult = ReturnType<typeof useUniversityMajorsLazyQuery>;
export type UniversityMajorsQueryResult = ApolloReactCommon.QueryResult<UniversityMajorsQuery, UniversityMajorsQueryVariables>;
export const Upsert_Season_InfoDocument = gql`
    mutation UPSERT_SEASON_INFO($banner: String, $defaultSeriesLength: Int!, $divisionName: String!, $firstMatchDate: String!, $id: ID, $isPremiere: Boolean!, $isPremium: Boolean!, $maximumTeamSize: Int, $minimumTeamSize: Int, $name: String!, $price: Int!, $regionChoice: SeasonRegions!, $registrationCloseDate: String!, $registrationOpenDate: String!, $rosterLockDate: String, $scheduleTypeChoice: SeasonSchedules!, $seasonEndDate: String!, $seasonYear: String!, $sponsorImage: String, $sport: String!, $published: Boolean!) {
  editSeasonBasicInfo(banner: $banner, defaultSeriesLength: $defaultSeriesLength, divisionName: $divisionName, firstMatchDate: $firstMatchDate, id: $id, isPremiere: $isPremiere, isPremium: $isPremium, maximumTeamSize: $maximumTeamSize, minimumTeamSize: $minimumTeamSize, name: $name, price: $price, regionChoice: $regionChoice, registrationCloseDate: $registrationCloseDate, registrationOpenDate: $registrationOpenDate, rosterLockDate: $rosterLockDate, scheduleTypeChoice: $scheduleTypeChoice, seasonEndDate: $seasonEndDate, seasonYear: $seasonYear, sponsorImage: $sponsorImage, sport: $sport, published: $published) {
    season {
      id
      ...AdminSeasonInfoQuery
    }
    errors {
      field
      message
    }
  }
}
    ${AdminSeasonInfoQueryFragmentDoc}`;
export type Upsert_Season_InfoMutationFn = ApolloReactCommon.MutationFunction<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables>;
export type Upsert_Season_InfoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables>, 'mutation'>;

    export const Upsert_Season_InfoComponent = (props: Upsert_Season_InfoComponentProps) => (
      <ApolloReactComponents.Mutation<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables> mutation={Upsert_Season_InfoDocument} {...props} />
    );
    
export type Upsert_Season_InfoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables> & TChildProps;
export function withUpsert_Season_Info<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Upsert_Season_InfoMutation,
  Upsert_Season_InfoMutationVariables,
  Upsert_Season_InfoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables, Upsert_Season_InfoProps<TChildProps>>(Upsert_Season_InfoDocument, {
      alias: 'upsertSeasonInfo',
      ...operationOptions
    });
};

/**
 * __useUpsert_Season_InfoMutation__
 *
 * To run a mutation, you first call `useUpsert_Season_InfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_Season_InfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSeasonInfoMutation, { data, loading, error }] = useUpsert_Season_InfoMutation({
 *   variables: {
 *      banner: // value for 'banner'
 *      defaultSeriesLength: // value for 'defaultSeriesLength'
 *      divisionName: // value for 'divisionName'
 *      firstMatchDate: // value for 'firstMatchDate'
 *      id: // value for 'id'
 *      isPremiere: // value for 'isPremiere'
 *      isPremium: // value for 'isPremium'
 *      maximumTeamSize: // value for 'maximumTeamSize'
 *      minimumTeamSize: // value for 'minimumTeamSize'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      regionChoice: // value for 'regionChoice'
 *      registrationCloseDate: // value for 'registrationCloseDate'
 *      registrationOpenDate: // value for 'registrationOpenDate'
 *      rosterLockDate: // value for 'rosterLockDate'
 *      scheduleTypeChoice: // value for 'scheduleTypeChoice'
 *      seasonEndDate: // value for 'seasonEndDate'
 *      seasonYear: // value for 'seasonYear'
 *      sponsorImage: // value for 'sponsorImage'
 *      sport: // value for 'sport'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useUpsert_Season_InfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables>) {
        return ApolloReactHooks.useMutation<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables>(Upsert_Season_InfoDocument, baseOptions);
      }
export type Upsert_Season_InfoMutationHookResult = ReturnType<typeof useUpsert_Season_InfoMutation>;
export type Upsert_Season_InfoMutationResult = ApolloReactCommon.MutationResult<Upsert_Season_InfoMutation>;
export type Upsert_Season_InfoMutationOptions = ApolloReactCommon.BaseMutationOptions<Upsert_Season_InfoMutation, Upsert_Season_InfoMutationVariables>;
export const PopulateSeasonDocument = gql`
    mutation populateSeason($seasonId: ID!) {
  populateSeason(seasonId: $seasonId) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type PopulateSeasonMutationFn = ApolloReactCommon.MutationFunction<PopulateSeasonMutation, PopulateSeasonMutationVariables>;
export type PopulateSeasonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PopulateSeasonMutation, PopulateSeasonMutationVariables>, 'mutation'>;

    export const PopulateSeasonComponent = (props: PopulateSeasonComponentProps) => (
      <ApolloReactComponents.Mutation<PopulateSeasonMutation, PopulateSeasonMutationVariables> mutation={PopulateSeasonDocument} {...props} />
    );
    
export type PopulateSeasonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PopulateSeasonMutation, PopulateSeasonMutationVariables> & TChildProps;
export function withPopulateSeason<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PopulateSeasonMutation,
  PopulateSeasonMutationVariables,
  PopulateSeasonProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PopulateSeasonMutation, PopulateSeasonMutationVariables, PopulateSeasonProps<TChildProps>>(PopulateSeasonDocument, {
      alias: 'populateSeason',
      ...operationOptions
    });
};

/**
 * __usePopulateSeasonMutation__
 *
 * To run a mutation, you first call `usePopulateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePopulateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [populateSeasonMutation, { data, loading, error }] = usePopulateSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function usePopulateSeasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PopulateSeasonMutation, PopulateSeasonMutationVariables>) {
        return ApolloReactHooks.useMutation<PopulateSeasonMutation, PopulateSeasonMutationVariables>(PopulateSeasonDocument, baseOptions);
      }
export type PopulateSeasonMutationHookResult = ReturnType<typeof usePopulateSeasonMutation>;
export type PopulateSeasonMutationResult = ApolloReactCommon.MutationResult<PopulateSeasonMutation>;
export type PopulateSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<PopulateSeasonMutation, PopulateSeasonMutationVariables>;
export const SeasonAdminDocument = gql`
    query seasonAdmin($id: ID!) {
  season(id: $id) {
    ...AdminSeasonInfoQuery
    ...AdminSeasonTeamsQuery
  }
}
    ${AdminSeasonInfoQueryFragmentDoc}
${AdminSeasonTeamsQueryFragmentDoc}`;
export type SeasonAdminComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SeasonAdminQuery, SeasonAdminQueryVariables>, 'query'> & ({ variables: SeasonAdminQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SeasonAdminComponent = (props: SeasonAdminComponentProps) => (
      <ApolloReactComponents.Query<SeasonAdminQuery, SeasonAdminQueryVariables> query={SeasonAdminDocument} {...props} />
    );
    
export type SeasonAdminProps<TChildProps = {}> = ApolloReactHoc.DataProps<SeasonAdminQuery, SeasonAdminQueryVariables> & TChildProps;
export function withSeasonAdmin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SeasonAdminQuery,
  SeasonAdminQueryVariables,
  SeasonAdminProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SeasonAdminQuery, SeasonAdminQueryVariables, SeasonAdminProps<TChildProps>>(SeasonAdminDocument, {
      alias: 'seasonAdmin',
      ...operationOptions
    });
};

/**
 * __useSeasonAdminQuery__
 *
 * To run a query within a React component, call `useSeasonAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeasonAdminQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonAdminQuery, SeasonAdminQueryVariables>) {
        return ApolloReactHooks.useQuery<SeasonAdminQuery, SeasonAdminQueryVariables>(SeasonAdminDocument, baseOptions);
      }
export function useSeasonAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonAdminQuery, SeasonAdminQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeasonAdminQuery, SeasonAdminQueryVariables>(SeasonAdminDocument, baseOptions);
        }
export type SeasonAdminQueryHookResult = ReturnType<typeof useSeasonAdminQuery>;
export type SeasonAdminLazyQueryHookResult = ReturnType<typeof useSeasonAdminLazyQuery>;
export type SeasonAdminQueryResult = ApolloReactCommon.QueryResult<SeasonAdminQuery, SeasonAdminQueryVariables>;
export const UpsertConferencesDocument = gql`
    mutation upsertConferences($seasonId: ID!, $conferences: [ConferenceAttributes!], $registeredTeams: [ID!]!) {
  upsertConferences(seasonId: $seasonId, conferences: $conferences, registeredTeams: $registeredTeams) {
    season {
      id
      isEditable
      ...AdminSeasonTeamsQuery
    }
    errors {
      field
      message
    }
  }
}
    ${AdminSeasonTeamsQueryFragmentDoc}`;
export type UpsertConferencesMutationFn = ApolloReactCommon.MutationFunction<UpsertConferencesMutation, UpsertConferencesMutationVariables>;
export type UpsertConferencesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertConferencesMutation, UpsertConferencesMutationVariables>, 'mutation'>;

    export const UpsertConferencesComponent = (props: UpsertConferencesComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertConferencesMutation, UpsertConferencesMutationVariables> mutation={UpsertConferencesDocument} {...props} />
    );
    
export type UpsertConferencesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpsertConferencesMutation, UpsertConferencesMutationVariables> & TChildProps;
export function withUpsertConferences<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertConferencesMutation,
  UpsertConferencesMutationVariables,
  UpsertConferencesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertConferencesMutation, UpsertConferencesMutationVariables, UpsertConferencesProps<TChildProps>>(UpsertConferencesDocument, {
      alias: 'upsertConferences',
      ...operationOptions
    });
};

/**
 * __useUpsertConferencesMutation__
 *
 * To run a mutation, you first call `useUpsertConferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertConferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertConferencesMutation, { data, loading, error }] = useUpsertConferencesMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      conferences: // value for 'conferences'
 *      registeredTeams: // value for 'registeredTeams'
 *   },
 * });
 */
export function useUpsertConferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertConferencesMutation, UpsertConferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertConferencesMutation, UpsertConferencesMutationVariables>(UpsertConferencesDocument, baseOptions);
      }
export type UpsertConferencesMutationHookResult = ReturnType<typeof useUpsertConferencesMutation>;
export type UpsertConferencesMutationResult = ApolloReactCommon.MutationResult<UpsertConferencesMutation>;
export type UpsertConferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertConferencesMutation, UpsertConferencesMutationVariables>;
export const AdminSeasonEligibleTeamsDocument = gql`
    query AdminSeasonEligibleTeams($seasonId: ID!, $name: String) {
  season(id: $seasonId) {
    id
    eligibleTeams(name: $name) {
      ...eligibleTeamInfoSeason
    }
  }
}
    ${EligibleTeamInfoSeasonFragmentDoc}`;
export type AdminSeasonEligibleTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables>, 'query'> & ({ variables: AdminSeasonEligibleTeamsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminSeasonEligibleTeamsComponent = (props: AdminSeasonEligibleTeamsComponentProps) => (
      <ApolloReactComponents.Query<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables> query={AdminSeasonEligibleTeamsDocument} {...props} />
    );
    
export type AdminSeasonEligibleTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables> & TChildProps;
export function withAdminSeasonEligibleTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AdminSeasonEligibleTeamsQuery,
  AdminSeasonEligibleTeamsQueryVariables,
  AdminSeasonEligibleTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables, AdminSeasonEligibleTeamsProps<TChildProps>>(AdminSeasonEligibleTeamsDocument, {
      alias: 'adminSeasonEligibleTeams',
      ...operationOptions
    });
};

/**
 * __useAdminSeasonEligibleTeamsQuery__
 *
 * To run a query within a React component, call `useAdminSeasonEligibleTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSeasonEligibleTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSeasonEligibleTeamsQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAdminSeasonEligibleTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables>(AdminSeasonEligibleTeamsDocument, baseOptions);
      }
export function useAdminSeasonEligibleTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables>(AdminSeasonEligibleTeamsDocument, baseOptions);
        }
export type AdminSeasonEligibleTeamsQueryHookResult = ReturnType<typeof useAdminSeasonEligibleTeamsQuery>;
export type AdminSeasonEligibleTeamsLazyQueryHookResult = ReturnType<typeof useAdminSeasonEligibleTeamsLazyQuery>;
export type AdminSeasonEligibleTeamsQueryResult = ApolloReactCommon.QueryResult<AdminSeasonEligibleTeamsQuery, AdminSeasonEligibleTeamsQueryVariables>;
export const AdminRegisterTeamForSeasonDocument = gql`
    mutation AdminRegisterTeamForSeason($teamId: ID!, $seasonId: ID!) {
  registerTeamForSeason(teamId: $teamId, seasonId: $seasonId) {
    success
    value
    errors {
      field
      message
    }
  }
}
    `;
export type AdminRegisterTeamForSeasonMutationFn = ApolloReactCommon.MutationFunction<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables>;
export type AdminRegisterTeamForSeasonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables>, 'mutation'>;

    export const AdminRegisterTeamForSeasonComponent = (props: AdminRegisterTeamForSeasonComponentProps) => (
      <ApolloReactComponents.Mutation<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables> mutation={AdminRegisterTeamForSeasonDocument} {...props} />
    );
    
export type AdminRegisterTeamForSeasonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables> & TChildProps;
export function withAdminRegisterTeamForSeason<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AdminRegisterTeamForSeasonMutation,
  AdminRegisterTeamForSeasonMutationVariables,
  AdminRegisterTeamForSeasonProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables, AdminRegisterTeamForSeasonProps<TChildProps>>(AdminRegisterTeamForSeasonDocument, {
      alias: 'adminRegisterTeamForSeason',
      ...operationOptions
    });
};

/**
 * __useAdminRegisterTeamForSeasonMutation__
 *
 * To run a mutation, you first call `useAdminRegisterTeamForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRegisterTeamForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRegisterTeamForSeasonMutation, { data, loading, error }] = useAdminRegisterTeamForSeasonMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useAdminRegisterTeamForSeasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables>(AdminRegisterTeamForSeasonDocument, baseOptions);
      }
export type AdminRegisterTeamForSeasonMutationHookResult = ReturnType<typeof useAdminRegisterTeamForSeasonMutation>;
export type AdminRegisterTeamForSeasonMutationResult = ApolloReactCommon.MutationResult<AdminRegisterTeamForSeasonMutation>;
export type AdminRegisterTeamForSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminRegisterTeamForSeasonMutation, AdminRegisterTeamForSeasonMutationVariables>;
export const Upsert_Tournament_InfoDocument = gql`
    mutation upsert_tournament_info($id: ID, $sport: String!, $name: String!, $firstMatchDate: String!, $registrationCloseDate: String!, $registrationOpenDate: String!, $banner: String, $sponsorImage: String, $description: String, $isPremium: Boolean, $checkinLength: Int, $published: Boolean, $platform: ID) {
  editTournamentBasicInfo(id: $id, sport: $sport, name: $name, firstMatchDate: $firstMatchDate, registrationOpenDate: $registrationOpenDate, registrationCloseDate: $registrationCloseDate, banner: $banner, sponsorImage: $sponsorImage, description: $description, isPremium: $isPremium, checkinLength: $checkinLength, published: $published, platform: $platform) {
    id
    tournament {
      ...AdminTournamentInfo
      adminTournamentTeams {
        ...AdminTournamentTeamInfo
      }
    }
    errors {
      field
      message
    }
  }
}
    ${AdminTournamentInfoFragmentDoc}
${AdminTournamentTeamInfoFragmentDoc}`;
export type Upsert_Tournament_InfoMutationFn = ApolloReactCommon.MutationFunction<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables>;
export type Upsert_Tournament_InfoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables>, 'mutation'>;

    export const Upsert_Tournament_InfoComponent = (props: Upsert_Tournament_InfoComponentProps) => (
      <ApolloReactComponents.Mutation<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables> mutation={Upsert_Tournament_InfoDocument} {...props} />
    );
    
export type Upsert_Tournament_InfoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables> & TChildProps;
export function withUpsert_Tournament_Info<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Upsert_Tournament_InfoMutation,
  Upsert_Tournament_InfoMutationVariables,
  Upsert_Tournament_InfoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables, Upsert_Tournament_InfoProps<TChildProps>>(Upsert_Tournament_InfoDocument, {
      alias: 'upsertTournamentInfo',
      ...operationOptions
    });
};

/**
 * __useUpsert_Tournament_InfoMutation__
 *
 * To run a mutation, you first call `useUpsert_Tournament_InfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_Tournament_InfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTournamentInfoMutation, { data, loading, error }] = useUpsert_Tournament_InfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sport: // value for 'sport'
 *      name: // value for 'name'
 *      firstMatchDate: // value for 'firstMatchDate'
 *      registrationCloseDate: // value for 'registrationCloseDate'
 *      registrationOpenDate: // value for 'registrationOpenDate'
 *      banner: // value for 'banner'
 *      sponsorImage: // value for 'sponsorImage'
 *      description: // value for 'description'
 *      isPremium: // value for 'isPremium'
 *      checkinLength: // value for 'checkinLength'
 *      published: // value for 'published'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useUpsert_Tournament_InfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables>) {
        return ApolloReactHooks.useMutation<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables>(Upsert_Tournament_InfoDocument, baseOptions);
      }
export type Upsert_Tournament_InfoMutationHookResult = ReturnType<typeof useUpsert_Tournament_InfoMutation>;
export type Upsert_Tournament_InfoMutationResult = ApolloReactCommon.MutationResult<Upsert_Tournament_InfoMutation>;
export type Upsert_Tournament_InfoMutationOptions = ApolloReactCommon.BaseMutationOptions<Upsert_Tournament_InfoMutation, Upsert_Tournament_InfoMutationVariables>;
export const PopulateTournamentDocument = gql`
    mutation populateTournament($tournamentId: ID!) {
  populateTournament(tournamentId: $tournamentId) {
    value {
      ...AdminTournamentInfo
      adminTournamentTeams {
        ...AdminTournamentTeamInfo
      }
    }
    errors {
      field
      message
    }
  }
}
    ${AdminTournamentInfoFragmentDoc}
${AdminTournamentTeamInfoFragmentDoc}`;
export type PopulateTournamentMutationFn = ApolloReactCommon.MutationFunction<PopulateTournamentMutation, PopulateTournamentMutationVariables>;
export type PopulateTournamentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PopulateTournamentMutation, PopulateTournamentMutationVariables>, 'mutation'>;

    export const PopulateTournamentComponent = (props: PopulateTournamentComponentProps) => (
      <ApolloReactComponents.Mutation<PopulateTournamentMutation, PopulateTournamentMutationVariables> mutation={PopulateTournamentDocument} {...props} />
    );
    
export type PopulateTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PopulateTournamentMutation, PopulateTournamentMutationVariables> & TChildProps;
export function withPopulateTournament<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PopulateTournamentMutation,
  PopulateTournamentMutationVariables,
  PopulateTournamentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PopulateTournamentMutation, PopulateTournamentMutationVariables, PopulateTournamentProps<TChildProps>>(PopulateTournamentDocument, {
      alias: 'populateTournament',
      ...operationOptions
    });
};

/**
 * __usePopulateTournamentMutation__
 *
 * To run a mutation, you first call `usePopulateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePopulateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [populateTournamentMutation, { data, loading, error }] = usePopulateTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function usePopulateTournamentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PopulateTournamentMutation, PopulateTournamentMutationVariables>) {
        return ApolloReactHooks.useMutation<PopulateTournamentMutation, PopulateTournamentMutationVariables>(PopulateTournamentDocument, baseOptions);
      }
export type PopulateTournamentMutationHookResult = ReturnType<typeof usePopulateTournamentMutation>;
export type PopulateTournamentMutationResult = ApolloReactCommon.MutationResult<PopulateTournamentMutation>;
export type PopulateTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<PopulateTournamentMutation, PopulateTournamentMutationVariables>;
export const GetPlatformsDocument = gql`
    query GetPlatforms {
  platforms {
    id
    uid
    title
  }
}
    `;
export type GetPlatformsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlatformsQuery, GetPlatformsQueryVariables>, 'query'>;

    export const GetPlatformsComponent = (props: GetPlatformsComponentProps) => (
      <ApolloReactComponents.Query<GetPlatformsQuery, GetPlatformsQueryVariables> query={GetPlatformsDocument} {...props} />
    );
    
export type GetPlatformsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlatformsQuery, GetPlatformsQueryVariables> & TChildProps;
export function withGetPlatforms<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlatformsQuery,
  GetPlatformsQueryVariables,
  GetPlatformsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlatformsQuery, GetPlatformsQueryVariables, GetPlatformsProps<TChildProps>>(GetPlatformsDocument, {
      alias: 'getPlatforms',
      ...operationOptions
    });
};

/**
 * __useGetPlatformsQuery__
 *
 * To run a query within a React component, call `useGetPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlatformsQuery, GetPlatformsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlatformsQuery, GetPlatformsQueryVariables>(GetPlatformsDocument, baseOptions);
      }
export function useGetPlatformsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlatformsQuery, GetPlatformsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlatformsQuery, GetPlatformsQueryVariables>(GetPlatformsDocument, baseOptions);
        }
export type GetPlatformsQueryHookResult = ReturnType<typeof useGetPlatformsQuery>;
export type GetPlatformsLazyQueryHookResult = ReturnType<typeof useGetPlatformsLazyQuery>;
export type GetPlatformsQueryResult = ApolloReactCommon.QueryResult<GetPlatformsQuery, GetPlatformsQueryVariables>;
export const Edit_Tournament_IrlDocument = gql`
    mutation edit_tournament_irl($id: ID!, $address: String!, $state: String!, $onsiteEntryCode: String!) {
  editTournamentIrl(id: $id, address: $address, state: $state, onsiteEntryCode: $onsiteEntryCode) {
    id
    errors {
      field
      message
    }
  }
}
    `;
export type Edit_Tournament_IrlMutationFn = ApolloReactCommon.MutationFunction<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables>;
export type Edit_Tournament_IrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables>, 'mutation'>;

    export const Edit_Tournament_IrlComponent = (props: Edit_Tournament_IrlComponentProps) => (
      <ApolloReactComponents.Mutation<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables> mutation={Edit_Tournament_IrlDocument} {...props} />
    );
    
export type Edit_Tournament_IrlProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables> & TChildProps;
export function withEdit_Tournament_Irl<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Edit_Tournament_IrlMutation,
  Edit_Tournament_IrlMutationVariables,
  Edit_Tournament_IrlProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables, Edit_Tournament_IrlProps<TChildProps>>(Edit_Tournament_IrlDocument, {
      alias: 'editTournamentIrl',
      ...operationOptions
    });
};

/**
 * __useEdit_Tournament_IrlMutation__
 *
 * To run a mutation, you first call `useEdit_Tournament_IrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEdit_Tournament_IrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTournamentIrlMutation, { data, loading, error }] = useEdit_Tournament_IrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      address: // value for 'address'
 *      state: // value for 'state'
 *      onsiteEntryCode: // value for 'onsiteEntryCode'
 *   },
 * });
 */
export function useEdit_Tournament_IrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables>) {
        return ApolloReactHooks.useMutation<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables>(Edit_Tournament_IrlDocument, baseOptions);
      }
export type Edit_Tournament_IrlMutationHookResult = ReturnType<typeof useEdit_Tournament_IrlMutation>;
export type Edit_Tournament_IrlMutationResult = ApolloReactCommon.MutationResult<Edit_Tournament_IrlMutation>;
export type Edit_Tournament_IrlMutationOptions = ApolloReactCommon.BaseMutationOptions<Edit_Tournament_IrlMutation, Edit_Tournament_IrlMutationVariables>;
export const EditTournamentLogisticsDocument = gql`
    mutation EditTournamentLogistics($defaultSeriesLength: Int!, $feedsIntoId: ID!, $id: ID!, $minTeamSize: Int!, $numberOfTeams: Int!, $priceCents: Int!, $seasonId: ID!, $styleChoice: BracketTypes!, $typeChoice: TournamentTypes!, $multibracketCapable: Boolean!, $desiredBrackets: Int!) {
  editTournamentLogistics(defaultSeriesLength: $defaultSeriesLength, feedsIntoId: $feedsIntoId, id: $id, minTeamSize: $minTeamSize, numberOfTeams: $numberOfTeams, priceCents: $priceCents, seasonId: $seasonId, styleChoice: $styleChoice, typeChoice: $typeChoice, desiredBrackets: $desiredBrackets, multibracketCapable: $multibracketCapable) {
    value {
      ...AdminTournamentInfo
      adminTournamentTeams {
        ...AdminTournamentTeamInfo
      }
    }
  }
}
    ${AdminTournamentInfoFragmentDoc}
${AdminTournamentTeamInfoFragmentDoc}`;
export type EditTournamentLogisticsMutationFn = ApolloReactCommon.MutationFunction<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables>;
export type EditTournamentLogisticsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables>, 'mutation'>;

    export const EditTournamentLogisticsComponent = (props: EditTournamentLogisticsComponentProps) => (
      <ApolloReactComponents.Mutation<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables> mutation={EditTournamentLogisticsDocument} {...props} />
    );
    
export type EditTournamentLogisticsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables> & TChildProps;
export function withEditTournamentLogistics<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditTournamentLogisticsMutation,
  EditTournamentLogisticsMutationVariables,
  EditTournamentLogisticsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables, EditTournamentLogisticsProps<TChildProps>>(EditTournamentLogisticsDocument, {
      alias: 'editTournamentLogistics',
      ...operationOptions
    });
};

/**
 * __useEditTournamentLogisticsMutation__
 *
 * To run a mutation, you first call `useEditTournamentLogisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTournamentLogisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTournamentLogisticsMutation, { data, loading, error }] = useEditTournamentLogisticsMutation({
 *   variables: {
 *      defaultSeriesLength: // value for 'defaultSeriesLength'
 *      feedsIntoId: // value for 'feedsIntoId'
 *      id: // value for 'id'
 *      minTeamSize: // value for 'minTeamSize'
 *      numberOfTeams: // value for 'numberOfTeams'
 *      priceCents: // value for 'priceCents'
 *      seasonId: // value for 'seasonId'
 *      styleChoice: // value for 'styleChoice'
 *      typeChoice: // value for 'typeChoice'
 *      multibracketCapable: // value for 'multibracketCapable'
 *      desiredBrackets: // value for 'desiredBrackets'
 *   },
 * });
 */
export function useEditTournamentLogisticsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables>(EditTournamentLogisticsDocument, baseOptions);
      }
export type EditTournamentLogisticsMutationHookResult = ReturnType<typeof useEditTournamentLogisticsMutation>;
export type EditTournamentLogisticsMutationResult = ApolloReactCommon.MutationResult<EditTournamentLogisticsMutation>;
export type EditTournamentLogisticsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTournamentLogisticsMutation, EditTournamentLogisticsMutationVariables>;
export const EditTournamentRoundPrototypesDocument = gql`
    mutation EditTournamentRoundPrototypes($tournamentId: ID!, $roundPrototypes: [RoundMatchPrototypeInput!]!) {
  editTournamentRoundPrototypes(roundPrototypes: $roundPrototypes, tournamentId: $tournamentId) {
    value {
      ...AdminTournamentInfo
      adminTournamentTeams {
        ...AdminTournamentTeamInfo
      }
    }
  }
}
    ${AdminTournamentInfoFragmentDoc}
${AdminTournamentTeamInfoFragmentDoc}`;
export type EditTournamentRoundPrototypesMutationFn = ApolloReactCommon.MutationFunction<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables>;
export type EditTournamentRoundPrototypesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables>, 'mutation'>;

    export const EditTournamentRoundPrototypesComponent = (props: EditTournamentRoundPrototypesComponentProps) => (
      <ApolloReactComponents.Mutation<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables> mutation={EditTournamentRoundPrototypesDocument} {...props} />
    );
    
export type EditTournamentRoundPrototypesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables> & TChildProps;
export function withEditTournamentRoundPrototypes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditTournamentRoundPrototypesMutation,
  EditTournamentRoundPrototypesMutationVariables,
  EditTournamentRoundPrototypesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables, EditTournamentRoundPrototypesProps<TChildProps>>(EditTournamentRoundPrototypesDocument, {
      alias: 'editTournamentRoundPrototypes',
      ...operationOptions
    });
};

/**
 * __useEditTournamentRoundPrototypesMutation__
 *
 * To run a mutation, you first call `useEditTournamentRoundPrototypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTournamentRoundPrototypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTournamentRoundPrototypesMutation, { data, loading, error }] = useEditTournamentRoundPrototypesMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      roundPrototypes: // value for 'roundPrototypes'
 *   },
 * });
 */
export function useEditTournamentRoundPrototypesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables>(EditTournamentRoundPrototypesDocument, baseOptions);
      }
export type EditTournamentRoundPrototypesMutationHookResult = ReturnType<typeof useEditTournamentRoundPrototypesMutation>;
export type EditTournamentRoundPrototypesMutationResult = ApolloReactCommon.MutationResult<EditTournamentRoundPrototypesMutation>;
export type EditTournamentRoundPrototypesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTournamentRoundPrototypesMutation, EditTournamentRoundPrototypesMutationVariables>;
export const GetSeasonsDocument = gql`
    query getSeasons($sport: String!) {
  sportSeasons(sport: $sport) {
    ...sportSeasonsInfoFragment
  }
}
    ${SportSeasonsInfoFragmentFragmentDoc}`;
export type GetSeasonsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSeasonsQuery, GetSeasonsQueryVariables>, 'query'> & ({ variables: GetSeasonsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSeasonsComponent = (props: GetSeasonsComponentProps) => (
      <ApolloReactComponents.Query<GetSeasonsQuery, GetSeasonsQueryVariables> query={GetSeasonsDocument} {...props} />
    );
    
export type GetSeasonsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSeasonsQuery, GetSeasonsQueryVariables> & TChildProps;
export function withGetSeasons<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSeasonsQuery,
  GetSeasonsQueryVariables,
  GetSeasonsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSeasonsQuery, GetSeasonsQueryVariables, GetSeasonsProps<TChildProps>>(GetSeasonsDocument, {
      alias: 'getSeasons',
      ...operationOptions
    });
};

/**
 * __useGetSeasonsQuery__
 *
 * To run a query within a React component, call `useGetSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonsQuery({
 *   variables: {
 *      sport: // value for 'sport'
 *   },
 * });
 */
export function useGetSeasonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
      }
export function useGetSeasonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
        }
export type GetSeasonsQueryHookResult = ReturnType<typeof useGetSeasonsQuery>;
export type GetSeasonsLazyQueryHookResult = ReturnType<typeof useGetSeasonsLazyQuery>;
export type GetSeasonsQueryResult = ApolloReactCommon.QueryResult<GetSeasonsQuery, GetSeasonsQueryVariables>;
export const EditTournamentTeamsDocument = gql`
    mutation EditTournamentTeams($tournamentId: ID!, $teamRegistriesMap: [TeamTournamentRegistryMap!]!) {
  editTournamentTeams(tournamentId: $tournamentId, teamRegistriesMap: $teamRegistriesMap) {
    value {
      ...AdminTournamentInfo
      adminTournamentTeams {
        ...AdminTournamentTeamInfo
      }
    }
  }
}
    ${AdminTournamentInfoFragmentDoc}
${AdminTournamentTeamInfoFragmentDoc}`;
export type EditTournamentTeamsMutationFn = ApolloReactCommon.MutationFunction<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables>;
export type EditTournamentTeamsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables>, 'mutation'>;

    export const EditTournamentTeamsComponent = (props: EditTournamentTeamsComponentProps) => (
      <ApolloReactComponents.Mutation<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables> mutation={EditTournamentTeamsDocument} {...props} />
    );
    
export type EditTournamentTeamsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables> & TChildProps;
export function withEditTournamentTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditTournamentTeamsMutation,
  EditTournamentTeamsMutationVariables,
  EditTournamentTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables, EditTournamentTeamsProps<TChildProps>>(EditTournamentTeamsDocument, {
      alias: 'editTournamentTeams',
      ...operationOptions
    });
};

/**
 * __useEditTournamentTeamsMutation__
 *
 * To run a mutation, you first call `useEditTournamentTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTournamentTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTournamentTeamsMutation, { data, loading, error }] = useEditTournamentTeamsMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      teamRegistriesMap: // value for 'teamRegistriesMap'
 *   },
 * });
 */
export function useEditTournamentTeamsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables>(EditTournamentTeamsDocument, baseOptions);
      }
export type EditTournamentTeamsMutationHookResult = ReturnType<typeof useEditTournamentTeamsMutation>;
export type EditTournamentTeamsMutationResult = ApolloReactCommon.MutationResult<EditTournamentTeamsMutation>;
export type EditTournamentTeamsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTournamentTeamsMutation, EditTournamentTeamsMutationVariables>;
export const AdminTournnamentEligibleTeamsDocument = gql`
    query AdminTournnamentEligibleTeams($tournamentId: ID!, $name: String) {
  tournament(id: $tournamentId) {
    id
    eligibleTeams(name: $name) {
      ...eligibleTeamInfoTournament
    }
  }
}
    ${EligibleTeamInfoTournamentFragmentDoc}`;
export type AdminTournnamentEligibleTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables>, 'query'> & ({ variables: AdminTournnamentEligibleTeamsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminTournnamentEligibleTeamsComponent = (props: AdminTournnamentEligibleTeamsComponentProps) => (
      <ApolloReactComponents.Query<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables> query={AdminTournnamentEligibleTeamsDocument} {...props} />
    );
    
export type AdminTournnamentEligibleTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables> & TChildProps;
export function withAdminTournnamentEligibleTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AdminTournnamentEligibleTeamsQuery,
  AdminTournnamentEligibleTeamsQueryVariables,
  AdminTournnamentEligibleTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables, AdminTournnamentEligibleTeamsProps<TChildProps>>(AdminTournnamentEligibleTeamsDocument, {
      alias: 'adminTournnamentEligibleTeams',
      ...operationOptions
    });
};

/**
 * __useAdminTournnamentEligibleTeamsQuery__
 *
 * To run a query within a React component, call `useAdminTournnamentEligibleTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTournnamentEligibleTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTournnamentEligibleTeamsQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAdminTournnamentEligibleTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables>(AdminTournnamentEligibleTeamsDocument, baseOptions);
      }
export function useAdminTournnamentEligibleTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables>(AdminTournnamentEligibleTeamsDocument, baseOptions);
        }
export type AdminTournnamentEligibleTeamsQueryHookResult = ReturnType<typeof useAdminTournnamentEligibleTeamsQuery>;
export type AdminTournnamentEligibleTeamsLazyQueryHookResult = ReturnType<typeof useAdminTournnamentEligibleTeamsLazyQuery>;
export type AdminTournnamentEligibleTeamsQueryResult = ApolloReactCommon.QueryResult<AdminTournnamentEligibleTeamsQuery, AdminTournnamentEligibleTeamsQueryVariables>;
export const AdminRegisterTeamForTournamentDocument = gql`
    mutation AdminRegisterTeamForTournament($teamId: ID!, $tournamentId: ID!) {
  registerTeamForTournament(teamId: $teamId, tournamentId: $tournamentId) {
    value
    errors {
      field
      message
    }
  }
}
    `;
export type AdminRegisterTeamForTournamentMutationFn = ApolloReactCommon.MutationFunction<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables>;
export type AdminRegisterTeamForTournamentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables>, 'mutation'>;

    export const AdminRegisterTeamForTournamentComponent = (props: AdminRegisterTeamForTournamentComponentProps) => (
      <ApolloReactComponents.Mutation<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables> mutation={AdminRegisterTeamForTournamentDocument} {...props} />
    );
    
export type AdminRegisterTeamForTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables> & TChildProps;
export function withAdminRegisterTeamForTournament<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AdminRegisterTeamForTournamentMutation,
  AdminRegisterTeamForTournamentMutationVariables,
  AdminRegisterTeamForTournamentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables, AdminRegisterTeamForTournamentProps<TChildProps>>(AdminRegisterTeamForTournamentDocument, {
      alias: 'adminRegisterTeamForTournament',
      ...operationOptions
    });
};

/**
 * __useAdminRegisterTeamForTournamentMutation__
 *
 * To run a mutation, you first call `useAdminRegisterTeamForTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRegisterTeamForTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRegisterTeamForTournamentMutation, { data, loading, error }] = useAdminRegisterTeamForTournamentMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useAdminRegisterTeamForTournamentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables>(AdminRegisterTeamForTournamentDocument, baseOptions);
      }
export type AdminRegisterTeamForTournamentMutationHookResult = ReturnType<typeof useAdminRegisterTeamForTournamentMutation>;
export type AdminRegisterTeamForTournamentMutationResult = ApolloReactCommon.MutationResult<AdminRegisterTeamForTournamentMutation>;
export type AdminRegisterTeamForTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminRegisterTeamForTournamentMutation, AdminRegisterTeamForTournamentMutationVariables>;
export const UpsertUniversityInfoDocument = gql`
    mutation UpsertUniversityInfo($id: ID, $name: String!, $abbreviation: String, $state: String, $domains: [String!]) {
  upsertUniversity(id: $id, name: $name, abbreviation: $abbreviation, state: $state, domains: $domains) {
    success
    value {
      id
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpsertUniversityInfoMutationFn = ApolloReactCommon.MutationFunction<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables>;
export type UpsertUniversityInfoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables>, 'mutation'>;

    export const UpsertUniversityInfoComponent = (props: UpsertUniversityInfoComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables> mutation={UpsertUniversityInfoDocument} {...props} />
    );
    
export type UpsertUniversityInfoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables> & TChildProps;
export function withUpsertUniversityInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertUniversityInfoMutation,
  UpsertUniversityInfoMutationVariables,
  UpsertUniversityInfoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables, UpsertUniversityInfoProps<TChildProps>>(UpsertUniversityInfoDocument, {
      alias: 'upsertUniversityInfo',
      ...operationOptions
    });
};

/**
 * __useUpsertUniversityInfoMutation__
 *
 * To run a mutation, you first call `useUpsertUniversityInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUniversityInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUniversityInfoMutation, { data, loading, error }] = useUpsertUniversityInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      state: // value for 'state'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useUpsertUniversityInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables>(UpsertUniversityInfoDocument, baseOptions);
      }
export type UpsertUniversityInfoMutationHookResult = ReturnType<typeof useUpsertUniversityInfoMutation>;
export type UpsertUniversityInfoMutationResult = ApolloReactCommon.MutationResult<UpsertUniversityInfoMutation>;
export type UpsertUniversityInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUniversityInfoMutation, UpsertUniversityInfoMutationVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($name: String!, $sport: String!, $coordinatorRole: String!) {
  upsertTeam(name: $name, sport: $sport, coordinatorRole: $coordinatorRole) {
    success
    value {
      id
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateTeamMutationFn = ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;
export type CreateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTeamMutation, CreateTeamMutationVariables>, 'mutation'>;

    export const CreateTeamComponent = (props: CreateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTeamMutation, CreateTeamMutationVariables> mutation={CreateTeamDocument} {...props} />
    );
    
export type CreateTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateTeamMutation, CreateTeamMutationVariables> & TChildProps;
export function withCreateTeam<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTeamMutation,
  CreateTeamMutationVariables,
  CreateTeamProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTeamMutation, CreateTeamMutationVariables, CreateTeamProps<TChildProps>>(CreateTeamDocument, {
      alias: 'createTeam',
      ...operationOptions
    });
};

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      sport: // value for 'sport'
 *      coordinatorRole: // value for 'coordinatorRole'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = ApolloReactCommon.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const ReportForfeitDocument = gql`
    mutation ReportForfeit($matchId: ID!, $explanation: String!, $proofScreenshot: Upload!, $requestingTeamId: ID) {
  reportForfeit(matchId: $matchId, explanation: $explanation, proofScreenshot: $proofScreenshot, requestingTeamId: $requestingTeamId) {
    errors
  }
}
    `;
export type ReportForfeitMutationFn = ApolloReactCommon.MutationFunction<ReportForfeitMutation, ReportForfeitMutationVariables>;
export type ReportForfeitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportForfeitMutation, ReportForfeitMutationVariables>, 'mutation'>;

    export const ReportForfeitComponent = (props: ReportForfeitComponentProps) => (
      <ApolloReactComponents.Mutation<ReportForfeitMutation, ReportForfeitMutationVariables> mutation={ReportForfeitDocument} {...props} />
    );
    
export type ReportForfeitProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReportForfeitMutation, ReportForfeitMutationVariables> & TChildProps;
export function withReportForfeit<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportForfeitMutation,
  ReportForfeitMutationVariables,
  ReportForfeitProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReportForfeitMutation, ReportForfeitMutationVariables, ReportForfeitProps<TChildProps>>(ReportForfeitDocument, {
      alias: 'reportForfeit',
      ...operationOptions
    });
};

/**
 * __useReportForfeitMutation__
 *
 * To run a mutation, you first call `useReportForfeitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportForfeitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportForfeitMutation, { data, loading, error }] = useReportForfeitMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      explanation: // value for 'explanation'
 *      proofScreenshot: // value for 'proofScreenshot'
 *      requestingTeamId: // value for 'requestingTeamId'
 *   },
 * });
 */
export function useReportForfeitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportForfeitMutation, ReportForfeitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportForfeitMutation, ReportForfeitMutationVariables>(ReportForfeitDocument, baseOptions);
      }
export type ReportForfeitMutationHookResult = ReturnType<typeof useReportForfeitMutation>;
export type ReportForfeitMutationResult = ApolloReactCommon.MutationResult<ReportForfeitMutation>;
export type ReportForfeitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportForfeitMutation, ReportForfeitMutationVariables>;
export const SendPasswordResetDocument = gql`
    mutation sendPasswordReset($email: String!) {
  sendPasswordReset(email: $email) {
    success
  }
}
    `;
export type SendPasswordResetMutationFn = ApolloReactCommon.MutationFunction<SendPasswordResetMutation, SendPasswordResetMutationVariables>;
export type SendPasswordResetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>, 'mutation'>;

    export const SendPasswordResetComponent = (props: SendPasswordResetComponentProps) => (
      <ApolloReactComponents.Mutation<SendPasswordResetMutation, SendPasswordResetMutationVariables> mutation={SendPasswordResetDocument} {...props} />
    );
    
export type SendPasswordResetProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendPasswordResetMutation, SendPasswordResetMutationVariables> & TChildProps;
export function withSendPasswordReset<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendPasswordResetMutation,
  SendPasswordResetMutationVariables,
  SendPasswordResetProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendPasswordResetMutation, SendPasswordResetMutationVariables, SendPasswordResetProps<TChildProps>>(SendPasswordResetDocument, {
      alias: 'sendPasswordReset',
      ...operationOptions
    });
};

/**
 * __useSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetMutation, { data, loading, error }] = useSendPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<SendPasswordResetMutation, SendPasswordResetMutationVariables>(SendPasswordResetDocument, baseOptions);
      }
export type SendPasswordResetMutationHookResult = ReturnType<typeof useSendPasswordResetMutation>;
export type SendPasswordResetMutationResult = ApolloReactCommon.MutationResult<SendPasswordResetMutation>;
export type SendPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>;
export const Report_Csgo_GameDocument = gql`
    mutation report_csgo_game($gameId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $mapId: ID!, $evidenceScreenshot: String!) {
  reportCsgoGame(gameId: $gameId, homeTeam: $homeTeam, awayTeam: $awayTeam, mapId: $mapId, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Csgo_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables>;
export type Report_Csgo_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables>, 'mutation'>;

    export const Report_Csgo_GameComponent = (props: Report_Csgo_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables> mutation={Report_Csgo_GameDocument} {...props} />
    );
    
export type Report_Csgo_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables> & TChildProps;
export function withReport_Csgo_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Csgo_GameMutation,
  Report_Csgo_GameMutationVariables,
  Report_Csgo_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables, Report_Csgo_GameProps<TChildProps>>(Report_Csgo_GameDocument, {
      alias: 'reportCsgoGame',
      ...operationOptions
    });
};

/**
 * __useReport_Csgo_GameMutation__
 *
 * To run a mutation, you first call `useReport_Csgo_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Csgo_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCsgoGameMutation, { data, loading, error }] = useReport_Csgo_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      mapId: // value for 'mapId'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Csgo_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables>(Report_Csgo_GameDocument, baseOptions);
      }
export type Report_Csgo_GameMutationHookResult = ReturnType<typeof useReport_Csgo_GameMutation>;
export type Report_Csgo_GameMutationResult = ApolloReactCommon.MutationResult<Report_Csgo_GameMutation>;
export type Report_Csgo_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Csgo_GameMutation, Report_Csgo_GameMutationVariables>;
export const Dota2HeroesDocument = gql`
    query dota2Heroes {
  sportPlayerCharacteristics(dota2Heroes: true) {
    dota2Heroes {
      options
    }
  }
}
    `;
export type Dota2HeroesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Dota2HeroesQuery, Dota2HeroesQueryVariables>, 'query'>;

    export const Dota2HeroesComponent = (props: Dota2HeroesComponentProps) => (
      <ApolloReactComponents.Query<Dota2HeroesQuery, Dota2HeroesQueryVariables> query={Dota2HeroesDocument} {...props} />
    );
    
export type Dota2HeroesProps<TChildProps = {}> = ApolloReactHoc.DataProps<Dota2HeroesQuery, Dota2HeroesQueryVariables> & TChildProps;
export function withDota2Heroes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Dota2HeroesQuery,
  Dota2HeroesQueryVariables,
  Dota2HeroesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, Dota2HeroesQuery, Dota2HeroesQueryVariables, Dota2HeroesProps<TChildProps>>(Dota2HeroesDocument, {
      alias: 'dota2Heroes',
      ...operationOptions
    });
};

/**
 * __useDota2HeroesQuery__
 *
 * To run a query within a React component, call `useDota2HeroesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDota2HeroesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDota2HeroesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDota2HeroesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Dota2HeroesQuery, Dota2HeroesQueryVariables>) {
        return ApolloReactHooks.useQuery<Dota2HeroesQuery, Dota2HeroesQueryVariables>(Dota2HeroesDocument, baseOptions);
      }
export function useDota2HeroesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Dota2HeroesQuery, Dota2HeroesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Dota2HeroesQuery, Dota2HeroesQueryVariables>(Dota2HeroesDocument, baseOptions);
        }
export type Dota2HeroesQueryHookResult = ReturnType<typeof useDota2HeroesQuery>;
export type Dota2HeroesLazyQueryHookResult = ReturnType<typeof useDota2HeroesLazyQuery>;
export type Dota2HeroesQueryResult = ApolloReactCommon.QueryResult<Dota2HeroesQuery, Dota2HeroesQueryVariables>;
export const Report_Dota2_GameDocument = gql`
    mutation report_dota2_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportDota2Game(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Dota2_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables>;
export type Report_Dota2_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables>, 'mutation'>;

    export const Report_Dota2_GameComponent = (props: Report_Dota2_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables> mutation={Report_Dota2_GameDocument} {...props} />
    );
    
export type Report_Dota2_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables> & TChildProps;
export function withReport_Dota2_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Dota2_GameMutation,
  Report_Dota2_GameMutationVariables,
  Report_Dota2_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables, Report_Dota2_GameProps<TChildProps>>(Report_Dota2_GameDocument, {
      alias: 'reportDota2Game',
      ...operationOptions
    });
};

/**
 * __useReport_Dota2_GameMutation__
 *
 * To run a mutation, you first call `useReport_Dota2_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Dota2_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportDota2GameMutation, { data, loading, error }] = useReport_Dota2_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Dota2_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables>(Report_Dota2_GameDocument, baseOptions);
      }
export type Report_Dota2_GameMutationHookResult = ReturnType<typeof useReport_Dota2_GameMutation>;
export type Report_Dota2_GameMutationResult = ApolloReactCommon.MutationResult<Report_Dota2_GameMutation>;
export type Report_Dota2_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Dota2_GameMutation, Report_Dota2_GameMutationVariables>;
export const Report_Fortnite_GameDocument = gql`
    mutation report_fortnite_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportFortniteGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Fortnite_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables>;
export type Report_Fortnite_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables>, 'mutation'>;

    export const Report_Fortnite_GameComponent = (props: Report_Fortnite_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables> mutation={Report_Fortnite_GameDocument} {...props} />
    );
    
export type Report_Fortnite_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables> & TChildProps;
export function withReport_Fortnite_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Fortnite_GameMutation,
  Report_Fortnite_GameMutationVariables,
  Report_Fortnite_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables, Report_Fortnite_GameProps<TChildProps>>(Report_Fortnite_GameDocument, {
      alias: 'reportFortniteGame',
      ...operationOptions
    });
};

/**
 * __useReport_Fortnite_GameMutation__
 *
 * To run a mutation, you first call `useReport_Fortnite_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Fortnite_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFortniteGameMutation, { data, loading, error }] = useReport_Fortnite_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Fortnite_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables>(Report_Fortnite_GameDocument, baseOptions);
      }
export type Report_Fortnite_GameMutationHookResult = ReturnType<typeof useReport_Fortnite_GameMutation>;
export type Report_Fortnite_GameMutationResult = ApolloReactCommon.MutationResult<Report_Fortnite_GameMutation>;
export type Report_Fortnite_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Fortnite_GameMutation, Report_Fortnite_GameMutationVariables>;
export const LolChampionsDocument = gql`
    query lolChampions {
  sportPlayerCharacteristics(lolChampions: true) {
    lolChampions {
      options
    }
  }
}
    `;
export type LolChampionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LolChampionsQuery, LolChampionsQueryVariables>, 'query'>;

    export const LolChampionsComponent = (props: LolChampionsComponentProps) => (
      <ApolloReactComponents.Query<LolChampionsQuery, LolChampionsQueryVariables> query={LolChampionsDocument} {...props} />
    );
    
export type LolChampionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<LolChampionsQuery, LolChampionsQueryVariables> & TChildProps;
export function withLolChampions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LolChampionsQuery,
  LolChampionsQueryVariables,
  LolChampionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, LolChampionsQuery, LolChampionsQueryVariables, LolChampionsProps<TChildProps>>(LolChampionsDocument, {
      alias: 'lolChampions',
      ...operationOptions
    });
};

/**
 * __useLolChampionsQuery__
 *
 * To run a query within a React component, call `useLolChampionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLolChampionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLolChampionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLolChampionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LolChampionsQuery, LolChampionsQueryVariables>) {
        return ApolloReactHooks.useQuery<LolChampionsQuery, LolChampionsQueryVariables>(LolChampionsDocument, baseOptions);
      }
export function useLolChampionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LolChampionsQuery, LolChampionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LolChampionsQuery, LolChampionsQueryVariables>(LolChampionsDocument, baseOptions);
        }
export type LolChampionsQueryHookResult = ReturnType<typeof useLolChampionsQuery>;
export type LolChampionsLazyQueryHookResult = ReturnType<typeof useLolChampionsLazyQuery>;
export type LolChampionsQueryResult = ApolloReactCommon.QueryResult<LolChampionsQuery, LolChampionsQueryVariables>;
export const Report_Lol_GameDocument = gql`
    mutation report_lol_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportLolGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Lol_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Lol_GameMutation, Report_Lol_GameMutationVariables>;
export type Report_Lol_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Lol_GameMutation, Report_Lol_GameMutationVariables>, 'mutation'>;

    export const Report_Lol_GameComponent = (props: Report_Lol_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Lol_GameMutation, Report_Lol_GameMutationVariables> mutation={Report_Lol_GameDocument} {...props} />
    );
    
export type Report_Lol_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Lol_GameMutation, Report_Lol_GameMutationVariables> & TChildProps;
export function withReport_Lol_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Lol_GameMutation,
  Report_Lol_GameMutationVariables,
  Report_Lol_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Lol_GameMutation, Report_Lol_GameMutationVariables, Report_Lol_GameProps<TChildProps>>(Report_Lol_GameDocument, {
      alias: 'reportLolGame',
      ...operationOptions
    });
};

/**
 * __useReport_Lol_GameMutation__
 *
 * To run a mutation, you first call `useReport_Lol_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Lol_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportLolGameMutation, { data, loading, error }] = useReport_Lol_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Lol_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Lol_GameMutation, Report_Lol_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Lol_GameMutation, Report_Lol_GameMutationVariables>(Report_Lol_GameDocument, baseOptions);
      }
export type Report_Lol_GameMutationHookResult = ReturnType<typeof useReport_Lol_GameMutation>;
export type Report_Lol_GameMutationResult = ApolloReactCommon.MutationResult<Report_Lol_GameMutation>;
export type Report_Lol_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Lol_GameMutation, Report_Lol_GameMutationVariables>;
export const ReportMaddenGameDocument = gql`
    mutation ReportMaddenGame($gameId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $winningTeamId: ID!, $evidenceScreenshot: String!) {
  reportMaddenGame(gameId: $gameId, homeTeam: $homeTeam, awayTeam: $awayTeam, winningTeamId: $winningTeamId, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type ReportMaddenGameMutationFn = ApolloReactCommon.MutationFunction<ReportMaddenGameMutation, ReportMaddenGameMutationVariables>;
export type ReportMaddenGameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportMaddenGameMutation, ReportMaddenGameMutationVariables>, 'mutation'>;

    export const ReportMaddenGameComponent = (props: ReportMaddenGameComponentProps) => (
      <ApolloReactComponents.Mutation<ReportMaddenGameMutation, ReportMaddenGameMutationVariables> mutation={ReportMaddenGameDocument} {...props} />
    );
    
export type ReportMaddenGameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReportMaddenGameMutation, ReportMaddenGameMutationVariables> & TChildProps;
export function withReportMaddenGame<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportMaddenGameMutation,
  ReportMaddenGameMutationVariables,
  ReportMaddenGameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReportMaddenGameMutation, ReportMaddenGameMutationVariables, ReportMaddenGameProps<TChildProps>>(ReportMaddenGameDocument, {
      alias: 'reportMaddenGame',
      ...operationOptions
    });
};

/**
 * __useReportMaddenGameMutation__
 *
 * To run a mutation, you first call `useReportMaddenGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMaddenGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMaddenGameMutation, { data, loading, error }] = useReportMaddenGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      winningTeamId: // value for 'winningTeamId'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReportMaddenGameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportMaddenGameMutation, ReportMaddenGameMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportMaddenGameMutation, ReportMaddenGameMutationVariables>(ReportMaddenGameDocument, baseOptions);
      }
export type ReportMaddenGameMutationHookResult = ReturnType<typeof useReportMaddenGameMutation>;
export type ReportMaddenGameMutationResult = ApolloReactCommon.MutationResult<ReportMaddenGameMutation>;
export type ReportMaddenGameMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportMaddenGameMutation, ReportMaddenGameMutationVariables>;
export const Report_Mtga_GameDocument = gql`
    mutation report_mtga_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportMtgaGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Mtga_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables>;
export type Report_Mtga_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables>, 'mutation'>;

    export const Report_Mtga_GameComponent = (props: Report_Mtga_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables> mutation={Report_Mtga_GameDocument} {...props} />
    );
    
export type Report_Mtga_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables> & TChildProps;
export function withReport_Mtga_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Mtga_GameMutation,
  Report_Mtga_GameMutationVariables,
  Report_Mtga_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables, Report_Mtga_GameProps<TChildProps>>(Report_Mtga_GameDocument, {
      alias: 'reportMtgaGame',
      ...operationOptions
    });
};

/**
 * __useReport_Mtga_GameMutation__
 *
 * To run a mutation, you first call `useReport_Mtga_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Mtga_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMtgaGameMutation, { data, loading, error }] = useReport_Mtga_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Mtga_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables>(Report_Mtga_GameDocument, baseOptions);
      }
export type Report_Mtga_GameMutationHookResult = ReturnType<typeof useReport_Mtga_GameMutation>;
export type Report_Mtga_GameMutationResult = ApolloReactCommon.MutationResult<Report_Mtga_GameMutation>;
export type Report_Mtga_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Mtga_GameMutation, Report_Mtga_GameMutationVariables>;
export const NbaTeamsDocument = gql`
    query NbaTeams {
  sportPlayerCharacteristics(nbaTeams: true) {
    nbaTeams {
      options
    }
  }
}
    `;
export type NbaTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NbaTeamsQuery, NbaTeamsQueryVariables>, 'query'>;

    export const NbaTeamsComponent = (props: NbaTeamsComponentProps) => (
      <ApolloReactComponents.Query<NbaTeamsQuery, NbaTeamsQueryVariables> query={NbaTeamsDocument} {...props} />
    );
    
export type NbaTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<NbaTeamsQuery, NbaTeamsQueryVariables> & TChildProps;
export function withNbaTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NbaTeamsQuery,
  NbaTeamsQueryVariables,
  NbaTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NbaTeamsQuery, NbaTeamsQueryVariables, NbaTeamsProps<TChildProps>>(NbaTeamsDocument, {
      alias: 'nbaTeams',
      ...operationOptions
    });
};

/**
 * __useNbaTeamsQuery__
 *
 * To run a query within a React component, call `useNbaTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNbaTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNbaTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNbaTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NbaTeamsQuery, NbaTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<NbaTeamsQuery, NbaTeamsQueryVariables>(NbaTeamsDocument, baseOptions);
      }
export function useNbaTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NbaTeamsQuery, NbaTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NbaTeamsQuery, NbaTeamsQueryVariables>(NbaTeamsDocument, baseOptions);
        }
export type NbaTeamsQueryHookResult = ReturnType<typeof useNbaTeamsQuery>;
export type NbaTeamsLazyQueryHookResult = ReturnType<typeof useNbaTeamsLazyQuery>;
export type NbaTeamsQueryResult = ApolloReactCommon.QueryResult<NbaTeamsQuery, NbaTeamsQueryVariables>;
export const Report_Nba_GameDocument = gql`
    mutation report_nba_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportNbaGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Nba_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Nba_GameMutation, Report_Nba_GameMutationVariables>;
export type Report_Nba_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Nba_GameMutation, Report_Nba_GameMutationVariables>, 'mutation'>;

    export const Report_Nba_GameComponent = (props: Report_Nba_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Nba_GameMutation, Report_Nba_GameMutationVariables> mutation={Report_Nba_GameDocument} {...props} />
    );
    
export type Report_Nba_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Nba_GameMutation, Report_Nba_GameMutationVariables> & TChildProps;
export function withReport_Nba_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Nba_GameMutation,
  Report_Nba_GameMutationVariables,
  Report_Nba_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Nba_GameMutation, Report_Nba_GameMutationVariables, Report_Nba_GameProps<TChildProps>>(Report_Nba_GameDocument, {
      alias: 'reportNbaGame',
      ...operationOptions
    });
};

/**
 * __useReport_Nba_GameMutation__
 *
 * To run a mutation, you first call `useReport_Nba_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Nba_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportNbaGameMutation, { data, loading, error }] = useReport_Nba_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Nba_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Nba_GameMutation, Report_Nba_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Nba_GameMutation, Report_Nba_GameMutationVariables>(Report_Nba_GameDocument, baseOptions);
      }
export type Report_Nba_GameMutationHookResult = ReturnType<typeof useReport_Nba_GameMutation>;
export type Report_Nba_GameMutationResult = ApolloReactCommon.MutationResult<Report_Nba_GameMutation>;
export type Report_Nba_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Nba_GameMutation, Report_Nba_GameMutationVariables>;
export const NhlTeamsDocument = gql`
    query NhlTeams {
  sportPlayerCharacteristics(nhlTeams: true) {
    nhlTeams {
      options
    }
  }
}
    `;
export type NhlTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NhlTeamsQuery, NhlTeamsQueryVariables>, 'query'>;

    export const NhlTeamsComponent = (props: NhlTeamsComponentProps) => (
      <ApolloReactComponents.Query<NhlTeamsQuery, NhlTeamsQueryVariables> query={NhlTeamsDocument} {...props} />
    );
    
export type NhlTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<NhlTeamsQuery, NhlTeamsQueryVariables> & TChildProps;
export function withNhlTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NhlTeamsQuery,
  NhlTeamsQueryVariables,
  NhlTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NhlTeamsQuery, NhlTeamsQueryVariables, NhlTeamsProps<TChildProps>>(NhlTeamsDocument, {
      alias: 'nhlTeams',
      ...operationOptions
    });
};

/**
 * __useNhlTeamsQuery__
 *
 * To run a query within a React component, call `useNhlTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNhlTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNhlTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNhlTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NhlTeamsQuery, NhlTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<NhlTeamsQuery, NhlTeamsQueryVariables>(NhlTeamsDocument, baseOptions);
      }
export function useNhlTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NhlTeamsQuery, NhlTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NhlTeamsQuery, NhlTeamsQueryVariables>(NhlTeamsDocument, baseOptions);
        }
export type NhlTeamsQueryHookResult = ReturnType<typeof useNhlTeamsQuery>;
export type NhlTeamsLazyQueryHookResult = ReturnType<typeof useNhlTeamsLazyQuery>;
export type NhlTeamsQueryResult = ApolloReactCommon.QueryResult<NhlTeamsQuery, NhlTeamsQueryVariables>;
export const Report_Nhl_GameDocument = gql`
    mutation report_nhl_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportNhlGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Nhl_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables>;
export type Report_Nhl_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables>, 'mutation'>;

    export const Report_Nhl_GameComponent = (props: Report_Nhl_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables> mutation={Report_Nhl_GameDocument} {...props} />
    );
    
export type Report_Nhl_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables> & TChildProps;
export function withReport_Nhl_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Nhl_GameMutation,
  Report_Nhl_GameMutationVariables,
  Report_Nhl_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables, Report_Nhl_GameProps<TChildProps>>(Report_Nhl_GameDocument, {
      alias: 'reportNhlGame',
      ...operationOptions
    });
};

/**
 * __useReport_Nhl_GameMutation__
 *
 * To run a mutation, you first call `useReport_Nhl_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Nhl_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportNhlGameMutation, { data, loading, error }] = useReport_Nhl_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Nhl_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables>(Report_Nhl_GameDocument, baseOptions);
      }
export type Report_Nhl_GameMutationHookResult = ReturnType<typeof useReport_Nhl_GameMutation>;
export type Report_Nhl_GameMutationResult = ApolloReactCommon.MutationResult<Report_Nhl_GameMutation>;
export type Report_Nhl_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Nhl_GameMutation, Report_Nhl_GameMutationVariables>;
export const OverwatchHeroesDocument = gql`
    query OverwatchHeroes {
  sportPlayerCharacteristics(owHeroes: true) {
    owHeroes {
      options
    }
  }
}
    `;
export type OverwatchHeroesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OverwatchHeroesQuery, OverwatchHeroesQueryVariables>, 'query'>;

    export const OverwatchHeroesComponent = (props: OverwatchHeroesComponentProps) => (
      <ApolloReactComponents.Query<OverwatchHeroesQuery, OverwatchHeroesQueryVariables> query={OverwatchHeroesDocument} {...props} />
    );
    
export type OverwatchHeroesProps<TChildProps = {}> = ApolloReactHoc.DataProps<OverwatchHeroesQuery, OverwatchHeroesQueryVariables> & TChildProps;
export function withOverwatchHeroes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OverwatchHeroesQuery,
  OverwatchHeroesQueryVariables,
  OverwatchHeroesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, OverwatchHeroesQuery, OverwatchHeroesQueryVariables, OverwatchHeroesProps<TChildProps>>(OverwatchHeroesDocument, {
      alias: 'overwatchHeroes',
      ...operationOptions
    });
};

/**
 * __useOverwatchHeroesQuery__
 *
 * To run a query within a React component, call `useOverwatchHeroesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverwatchHeroesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverwatchHeroesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOverwatchHeroesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OverwatchHeroesQuery, OverwatchHeroesQueryVariables>) {
        return ApolloReactHooks.useQuery<OverwatchHeroesQuery, OverwatchHeroesQueryVariables>(OverwatchHeroesDocument, baseOptions);
      }
export function useOverwatchHeroesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OverwatchHeroesQuery, OverwatchHeroesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OverwatchHeroesQuery, OverwatchHeroesQueryVariables>(OverwatchHeroesDocument, baseOptions);
        }
export type OverwatchHeroesQueryHookResult = ReturnType<typeof useOverwatchHeroesQuery>;
export type OverwatchHeroesLazyQueryHookResult = ReturnType<typeof useOverwatchHeroesLazyQuery>;
export type OverwatchHeroesQueryResult = ApolloReactCommon.QueryResult<OverwatchHeroesQuery, OverwatchHeroesQueryVariables>;
export const ReportOwGameDocument = gql`
    mutation ReportOwGame($gameId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $winningTeamId: ID!, $mapId: ID!, $evidenceScreenshot: String!) {
  reportOwGame(gameId: $gameId, homeTeam: $homeTeam, awayTeam: $awayTeam, winningTeamId: $winningTeamId, mapId: $mapId, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type ReportOwGameMutationFn = ApolloReactCommon.MutationFunction<ReportOwGameMutation, ReportOwGameMutationVariables>;
export type ReportOwGameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportOwGameMutation, ReportOwGameMutationVariables>, 'mutation'>;

    export const ReportOwGameComponent = (props: ReportOwGameComponentProps) => (
      <ApolloReactComponents.Mutation<ReportOwGameMutation, ReportOwGameMutationVariables> mutation={ReportOwGameDocument} {...props} />
    );
    
export type ReportOwGameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReportOwGameMutation, ReportOwGameMutationVariables> & TChildProps;
export function withReportOwGame<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportOwGameMutation,
  ReportOwGameMutationVariables,
  ReportOwGameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReportOwGameMutation, ReportOwGameMutationVariables, ReportOwGameProps<TChildProps>>(ReportOwGameDocument, {
      alias: 'reportOwGame',
      ...operationOptions
    });
};

/**
 * __useReportOwGameMutation__
 *
 * To run a mutation, you first call `useReportOwGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportOwGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportOwGameMutation, { data, loading, error }] = useReportOwGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      winningTeamId: // value for 'winningTeamId'
 *      mapId: // value for 'mapId'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReportOwGameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportOwGameMutation, ReportOwGameMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportOwGameMutation, ReportOwGameMutationVariables>(ReportOwGameDocument, baseOptions);
      }
export type ReportOwGameMutationHookResult = ReturnType<typeof useReportOwGameMutation>;
export type ReportOwGameMutationResult = ApolloReactCommon.MutationResult<ReportOwGameMutation>;
export type ReportOwGameMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportOwGameMutation, ReportOwGameMutationVariables>;
export const Report_Rl_GameDocument = gql`
    mutation report_rl_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportRlGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Rl_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Rl_GameMutation, Report_Rl_GameMutationVariables>;
export type Report_Rl_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Rl_GameMutation, Report_Rl_GameMutationVariables>, 'mutation'>;

    export const Report_Rl_GameComponent = (props: Report_Rl_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Rl_GameMutation, Report_Rl_GameMutationVariables> mutation={Report_Rl_GameDocument} {...props} />
    );
    
export type Report_Rl_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Rl_GameMutation, Report_Rl_GameMutationVariables> & TChildProps;
export function withReport_Rl_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Rl_GameMutation,
  Report_Rl_GameMutationVariables,
  Report_Rl_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Rl_GameMutation, Report_Rl_GameMutationVariables, Report_Rl_GameProps<TChildProps>>(Report_Rl_GameDocument, {
      alias: 'reportRlGame',
      ...operationOptions
    });
};

/**
 * __useReport_Rl_GameMutation__
 *
 * To run a mutation, you first call `useReport_Rl_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Rl_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportRlGameMutation, { data, loading, error }] = useReport_Rl_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Rl_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Rl_GameMutation, Report_Rl_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Rl_GameMutation, Report_Rl_GameMutationVariables>(Report_Rl_GameDocument, baseOptions);
      }
export type Report_Rl_GameMutationHookResult = ReturnType<typeof useReport_Rl_GameMutation>;
export type Report_Rl_GameMutationResult = ApolloReactCommon.MutationResult<Report_Rl_GameMutation>;
export type Report_Rl_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Rl_GameMutation, Report_Rl_GameMutationVariables>;
export const SfvFightersDocument = gql`
    query SfvFighters {
  sportPlayerCharacteristics(sfvFighters: true) {
    sfvFighters {
      options
    }
  }
}
    `;
export type SfvFightersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SfvFightersQuery, SfvFightersQueryVariables>, 'query'>;

    export const SfvFightersComponent = (props: SfvFightersComponentProps) => (
      <ApolloReactComponents.Query<SfvFightersQuery, SfvFightersQueryVariables> query={SfvFightersDocument} {...props} />
    );
    
export type SfvFightersProps<TChildProps = {}> = ApolloReactHoc.DataProps<SfvFightersQuery, SfvFightersQueryVariables> & TChildProps;
export function withSfvFighters<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SfvFightersQuery,
  SfvFightersQueryVariables,
  SfvFightersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SfvFightersQuery, SfvFightersQueryVariables, SfvFightersProps<TChildProps>>(SfvFightersDocument, {
      alias: 'sfvFighters',
      ...operationOptions
    });
};

/**
 * __useSfvFightersQuery__
 *
 * To run a query within a React component, call `useSfvFightersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSfvFightersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSfvFightersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSfvFightersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SfvFightersQuery, SfvFightersQueryVariables>) {
        return ApolloReactHooks.useQuery<SfvFightersQuery, SfvFightersQueryVariables>(SfvFightersDocument, baseOptions);
      }
export function useSfvFightersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SfvFightersQuery, SfvFightersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SfvFightersQuery, SfvFightersQueryVariables>(SfvFightersDocument, baseOptions);
        }
export type SfvFightersQueryHookResult = ReturnType<typeof useSfvFightersQuery>;
export type SfvFightersLazyQueryHookResult = ReturnType<typeof useSfvFightersLazyQuery>;
export type SfvFightersQueryResult = ApolloReactCommon.QueryResult<SfvFightersQuery, SfvFightersQueryVariables>;
export const Report_Sfv_GameDocument = gql`
    mutation report_sfv_game($gameId: ID!, $winningTeamId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $evidenceScreenshot: String!) {
  reportSfvGame(gameId: $gameId, winningTeamId: $winningTeamId, homeTeam: $homeTeam, awayTeam: $awayTeam, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Sfv_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables>;
export type Report_Sfv_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables>, 'mutation'>;

    export const Report_Sfv_GameComponent = (props: Report_Sfv_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables> mutation={Report_Sfv_GameDocument} {...props} />
    );
    
export type Report_Sfv_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables> & TChildProps;
export function withReport_Sfv_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Sfv_GameMutation,
  Report_Sfv_GameMutationVariables,
  Report_Sfv_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables, Report_Sfv_GameProps<TChildProps>>(Report_Sfv_GameDocument, {
      alias: 'reportSfvGame',
      ...operationOptions
    });
};

/**
 * __useReport_Sfv_GameMutation__
 *
 * To run a mutation, you first call `useReport_Sfv_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Sfv_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportSfvGameMutation, { data, loading, error }] = useReport_Sfv_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      winningTeamId: // value for 'winningTeamId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Sfv_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables>(Report_Sfv_GameDocument, baseOptions);
      }
export type Report_Sfv_GameMutationHookResult = ReturnType<typeof useReport_Sfv_GameMutation>;
export type Report_Sfv_GameMutationResult = ApolloReactCommon.MutationResult<Report_Sfv_GameMutation>;
export type Report_Sfv_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Sfv_GameMutation, Report_Sfv_GameMutationVariables>;
export const SmashCharactersDocument = gql`
    query SmashCharacters {
  sportPlayerCharacteristics(smashCharacters: true) {
    smashCharacters {
      options
    }
  }
}
    `;
export type SmashCharactersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SmashCharactersQuery, SmashCharactersQueryVariables>, 'query'>;

    export const SmashCharactersComponent = (props: SmashCharactersComponentProps) => (
      <ApolloReactComponents.Query<SmashCharactersQuery, SmashCharactersQueryVariables> query={SmashCharactersDocument} {...props} />
    );
    
export type SmashCharactersProps<TChildProps = {}> = ApolloReactHoc.DataProps<SmashCharactersQuery, SmashCharactersQueryVariables> & TChildProps;
export function withSmashCharacters<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SmashCharactersQuery,
  SmashCharactersQueryVariables,
  SmashCharactersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SmashCharactersQuery, SmashCharactersQueryVariables, SmashCharactersProps<TChildProps>>(SmashCharactersDocument, {
      alias: 'smashCharacters',
      ...operationOptions
    });
};

/**
 * __useSmashCharactersQuery__
 *
 * To run a query within a React component, call `useSmashCharactersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmashCharactersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmashCharactersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSmashCharactersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SmashCharactersQuery, SmashCharactersQueryVariables>) {
        return ApolloReactHooks.useQuery<SmashCharactersQuery, SmashCharactersQueryVariables>(SmashCharactersDocument, baseOptions);
      }
export function useSmashCharactersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SmashCharactersQuery, SmashCharactersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SmashCharactersQuery, SmashCharactersQueryVariables>(SmashCharactersDocument, baseOptions);
        }
export type SmashCharactersQueryHookResult = ReturnType<typeof useSmashCharactersQuery>;
export type SmashCharactersLazyQueryHookResult = ReturnType<typeof useSmashCharactersLazyQuery>;
export type SmashCharactersQueryResult = ApolloReactCommon.QueryResult<SmashCharactersQuery, SmashCharactersQueryVariables>;
export const ReportSmashGameDocument = gql`
    mutation ReportSmashGame($gameId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $winningTeamId: ID!, $mapId: ID!, $evidenceScreenshot: String!) {
  reportSmashGame(gameId: $gameId, homeTeam: $homeTeam, awayTeam: $awayTeam, winningTeamId: $winningTeamId, mapId: $mapId, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type ReportSmashGameMutationFn = ApolloReactCommon.MutationFunction<ReportSmashGameMutation, ReportSmashGameMutationVariables>;
export type ReportSmashGameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportSmashGameMutation, ReportSmashGameMutationVariables>, 'mutation'>;

    export const ReportSmashGameComponent = (props: ReportSmashGameComponentProps) => (
      <ApolloReactComponents.Mutation<ReportSmashGameMutation, ReportSmashGameMutationVariables> mutation={ReportSmashGameDocument} {...props} />
    );
    
export type ReportSmashGameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReportSmashGameMutation, ReportSmashGameMutationVariables> & TChildProps;
export function withReportSmashGame<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportSmashGameMutation,
  ReportSmashGameMutationVariables,
  ReportSmashGameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReportSmashGameMutation, ReportSmashGameMutationVariables, ReportSmashGameProps<TChildProps>>(ReportSmashGameDocument, {
      alias: 'reportSmashGame',
      ...operationOptions
    });
};

/**
 * __useReportSmashGameMutation__
 *
 * To run a mutation, you first call `useReportSmashGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportSmashGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportSmashGameMutation, { data, loading, error }] = useReportSmashGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      winningTeamId: // value for 'winningTeamId'
 *      mapId: // value for 'mapId'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReportSmashGameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportSmashGameMutation, ReportSmashGameMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportSmashGameMutation, ReportSmashGameMutationVariables>(ReportSmashGameDocument, baseOptions);
      }
export type ReportSmashGameMutationHookResult = ReturnType<typeof useReportSmashGameMutation>;
export type ReportSmashGameMutationResult = ApolloReactCommon.MutationResult<ReportSmashGameMutation>;
export type ReportSmashGameMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportSmashGameMutation, ReportSmashGameMutationVariables>;
export const ValorantAgentsDocument = gql`
    query ValorantAgents {
  sportPlayerCharacteristics(valorantAgents: true) {
    valorantAgents {
      options
    }
  }
}
    `;
export type ValorantAgentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ValorantAgentsQuery, ValorantAgentsQueryVariables>, 'query'>;

    export const ValorantAgentsComponent = (props: ValorantAgentsComponentProps) => (
      <ApolloReactComponents.Query<ValorantAgentsQuery, ValorantAgentsQueryVariables> query={ValorantAgentsDocument} {...props} />
    );
    
export type ValorantAgentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ValorantAgentsQuery, ValorantAgentsQueryVariables> & TChildProps;
export function withValorantAgents<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ValorantAgentsQuery,
  ValorantAgentsQueryVariables,
  ValorantAgentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ValorantAgentsQuery, ValorantAgentsQueryVariables, ValorantAgentsProps<TChildProps>>(ValorantAgentsDocument, {
      alias: 'valorantAgents',
      ...operationOptions
    });
};

/**
 * __useValorantAgentsQuery__
 *
 * To run a query within a React component, call `useValorantAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValorantAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValorantAgentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useValorantAgentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValorantAgentsQuery, ValorantAgentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ValorantAgentsQuery, ValorantAgentsQueryVariables>(ValorantAgentsDocument, baseOptions);
      }
export function useValorantAgentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValorantAgentsQuery, ValorantAgentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValorantAgentsQuery, ValorantAgentsQueryVariables>(ValorantAgentsDocument, baseOptions);
        }
export type ValorantAgentsQueryHookResult = ReturnType<typeof useValorantAgentsQuery>;
export type ValorantAgentsLazyQueryHookResult = ReturnType<typeof useValorantAgentsLazyQuery>;
export type ValorantAgentsQueryResult = ApolloReactCommon.QueryResult<ValorantAgentsQuery, ValorantAgentsQueryVariables>;
export const Report_Valorant_GameDocument = gql`
    mutation report_valorant_game($gameId: ID!, $homeTeam: UserReportedGame!, $awayTeam: UserReportedGame!, $mapId: ID!, $evidenceScreenshot: String!) {
  reportValorantGame(gameId: $gameId, homeTeam: $homeTeam, awayTeam: $awayTeam, mapId: $mapId, evidenceScreenshot: $evidenceScreenshot) {
    match {
      id
      settled
    }
  }
}
    `;
export type Report_Valorant_GameMutationFn = ApolloReactCommon.MutationFunction<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables>;
export type Report_Valorant_GameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables>, 'mutation'>;

    export const Report_Valorant_GameComponent = (props: Report_Valorant_GameComponentProps) => (
      <ApolloReactComponents.Mutation<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables> mutation={Report_Valorant_GameDocument} {...props} />
    );
    
export type Report_Valorant_GameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables> & TChildProps;
export function withReport_Valorant_Game<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Report_Valorant_GameMutation,
  Report_Valorant_GameMutationVariables,
  Report_Valorant_GameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables, Report_Valorant_GameProps<TChildProps>>(Report_Valorant_GameDocument, {
      alias: 'reportValorantGame',
      ...operationOptions
    });
};

/**
 * __useReport_Valorant_GameMutation__
 *
 * To run a mutation, you first call `useReport_Valorant_GameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReport_Valorant_GameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportValorantGameMutation, { data, loading, error }] = useReport_Valorant_GameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      homeTeam: // value for 'homeTeam'
 *      awayTeam: // value for 'awayTeam'
 *      mapId: // value for 'mapId'
 *      evidenceScreenshot: // value for 'evidenceScreenshot'
 *   },
 * });
 */
export function useReport_Valorant_GameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables>) {
        return ApolloReactHooks.useMutation<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables>(Report_Valorant_GameDocument, baseOptions);
      }
export type Report_Valorant_GameMutationHookResult = ReturnType<typeof useReport_Valorant_GameMutation>;
export type Report_Valorant_GameMutationResult = ApolloReactCommon.MutationResult<Report_Valorant_GameMutation>;
export type Report_Valorant_GameMutationOptions = ApolloReactCommon.BaseMutationOptions<Report_Valorant_GameMutation, Report_Valorant_GameMutationVariables>;
export const GetTopTeamsDocument = gql`
    query getTopTeams {
  topTeams {
    rank
    team {
      id
      name
      selectedSeasonStats {
        losses
        wins
        id
      }
    }
  }
}
    `;
export type GetTopTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTopTeamsQuery, GetTopTeamsQueryVariables>, 'query'>;

    export const GetTopTeamsComponent = (props: GetTopTeamsComponentProps) => (
      <ApolloReactComponents.Query<GetTopTeamsQuery, GetTopTeamsQueryVariables> query={GetTopTeamsDocument} {...props} />
    );
    
export type GetTopTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTopTeamsQuery, GetTopTeamsQueryVariables> & TChildProps;
export function withGetTopTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTopTeamsQuery,
  GetTopTeamsQueryVariables,
  GetTopTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTopTeamsQuery, GetTopTeamsQueryVariables, GetTopTeamsProps<TChildProps>>(GetTopTeamsDocument, {
      alias: 'getTopTeams',
      ...operationOptions
    });
};

/**
 * __useGetTopTeamsQuery__
 *
 * To run a query within a React component, call `useGetTopTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTopTeamsQuery, GetTopTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTopTeamsQuery, GetTopTeamsQueryVariables>(GetTopTeamsDocument, baseOptions);
      }
export function useGetTopTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTopTeamsQuery, GetTopTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTopTeamsQuery, GetTopTeamsQueryVariables>(GetTopTeamsDocument, baseOptions);
        }
export type GetTopTeamsQueryHookResult = ReturnType<typeof useGetTopTeamsQuery>;
export type GetTopTeamsLazyQueryHookResult = ReturnType<typeof useGetTopTeamsLazyQuery>;
export type GetTopTeamsQueryResult = ApolloReactCommon.QueryResult<GetTopTeamsQuery, GetTopTeamsQueryVariables>;
export const InviteUserToTeamDocument = gql`
    mutation inviteUserToTeam($email: String!, $teamId: ID!, $gameRole: String) {
  inviteUserToTeam(email: $email, teamId: $teamId, gameRole: $gameRole) {
    errors {
      field
      message
    }
  }
}
    `;
export type InviteUserToTeamMutationFn = ApolloReactCommon.MutationFunction<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>;
export type InviteUserToTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>, 'mutation'>;

    export const InviteUserToTeamComponent = (props: InviteUserToTeamComponentProps) => (
      <ApolloReactComponents.Mutation<InviteUserToTeamMutation, InviteUserToTeamMutationVariables> mutation={InviteUserToTeamDocument} {...props} />
    );
    
export type InviteUserToTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InviteUserToTeamMutation, InviteUserToTeamMutationVariables> & TChildProps;
export function withInviteUserToTeam<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteUserToTeamMutation,
  InviteUserToTeamMutationVariables,
  InviteUserToTeamProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InviteUserToTeamMutation, InviteUserToTeamMutationVariables, InviteUserToTeamProps<TChildProps>>(InviteUserToTeamDocument, {
      alias: 'inviteUserToTeam',
      ...operationOptions
    });
};

/**
 * __useInviteUserToTeamMutation__
 *
 * To run a mutation, you first call `useInviteUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToTeamMutation, { data, loading, error }] = useInviteUserToTeamMutation({
 *   variables: {
 *      email: // value for 'email'
 *      teamId: // value for 'teamId'
 *      gameRole: // value for 'gameRole'
 *   },
 * });
 */
export function useInviteUserToTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>(InviteUserToTeamDocument, baseOptions);
      }
export type InviteUserToTeamMutationHookResult = ReturnType<typeof useInviteUserToTeamMutation>;
export type InviteUserToTeamMutationResult = ApolloReactCommon.MutationResult<InviteUserToTeamMutation>;
export type InviteUserToTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>;
export const InviteUserToPlatformDocument = gql`
    mutation inviteUserToPlatform($email: String!) {
  inviteUserToPlatform(email: $email) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type InviteUserToPlatformMutationFn = ApolloReactCommon.MutationFunction<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables>;
export type InviteUserToPlatformComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables>, 'mutation'>;

    export const InviteUserToPlatformComponent = (props: InviteUserToPlatformComponentProps) => (
      <ApolloReactComponents.Mutation<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables> mutation={InviteUserToPlatformDocument} {...props} />
    );
    
export type InviteUserToPlatformProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables> & TChildProps;
export function withInviteUserToPlatform<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteUserToPlatformMutation,
  InviteUserToPlatformMutationVariables,
  InviteUserToPlatformProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables, InviteUserToPlatformProps<TChildProps>>(InviteUserToPlatformDocument, {
      alias: 'inviteUserToPlatform',
      ...operationOptions
    });
};

/**
 * __useInviteUserToPlatformMutation__
 *
 * To run a mutation, you first call `useInviteUserToPlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToPlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToPlatformMutation, { data, loading, error }] = useInviteUserToPlatformMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteUserToPlatformMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables>(InviteUserToPlatformDocument, baseOptions);
      }
export type InviteUserToPlatformMutationHookResult = ReturnType<typeof useInviteUserToPlatformMutation>;
export type InviteUserToPlatformMutationResult = ApolloReactCommon.MutationResult<InviteUserToPlatformMutation>;
export type InviteUserToPlatformMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserToPlatformMutation, InviteUserToPlatformMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) {
    success
    value {
      ...AuthenticationAttributes
    }
    errors {
      field
      message
    }
  }
}
    ${AuthenticationAttributesFragmentDoc}`;
export type SignInMutationFn = ApolloReactCommon.MutationFunction<SignInMutation, SignInMutationVariables>;
export type SignInComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignInMutation, SignInMutationVariables>, 'mutation'>;

    export const SignInComponent = (props: SignInComponentProps) => (
      <ApolloReactComponents.Mutation<SignInMutation, SignInMutationVariables> mutation={SignInDocument} {...props} />
    );
    
export type SignInProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SignInMutation, SignInMutationVariables> & TChildProps;
export function withSignIn<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignInMutation,
  SignInMutationVariables,
  SignInProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SignInMutation, SignInMutationVariables, SignInProps<TChildProps>>(SignInDocument, {
      alias: 'signIn',
      ...operationOptions
    });
};

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        return ApolloReactHooks.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, baseOptions);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = ApolloReactCommon.MutationResult<SignInMutation>;
export type SignInMutationOptions = ApolloReactCommon.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($email: String!, $password: String!, $username: String!, $invitationToken: String) {
  signUp(email: $email, password: $password, username: $username, invitationToken: $invitationToken) {
    success
    value {
      ...AuthenticationAttributes
    }
    errors {
      field
      message
    }
  }
}
    ${AuthenticationAttributesFragmentDoc}`;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;
export type SignUpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignUpMutation, SignUpMutationVariables>, 'mutation'>;

    export const SignUpComponent = (props: SignUpComponentProps) => (
      <ApolloReactComponents.Mutation<SignUpMutation, SignUpMutationVariables> mutation={SignUpDocument} {...props} />
    );
    
export type SignUpProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SignUpMutation, SignUpMutationVariables> & TChildProps;
export function withSignUp<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignUpMutation,
  SignUpMutationVariables,
  SignUpProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SignUpMutation, SignUpMutationVariables, SignUpProps<TChildProps>>(SignUpDocument, {
      alias: 'signUp',
      ...operationOptions
    });
};

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      username: // value for 'username'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
  signOut {
    success
  }
}
    `;
export type SignOutMutationFn = ApolloReactCommon.MutationFunction<SignOutMutation, SignOutMutationVariables>;
export type SignOutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignOutMutation, SignOutMutationVariables>, 'mutation'>;

    export const SignOutComponent = (props: SignOutComponentProps) => (
      <ApolloReactComponents.Mutation<SignOutMutation, SignOutMutationVariables> mutation={SignOutDocument} {...props} />
    );
    
export type SignOutProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SignOutMutation, SignOutMutationVariables> & TChildProps;
export function withSignOut<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignOutMutation,
  SignOutMutationVariables,
  SignOutProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SignOutMutation, SignOutMutationVariables, SignOutProps<TChildProps>>(SignOutDocument, {
      alias: 'signOut',
      ...operationOptions
    });
};

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        return ApolloReactHooks.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, baseOptions);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = ApolloReactCommon.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = ApolloReactCommon.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const CheckinTeamForMatchDocument = gql`
    mutation CheckinTeamForMatch($teamId: ID!, $matchId: ID!) {
  createNewMatchCheckin(teamId: $teamId, matchId: $matchId) {
    success
    value
    errors {
      field
      message
    }
  }
}
    `;
export type CheckinTeamForMatchMutationFn = ApolloReactCommon.MutationFunction<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables>;
export type CheckinTeamForMatchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables>, 'mutation'>;

    export const CheckinTeamForMatchComponent = (props: CheckinTeamForMatchComponentProps) => (
      <ApolloReactComponents.Mutation<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables> mutation={CheckinTeamForMatchDocument} {...props} />
    );
    
export type CheckinTeamForMatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables> & TChildProps;
export function withCheckinTeamForMatch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckinTeamForMatchMutation,
  CheckinTeamForMatchMutationVariables,
  CheckinTeamForMatchProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables, CheckinTeamForMatchProps<TChildProps>>(CheckinTeamForMatchDocument, {
      alias: 'checkinTeamForMatch',
      ...operationOptions
    });
};

/**
 * __useCheckinTeamForMatchMutation__
 *
 * To run a mutation, you first call `useCheckinTeamForMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckinTeamForMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkinTeamForMatchMutation, { data, loading, error }] = useCheckinTeamForMatchMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useCheckinTeamForMatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables>(CheckinTeamForMatchDocument, baseOptions);
      }
export type CheckinTeamForMatchMutationHookResult = ReturnType<typeof useCheckinTeamForMatchMutation>;
export type CheckinTeamForMatchMutationResult = ApolloReactCommon.MutationResult<CheckinTeamForMatchMutation>;
export type CheckinTeamForMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckinTeamForMatchMutation, CheckinTeamForMatchMutationVariables>;
export const UncheckinTeamFromMatchDocument = gql`
    mutation UncheckinTeamFromMatch($teamId: ID!, $matchId: ID!) {
  deleteMatchCheckin(teamId: $teamId, matchId: $matchId) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UncheckinTeamFromMatchMutationFn = ApolloReactCommon.MutationFunction<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables>;
export type UncheckinTeamFromMatchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables>, 'mutation'>;

    export const UncheckinTeamFromMatchComponent = (props: UncheckinTeamFromMatchComponentProps) => (
      <ApolloReactComponents.Mutation<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables> mutation={UncheckinTeamFromMatchDocument} {...props} />
    );
    
export type UncheckinTeamFromMatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables> & TChildProps;
export function withUncheckinTeamFromMatch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UncheckinTeamFromMatchMutation,
  UncheckinTeamFromMatchMutationVariables,
  UncheckinTeamFromMatchProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables, UncheckinTeamFromMatchProps<TChildProps>>(UncheckinTeamFromMatchDocument, {
      alias: 'uncheckinTeamFromMatch',
      ...operationOptions
    });
};

/**
 * __useUncheckinTeamFromMatchMutation__
 *
 * To run a mutation, you first call `useUncheckinTeamFromMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncheckinTeamFromMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncheckinTeamFromMatchMutation, { data, loading, error }] = useUncheckinTeamFromMatchMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useUncheckinTeamFromMatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables>) {
        return ApolloReactHooks.useMutation<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables>(UncheckinTeamFromMatchDocument, baseOptions);
      }
export type UncheckinTeamFromMatchMutationHookResult = ReturnType<typeof useUncheckinTeamFromMatchMutation>;
export type UncheckinTeamFromMatchMutationResult = ApolloReactCommon.MutationResult<UncheckinTeamFromMatchMutation>;
export type UncheckinTeamFromMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<UncheckinTeamFromMatchMutation, UncheckinTeamFromMatchMutationVariables>;
export const AutoReportLolGameDocument = gql`
    mutation AutoReportLolGame($id: ID!) {
  autoReportLolGame(id: $id) {
    match {
      id
      settled
    }
  }
}
    `;
export type AutoReportLolGameMutationFn = ApolloReactCommon.MutationFunction<AutoReportLolGameMutation, AutoReportLolGameMutationVariables>;
export type AutoReportLolGameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AutoReportLolGameMutation, AutoReportLolGameMutationVariables>, 'mutation'>;

    export const AutoReportLolGameComponent = (props: AutoReportLolGameComponentProps) => (
      <ApolloReactComponents.Mutation<AutoReportLolGameMutation, AutoReportLolGameMutationVariables> mutation={AutoReportLolGameDocument} {...props} />
    );
    
export type AutoReportLolGameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AutoReportLolGameMutation, AutoReportLolGameMutationVariables> & TChildProps;
export function withAutoReportLolGame<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AutoReportLolGameMutation,
  AutoReportLolGameMutationVariables,
  AutoReportLolGameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AutoReportLolGameMutation, AutoReportLolGameMutationVariables, AutoReportLolGameProps<TChildProps>>(AutoReportLolGameDocument, {
      alias: 'autoReportLolGame',
      ...operationOptions
    });
};

/**
 * __useAutoReportLolGameMutation__
 *
 * To run a mutation, you first call `useAutoReportLolGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoReportLolGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoReportLolGameMutation, { data, loading, error }] = useAutoReportLolGameMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAutoReportLolGameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AutoReportLolGameMutation, AutoReportLolGameMutationVariables>) {
        return ApolloReactHooks.useMutation<AutoReportLolGameMutation, AutoReportLolGameMutationVariables>(AutoReportLolGameDocument, baseOptions);
      }
export type AutoReportLolGameMutationHookResult = ReturnType<typeof useAutoReportLolGameMutation>;
export type AutoReportLolGameMutationResult = ApolloReactCommon.MutationResult<AutoReportLolGameMutation>;
export type AutoReportLolGameMutationOptions = ApolloReactCommon.BaseMutationOptions<AutoReportLolGameMutation, AutoReportLolGameMutationVariables>;
export const UpdateMatchMessagesDocument = gql`
    query UpdateMatchMessages($matchId: ID!, $since: Int) {
  match(id: $matchId) {
    id
    matchMessages(since: $since) {
      id
      hidden
      user {
        displayName
        isAdmin
        id
      }
      body
      createdAt
    }
  }
}
    `;
export type UpdateMatchMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables>, 'query'> & ({ variables: UpdateMatchMessagesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateMatchMessagesComponent = (props: UpdateMatchMessagesComponentProps) => (
      <ApolloReactComponents.Query<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables> query={UpdateMatchMessagesDocument} {...props} />
    );
    
export type UpdateMatchMessagesProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables> & TChildProps;
export function withUpdateMatchMessages<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateMatchMessagesQuery,
  UpdateMatchMessagesQueryVariables,
  UpdateMatchMessagesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables, UpdateMatchMessagesProps<TChildProps>>(UpdateMatchMessagesDocument, {
      alias: 'updateMatchMessages',
      ...operationOptions
    });
};

/**
 * __useUpdateMatchMessagesQuery__
 *
 * To run a query within a React component, call `useUpdateMatchMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateMatchMessagesQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useUpdateMatchMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables>(UpdateMatchMessagesDocument, baseOptions);
      }
export function useUpdateMatchMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables>(UpdateMatchMessagesDocument, baseOptions);
        }
export type UpdateMatchMessagesQueryHookResult = ReturnType<typeof useUpdateMatchMessagesQuery>;
export type UpdateMatchMessagesLazyQueryHookResult = ReturnType<typeof useUpdateMatchMessagesLazyQuery>;
export type UpdateMatchMessagesQueryResult = ApolloReactCommon.QueryResult<UpdateMatchMessagesQuery, UpdateMatchMessagesQueryVariables>;
export const GetMatchMessageCountDocument = gql`
    query GetMatchMessageCount($matchId: ID!) {
  match(id: $matchId) {
    id
    messagesCount
    hiddenMessagesCount
  }
}
    `;
export type GetMatchMessageCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables>, 'query'> & ({ variables: GetMatchMessageCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMatchMessageCountComponent = (props: GetMatchMessageCountComponentProps) => (
      <ApolloReactComponents.Query<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables> query={GetMatchMessageCountDocument} {...props} />
    );
    
export type GetMatchMessageCountProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables> & TChildProps;
export function withGetMatchMessageCount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMatchMessageCountQuery,
  GetMatchMessageCountQueryVariables,
  GetMatchMessageCountProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables, GetMatchMessageCountProps<TChildProps>>(GetMatchMessageCountDocument, {
      alias: 'getMatchMessageCount',
      ...operationOptions
    });
};

/**
 * __useGetMatchMessageCountQuery__
 *
 * To run a query within a React component, call `useGetMatchMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchMessageCountQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useGetMatchMessageCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables>(GetMatchMessageCountDocument, baseOptions);
      }
export function useGetMatchMessageCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables>(GetMatchMessageCountDocument, baseOptions);
        }
export type GetMatchMessageCountQueryHookResult = ReturnType<typeof useGetMatchMessageCountQuery>;
export type GetMatchMessageCountLazyQueryHookResult = ReturnType<typeof useGetMatchMessageCountLazyQuery>;
export type GetMatchMessageCountQueryResult = ApolloReactCommon.QueryResult<GetMatchMessageCountQuery, GetMatchMessageCountQueryVariables>;
export const AddMatchMessageDocument = gql`
    mutation addMatchMessage($id: ID!, $body: String!) {
  addMatchMessage(id: $id, body: $body) {
    success
    errors {
      message
    }
  }
}
    `;
export type AddMatchMessageMutationFn = ApolloReactCommon.MutationFunction<AddMatchMessageMutation, AddMatchMessageMutationVariables>;
export type AddMatchMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMatchMessageMutation, AddMatchMessageMutationVariables>, 'mutation'>;

    export const AddMatchMessageComponent = (props: AddMatchMessageComponentProps) => (
      <ApolloReactComponents.Mutation<AddMatchMessageMutation, AddMatchMessageMutationVariables> mutation={AddMatchMessageDocument} {...props} />
    );
    
export type AddMatchMessageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddMatchMessageMutation, AddMatchMessageMutationVariables> & TChildProps;
export function withAddMatchMessage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddMatchMessageMutation,
  AddMatchMessageMutationVariables,
  AddMatchMessageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddMatchMessageMutation, AddMatchMessageMutationVariables, AddMatchMessageProps<TChildProps>>(AddMatchMessageDocument, {
      alias: 'addMatchMessage',
      ...operationOptions
    });
};

/**
 * __useAddMatchMessageMutation__
 *
 * To run a mutation, you first call `useAddMatchMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatchMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatchMessageMutation, { data, loading, error }] = useAddMatchMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddMatchMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMatchMessageMutation, AddMatchMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMatchMessageMutation, AddMatchMessageMutationVariables>(AddMatchMessageDocument, baseOptions);
      }
export type AddMatchMessageMutationHookResult = ReturnType<typeof useAddMatchMessageMutation>;
export type AddMatchMessageMutationResult = ApolloReactCommon.MutationResult<AddMatchMessageMutation>;
export type AddMatchMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMatchMessageMutation, AddMatchMessageMutationVariables>;
export const CheckAuthDocument = gql`
    query checkAuth {
  currentUser {
    id
    username
    isAdmin
    isIncompleteAccount
    emailForVerification
  }
}
    `;
export type CheckAuthComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckAuthQuery, CheckAuthQueryVariables>, 'query'>;

    export const CheckAuthComponent = (props: CheckAuthComponentProps) => (
      <ApolloReactComponents.Query<CheckAuthQuery, CheckAuthQueryVariables> query={CheckAuthDocument} {...props} />
    );
    
export type CheckAuthProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckAuthQuery, CheckAuthQueryVariables> & TChildProps;
export function withCheckAuth<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckAuthQuery,
  CheckAuthQueryVariables,
  CheckAuthProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckAuthQuery, CheckAuthQueryVariables, CheckAuthProps<TChildProps>>(CheckAuthDocument, {
      alias: 'checkAuth',
      ...operationOptions
    });
};

/**
 * __useCheckAuthQuery__
 *
 * To run a query within a React component, call `useCheckAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, baseOptions);
      }
export function useCheckAuthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, baseOptions);
        }
export type CheckAuthQueryHookResult = ReturnType<typeof useCheckAuthQuery>;
export type CheckAuthLazyQueryHookResult = ReturnType<typeof useCheckAuthLazyQuery>;
export type CheckAuthQueryResult = ApolloReactCommon.QueryResult<CheckAuthQuery, CheckAuthQueryVariables>;
export const NewsBriefDocument = gql`
    query newsBrief {
  newsBrief {
    ...NewsBrief_NewsArticle
  }
}
    ${NewsBrief_NewsArticleFragmentDoc}`;
export type NewsBriefComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsBriefQuery, NewsBriefQueryVariables>, 'query'>;

    export const NewsBriefComponent = (props: NewsBriefComponentProps) => (
      <ApolloReactComponents.Query<NewsBriefQuery, NewsBriefQueryVariables> query={NewsBriefDocument} {...props} />
    );
    
export type NewsBriefProps<TChildProps = {}> = ApolloReactHoc.DataProps<NewsBriefQuery, NewsBriefQueryVariables> & TChildProps;
export function withNewsBrief<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsBriefQuery,
  NewsBriefQueryVariables,
  NewsBriefProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NewsBriefQuery, NewsBriefQueryVariables, NewsBriefProps<TChildProps>>(NewsBriefDocument, {
      alias: 'newsBrief',
      ...operationOptions
    });
};

/**
 * __useNewsBriefQuery__
 *
 * To run a query within a React component, call `useNewsBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsBriefQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewsBriefQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsBriefQuery, NewsBriefQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsBriefQuery, NewsBriefQueryVariables>(NewsBriefDocument, baseOptions);
      }
export function useNewsBriefLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsBriefQuery, NewsBriefQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsBriefQuery, NewsBriefQueryVariables>(NewsBriefDocument, baseOptions);
        }
export type NewsBriefQueryHookResult = ReturnType<typeof useNewsBriefQuery>;
export type NewsBriefLazyQueryHookResult = ReturnType<typeof useNewsBriefLazyQuery>;
export type NewsBriefQueryResult = ApolloReactCommon.QueryResult<NewsBriefQuery, NewsBriefQueryVariables>;
export const MyNotificationsDocument = gql`
    query MyNotifications {
  myNotifications {
    id
    description
    title
    read
    createdAt
  }
}
    `;
export type MyNotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyNotificationsQuery, MyNotificationsQueryVariables>, 'query'>;

    export const MyNotificationsComponent = (props: MyNotificationsComponentProps) => (
      <ApolloReactComponents.Query<MyNotificationsQuery, MyNotificationsQueryVariables> query={MyNotificationsDocument} {...props} />
    );
    
export type MyNotificationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<MyNotificationsQuery, MyNotificationsQueryVariables> & TChildProps;
export function withMyNotifications<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MyNotificationsQuery,
  MyNotificationsQueryVariables,
  MyNotificationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MyNotificationsQuery, MyNotificationsQueryVariables, MyNotificationsProps<TChildProps>>(MyNotificationsDocument, {
      alias: 'myNotifications',
      ...operationOptions
    });
};

/**
 * __useMyNotificationsQuery__
 *
 * To run a query within a React component, call `useMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, baseOptions);
      }
export function useMyNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, baseOptions);
        }
export type MyNotificationsQueryHookResult = ReturnType<typeof useMyNotificationsQuery>;
export type MyNotificationsLazyQueryHookResult = ReturnType<typeof useMyNotificationsLazyQuery>;
export type MyNotificationsQueryResult = ApolloReactCommon.QueryResult<MyNotificationsQuery, MyNotificationsQueryVariables>;
export const MarkNotificationsReadDocument = gql`
    mutation MarkNotificationsRead($ids: [ID!]!) {
  markNotificationsRead(ids: $ids) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type MarkNotificationsReadMutationFn = ApolloReactCommon.MutationFunction<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;
export type MarkNotificationsReadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>, 'mutation'>;

    export const MarkNotificationsReadComponent = (props: MarkNotificationsReadComponentProps) => (
      <ApolloReactComponents.Mutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables> mutation={MarkNotificationsReadDocument} {...props} />
    );
    
export type MarkNotificationsReadProps<TChildProps = {}> = ApolloReactHoc.MutateProps<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables> & TChildProps;
export function withMarkNotificationsRead<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables,
  MarkNotificationsReadProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables, MarkNotificationsReadProps<TChildProps>>(MarkNotificationsReadDocument, {
      alias: 'markNotificationsRead',
      ...operationOptions
    });
};

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>(MarkNotificationsReadDocument, baseOptions);
      }
export type MarkNotificationsReadMutationHookResult = ReturnType<typeof useMarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationResult = ApolloReactCommon.MutationResult<MarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;
export const OauthSignInDocument = gql`
    mutation oauthSignIn($code: String!, $provider: OAuthProviderEnum!, $state: String!) {
  oauthSignIn(code: $code, provider: $provider, state: $state) {
    ...AuthenticationAttributes
  }
}
    ${AuthenticationAttributesFragmentDoc}`;
export type OauthSignInMutationFn = ApolloReactCommon.MutationFunction<OauthSignInMutation, OauthSignInMutationVariables>;
export type OauthSignInComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OauthSignInMutation, OauthSignInMutationVariables>, 'mutation'>;

    export const OauthSignInComponent = (props: OauthSignInComponentProps) => (
      <ApolloReactComponents.Mutation<OauthSignInMutation, OauthSignInMutationVariables> mutation={OauthSignInDocument} {...props} />
    );
    
export type OauthSignInProps<TChildProps = {}> = ApolloReactHoc.MutateProps<OauthSignInMutation, OauthSignInMutationVariables> & TChildProps;
export function withOauthSignIn<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OauthSignInMutation,
  OauthSignInMutationVariables,
  OauthSignInProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, OauthSignInMutation, OauthSignInMutationVariables, OauthSignInProps<TChildProps>>(OauthSignInDocument, {
      alias: 'oauthSignIn',
      ...operationOptions
    });
};

/**
 * __useOauthSignInMutation__
 *
 * To run a mutation, you first call `useOauthSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOauthSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oauthSignInMutation, { data, loading, error }] = useOauthSignInMutation({
 *   variables: {
 *      code: // value for 'code'
 *      provider: // value for 'provider'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useOauthSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OauthSignInMutation, OauthSignInMutationVariables>) {
        return ApolloReactHooks.useMutation<OauthSignInMutation, OauthSignInMutationVariables>(OauthSignInDocument, baseOptions);
      }
export type OauthSignInMutationHookResult = ReturnType<typeof useOauthSignInMutation>;
export type OauthSignInMutationResult = ApolloReactCommon.MutationResult<OauthSignInMutation>;
export type OauthSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<OauthSignInMutation, OauthSignInMutationVariables>;
export const HomepageRankingTournamentsDocument = gql`
    query HomepageRankingTournaments {
  homepageRankingTournaments {
    id
    name
    firstMatchDate
    style
    published
    registrationCloseDate
    registrationOpenDate
    decided
    platform {
      id
      title
    }
  }
}
    `;
export type HomepageRankingTournamentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables>, 'query'>;

    export const HomepageRankingTournamentsComponent = (props: HomepageRankingTournamentsComponentProps) => (
      <ApolloReactComponents.Query<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables> query={HomepageRankingTournamentsDocument} {...props} />
    );
    
export type HomepageRankingTournamentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables> & TChildProps;
export function withHomepageRankingTournaments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HomepageRankingTournamentsQuery,
  HomepageRankingTournamentsQueryVariables,
  HomepageRankingTournamentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables, HomepageRankingTournamentsProps<TChildProps>>(HomepageRankingTournamentsDocument, {
      alias: 'homepageRankingTournaments',
      ...operationOptions
    });
};

/**
 * __useHomepageRankingTournamentsQuery__
 *
 * To run a query within a React component, call `useHomepageRankingTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageRankingTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageRankingTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageRankingTournamentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables>) {
        return ApolloReactHooks.useQuery<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables>(HomepageRankingTournamentsDocument, baseOptions);
      }
export function useHomepageRankingTournamentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables>(HomepageRankingTournamentsDocument, baseOptions);
        }
export type HomepageRankingTournamentsQueryHookResult = ReturnType<typeof useHomepageRankingTournamentsQuery>;
export type HomepageRankingTournamentsLazyQueryHookResult = ReturnType<typeof useHomepageRankingTournamentsLazyQuery>;
export type HomepageRankingTournamentsQueryResult = ApolloReactCommon.QueryResult<HomepageRankingTournamentsQuery, HomepageRankingTournamentsQueryVariables>;
export const GetTopUniversitiesDocument = gql`
    query getTopUniversities {
  topUniversities {
    rank
    university {
      id
      name
      state
    }
  }
}
    `;
export type GetTopUniversitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables>, 'query'>;

    export const GetTopUniversitiesComponent = (props: GetTopUniversitiesComponentProps) => (
      <ApolloReactComponents.Query<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables> query={GetTopUniversitiesDocument} {...props} />
    );
    
export type GetTopUniversitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables> & TChildProps;
export function withGetTopUniversities<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTopUniversitiesQuery,
  GetTopUniversitiesQueryVariables,
  GetTopUniversitiesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables, GetTopUniversitiesProps<TChildProps>>(GetTopUniversitiesDocument, {
      alias: 'getTopUniversities',
      ...operationOptions
    });
};

/**
 * __useGetTopUniversitiesQuery__
 *
 * To run a query within a React component, call `useGetTopUniversitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopUniversitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopUniversitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopUniversitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables>(GetTopUniversitiesDocument, baseOptions);
      }
export function useGetTopUniversitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables>(GetTopUniversitiesDocument, baseOptions);
        }
export type GetTopUniversitiesQueryHookResult = ReturnType<typeof useGetTopUniversitiesQuery>;
export type GetTopUniversitiesLazyQueryHookResult = ReturnType<typeof useGetTopUniversitiesLazyQuery>;
export type GetTopUniversitiesQueryResult = ApolloReactCommon.QueryResult<GetTopUniversitiesQuery, GetTopUniversitiesQueryVariables>;
export const RequestMatchRescheduleDocument = gql`
    mutation requestMatchReschedule($matchId: ID!, $rescheduleDate: DateTime!) {
  requestMatchReschedule(matchId: $matchId, rescheduleDate: $rescheduleDate) {
    success
    errors {
      field
      message
    }
    value {
      ...MatchDetailAttributes
    }
  }
}
    ${MatchDetailAttributesFragmentDoc}`;
export type RequestMatchRescheduleMutationFn = ApolloReactCommon.MutationFunction<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables>;
export type RequestMatchRescheduleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables>, 'mutation'>;

    export const RequestMatchRescheduleComponent = (props: RequestMatchRescheduleComponentProps) => (
      <ApolloReactComponents.Mutation<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables> mutation={RequestMatchRescheduleDocument} {...props} />
    );
    
export type RequestMatchRescheduleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables> & TChildProps;
export function withRequestMatchReschedule<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RequestMatchRescheduleMutation,
  RequestMatchRescheduleMutationVariables,
  RequestMatchRescheduleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables, RequestMatchRescheduleProps<TChildProps>>(RequestMatchRescheduleDocument, {
      alias: 'requestMatchReschedule',
      ...operationOptions
    });
};

/**
 * __useRequestMatchRescheduleMutation__
 *
 * To run a mutation, you first call `useRequestMatchRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMatchRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMatchRescheduleMutation, { data, loading, error }] = useRequestMatchRescheduleMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      rescheduleDate: // value for 'rescheduleDate'
 *   },
 * });
 */
export function useRequestMatchRescheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables>(RequestMatchRescheduleDocument, baseOptions);
      }
export type RequestMatchRescheduleMutationHookResult = ReturnType<typeof useRequestMatchRescheduleMutation>;
export type RequestMatchRescheduleMutationResult = ApolloReactCommon.MutationResult<RequestMatchRescheduleMutation>;
export type RequestMatchRescheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestMatchRescheduleMutation, RequestMatchRescheduleMutationVariables>;
export const RescheduleMatchDocument = gql`
    mutation rescheduleMatch($matchId: ID!, $rescheduleDate: DateTime!) {
  rescheduleMatch(matchId: $matchId, rescheduleDate: $rescheduleDate) {
    success
    errors {
      field
      message
    }
    value {
      ...MatchDetailAttributes
    }
  }
}
    ${MatchDetailAttributesFragmentDoc}`;
export type RescheduleMatchMutationFn = ApolloReactCommon.MutationFunction<RescheduleMatchMutation, RescheduleMatchMutationVariables>;
export type RescheduleMatchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RescheduleMatchMutation, RescheduleMatchMutationVariables>, 'mutation'>;

    export const RescheduleMatchComponent = (props: RescheduleMatchComponentProps) => (
      <ApolloReactComponents.Mutation<RescheduleMatchMutation, RescheduleMatchMutationVariables> mutation={RescheduleMatchDocument} {...props} />
    );
    
export type RescheduleMatchProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RescheduleMatchMutation, RescheduleMatchMutationVariables> & TChildProps;
export function withRescheduleMatch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RescheduleMatchMutation,
  RescheduleMatchMutationVariables,
  RescheduleMatchProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RescheduleMatchMutation, RescheduleMatchMutationVariables, RescheduleMatchProps<TChildProps>>(RescheduleMatchDocument, {
      alias: 'rescheduleMatch',
      ...operationOptions
    });
};

/**
 * __useRescheduleMatchMutation__
 *
 * To run a mutation, you first call `useRescheduleMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleMatchMutation, { data, loading, error }] = useRescheduleMatchMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      rescheduleDate: // value for 'rescheduleDate'
 *   },
 * });
 */
export function useRescheduleMatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RescheduleMatchMutation, RescheduleMatchMutationVariables>) {
        return ApolloReactHooks.useMutation<RescheduleMatchMutation, RescheduleMatchMutationVariables>(RescheduleMatchDocument, baseOptions);
      }
export type RescheduleMatchMutationHookResult = ReturnType<typeof useRescheduleMatchMutation>;
export type RescheduleMatchMutationResult = ApolloReactCommon.MutationResult<RescheduleMatchMutation>;
export type RescheduleMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<RescheduleMatchMutation, RescheduleMatchMutationVariables>;
export const ApproveMatchRescheduleDocument = gql`
    mutation approveMatchReschedule($matchId: ID!, $approve: Boolean!) {
  approveMatchReschedule(matchId: $matchId, approve: $approve) {
    success
    errors {
      field
      message
    }
    value {
      ...MatchDetailAttributes
    }
  }
}
    ${MatchDetailAttributesFragmentDoc}`;
export type ApproveMatchRescheduleMutationFn = ApolloReactCommon.MutationFunction<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables>;
export type ApproveMatchRescheduleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables>, 'mutation'>;

    export const ApproveMatchRescheduleComponent = (props: ApproveMatchRescheduleComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables> mutation={ApproveMatchRescheduleDocument} {...props} />
    );
    
export type ApproveMatchRescheduleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables> & TChildProps;
export function withApproveMatchReschedule<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApproveMatchRescheduleMutation,
  ApproveMatchRescheduleMutationVariables,
  ApproveMatchRescheduleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables, ApproveMatchRescheduleProps<TChildProps>>(ApproveMatchRescheduleDocument, {
      alias: 'approveMatchReschedule',
      ...operationOptions
    });
};

/**
 * __useApproveMatchRescheduleMutation__
 *
 * To run a mutation, you first call `useApproveMatchRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMatchRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMatchRescheduleMutation, { data, loading, error }] = useApproveMatchRescheduleMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      approve: // value for 'approve'
 *   },
 * });
 */
export function useApproveMatchRescheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables>(ApproveMatchRescheduleDocument, baseOptions);
      }
export type ApproveMatchRescheduleMutationHookResult = ReturnType<typeof useApproveMatchRescheduleMutation>;
export type ApproveMatchRescheduleMutationResult = ApolloReactCommon.MutationResult<ApproveMatchRescheduleMutation>;
export type ApproveMatchRescheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveMatchRescheduleMutation, ApproveMatchRescheduleMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    
export type ResetPasswordProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ResetPasswordMutation, ResetPasswordMutationVariables> & TChildProps;
export function withResetPassword<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ResetPasswordProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ResetPasswordMutation, ResetPasswordMutationVariables, ResetPasswordProps<TChildProps>>(ResetPasswordDocument, {
      alias: 'resetPassword',
      ...operationOptions
    });
};

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ApplyForTeamDocument = gql`
    mutation applyForTeam($id: ID!, $gameRole: String) {
  applyForTeam(id: $id, gameRole: $gameRole) {
    success
  }
}
    `;
export type ApplyForTeamMutationFn = ApolloReactCommon.MutationFunction<ApplyForTeamMutation, ApplyForTeamMutationVariables>;
export type ApplyForTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApplyForTeamMutation, ApplyForTeamMutationVariables>, 'mutation'>;

    export const ApplyForTeamComponent = (props: ApplyForTeamComponentProps) => (
      <ApolloReactComponents.Mutation<ApplyForTeamMutation, ApplyForTeamMutationVariables> mutation={ApplyForTeamDocument} {...props} />
    );
    
export type ApplyForTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ApplyForTeamMutation, ApplyForTeamMutationVariables> & TChildProps;
export function withApplyForTeam<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApplyForTeamMutation,
  ApplyForTeamMutationVariables,
  ApplyForTeamProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ApplyForTeamMutation, ApplyForTeamMutationVariables, ApplyForTeamProps<TChildProps>>(ApplyForTeamDocument, {
      alias: 'applyForTeam',
      ...operationOptions
    });
};

/**
 * __useApplyForTeamMutation__
 *
 * To run a mutation, you first call `useApplyForTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForTeamMutation, { data, loading, error }] = useApplyForTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      gameRole: // value for 'gameRole'
 *   },
 * });
 */
export function useApplyForTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyForTeamMutation, ApplyForTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplyForTeamMutation, ApplyForTeamMutationVariables>(ApplyForTeamDocument, baseOptions);
      }
export type ApplyForTeamMutationHookResult = ReturnType<typeof useApplyForTeamMutation>;
export type ApplyForTeamMutationResult = ApolloReactCommon.MutationResult<ApplyForTeamMutation>;
export type ApplyForTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyForTeamMutation, ApplyForTeamMutationVariables>;
export const TeamDetailPageDocument = gql`
    query teamDetailPage($id: ID!) {
  team(id: $id) {
    ...TeamDetailQuery
    ...TeamPendingInvitationsQuery
    ...TeamRosterEntriesQuery
    ...TeamSeasonInfoQuery
    ...TeamSettledMatchesQuery
    ...UpcomingMatchesQuery
    ...UnreportedMatchesQuery
    ...TournamentsToCheckinToQuery
    ...TeamMessageBoard
  }
}
    ${TeamDetailQueryFragmentDoc}
${TeamPendingInvitationsQueryFragmentDoc}
${TeamRosterEntriesQueryFragmentDoc}
${TeamSeasonInfoQueryFragmentDoc}
${TeamSettledMatchesQueryFragmentDoc}
${UpcomingMatchesQueryFragmentDoc}
${UnreportedMatchesQueryFragmentDoc}
${TournamentsToCheckinToQueryFragmentDoc}
${TeamMessageBoardFragmentDoc}`;
export type TeamDetailPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamDetailPageQuery, TeamDetailPageQueryVariables>, 'query'> & ({ variables: TeamDetailPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamDetailPageComponent = (props: TeamDetailPageComponentProps) => (
      <ApolloReactComponents.Query<TeamDetailPageQuery, TeamDetailPageQueryVariables> query={TeamDetailPageDocument} {...props} />
    );
    
export type TeamDetailPageProps<TChildProps = {}> = ApolloReactHoc.DataProps<TeamDetailPageQuery, TeamDetailPageQueryVariables> & TChildProps;
export function withTeamDetailPage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamDetailPageQuery,
  TeamDetailPageQueryVariables,
  TeamDetailPageProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TeamDetailPageQuery, TeamDetailPageQueryVariables, TeamDetailPageProps<TChildProps>>(TeamDetailPageDocument, {
      alias: 'teamDetailPage',
      ...operationOptions
    });
};

/**
 * __useTeamDetailPageQuery__
 *
 * To run a query within a React component, call `useTeamDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamDetailPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamDetailPageQuery, TeamDetailPageQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamDetailPageQuery, TeamDetailPageQueryVariables>(TeamDetailPageDocument, baseOptions);
      }
export function useTeamDetailPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamDetailPageQuery, TeamDetailPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamDetailPageQuery, TeamDetailPageQueryVariables>(TeamDetailPageDocument, baseOptions);
        }
export type TeamDetailPageQueryHookResult = ReturnType<typeof useTeamDetailPageQuery>;
export type TeamDetailPageLazyQueryHookResult = ReturnType<typeof useTeamDetailPageLazyQuery>;
export type TeamDetailPageQueryResult = ApolloReactCommon.QueryResult<TeamDetailPageQuery, TeamDetailPageQueryVariables>;
export const UpdateTeamMessagesDocument = gql`
    query UpdateTeamMessages($teamId: ID!, $since: Int) {
  team(id: $teamId) {
    id
    teamMessages(since: $since) {
      id
      user {
        displayName
        isAdmin
        id
      }
      body
      createdAt
    }
  }
}
    `;
export type UpdateTeamMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables>, 'query'> & ({ variables: UpdateTeamMessagesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdateTeamMessagesComponent = (props: UpdateTeamMessagesComponentProps) => (
      <ApolloReactComponents.Query<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables> query={UpdateTeamMessagesDocument} {...props} />
    );
    
export type UpdateTeamMessagesProps<TChildProps = {}> = ApolloReactHoc.DataProps<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables> & TChildProps;
export function withUpdateTeamMessages<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTeamMessagesQuery,
  UpdateTeamMessagesQueryVariables,
  UpdateTeamMessagesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables, UpdateTeamMessagesProps<TChildProps>>(UpdateTeamMessagesDocument, {
      alias: 'updateTeamMessages',
      ...operationOptions
    });
};

/**
 * __useUpdateTeamMessagesQuery__
 *
 * To run a query within a React component, call `useUpdateTeamMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateTeamMessagesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useUpdateTeamMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables>(UpdateTeamMessagesDocument, baseOptions);
      }
export function useUpdateTeamMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables>(UpdateTeamMessagesDocument, baseOptions);
        }
export type UpdateTeamMessagesQueryHookResult = ReturnType<typeof useUpdateTeamMessagesQuery>;
export type UpdateTeamMessagesLazyQueryHookResult = ReturnType<typeof useUpdateTeamMessagesLazyQuery>;
export type UpdateTeamMessagesQueryResult = ApolloReactCommon.QueryResult<UpdateTeamMessagesQuery, UpdateTeamMessagesQueryVariables>;
export const GetTeamMessageCountDocument = gql`
    query GetTeamMessageCount($teamId: ID!) {
  team(id: $teamId) {
    id
    messagesCount
  }
}
    `;
export type GetTeamMessageCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables>, 'query'> & ({ variables: GetTeamMessageCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTeamMessageCountComponent = (props: GetTeamMessageCountComponentProps) => (
      <ApolloReactComponents.Query<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables> query={GetTeamMessageCountDocument} {...props} />
    );
    
export type GetTeamMessageCountProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables> & TChildProps;
export function withGetTeamMessageCount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTeamMessageCountQuery,
  GetTeamMessageCountQueryVariables,
  GetTeamMessageCountProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables, GetTeamMessageCountProps<TChildProps>>(GetTeamMessageCountDocument, {
      alias: 'getTeamMessageCount',
      ...operationOptions
    });
};

/**
 * __useGetTeamMessageCountQuery__
 *
 * To run a query within a React component, call `useGetTeamMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMessageCountQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamMessageCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables>(GetTeamMessageCountDocument, baseOptions);
      }
export function useGetTeamMessageCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables>(GetTeamMessageCountDocument, baseOptions);
        }
export type GetTeamMessageCountQueryHookResult = ReturnType<typeof useGetTeamMessageCountQuery>;
export type GetTeamMessageCountLazyQueryHookResult = ReturnType<typeof useGetTeamMessageCountLazyQuery>;
export type GetTeamMessageCountQueryResult = ApolloReactCommon.QueryResult<GetTeamMessageCountQuery, GetTeamMessageCountQueryVariables>;
export const AddTeamMessageDocument = gql`
    mutation addTeamMessage($id: ID!, $body: String!) {
  addTeamMessage(id: $id, body: $body) {
    success
    errors {
      message
    }
  }
}
    `;
export type AddTeamMessageMutationFn = ApolloReactCommon.MutationFunction<AddTeamMessageMutation, AddTeamMessageMutationVariables>;
export type AddTeamMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddTeamMessageMutation, AddTeamMessageMutationVariables>, 'mutation'>;

    export const AddTeamMessageComponent = (props: AddTeamMessageComponentProps) => (
      <ApolloReactComponents.Mutation<AddTeamMessageMutation, AddTeamMessageMutationVariables> mutation={AddTeamMessageDocument} {...props} />
    );
    
export type AddTeamMessageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddTeamMessageMutation, AddTeamMessageMutationVariables> & TChildProps;
export function withAddTeamMessage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddTeamMessageMutation,
  AddTeamMessageMutationVariables,
  AddTeamMessageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddTeamMessageMutation, AddTeamMessageMutationVariables, AddTeamMessageProps<TChildProps>>(AddTeamMessageDocument, {
      alias: 'addTeamMessage',
      ...operationOptions
    });
};

/**
 * __useAddTeamMessageMutation__
 *
 * To run a mutation, you first call `useAddTeamMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMessageMutation, { data, loading, error }] = useAddTeamMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddTeamMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTeamMessageMutation, AddTeamMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTeamMessageMutation, AddTeamMessageMutationVariables>(AddTeamMessageDocument, baseOptions);
      }
export type AddTeamMessageMutationHookResult = ReturnType<typeof useAddTeamMessageMutation>;
export type AddTeamMessageMutationResult = ApolloReactCommon.MutationResult<AddTeamMessageMutation>;
export type AddTeamMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTeamMessageMutation, AddTeamMessageMutationVariables>;
export const TournamentAdminDocument = gql`
    query TournamentAdmin($id: ID!) {
  tournament(id: $id) {
    ...AdminTournamentInfo
    adminTournamentTeams {
      ...AdminTournamentTeamInfo
    }
    checkedInTeams {
      id
    }
  }
}
    ${AdminTournamentInfoFragmentDoc}
${AdminTournamentTeamInfoFragmentDoc}`;
export type TournamentAdminComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TournamentAdminQuery, TournamentAdminQueryVariables>, 'query'> & ({ variables: TournamentAdminQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TournamentAdminComponent = (props: TournamentAdminComponentProps) => (
      <ApolloReactComponents.Query<TournamentAdminQuery, TournamentAdminQueryVariables> query={TournamentAdminDocument} {...props} />
    );
    
export type TournamentAdminProps<TChildProps = {}> = ApolloReactHoc.DataProps<TournamentAdminQuery, TournamentAdminQueryVariables> & TChildProps;
export function withTournamentAdmin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TournamentAdminQuery,
  TournamentAdminQueryVariables,
  TournamentAdminProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TournamentAdminQuery, TournamentAdminQueryVariables, TournamentAdminProps<TChildProps>>(TournamentAdminDocument, {
      alias: 'tournamentAdmin',
      ...operationOptions
    });
};

/**
 * __useTournamentAdminQuery__
 *
 * To run a query within a React component, call `useTournamentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentAdminQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentAdminQuery, TournamentAdminQueryVariables>) {
        return ApolloReactHooks.useQuery<TournamentAdminQuery, TournamentAdminQueryVariables>(TournamentAdminDocument, baseOptions);
      }
export function useTournamentAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentAdminQuery, TournamentAdminQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TournamentAdminQuery, TournamentAdminQueryVariables>(TournamentAdminDocument, baseOptions);
        }
export type TournamentAdminQueryHookResult = ReturnType<typeof useTournamentAdminQuery>;
export type TournamentAdminLazyQueryHookResult = ReturnType<typeof useTournamentAdminLazyQuery>;
export type TournamentAdminQueryResult = ApolloReactCommon.QueryResult<TournamentAdminQuery, TournamentAdminQueryVariables>;
export const CheckinTeamForTournamentDocument = gql`
    mutation CheckinTeamForTournament($teamId: ID!, $tournamentId: ID!) {
  createNewCheckin(teamId: $teamId, tournamentId: $tournamentId) {
    success
    value
    errors {
      field
      message
    }
  }
}
    `;
export type CheckinTeamForTournamentMutationFn = ApolloReactCommon.MutationFunction<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables>;
export type CheckinTeamForTournamentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables>, 'mutation'>;

    export const CheckinTeamForTournamentComponent = (props: CheckinTeamForTournamentComponentProps) => (
      <ApolloReactComponents.Mutation<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables> mutation={CheckinTeamForTournamentDocument} {...props} />
    );
    
export type CheckinTeamForTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables> & TChildProps;
export function withCheckinTeamForTournament<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckinTeamForTournamentMutation,
  CheckinTeamForTournamentMutationVariables,
  CheckinTeamForTournamentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables, CheckinTeamForTournamentProps<TChildProps>>(CheckinTeamForTournamentDocument, {
      alias: 'checkinTeamForTournament',
      ...operationOptions
    });
};

/**
 * __useCheckinTeamForTournamentMutation__
 *
 * To run a mutation, you first call `useCheckinTeamForTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckinTeamForTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkinTeamForTournamentMutation, { data, loading, error }] = useCheckinTeamForTournamentMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useCheckinTeamForTournamentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables>(CheckinTeamForTournamentDocument, baseOptions);
      }
export type CheckinTeamForTournamentMutationHookResult = ReturnType<typeof useCheckinTeamForTournamentMutation>;
export type CheckinTeamForTournamentMutationResult = ApolloReactCommon.MutationResult<CheckinTeamForTournamentMutation>;
export type CheckinTeamForTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckinTeamForTournamentMutation, CheckinTeamForTournamentMutationVariables>;
export const UncheckinTeamFromTournamentDocument = gql`
    mutation UncheckinTeamFromTournament($teamId: ID!, $tournamentId: ID!) {
  deleteTournamentCheckin(teamId: $teamId, tournamentId: $tournamentId) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UncheckinTeamFromTournamentMutationFn = ApolloReactCommon.MutationFunction<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables>;
export type UncheckinTeamFromTournamentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables>, 'mutation'>;

    export const UncheckinTeamFromTournamentComponent = (props: UncheckinTeamFromTournamentComponentProps) => (
      <ApolloReactComponents.Mutation<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables> mutation={UncheckinTeamFromTournamentDocument} {...props} />
    );
    
export type UncheckinTeamFromTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables> & TChildProps;
export function withUncheckinTeamFromTournament<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UncheckinTeamFromTournamentMutation,
  UncheckinTeamFromTournamentMutationVariables,
  UncheckinTeamFromTournamentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables, UncheckinTeamFromTournamentProps<TChildProps>>(UncheckinTeamFromTournamentDocument, {
      alias: 'uncheckinTeamFromTournament',
      ...operationOptions
    });
};

/**
 * __useUncheckinTeamFromTournamentMutation__
 *
 * To run a mutation, you first call `useUncheckinTeamFromTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncheckinTeamFromTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncheckinTeamFromTournamentMutation, { data, loading, error }] = useUncheckinTeamFromTournamentMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useUncheckinTeamFromTournamentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables>) {
        return ApolloReactHooks.useMutation<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables>(UncheckinTeamFromTournamentDocument, baseOptions);
      }
export type UncheckinTeamFromTournamentMutationHookResult = ReturnType<typeof useUncheckinTeamFromTournamentMutation>;
export type UncheckinTeamFromTournamentMutationResult = ApolloReactCommon.MutationResult<UncheckinTeamFromTournamentMutation>;
export type UncheckinTeamFromTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<UncheckinTeamFromTournamentMutation, UncheckinTeamFromTournamentMutationVariables>;
export const TournamentDetailRegModalDocument = gql`
    query TournamentDetailRegModal {
  currentUser {
    ...TournamentDetail_CurrentUser
  }
}
    ${TournamentDetail_CurrentUserFragmentDoc}`;
export type TournamentDetailRegModalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables>, 'query'>;

    export const TournamentDetailRegModalComponent = (props: TournamentDetailRegModalComponentProps) => (
      <ApolloReactComponents.Query<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables> query={TournamentDetailRegModalDocument} {...props} />
    );
    
export type TournamentDetailRegModalProps<TChildProps = {}> = ApolloReactHoc.DataProps<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables> & TChildProps;
export function withTournamentDetailRegModal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TournamentDetailRegModalQuery,
  TournamentDetailRegModalQueryVariables,
  TournamentDetailRegModalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables, TournamentDetailRegModalProps<TChildProps>>(TournamentDetailRegModalDocument, {
      alias: 'tournamentDetailRegModal',
      ...operationOptions
    });
};

/**
 * __useTournamentDetailRegModalQuery__
 *
 * To run a query within a React component, call `useTournamentDetailRegModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDetailRegModalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDetailRegModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useTournamentDetailRegModalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables>) {
        return ApolloReactHooks.useQuery<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables>(TournamentDetailRegModalDocument, baseOptions);
      }
export function useTournamentDetailRegModalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables>(TournamentDetailRegModalDocument, baseOptions);
        }
export type TournamentDetailRegModalQueryHookResult = ReturnType<typeof useTournamentDetailRegModalQuery>;
export type TournamentDetailRegModalLazyQueryHookResult = ReturnType<typeof useTournamentDetailRegModalLazyQuery>;
export type TournamentDetailRegModalQueryResult = ApolloReactCommon.QueryResult<TournamentDetailRegModalQuery, TournamentDetailRegModalQueryVariables>;
export const RegisterTeamForTournamentDocument = gql`
    mutation RegisterTeamForTournament($teamId: ID!, $tournamentId: ID!) {
  registerTeamForTournament(teamId: $teamId, tournamentId: $tournamentId) {
    success
    value
    errors {
      field
      message
    }
  }
}
    `;
export type RegisterTeamForTournamentMutationFn = ApolloReactCommon.MutationFunction<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables>;
export type RegisterTeamForTournamentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables>, 'mutation'>;

    export const RegisterTeamForTournamentComponent = (props: RegisterTeamForTournamentComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables> mutation={RegisterTeamForTournamentDocument} {...props} />
    );
    
export type RegisterTeamForTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables> & TChildProps;
export function withRegisterTeamForTournament<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisterTeamForTournamentMutation,
  RegisterTeamForTournamentMutationVariables,
  RegisterTeamForTournamentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables, RegisterTeamForTournamentProps<TChildProps>>(RegisterTeamForTournamentDocument, {
      alias: 'registerTeamForTournament',
      ...operationOptions
    });
};

/**
 * __useRegisterTeamForTournamentMutation__
 *
 * To run a mutation, you first call `useRegisterTeamForTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTeamForTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTeamForTournamentMutation, { data, loading, error }] = useRegisterTeamForTournamentMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useRegisterTeamForTournamentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables>(RegisterTeamForTournamentDocument, baseOptions);
      }
export type RegisterTeamForTournamentMutationHookResult = ReturnType<typeof useRegisterTeamForTournamentMutation>;
export type RegisterTeamForTournamentMutationResult = ApolloReactCommon.MutationResult<RegisterTeamForTournamentMutation>;
export type RegisterTeamForTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterTeamForTournamentMutation, RegisterTeamForTournamentMutationVariables>;
export const UnregisterTeamFromTournamentDocument = gql`
    mutation UnregisterTeamFromTournament($id: ID!) {
  deleteTournamentRegistry(id: $id) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UnregisterTeamFromTournamentMutationFn = ApolloReactCommon.MutationFunction<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables>;
export type UnregisterTeamFromTournamentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables>, 'mutation'>;

    export const UnregisterTeamFromTournamentComponent = (props: UnregisterTeamFromTournamentComponentProps) => (
      <ApolloReactComponents.Mutation<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables> mutation={UnregisterTeamFromTournamentDocument} {...props} />
    );
    
export type UnregisterTeamFromTournamentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables> & TChildProps;
export function withUnregisterTeamFromTournament<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnregisterTeamFromTournamentMutation,
  UnregisterTeamFromTournamentMutationVariables,
  UnregisterTeamFromTournamentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables, UnregisterTeamFromTournamentProps<TChildProps>>(UnregisterTeamFromTournamentDocument, {
      alias: 'unregisterTeamFromTournament',
      ...operationOptions
    });
};

/**
 * __useUnregisterTeamFromTournamentMutation__
 *
 * To run a mutation, you first call `useUnregisterTeamFromTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterTeamFromTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterTeamFromTournamentMutation, { data, loading, error }] = useUnregisterTeamFromTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnregisterTeamFromTournamentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables>) {
        return ApolloReactHooks.useMutation<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables>(UnregisterTeamFromTournamentDocument, baseOptions);
      }
export type UnregisterTeamFromTournamentMutationHookResult = ReturnType<typeof useUnregisterTeamFromTournamentMutation>;
export type UnregisterTeamFromTournamentMutationResult = ApolloReactCommon.MutationResult<UnregisterTeamFromTournamentMutation>;
export type UnregisterTeamFromTournamentMutationOptions = ApolloReactCommon.BaseMutationOptions<UnregisterTeamFromTournamentMutation, UnregisterTeamFromTournamentMutationVariables>;
export const ReviewForfeitDocument = gql`
    mutation ReviewForfeit($forfeitId: ID!, $rejectedMessage: String, $accepted: Boolean!) {
  reviewForfeit(forfeitId: $forfeitId, rejectedMessage: $rejectedMessage, accepted: $accepted) {
    errors
  }
}
    `;
export type ReviewForfeitMutationFn = ApolloReactCommon.MutationFunction<ReviewForfeitMutation, ReviewForfeitMutationVariables>;
export type ReviewForfeitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReviewForfeitMutation, ReviewForfeitMutationVariables>, 'mutation'>;

    export const ReviewForfeitComponent = (props: ReviewForfeitComponentProps) => (
      <ApolloReactComponents.Mutation<ReviewForfeitMutation, ReviewForfeitMutationVariables> mutation={ReviewForfeitDocument} {...props} />
    );
    
export type ReviewForfeitProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReviewForfeitMutation, ReviewForfeitMutationVariables> & TChildProps;
export function withReviewForfeit<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReviewForfeitMutation,
  ReviewForfeitMutationVariables,
  ReviewForfeitProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReviewForfeitMutation, ReviewForfeitMutationVariables, ReviewForfeitProps<TChildProps>>(ReviewForfeitDocument, {
      alias: 'reviewForfeit',
      ...operationOptions
    });
};

/**
 * __useReviewForfeitMutation__
 *
 * To run a mutation, you first call `useReviewForfeitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewForfeitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewForfeitMutation, { data, loading, error }] = useReviewForfeitMutation({
 *   variables: {
 *      forfeitId: // value for 'forfeitId'
 *      rejectedMessage: // value for 'rejectedMessage'
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function useReviewForfeitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewForfeitMutation, ReviewForfeitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewForfeitMutation, ReviewForfeitMutationVariables>(ReviewForfeitDocument, baseOptions);
      }
export type ReviewForfeitMutationHookResult = ReturnType<typeof useReviewForfeitMutation>;
export type ReviewForfeitMutationResult = ApolloReactCommon.MutationResult<ReviewForfeitMutation>;
export type ReviewForfeitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewForfeitMutation, ReviewForfeitMutationVariables>;
export const UserAccountSettingsDocument = gql`
    query userAccountSettings($id: ID) {
  userSettings(id: $id) {
    username
    subscriptionEndDate
    personalEmail
    isPremiumUser
  }
}
    `;
export type UserAccountSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserAccountSettingsQuery, UserAccountSettingsQueryVariables>, 'query'>;

    export const UserAccountSettingsComponent = (props: UserAccountSettingsComponentProps) => (
      <ApolloReactComponents.Query<UserAccountSettingsQuery, UserAccountSettingsQueryVariables> query={UserAccountSettingsDocument} {...props} />
    );
    
export type UserAccountSettingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserAccountSettingsQuery, UserAccountSettingsQueryVariables> & TChildProps;
export function withUserAccountSettings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserAccountSettingsQuery,
  UserAccountSettingsQueryVariables,
  UserAccountSettingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UserAccountSettingsQuery, UserAccountSettingsQueryVariables, UserAccountSettingsProps<TChildProps>>(UserAccountSettingsDocument, {
      alias: 'userAccountSettings',
      ...operationOptions
    });
};

/**
 * __useUserAccountSettingsQuery__
 *
 * To run a query within a React component, call `useUserAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAccountSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAccountSettingsQuery, UserAccountSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAccountSettingsQuery, UserAccountSettingsQueryVariables>(UserAccountSettingsDocument, baseOptions);
      }
export function useUserAccountSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAccountSettingsQuery, UserAccountSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAccountSettingsQuery, UserAccountSettingsQueryVariables>(UserAccountSettingsDocument, baseOptions);
        }
export type UserAccountSettingsQueryHookResult = ReturnType<typeof useUserAccountSettingsQuery>;
export type UserAccountSettingsLazyQueryHookResult = ReturnType<typeof useUserAccountSettingsLazyQuery>;
export type UserAccountSettingsQueryResult = ApolloReactCommon.QueryResult<UserAccountSettingsQuery, UserAccountSettingsQueryVariables>;
export const VerfiyNewEsportsUserDocument = gql`
    mutation VerfiyNewEsportsUser($name: String!, $discordUsername: String!, $gender: String) {
  verifyNewUser(name: $name, discordUsername: $discordUsername, gender: $gender) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type VerfiyNewEsportsUserMutationFn = ApolloReactCommon.MutationFunction<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables>;
export type VerfiyNewEsportsUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables>, 'mutation'>;

    export const VerfiyNewEsportsUserComponent = (props: VerfiyNewEsportsUserComponentProps) => (
      <ApolloReactComponents.Mutation<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables> mutation={VerfiyNewEsportsUserDocument} {...props} />
    );
    
export type VerfiyNewEsportsUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables> & TChildProps;
export function withVerfiyNewEsportsUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VerfiyNewEsportsUserMutation,
  VerfiyNewEsportsUserMutationVariables,
  VerfiyNewEsportsUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables, VerfiyNewEsportsUserProps<TChildProps>>(VerfiyNewEsportsUserDocument, {
      alias: 'verfiyNewEsportsUser',
      ...operationOptions
    });
};

/**
 * __useVerfiyNewEsportsUserMutation__
 *
 * To run a mutation, you first call `useVerfiyNewEsportsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerfiyNewEsportsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verfiyNewEsportsUserMutation, { data, loading, error }] = useVerfiyNewEsportsUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      discordUsername: // value for 'discordUsername'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useVerfiyNewEsportsUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables>) {
        return ApolloReactHooks.useMutation<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables>(VerfiyNewEsportsUserDocument, baseOptions);
      }
export type VerfiyNewEsportsUserMutationHookResult = ReturnType<typeof useVerfiyNewEsportsUserMutation>;
export type VerfiyNewEsportsUserMutationResult = ApolloReactCommon.MutationResult<VerfiyNewEsportsUserMutation>;
export type VerfiyNewEsportsUserMutationOptions = ApolloReactCommon.BaseMutationOptions<VerfiyNewEsportsUserMutation, VerfiyNewEsportsUserMutationVariables>;
export const VerfiyNewUserDocument = gql`
    mutation VerfiyNewUser($name: String!, $email: String!, $universityId: ID!, $universityMajorId: ID, $graduationYear: Int!, $discordUsername: String!, $gender: String) {
  verifyNewUser(name: $name, email: $email, universityId: $universityId, universityMajorId: $universityMajorId, graduationYear: $graduationYear, discordUsername: $discordUsername, gender: $gender) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type VerfiyNewUserMutationFn = ApolloReactCommon.MutationFunction<VerfiyNewUserMutation, VerfiyNewUserMutationVariables>;
export type VerfiyNewUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerfiyNewUserMutation, VerfiyNewUserMutationVariables>, 'mutation'>;

    export const VerfiyNewUserComponent = (props: VerfiyNewUserComponentProps) => (
      <ApolloReactComponents.Mutation<VerfiyNewUserMutation, VerfiyNewUserMutationVariables> mutation={VerfiyNewUserDocument} {...props} />
    );
    
export type VerfiyNewUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<VerfiyNewUserMutation, VerfiyNewUserMutationVariables> & TChildProps;
export function withVerfiyNewUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VerfiyNewUserMutation,
  VerfiyNewUserMutationVariables,
  VerfiyNewUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, VerfiyNewUserMutation, VerfiyNewUserMutationVariables, VerfiyNewUserProps<TChildProps>>(VerfiyNewUserDocument, {
      alias: 'verfiyNewUser',
      ...operationOptions
    });
};

/**
 * __useVerfiyNewUserMutation__
 *
 * To run a mutation, you first call `useVerfiyNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerfiyNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verfiyNewUserMutation, { data, loading, error }] = useVerfiyNewUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      universityId: // value for 'universityId'
 *      universityMajorId: // value for 'universityMajorId'
 *      graduationYear: // value for 'graduationYear'
 *      discordUsername: // value for 'discordUsername'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useVerfiyNewUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerfiyNewUserMutation, VerfiyNewUserMutationVariables>) {
        return ApolloReactHooks.useMutation<VerfiyNewUserMutation, VerfiyNewUserMutationVariables>(VerfiyNewUserDocument, baseOptions);
      }
export type VerfiyNewUserMutationHookResult = ReturnType<typeof useVerfiyNewUserMutation>;
export type VerfiyNewUserMutationResult = ApolloReactCommon.MutationResult<VerfiyNewUserMutation>;
export type VerfiyNewUserMutationOptions = ApolloReactCommon.BaseMutationOptions<VerfiyNewUserMutation, VerfiyNewUserMutationVariables>;
export const GetInvitedUserDocument = gql`
    query getInvitedUser {
  invitedUser {
    email
    university {
      ...CurrentUser_University
    }
    pendingTeamInvitations {
      ...CurrentUser_PendingInvitations
    }
  }
}
    ${CurrentUser_UniversityFragmentDoc}
${CurrentUser_PendingInvitationsFragmentDoc}`;
export type GetInvitedUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvitedUserQuery, GetInvitedUserQueryVariables>, 'query'>;

    export const GetInvitedUserComponent = (props: GetInvitedUserComponentProps) => (
      <ApolloReactComponents.Query<GetInvitedUserQuery, GetInvitedUserQueryVariables> query={GetInvitedUserDocument} {...props} />
    );
    
export type GetInvitedUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvitedUserQuery, GetInvitedUserQueryVariables> & TChildProps;
export function withGetInvitedUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvitedUserQuery,
  GetInvitedUserQueryVariables,
  GetInvitedUserProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvitedUserQuery, GetInvitedUserQueryVariables, GetInvitedUserProps<TChildProps>>(GetInvitedUserDocument, {
      alias: 'getInvitedUser',
      ...operationOptions
    });
};

/**
 * __useGetInvitedUserQuery__
 *
 * To run a query within a React component, call `useGetInvitedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvitedUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedUserQuery, GetInvitedUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedUserQuery, GetInvitedUserQueryVariables>(GetInvitedUserDocument, baseOptions);
      }
export function useGetInvitedUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedUserQuery, GetInvitedUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedUserQuery, GetInvitedUserQueryVariables>(GetInvitedUserDocument, baseOptions);
        }
export type GetInvitedUserQueryHookResult = ReturnType<typeof useGetInvitedUserQuery>;
export type GetInvitedUserLazyQueryHookResult = ReturnType<typeof useGetInvitedUserLazyQuery>;
export type GetInvitedUserQueryResult = ApolloReactCommon.QueryResult<GetInvitedUserQuery, GetInvitedUserQueryVariables>;
export const PendingForfeitsDocument = gql`
    query pendingForfeits($sports: [String!], $first: Int, $after: String) {
  pendingForfeitsConnection(sports: $sports, first: $first, after: $after) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        requestingTeamName
        opposingTeamName
        explanation
        proofScreenshotUrl
        updatedAt
        match {
          id
          sport
        }
      }
    }
  }
}
    `;
export type PendingForfeitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PendingForfeitsQuery, PendingForfeitsQueryVariables>, 'query'>;

    export const PendingForfeitsComponent = (props: PendingForfeitsComponentProps) => (
      <ApolloReactComponents.Query<PendingForfeitsQuery, PendingForfeitsQueryVariables> query={PendingForfeitsDocument} {...props} />
    );
    
export type PendingForfeitsProps<TChildProps = {}> = ApolloReactHoc.DataProps<PendingForfeitsQuery, PendingForfeitsQueryVariables> & TChildProps;
export function withPendingForfeits<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PendingForfeitsQuery,
  PendingForfeitsQueryVariables,
  PendingForfeitsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PendingForfeitsQuery, PendingForfeitsQueryVariables, PendingForfeitsProps<TChildProps>>(PendingForfeitsDocument, {
      alias: 'pendingForfeits',
      ...operationOptions
    });
};

/**
 * __usePendingForfeitsQuery__
 *
 * To run a query within a React component, call `usePendingForfeitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingForfeitsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingForfeitsQuery({
 *   variables: {
 *      sports: // value for 'sports'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePendingForfeitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingForfeitsQuery, PendingForfeitsQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingForfeitsQuery, PendingForfeitsQueryVariables>(PendingForfeitsDocument, baseOptions);
      }
export function usePendingForfeitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingForfeitsQuery, PendingForfeitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingForfeitsQuery, PendingForfeitsQueryVariables>(PendingForfeitsDocument, baseOptions);
        }
export type PendingForfeitsQueryHookResult = ReturnType<typeof usePendingForfeitsQuery>;
export type PendingForfeitsLazyQueryHookResult = ReturnType<typeof usePendingForfeitsLazyQuery>;
export type PendingForfeitsQueryResult = ApolloReactCommon.QueryResult<PendingForfeitsQuery, PendingForfeitsQueryVariables>;
export const LeagueDetailDocument = gql`
    query LeagueDetail($id: ID!) {
  currentUser {
    ...LeagueDetail_CurrentUser
  }
  season(id: $id) {
    ...LeagueDetail_Season
  }
}
    ${LeagueDetail_CurrentUserFragmentDoc}
${LeagueDetail_SeasonFragmentDoc}`;
export type LeagueDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeagueDetailQuery, LeagueDetailQueryVariables>, 'query'> & ({ variables: LeagueDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LeagueDetailComponent = (props: LeagueDetailComponentProps) => (
      <ApolloReactComponents.Query<LeagueDetailQuery, LeagueDetailQueryVariables> query={LeagueDetailDocument} {...props} />
    );
    
export type LeagueDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<LeagueDetailQuery, LeagueDetailQueryVariables> & TChildProps;
export function withLeagueDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LeagueDetailQuery,
  LeagueDetailQueryVariables,
  LeagueDetailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, LeagueDetailQuery, LeagueDetailQueryVariables, LeagueDetailProps<TChildProps>>(LeagueDetailDocument, {
      alias: 'leagueDetail',
      ...operationOptions
    });
};

/**
 * __useLeagueDetailQuery__
 *
 * To run a query within a React component, call `useLeagueDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeagueDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeagueDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeagueDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeagueDetailQuery, LeagueDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<LeagueDetailQuery, LeagueDetailQueryVariables>(LeagueDetailDocument, baseOptions);
      }
export function useLeagueDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeagueDetailQuery, LeagueDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeagueDetailQuery, LeagueDetailQueryVariables>(LeagueDetailDocument, baseOptions);
        }
export type LeagueDetailQueryHookResult = ReturnType<typeof useLeagueDetailQuery>;
export type LeagueDetailLazyQueryHookResult = ReturnType<typeof useLeagueDetailLazyQuery>;
export type LeagueDetailQueryResult = ApolloReactCommon.QueryResult<LeagueDetailQuery, LeagueDetailQueryVariables>;
export const LeagueDetailRegisterTeamForSeasonDocument = gql`
    mutation LeagueDetailRegisterTeamForSeason($teamId: ID!, $seasonId: ID!) {
  registerTeamForSeason(teamId: $teamId, seasonId: $seasonId) {
    success
    value
    errors {
      field
      message
    }
  }
}
    `;
export type LeagueDetailRegisterTeamForSeasonMutationFn = ApolloReactCommon.MutationFunction<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables>;
export type LeagueDetailRegisterTeamForSeasonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables>, 'mutation'>;

    export const LeagueDetailRegisterTeamForSeasonComponent = (props: LeagueDetailRegisterTeamForSeasonComponentProps) => (
      <ApolloReactComponents.Mutation<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables> mutation={LeagueDetailRegisterTeamForSeasonDocument} {...props} />
    );
    
export type LeagueDetailRegisterTeamForSeasonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables> & TChildProps;
export function withLeagueDetailRegisterTeamForSeason<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LeagueDetailRegisterTeamForSeasonMutation,
  LeagueDetailRegisterTeamForSeasonMutationVariables,
  LeagueDetailRegisterTeamForSeasonProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables, LeagueDetailRegisterTeamForSeasonProps<TChildProps>>(LeagueDetailRegisterTeamForSeasonDocument, {
      alias: 'leagueDetailRegisterTeamForSeason',
      ...operationOptions
    });
};

/**
 * __useLeagueDetailRegisterTeamForSeasonMutation__
 *
 * To run a mutation, you first call `useLeagueDetailRegisterTeamForSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeagueDetailRegisterTeamForSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leagueDetailRegisterTeamForSeasonMutation, { data, loading, error }] = useLeagueDetailRegisterTeamForSeasonMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useLeagueDetailRegisterTeamForSeasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables>) {
        return ApolloReactHooks.useMutation<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables>(LeagueDetailRegisterTeamForSeasonDocument, baseOptions);
      }
export type LeagueDetailRegisterTeamForSeasonMutationHookResult = ReturnType<typeof useLeagueDetailRegisterTeamForSeasonMutation>;
export type LeagueDetailRegisterTeamForSeasonMutationResult = ApolloReactCommon.MutationResult<LeagueDetailRegisterTeamForSeasonMutation>;
export type LeagueDetailRegisterTeamForSeasonMutationOptions = ApolloReactCommon.BaseMutationOptions<LeagueDetailRegisterTeamForSeasonMutation, LeagueDetailRegisterTeamForSeasonMutationVariables>;
export const SeasonSchedulesModalDocument = gql`
    query SeasonSchedulesModal($id: ID!, $weekNumber: Int!) {
  season(id: $id) {
    id
    roundIntervals {
      scheduledDate
      id
    }
    scheduleForWeek(weekNumber: $weekNumber) {
      id
      matches {
        ...SeasonScheduleModalMatch
      }
    }
  }
}
    ${SeasonScheduleModalMatchFragmentDoc}`;
export type SeasonSchedulesModalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables>, 'query'> & ({ variables: SeasonSchedulesModalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SeasonSchedulesModalComponent = (props: SeasonSchedulesModalComponentProps) => (
      <ApolloReactComponents.Query<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables> query={SeasonSchedulesModalDocument} {...props} />
    );
    
export type SeasonSchedulesModalProps<TChildProps = {}> = ApolloReactHoc.DataProps<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables> & TChildProps;
export function withSeasonSchedulesModal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SeasonSchedulesModalQuery,
  SeasonSchedulesModalQueryVariables,
  SeasonSchedulesModalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables, SeasonSchedulesModalProps<TChildProps>>(SeasonSchedulesModalDocument, {
      alias: 'seasonSchedulesModal',
      ...operationOptions
    });
};

/**
 * __useSeasonSchedulesModalQuery__
 *
 * To run a query within a React component, call `useSeasonSchedulesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonSchedulesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonSchedulesModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      weekNumber: // value for 'weekNumber'
 *   },
 * });
 */
export function useSeasonSchedulesModalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables>) {
        return ApolloReactHooks.useQuery<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables>(SeasonSchedulesModalDocument, baseOptions);
      }
export function useSeasonSchedulesModalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables>(SeasonSchedulesModalDocument, baseOptions);
        }
export type SeasonSchedulesModalQueryHookResult = ReturnType<typeof useSeasonSchedulesModalQuery>;
export type SeasonSchedulesModalLazyQueryHookResult = ReturnType<typeof useSeasonSchedulesModalLazyQuery>;
export type SeasonSchedulesModalQueryResult = ApolloReactCommon.QueryResult<SeasonSchedulesModalQuery, SeasonSchedulesModalQueryVariables>;
export const LeaguesDivisionsDocument = gql`
    query LeaguesDivisions($seasonYears: [String!]) {
  divisions(seasonYears: $seasonYears) {
    ...Leagues_Division
  }
}
    ${Leagues_DivisionFragmentDoc}`;
export type LeaguesDivisionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables>, 'query'>;

    export const LeaguesDivisionsComponent = (props: LeaguesDivisionsComponentProps) => (
      <ApolloReactComponents.Query<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables> query={LeaguesDivisionsDocument} {...props} />
    );
    
export type LeaguesDivisionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables> & TChildProps;
export function withLeaguesDivisions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LeaguesDivisionsQuery,
  LeaguesDivisionsQueryVariables,
  LeaguesDivisionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables, LeaguesDivisionsProps<TChildProps>>(LeaguesDivisionsDocument, {
      alias: 'leaguesDivisions',
      ...operationOptions
    });
};

/**
 * __useLeaguesDivisionsQuery__
 *
 * To run a query within a React component, call `useLeaguesDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaguesDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaguesDivisionsQuery({
 *   variables: {
 *      seasonYears: // value for 'seasonYears'
 *   },
 * });
 */
export function useLeaguesDivisionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables>(LeaguesDivisionsDocument, baseOptions);
      }
export function useLeaguesDivisionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables>(LeaguesDivisionsDocument, baseOptions);
        }
export type LeaguesDivisionsQueryHookResult = ReturnType<typeof useLeaguesDivisionsQuery>;
export type LeaguesDivisionsLazyQueryHookResult = ReturnType<typeof useLeaguesDivisionsLazyQuery>;
export type LeaguesDivisionsQueryResult = ApolloReactCommon.QueryResult<LeaguesDivisionsQuery, LeaguesDivisionsQueryVariables>;
export const NewsOrAnnouncementDocument = gql`
    query NewsOrAnnouncement($id: ID!) {
  newsOrAnnouncement(id: $id) {
    success
    value {
      ...ManageNewsAndAnnouncements_NewsOrAnnouncement
    }
    errors {
      field
      message
    }
  }
}
    ${ManageNewsAndAnnouncements_NewsOrAnnouncementFragmentDoc}`;
export type NewsOrAnnouncementComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables>, 'query'> & ({ variables: NewsOrAnnouncementQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NewsOrAnnouncementComponent = (props: NewsOrAnnouncementComponentProps) => (
      <ApolloReactComponents.Query<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables> query={NewsOrAnnouncementDocument} {...props} />
    );
    
export type NewsOrAnnouncementProps<TChildProps = {}> = ApolloReactHoc.DataProps<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables> & TChildProps;
export function withNewsOrAnnouncement<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsOrAnnouncementQuery,
  NewsOrAnnouncementQueryVariables,
  NewsOrAnnouncementProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables, NewsOrAnnouncementProps<TChildProps>>(NewsOrAnnouncementDocument, {
      alias: 'newsOrAnnouncement',
      ...operationOptions
    });
};

/**
 * __useNewsOrAnnouncementQuery__
 *
 * To run a query within a React component, call `useNewsOrAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsOrAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsOrAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsOrAnnouncementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables>(NewsOrAnnouncementDocument, baseOptions);
      }
export function useNewsOrAnnouncementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables>(NewsOrAnnouncementDocument, baseOptions);
        }
export type NewsOrAnnouncementQueryHookResult = ReturnType<typeof useNewsOrAnnouncementQuery>;
export type NewsOrAnnouncementLazyQueryHookResult = ReturnType<typeof useNewsOrAnnouncementLazyQuery>;
export type NewsOrAnnouncementQueryResult = ApolloReactCommon.QueryResult<NewsOrAnnouncementQuery, NewsOrAnnouncementQueryVariables>;
export const SetNewCoordinatorDocument = gql`
    mutation setNewCoordinator($id: ID!, $coordinatorId: ID!) {
  upsertTeam(id: $id, coordinatorId: $coordinatorId) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type SetNewCoordinatorMutationFn = ApolloReactCommon.MutationFunction<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables>;
export type SetNewCoordinatorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables>, 'mutation'>;

    export const SetNewCoordinatorComponent = (props: SetNewCoordinatorComponentProps) => (
      <ApolloReactComponents.Mutation<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables> mutation={SetNewCoordinatorDocument} {...props} />
    );
    
export type SetNewCoordinatorProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables> & TChildProps;
export function withSetNewCoordinator<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetNewCoordinatorMutation,
  SetNewCoordinatorMutationVariables,
  SetNewCoordinatorProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables, SetNewCoordinatorProps<TChildProps>>(SetNewCoordinatorDocument, {
      alias: 'setNewCoordinator',
      ...operationOptions
    });
};

/**
 * __useSetNewCoordinatorMutation__
 *
 * To run a mutation, you first call `useSetNewCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewCoordinatorMutation, { data, loading, error }] = useSetNewCoordinatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      coordinatorId: // value for 'coordinatorId'
 *   },
 * });
 */
export function useSetNewCoordinatorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables>) {
        return ApolloReactHooks.useMutation<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables>(SetNewCoordinatorDocument, baseOptions);
      }
export type SetNewCoordinatorMutationHookResult = ReturnType<typeof useSetNewCoordinatorMutation>;
export type SetNewCoordinatorMutationResult = ApolloReactCommon.MutationResult<SetNewCoordinatorMutation>;
export type SetNewCoordinatorMutationOptions = ApolloReactCommon.BaseMutationOptions<SetNewCoordinatorMutation, SetNewCoordinatorMutationVariables>;
export const UpdateRosterEntryDocument = gql`
    mutation updateRosterEntry($id: ID!, $role: String, $gameRole: String, $staffRole: String, $kicked: Boolean) {
  updateRosterEntry(id: $id, role: $role, gameRole: $gameRole, staffRole: $staffRole, kicked: $kicked) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateRosterEntryMutationFn = ApolloReactCommon.MutationFunction<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables>;
export type UpdateRosterEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables>, 'mutation'>;

    export const UpdateRosterEntryComponent = (props: UpdateRosterEntryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables> mutation={UpdateRosterEntryDocument} {...props} />
    );
    
export type UpdateRosterEntryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables> & TChildProps;
export function withUpdateRosterEntry<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRosterEntryMutation,
  UpdateRosterEntryMutationVariables,
  UpdateRosterEntryProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables, UpdateRosterEntryProps<TChildProps>>(UpdateRosterEntryDocument, {
      alias: 'updateRosterEntry',
      ...operationOptions
    });
};

/**
 * __useUpdateRosterEntryMutation__
 *
 * To run a mutation, you first call `useUpdateRosterEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRosterEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRosterEntryMutation, { data, loading, error }] = useUpdateRosterEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      gameRole: // value for 'gameRole'
 *      staffRole: // value for 'staffRole'
 *      kicked: // value for 'kicked'
 *   },
 * });
 */
export function useUpdateRosterEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables>(UpdateRosterEntryDocument, baseOptions);
      }
export type UpdateRosterEntryMutationHookResult = ReturnType<typeof useUpdateRosterEntryMutation>;
export type UpdateRosterEntryMutationResult = ApolloReactCommon.MutationResult<UpdateRosterEntryMutation>;
export type UpdateRosterEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRosterEntryMutation, UpdateRosterEntryMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation updateTeam($id: ID!, $name: String, $pendingLogo: String, $approvedLogo: String, $pendingBanner: String, $approvedBanner: String, $facebookUsername: String, $twitterUsername: String, $twitchUsername: String) {
  upsertTeam(id: $id, name: $name, approvedLogo: $approvedLogo, approvedBanner: $approvedBanner, pendingLogo: $pendingLogo, pendingBanner: $pendingBanner, facebookUsername: $facebookUsername, twitterUsername: $twitterUsername, twitchUsername: $twitchUsername) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateTeamMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;
export type UpdateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTeamMutation, UpdateTeamMutationVariables>, 'mutation'>;

    export const UpdateTeamComponent = (props: UpdateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTeamMutation, UpdateTeamMutationVariables> mutation={UpdateTeamDocument} {...props} />
    );
    
export type UpdateTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateTeamMutation, UpdateTeamMutationVariables> & TChildProps;
export function withUpdateTeam<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTeamMutation,
  UpdateTeamMutationVariables,
  UpdateTeamProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTeamMutation, UpdateTeamMutationVariables, UpdateTeamProps<TChildProps>>(UpdateTeamDocument, {
      alias: 'updateTeam',
      ...operationOptions
    });
};

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      pendingLogo: // value for 'pendingLogo'
 *      approvedLogo: // value for 'approvedLogo'
 *      pendingBanner: // value for 'pendingBanner'
 *      approvedBanner: // value for 'approvedBanner'
 *      facebookUsername: // value for 'facebookUsername'
 *      twitterUsername: // value for 'twitterUsername'
 *      twitchUsername: // value for 'twitchUsername'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, baseOptions);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = ApolloReactCommon.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const GetUniversityAdminDocument = gql`
    query GetUniversityAdmin($id: ID!) {
  university(id: $id) {
    id
    name
    abbreviation
    state
    domains
  }
}
    `;
export type GetUniversityAdminComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUniversityAdminQuery, GetUniversityAdminQueryVariables>, 'query'> & ({ variables: GetUniversityAdminQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUniversityAdminComponent = (props: GetUniversityAdminComponentProps) => (
      <ApolloReactComponents.Query<GetUniversityAdminQuery, GetUniversityAdminQueryVariables> query={GetUniversityAdminDocument} {...props} />
    );
    
export type GetUniversityAdminProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUniversityAdminQuery, GetUniversityAdminQueryVariables> & TChildProps;
export function withGetUniversityAdmin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUniversityAdminQuery,
  GetUniversityAdminQueryVariables,
  GetUniversityAdminProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUniversityAdminQuery, GetUniversityAdminQueryVariables, GetUniversityAdminProps<TChildProps>>(GetUniversityAdminDocument, {
      alias: 'getUniversityAdmin',
      ...operationOptions
    });
};

/**
 * __useGetUniversityAdminQuery__
 *
 * To run a query within a React component, call `useGetUniversityAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniversityAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniversityAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUniversityAdminQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUniversityAdminQuery, GetUniversityAdminQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUniversityAdminQuery, GetUniversityAdminQueryVariables>(GetUniversityAdminDocument, baseOptions);
      }
export function useGetUniversityAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUniversityAdminQuery, GetUniversityAdminQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUniversityAdminQuery, GetUniversityAdminQueryVariables>(GetUniversityAdminDocument, baseOptions);
        }
export type GetUniversityAdminQueryHookResult = ReturnType<typeof useGetUniversityAdminQuery>;
export type GetUniversityAdminLazyQueryHookResult = ReturnType<typeof useGetUniversityAdminLazyQuery>;
export type GetUniversityAdminQueryResult = ApolloReactCommon.QueryResult<GetUniversityAdminQuery, GetUniversityAdminQueryVariables>;
export const MatchDetailPageDocument = gql`
    query matchDetailPage($id: ID!) {
  match(id: $id) {
    ...MatchDetailAttributes
  }
}
    ${MatchDetailAttributesFragmentDoc}`;
export type MatchDetailPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MatchDetailPageQuery, MatchDetailPageQueryVariables>, 'query'> & ({ variables: MatchDetailPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MatchDetailPageComponent = (props: MatchDetailPageComponentProps) => (
      <ApolloReactComponents.Query<MatchDetailPageQuery, MatchDetailPageQueryVariables> query={MatchDetailPageDocument} {...props} />
    );
    
export type MatchDetailPageProps<TChildProps = {}> = ApolloReactHoc.DataProps<MatchDetailPageQuery, MatchDetailPageQueryVariables> & TChildProps;
export function withMatchDetailPage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MatchDetailPageQuery,
  MatchDetailPageQueryVariables,
  MatchDetailPageProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MatchDetailPageQuery, MatchDetailPageQueryVariables, MatchDetailPageProps<TChildProps>>(MatchDetailPageDocument, {
      alias: 'matchDetailPage',
      ...operationOptions
    });
};

/**
 * __useMatchDetailPageQuery__
 *
 * To run a query within a React component, call `useMatchDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMatchDetailPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchDetailPageQuery, MatchDetailPageQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchDetailPageQuery, MatchDetailPageQueryVariables>(MatchDetailPageDocument, baseOptions);
      }
export function useMatchDetailPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchDetailPageQuery, MatchDetailPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchDetailPageQuery, MatchDetailPageQueryVariables>(MatchDetailPageDocument, baseOptions);
        }
export type MatchDetailPageQueryHookResult = ReturnType<typeof useMatchDetailPageQuery>;
export type MatchDetailPageLazyQueryHookResult = ReturnType<typeof useMatchDetailPageLazyQuery>;
export type MatchDetailPageQueryResult = ApolloReactCommon.QueryResult<MatchDetailPageQuery, MatchDetailPageQueryVariables>;
export const GetTeamsWithPendingImagesDocument = gql`
    query getTeamsWithPendingImages {
  teams(withPendingImages: true) {
    id
    name
    coordinator {
      id
      username
    }
    pendingLogoImage {
      name
      dataUrl
    }
    pendingBannerImage {
      name
      dataUrl
    }
  }
}
    `;
export type GetTeamsWithPendingImagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables>, 'query'>;

    export const GetTeamsWithPendingImagesComponent = (props: GetTeamsWithPendingImagesComponentProps) => (
      <ApolloReactComponents.Query<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables> query={GetTeamsWithPendingImagesDocument} {...props} />
    );
    
export type GetTeamsWithPendingImagesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables> & TChildProps;
export function withGetTeamsWithPendingImages<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTeamsWithPendingImagesQuery,
  GetTeamsWithPendingImagesQueryVariables,
  GetTeamsWithPendingImagesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables, GetTeamsWithPendingImagesProps<TChildProps>>(GetTeamsWithPendingImagesDocument, {
      alias: 'getTeamsWithPendingImages',
      ...operationOptions
    });
};

/**
 * __useGetTeamsWithPendingImagesQuery__
 *
 * To run a query within a React component, call `useGetTeamsWithPendingImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsWithPendingImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsWithPendingImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamsWithPendingImagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables>(GetTeamsWithPendingImagesDocument, baseOptions);
      }
export function useGetTeamsWithPendingImagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables>(GetTeamsWithPendingImagesDocument, baseOptions);
        }
export type GetTeamsWithPendingImagesQueryHookResult = ReturnType<typeof useGetTeamsWithPendingImagesQuery>;
export type GetTeamsWithPendingImagesLazyQueryHookResult = ReturnType<typeof useGetTeamsWithPendingImagesLazyQuery>;
export type GetTeamsWithPendingImagesQueryResult = ApolloReactCommon.QueryResult<GetTeamsWithPendingImagesQuery, GetTeamsWithPendingImagesQueryVariables>;
export const ApproveTeamImagesDocument = gql`
    mutation approveTeamImages($id: ID!, $approveBanner: Boolean, $approveLogo: Boolean) {
  approveTeamImages(id: $id, approveBanner: $approveBanner, approveLogo: $approveLogo) {
    success
    value {
      id
    }
    errors {
      field
      message
    }
  }
}
    `;
export type ApproveTeamImagesMutationFn = ApolloReactCommon.MutationFunction<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables>;
export type ApproveTeamImagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables>, 'mutation'>;

    export const ApproveTeamImagesComponent = (props: ApproveTeamImagesComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables> mutation={ApproveTeamImagesDocument} {...props} />
    );
    
export type ApproveTeamImagesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables> & TChildProps;
export function withApproveTeamImages<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApproveTeamImagesMutation,
  ApproveTeamImagesMutationVariables,
  ApproveTeamImagesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables, ApproveTeamImagesProps<TChildProps>>(ApproveTeamImagesDocument, {
      alias: 'approveTeamImages',
      ...operationOptions
    });
};

/**
 * __useApproveTeamImagesMutation__
 *
 * To run a mutation, you first call `useApproveTeamImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTeamImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTeamImagesMutation, { data, loading, error }] = useApproveTeamImagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approveBanner: // value for 'approveBanner'
 *      approveLogo: // value for 'approveLogo'
 *   },
 * });
 */
export function useApproveTeamImagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables>(ApproveTeamImagesDocument, baseOptions);
      }
export type ApproveTeamImagesMutationHookResult = ReturnType<typeof useApproveTeamImagesMutation>;
export type ApproveTeamImagesMutationResult = ApolloReactCommon.MutationResult<ApproveTeamImagesMutation>;
export type ApproveTeamImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveTeamImagesMutation, ApproveTeamImagesMutationVariables>;
export const MyUniversityDetailPageDocument = gql`
    query myUniversityDetailPage($selectedYear: String) {
  myUniversity(selectedYear: $selectedYear) {
    ...UniversityHubQuery
  }
}
    ${UniversityHubQueryFragmentDoc}`;
export type MyUniversityDetailPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables>, 'query'>;

    export const MyUniversityDetailPageComponent = (props: MyUniversityDetailPageComponentProps) => (
      <ApolloReactComponents.Query<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables> query={MyUniversityDetailPageDocument} {...props} />
    );
    
export type MyUniversityDetailPageProps<TChildProps = {}> = ApolloReactHoc.DataProps<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables> & TChildProps;
export function withMyUniversityDetailPage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MyUniversityDetailPageQuery,
  MyUniversityDetailPageQueryVariables,
  MyUniversityDetailPageProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables, MyUniversityDetailPageProps<TChildProps>>(MyUniversityDetailPageDocument, {
      alias: 'myUniversityDetailPage',
      ...operationOptions
    });
};

/**
 * __useMyUniversityDetailPageQuery__
 *
 * To run a query within a React component, call `useMyUniversityDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUniversityDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUniversityDetailPageQuery({
 *   variables: {
 *      selectedYear: // value for 'selectedYear'
 *   },
 * });
 */
export function useMyUniversityDetailPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables>) {
        return ApolloReactHooks.useQuery<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables>(MyUniversityDetailPageDocument, baseOptions);
      }
export function useMyUniversityDetailPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables>(MyUniversityDetailPageDocument, baseOptions);
        }
export type MyUniversityDetailPageQueryHookResult = ReturnType<typeof useMyUniversityDetailPageQuery>;
export type MyUniversityDetailPageLazyQueryHookResult = ReturnType<typeof useMyUniversityDetailPageLazyQuery>;
export type MyUniversityDetailPageQueryResult = ApolloReactCommon.QueryResult<MyUniversityDetailPageQuery, MyUniversityDetailPageQueryVariables>;
export const NewsOrAnnouncementsDocument = gql`
    query NewsOrAnnouncements($page: Int!, $perPage: Int!, $type: NewsArticleTypes!, $sort: NewsArticlesSortOrders, $sports: [SportSlugs!], $categories: [PostCategories!]) {
  newsOrAnnouncements(page: $page, perPage: $perPage, type: $type, sort: $sort, sports: $sports, categories: $categories) {
    success
    value {
      ...NewsAndAnnouncements_Paginated
    }
    success
    errors {
      field
      message
    }
  }
}
    ${NewsAndAnnouncements_PaginatedFragmentDoc}`;
export type NewsOrAnnouncementsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables>, 'query'> & ({ variables: NewsOrAnnouncementsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NewsOrAnnouncementsComponent = (props: NewsOrAnnouncementsComponentProps) => (
      <ApolloReactComponents.Query<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables> query={NewsOrAnnouncementsDocument} {...props} />
    );
    
export type NewsOrAnnouncementsProps<TChildProps = {}> = ApolloReactHoc.DataProps<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables> & TChildProps;
export function withNewsOrAnnouncements<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsOrAnnouncementsQuery,
  NewsOrAnnouncementsQueryVariables,
  NewsOrAnnouncementsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables, NewsOrAnnouncementsProps<TChildProps>>(NewsOrAnnouncementsDocument, {
      alias: 'newsOrAnnouncements',
      ...operationOptions
    });
};

/**
 * __useNewsOrAnnouncementsQuery__
 *
 * To run a query within a React component, call `useNewsOrAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsOrAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsOrAnnouncementsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      type: // value for 'type'
 *      sort: // value for 'sort'
 *      sports: // value for 'sports'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useNewsOrAnnouncementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables>(NewsOrAnnouncementsDocument, baseOptions);
      }
export function useNewsOrAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables>(NewsOrAnnouncementsDocument, baseOptions);
        }
export type NewsOrAnnouncementsQueryHookResult = ReturnType<typeof useNewsOrAnnouncementsQuery>;
export type NewsOrAnnouncementsLazyQueryHookResult = ReturnType<typeof useNewsOrAnnouncementsLazyQuery>;
export type NewsOrAnnouncementsQueryResult = ApolloReactCommon.QueryResult<NewsOrAnnouncementsQuery, NewsOrAnnouncementsQueryVariables>;
export const UpsertNewsOrAnnouncementDocument = gql`
    mutation UpsertNewsOrAnnouncement($type: NewsArticleTypes!, $title: String!, $body: String!, $summary: String!, $category: PostCategories, $sport: SportSlugs, $featuredImage: String, $id: ID, $featured: Boolean, $position: Int, $published: Boolean) {
  upsertNewsOrAnnouncement(type: $type, category: $category, title: $title, body: $body, summary: $summary, sport: $sport, featuredImage: $featuredImage, id: $id, featured: $featured, position: $position, published: $published) {
    success
    value {
      id
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpsertNewsOrAnnouncementMutationFn = ApolloReactCommon.MutationFunction<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables>;
export type UpsertNewsOrAnnouncementComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables>, 'mutation'>;

    export const UpsertNewsOrAnnouncementComponent = (props: UpsertNewsOrAnnouncementComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables> mutation={UpsertNewsOrAnnouncementDocument} {...props} />
    );
    
export type UpsertNewsOrAnnouncementProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables> & TChildProps;
export function withUpsertNewsOrAnnouncement<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertNewsOrAnnouncementMutation,
  UpsertNewsOrAnnouncementMutationVariables,
  UpsertNewsOrAnnouncementProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables, UpsertNewsOrAnnouncementProps<TChildProps>>(UpsertNewsOrAnnouncementDocument, {
      alias: 'upsertNewsOrAnnouncement',
      ...operationOptions
    });
};

/**
 * __useUpsertNewsOrAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpsertNewsOrAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNewsOrAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNewsOrAnnouncementMutation, { data, loading, error }] = useUpsertNewsOrAnnouncementMutation({
 *   variables: {
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      summary: // value for 'summary'
 *      category: // value for 'category'
 *      sport: // value for 'sport'
 *      featuredImage: // value for 'featuredImage'
 *      id: // value for 'id'
 *      featured: // value for 'featured'
 *      position: // value for 'position'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useUpsertNewsOrAnnouncementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables>(UpsertNewsOrAnnouncementDocument, baseOptions);
      }
export type UpsertNewsOrAnnouncementMutationHookResult = ReturnType<typeof useUpsertNewsOrAnnouncementMutation>;
export type UpsertNewsOrAnnouncementMutationResult = ApolloReactCommon.MutationResult<UpsertNewsOrAnnouncementMutation>;
export type UpsertNewsOrAnnouncementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertNewsOrAnnouncementMutation, UpsertNewsOrAnnouncementMutationVariables>;
export const NewsArticleDetailDocument = gql`
    query NewsArticleDetail($slug: String!) {
  newsArticle(slug: $slug) {
    id
    createdAt
    title
    summary
    body
    featuredImage
    featured
    position
    type
    slug
    newsCategory {
      id
      name
    }
  }
}
    `;
export type NewsArticleDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsArticleDetailQuery, NewsArticleDetailQueryVariables>, 'query'> & ({ variables: NewsArticleDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NewsArticleDetailComponent = (props: NewsArticleDetailComponentProps) => (
      <ApolloReactComponents.Query<NewsArticleDetailQuery, NewsArticleDetailQueryVariables> query={NewsArticleDetailDocument} {...props} />
    );
    
export type NewsArticleDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<NewsArticleDetailQuery, NewsArticleDetailQueryVariables> & TChildProps;
export function withNewsArticleDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsArticleDetailQuery,
  NewsArticleDetailQueryVariables,
  NewsArticleDetailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NewsArticleDetailQuery, NewsArticleDetailQueryVariables, NewsArticleDetailProps<TChildProps>>(NewsArticleDetailDocument, {
      alias: 'newsArticleDetail',
      ...operationOptions
    });
};

/**
 * __useNewsArticleDetailQuery__
 *
 * To run a query within a React component, call `useNewsArticleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsArticleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsArticleDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useNewsArticleDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsArticleDetailQuery, NewsArticleDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsArticleDetailQuery, NewsArticleDetailQueryVariables>(NewsArticleDetailDocument, baseOptions);
      }
export function useNewsArticleDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsArticleDetailQuery, NewsArticleDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsArticleDetailQuery, NewsArticleDetailQueryVariables>(NewsArticleDetailDocument, baseOptions);
        }
export type NewsArticleDetailQueryHookResult = ReturnType<typeof useNewsArticleDetailQuery>;
export type NewsArticleDetailLazyQueryHookResult = ReturnType<typeof useNewsArticleDetailLazyQuery>;
export type NewsArticleDetailQueryResult = ApolloReactCommon.QueryResult<NewsArticleDetailQuery, NewsArticleDetailQueryVariables>;
export const PlayerProfileDocument = gql`
    query playerProfile($id: ID!) {
  user(id: $id) {
    id
    name
    username
    university {
      id
      name
    }
    discordId
    discordUsername
    facebookUsername
    twitterUsername
    twitchUsername
    lolSummonerTier
    lolChampionName
    lolRiotImageName
    lolSummonerName
    lolAverageStats {
      id
      kills
      deaths
      assists
      totalDamageDealtToChampions
      goldPerMin
      creepsPerMin
    }
    dota2HeroImageName
    dota2SteamId
    sc2PreferredRace
    hsPreferredClass
    vgPreferredHero
    nhlPreferredTeam
    owPreferredHero
    maddenPreferredTeam
    nbaPreferredTeam
    sfvPreferredFighter
    sfvFid
    mtgaPreferredColor
    csgoEseaProfileUrl
    csgoSteamId
    rlSteamId
    valorantRiotAccount
    valorantPreferredChampion
    playedSports {
      slug
    }
    activeRosterEntries {
      id
      inGameName
      gameRole
      player {
        id
        selectedSeasonStats {
          id
          wins
          losses
        }
      }
      team {
        id
        name
        createdAt
        sportSlug
        seasonYear
        teamAwards {
          id
        }
        selectedSeason {
          id
          divisionName
        }
        selectedSeasonConferenceName
        division
        selectedSeasonRank
        selectedSeasonStats {
          id
          wins
          losses
        }
        selectedSeasonMatches {
          id
          scheduledDate
          settled
          homeTeam {
            id
            name
          }
          awayTeam {
            id
            name
          }
          games {
            id
            winner {
              id
              team {
                id
              }
            }
          }
        }
      }
    }
    upcomingMatches {
      ...MatchCarouselMatchInfo
    }
    tournamentsToCheckinTo {
      ...MatchCarouselTournamentCheckinInfo
    }
    unreportedMatches {
      ...MatchCarouselMatchInfo
    }
    ...PlayerProfileMatchHistoryQuery
  }
}
    ${MatchCarouselMatchInfoFragmentDoc}
${MatchCarouselTournamentCheckinInfoFragmentDoc}
${PlayerProfileMatchHistoryQueryFragmentDoc}`;
export type PlayerProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlayerProfileQuery, PlayerProfileQueryVariables>, 'query'> & ({ variables: PlayerProfileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlayerProfileComponent = (props: PlayerProfileComponentProps) => (
      <ApolloReactComponents.Query<PlayerProfileQuery, PlayerProfileQueryVariables> query={PlayerProfileDocument} {...props} />
    );
    
export type PlayerProfileProps<TChildProps = {}> = ApolloReactHoc.DataProps<PlayerProfileQuery, PlayerProfileQueryVariables> & TChildProps;
export function withPlayerProfile<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PlayerProfileQuery,
  PlayerProfileQueryVariables,
  PlayerProfileProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PlayerProfileQuery, PlayerProfileQueryVariables, PlayerProfileProps<TChildProps>>(PlayerProfileDocument, {
      alias: 'playerProfile',
      ...operationOptions
    });
};

/**
 * __usePlayerProfileQuery__
 *
 * To run a query within a React component, call `usePlayerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlayerProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlayerProfileQuery, PlayerProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<PlayerProfileQuery, PlayerProfileQueryVariables>(PlayerProfileDocument, baseOptions);
      }
export function usePlayerProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlayerProfileQuery, PlayerProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlayerProfileQuery, PlayerProfileQueryVariables>(PlayerProfileDocument, baseOptions);
        }
export type PlayerProfileQueryHookResult = ReturnType<typeof usePlayerProfileQuery>;
export type PlayerProfileLazyQueryHookResult = ReturnType<typeof usePlayerProfileLazyQuery>;
export type PlayerProfileQueryResult = ApolloReactCommon.QueryResult<PlayerProfileQuery, PlayerProfileQueryVariables>;
export const UpdateUserAboutFieldsDocument = gql`
    mutation UpdateUserAboutFields($id: ID, $name: String, $email: String, $universityId: ID, $universityMajorId: ID, $graduationYear: Int, $discordUsername: String, $gender: String, $isConfirmed: Boolean) {
  updateUser(id: $id, name: $name, email: $email, universityId: $universityId, universityMajorId: $universityMajorId, graduationYear: $graduationYear, discordUsername: $discordUsername, gender: $gender, isConfirmed: $isConfirmed) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateUserAboutFieldsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables>;
export type UpdateUserAboutFieldsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables>, 'mutation'>;

    export const UpdateUserAboutFieldsComponent = (props: UpdateUserAboutFieldsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables> mutation={UpdateUserAboutFieldsDocument} {...props} />
    );
    
export type UpdateUserAboutFieldsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables> & TChildProps;
export function withUpdateUserAboutFields<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserAboutFieldsMutation,
  UpdateUserAboutFieldsMutationVariables,
  UpdateUserAboutFieldsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables, UpdateUserAboutFieldsProps<TChildProps>>(UpdateUserAboutFieldsDocument, {
      alias: 'updateUserAboutFields',
      ...operationOptions
    });
};

/**
 * __useUpdateUserAboutFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateUserAboutFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAboutFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAboutFieldsMutation, { data, loading, error }] = useUpdateUserAboutFieldsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      universityId: // value for 'universityId'
 *      universityMajorId: // value for 'universityMajorId'
 *      graduationYear: // value for 'graduationYear'
 *      discordUsername: // value for 'discordUsername'
 *      gender: // value for 'gender'
 *      isConfirmed: // value for 'isConfirmed'
 *   },
 * });
 */
export function useUpdateUserAboutFieldsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables>(UpdateUserAboutFieldsDocument, baseOptions);
      }
export type UpdateUserAboutFieldsMutationHookResult = ReturnType<typeof useUpdateUserAboutFieldsMutation>;
export type UpdateUserAboutFieldsMutationResult = ApolloReactCommon.MutationResult<UpdateUserAboutFieldsMutation>;
export type UpdateUserAboutFieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserAboutFieldsMutation, UpdateUserAboutFieldsMutationVariables>;
export const GetCurrentUserTeamInformationDocument = gql`
    query getCurrentUserTeamInformation($id: ID) {
  userSettings(id: $id) {
    university {
      id
    }
    pendingTeamInvitations {
      id
      gameRole
      playerPlaysSport
      team {
        id
        name
        sportSlug
        overallWins
        overallLosses
      }
    }
    pendingApplications {
      id
      gameRole
      team {
        id
        name
        sportSlug
        overallWins
        overallLosses
      }
    }
    activeRosterEntries {
      id
      gameRole
      team {
        id
        name
        sportSlug
        overallWins
        overallLosses
        currentUserIsCoordinator
      }
    }
  }
}
    `;
export type GetCurrentUserTeamInformationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables>, 'query'>;

    export const GetCurrentUserTeamInformationComponent = (props: GetCurrentUserTeamInformationComponentProps) => (
      <ApolloReactComponents.Query<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables> query={GetCurrentUserTeamInformationDocument} {...props} />
    );
    
export type GetCurrentUserTeamInformationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables> & TChildProps;
export function withGetCurrentUserTeamInformation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCurrentUserTeamInformationQuery,
  GetCurrentUserTeamInformationQueryVariables,
  GetCurrentUserTeamInformationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables, GetCurrentUserTeamInformationProps<TChildProps>>(GetCurrentUserTeamInformationDocument, {
      alias: 'getCurrentUserTeamInformation',
      ...operationOptions
    });
};

/**
 * __useGetCurrentUserTeamInformationQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserTeamInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserTeamInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserTeamInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentUserTeamInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables>(GetCurrentUserTeamInformationDocument, baseOptions);
      }
export function useGetCurrentUserTeamInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables>(GetCurrentUserTeamInformationDocument, baseOptions);
        }
export type GetCurrentUserTeamInformationQueryHookResult = ReturnType<typeof useGetCurrentUserTeamInformationQuery>;
export type GetCurrentUserTeamInformationLazyQueryHookResult = ReturnType<typeof useGetCurrentUserTeamInformationLazyQuery>;
export type GetCurrentUserTeamInformationQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserTeamInformationQuery, GetCurrentUserTeamInformationQueryVariables>;
export const CreateRosterEntryDocument = gql`
    mutation createRosterEntry($teamPlayerInviteId: ID!) {
  createRosterEntry(teamPlayerInviteId: $teamPlayerInviteId) {
    errors {
      field
      message
    }
  }
}
    `;
export type CreateRosterEntryMutationFn = ApolloReactCommon.MutationFunction<CreateRosterEntryMutation, CreateRosterEntryMutationVariables>;
export type CreateRosterEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRosterEntryMutation, CreateRosterEntryMutationVariables>, 'mutation'>;

    export const CreateRosterEntryComponent = (props: CreateRosterEntryComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRosterEntryMutation, CreateRosterEntryMutationVariables> mutation={CreateRosterEntryDocument} {...props} />
    );
    
export type CreateRosterEntryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateRosterEntryMutation, CreateRosterEntryMutationVariables> & TChildProps;
export function withCreateRosterEntry<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRosterEntryMutation,
  CreateRosterEntryMutationVariables,
  CreateRosterEntryProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRosterEntryMutation, CreateRosterEntryMutationVariables, CreateRosterEntryProps<TChildProps>>(CreateRosterEntryDocument, {
      alias: 'createRosterEntry',
      ...operationOptions
    });
};

/**
 * __useCreateRosterEntryMutation__
 *
 * To run a mutation, you first call `useCreateRosterEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRosterEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRosterEntryMutation, { data, loading, error }] = useCreateRosterEntryMutation({
 *   variables: {
 *      teamPlayerInviteId: // value for 'teamPlayerInviteId'
 *   },
 * });
 */
export function useCreateRosterEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRosterEntryMutation, CreateRosterEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRosterEntryMutation, CreateRosterEntryMutationVariables>(CreateRosterEntryDocument, baseOptions);
      }
export type CreateRosterEntryMutationHookResult = ReturnType<typeof useCreateRosterEntryMutation>;
export type CreateRosterEntryMutationResult = ApolloReactCommon.MutationResult<CreateRosterEntryMutation>;
export type CreateRosterEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRosterEntryMutation, CreateRosterEntryMutationVariables>;
export const DeleteTeamApplicationDocument = gql`
    mutation deleteTeamApplication($id: ID!) {
  deleteTeamApplication(id: $id) {
    errors {
      field
      message
    }
  }
}
    `;
export type DeleteTeamApplicationMutationFn = ApolloReactCommon.MutationFunction<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables>;
export type DeleteTeamApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables>, 'mutation'>;

    export const DeleteTeamApplicationComponent = (props: DeleteTeamApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables> mutation={DeleteTeamApplicationDocument} {...props} />
    );
    
export type DeleteTeamApplicationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables> & TChildProps;
export function withDeleteTeamApplication<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTeamApplicationMutation,
  DeleteTeamApplicationMutationVariables,
  DeleteTeamApplicationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables, DeleteTeamApplicationProps<TChildProps>>(DeleteTeamApplicationDocument, {
      alias: 'deleteTeamApplication',
      ...operationOptions
    });
};

/**
 * __useDeleteTeamApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteTeamApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamApplicationMutation, { data, loading, error }] = useDeleteTeamApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables>(DeleteTeamApplicationDocument, baseOptions);
      }
export type DeleteTeamApplicationMutationHookResult = ReturnType<typeof useDeleteTeamApplicationMutation>;
export type DeleteTeamApplicationMutationResult = ApolloReactCommon.MutationResult<DeleteTeamApplicationMutation>;
export type DeleteTeamApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTeamApplicationMutation, DeleteTeamApplicationMutationVariables>;
export const TournamentDetailDocument = gql`
    query TournamentDetail($id: ID!) {
  tournament(id: $id) {
    id
    name
    style
    sport
    numberOfTeams
    firstMatchDate
    description
    address
    banner
    sponsorImage
    registrationOpenDate
    registrationCloseDate
    checkinLength
    currentUserNumberOfEligibleTeams
    brackets {
      id
    }
    containerId
    checkins {
      teamId
    }
    activeRosterEntries {
      id
      gameRole
      inGameName
      player {
        id
        username
        discordId
      }
    }
    playerStats {
      id
      wins
      losses
      player {
        id
      }
    }
    teamStats {
      id
      wins
      losses
      team {
        id
      }
    }
    teamSeeds {
      teamId
      seed
    }
    teams {
      id
      name
      currentUserOnTeam
      coordinator {
        id
        username
      }
      university {
        id
        abbreviation
      }
      truncatedName
    }
    grandFinals {
      seat
    }
    bracketData {
      team1Id
      team1Wins
      settled
      matchId
      team2Id
      team2Wins
      seat
      scheduledDate
    }
    seatTree {
      seat
      winnerTo
      loserTo
    }
  }
}
    `;
export type TournamentDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TournamentDetailQuery, TournamentDetailQueryVariables>, 'query'> & ({ variables: TournamentDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TournamentDetailComponent = (props: TournamentDetailComponentProps) => (
      <ApolloReactComponents.Query<TournamentDetailQuery, TournamentDetailQueryVariables> query={TournamentDetailDocument} {...props} />
    );
    
export type TournamentDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<TournamentDetailQuery, TournamentDetailQueryVariables> & TChildProps;
export function withTournamentDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TournamentDetailQuery,
  TournamentDetailQueryVariables,
  TournamentDetailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TournamentDetailQuery, TournamentDetailQueryVariables, TournamentDetailProps<TChildProps>>(TournamentDetailDocument, {
      alias: 'tournamentDetail',
      ...operationOptions
    });
};

/**
 * __useTournamentDetailQuery__
 *
 * To run a query within a React component, call `useTournamentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentDetailQuery, TournamentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<TournamentDetailQuery, TournamentDetailQueryVariables>(TournamentDetailDocument, baseOptions);
      }
export function useTournamentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentDetailQuery, TournamentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TournamentDetailQuery, TournamentDetailQueryVariables>(TournamentDetailDocument, baseOptions);
        }
export type TournamentDetailQueryHookResult = ReturnType<typeof useTournamentDetailQuery>;
export type TournamentDetailLazyQueryHookResult = ReturnType<typeof useTournamentDetailLazyQuery>;
export type TournamentDetailQueryResult = ApolloReactCommon.QueryResult<TournamentDetailQuery, TournamentDetailQueryVariables>;
export const PlayoffsListDocument = gql`
    query PlayoffsList($featuredTournamentId: ID!) {
  playedSports {
    slug
  }
  tournament(id: $featuredTournamentId) {
    id
    name
    sport
    firstMatchDate
    numberOfTeams
    style
    registrationCloseDate
    registrationOpenDate
  }
}
    `;
export type PlayoffsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlayoffsListQuery, PlayoffsListQueryVariables>, 'query'> & ({ variables: PlayoffsListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlayoffsListComponent = (props: PlayoffsListComponentProps) => (
      <ApolloReactComponents.Query<PlayoffsListQuery, PlayoffsListQueryVariables> query={PlayoffsListDocument} {...props} />
    );
    
export type PlayoffsListProps<TChildProps = {}> = ApolloReactHoc.DataProps<PlayoffsListQuery, PlayoffsListQueryVariables> & TChildProps;
export function withPlayoffsList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PlayoffsListQuery,
  PlayoffsListQueryVariables,
  PlayoffsListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PlayoffsListQuery, PlayoffsListQueryVariables, PlayoffsListProps<TChildProps>>(PlayoffsListDocument, {
      alias: 'playoffsList',
      ...operationOptions
    });
};

/**
 * __usePlayoffsListQuery__
 *
 * To run a query within a React component, call `usePlayoffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayoffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayoffsListQuery({
 *   variables: {
 *      featuredTournamentId: // value for 'featuredTournamentId'
 *   },
 * });
 */
export function usePlayoffsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlayoffsListQuery, PlayoffsListQueryVariables>) {
        return ApolloReactHooks.useQuery<PlayoffsListQuery, PlayoffsListQueryVariables>(PlayoffsListDocument, baseOptions);
      }
export function usePlayoffsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlayoffsListQuery, PlayoffsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlayoffsListQuery, PlayoffsListQueryVariables>(PlayoffsListDocument, baseOptions);
        }
export type PlayoffsListQueryHookResult = ReturnType<typeof usePlayoffsListQuery>;
export type PlayoffsListLazyQueryHookResult = ReturnType<typeof usePlayoffsListLazyQuery>;
export type PlayoffsListQueryResult = ApolloReactCommon.QueryResult<PlayoffsListQuery, PlayoffsListQueryVariables>;
export const TournamentsDocument = gql`
    query Tournaments($sports: [String!], $state: String!, $first: Int, $after: String) {
  tournamentsConnection(sports: $sports, state: $state, first: $first, after: $after) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        sport
        banner
        firstMatchDate
        numberOfTeams
        style
        registrationCloseDate
        registrationOpenDate
        decided
        platform {
          id
          title
        }
      }
    }
  }
}
    `;
export type TournamentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TournamentsQuery, TournamentsQueryVariables>, 'query'> & ({ variables: TournamentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TournamentsComponent = (props: TournamentsComponentProps) => (
      <ApolloReactComponents.Query<TournamentsQuery, TournamentsQueryVariables> query={TournamentsDocument} {...props} />
    );
    
export type TournamentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<TournamentsQuery, TournamentsQueryVariables> & TChildProps;
export function withTournaments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TournamentsQuery,
  TournamentsQueryVariables,
  TournamentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TournamentsQuery, TournamentsQueryVariables, TournamentsProps<TChildProps>>(TournamentsDocument, {
      alias: 'tournaments',
      ...operationOptions
    });
};

/**
 * __useTournamentsQuery__
 *
 * To run a query within a React component, call `useTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentsQuery({
 *   variables: {
 *      sports: // value for 'sports'
 *      state: // value for 'state'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTournamentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TournamentsQuery, TournamentsQueryVariables>) {
        return ApolloReactHooks.useQuery<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument, baseOptions);
      }
export function useTournamentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentsQuery, TournamentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument, baseOptions);
        }
export type TournamentsQueryHookResult = ReturnType<typeof useTournamentsQuery>;
export type TournamentsLazyQueryHookResult = ReturnType<typeof useTournamentsLazyQuery>;
export type TournamentsQueryResult = ApolloReactCommon.QueryResult<TournamentsQuery, TournamentsQueryVariables>;
export const GetUniversitiesDocument = gql`
    query getUniversities {
  universities {
    id
    name
    state
  }
}
    `;
export type GetUniversitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUniversitiesQuery, GetUniversitiesQueryVariables>, 'query'>;

    export const GetUniversitiesComponent = (props: GetUniversitiesComponentProps) => (
      <ApolloReactComponents.Query<GetUniversitiesQuery, GetUniversitiesQueryVariables> query={GetUniversitiesDocument} {...props} />
    );
    
export type GetUniversitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUniversitiesQuery, GetUniversitiesQueryVariables> & TChildProps;
export function withGetUniversities<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUniversitiesQuery,
  GetUniversitiesQueryVariables,
  GetUniversitiesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUniversitiesQuery, GetUniversitiesQueryVariables, GetUniversitiesProps<TChildProps>>(GetUniversitiesDocument, {
      alias: 'getUniversities',
      ...operationOptions
    });
};

/**
 * __useGetUniversitiesQuery__
 *
 * To run a query within a React component, call `useGetUniversitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniversitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniversitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniversitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUniversitiesQuery, GetUniversitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUniversitiesQuery, GetUniversitiesQueryVariables>(GetUniversitiesDocument, baseOptions);
      }
export function useGetUniversitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUniversitiesQuery, GetUniversitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUniversitiesQuery, GetUniversitiesQueryVariables>(GetUniversitiesDocument, baseOptions);
        }
export type GetUniversitiesQueryHookResult = ReturnType<typeof useGetUniversitiesQuery>;
export type GetUniversitiesLazyQueryHookResult = ReturnType<typeof useGetUniversitiesLazyQuery>;
export type GetUniversitiesQueryResult = ApolloReactCommon.QueryResult<GetUniversitiesQuery, GetUniversitiesQueryVariables>;
export const UniversityDetailPageDocument = gql`
    query universityDetailPage($id: ID!, $selectedYear: String) {
  university(id: $id, selectedYear: $selectedYear) {
    ...UniversityHubQuery
  }
}
    ${UniversityHubQueryFragmentDoc}`;
export type UniversityDetailPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UniversityDetailPageQuery, UniversityDetailPageQueryVariables>, 'query'> & ({ variables: UniversityDetailPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UniversityDetailPageComponent = (props: UniversityDetailPageComponentProps) => (
      <ApolloReactComponents.Query<UniversityDetailPageQuery, UniversityDetailPageQueryVariables> query={UniversityDetailPageDocument} {...props} />
    );
    
export type UniversityDetailPageProps<TChildProps = {}> = ApolloReactHoc.DataProps<UniversityDetailPageQuery, UniversityDetailPageQueryVariables> & TChildProps;
export function withUniversityDetailPage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UniversityDetailPageQuery,
  UniversityDetailPageQueryVariables,
  UniversityDetailPageProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UniversityDetailPageQuery, UniversityDetailPageQueryVariables, UniversityDetailPageProps<TChildProps>>(UniversityDetailPageDocument, {
      alias: 'universityDetailPage',
      ...operationOptions
    });
};

/**
 * __useUniversityDetailPageQuery__
 *
 * To run a query within a React component, call `useUniversityDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniversityDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniversityDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      selectedYear: // value for 'selectedYear'
 *   },
 * });
 */
export function useUniversityDetailPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UniversityDetailPageQuery, UniversityDetailPageQueryVariables>) {
        return ApolloReactHooks.useQuery<UniversityDetailPageQuery, UniversityDetailPageQueryVariables>(UniversityDetailPageDocument, baseOptions);
      }
export function useUniversityDetailPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniversityDetailPageQuery, UniversityDetailPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UniversityDetailPageQuery, UniversityDetailPageQueryVariables>(UniversityDetailPageDocument, baseOptions);
        }
export type UniversityDetailPageQueryHookResult = ReturnType<typeof useUniversityDetailPageQuery>;
export type UniversityDetailPageLazyQueryHookResult = ReturnType<typeof useUniversityDetailPageLazyQuery>;
export type UniversityDetailPageQueryResult = ApolloReactCommon.QueryResult<UniversityDetailPageQuery, UniversityDetailPageQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  userDashboard {
    id
    activeTeams {
      ...MyTeamInfo
    }
    upcomingMatches {
      ...MatchCarouselMatchInfo
    }
    tournamentsToCheckinTo {
      ...MatchCarouselTournamentCheckinInfo
    }
    unreportedMatches {
      ...MatchCarouselMatchInfo
    }
    dashboardMatchHistory {
      ...DashboardMatchHistoryInfo
    }
  }
}
    ${MyTeamInfoFragmentDoc}
${MatchCarouselMatchInfoFragmentDoc}
${MatchCarouselTournamentCheckinInfoFragmentDoc}
${DashboardMatchHistoryInfoFragmentDoc}`;
export type DashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DashboardQuery, DashboardQueryVariables>, 'query'>;

    export const DashboardComponent = (props: DashboardComponentProps) => (
      <ApolloReactComponents.Query<DashboardQuery, DashboardQueryVariables> query={DashboardDocument} {...props} />
    );
    
export type DashboardProps<TChildProps = {}> = ApolloReactHoc.DataProps<DashboardQuery, DashboardQueryVariables> & TChildProps;
export function withDashboard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DashboardQuery,
  DashboardQueryVariables,
  DashboardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, DashboardQuery, DashboardQueryVariables, DashboardProps<TChildProps>>(DashboardDocument, {
      alias: 'dashboard',
      ...operationOptions
    });
};

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = ApolloReactCommon.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const UserSearchDocument = gql`
    query userSearch($email: String!) {
  userSearch(email: $email) {
    ...User_Search
  }
}
    ${User_SearchFragmentDoc}`;
export type UserSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserSearchQuery, UserSearchQueryVariables>, 'query'> & ({ variables: UserSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserSearchComponent = (props: UserSearchComponentProps) => (
      <ApolloReactComponents.Query<UserSearchQuery, UserSearchQueryVariables> query={UserSearchDocument} {...props} />
    );
    
export type UserSearchProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserSearchQuery, UserSearchQueryVariables> & TChildProps;
export function withUserSearch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserSearchQuery,
  UserSearchQueryVariables,
  UserSearchProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UserSearchQuery, UserSearchQueryVariables, UserSearchProps<TChildProps>>(UserSearchDocument, {
      alias: 'userSearch',
      ...operationOptions
    });
};

/**
 * __useUserSearchQuery__
 *
 * To run a query within a React component, call `useUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSearchQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSearchQuery, UserSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, baseOptions);
      }
export function useUserSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSearchQuery, UserSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, baseOptions);
        }
export type UserSearchQueryHookResult = ReturnType<typeof useUserSearchQuery>;
export type UserSearchLazyQueryHookResult = ReturnType<typeof useUserSearchLazyQuery>;
export type UserSearchQueryResult = ApolloReactCommon.QueryResult<UserSearchQuery, UserSearchQueryVariables>;
export const ConstantsDocument = gql`
    query Constants {
  constants {
    sportSlugs
    postCategories
    teamStaffRoles
    currentSeasonYear
  }
}
    `;
export type ConstantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConstantsQuery, ConstantsQueryVariables>, 'query'>;

    export const ConstantsComponent = (props: ConstantsComponentProps) => (
      <ApolloReactComponents.Query<ConstantsQuery, ConstantsQueryVariables> query={ConstantsDocument} {...props} />
    );
    
export type ConstantsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ConstantsQuery, ConstantsQueryVariables> & TChildProps;
export function withConstants<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConstantsQuery,
  ConstantsQueryVariables,
  ConstantsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ConstantsQuery, ConstantsQueryVariables, ConstantsProps<TChildProps>>(ConstantsDocument, {
      alias: 'constants',
      ...operationOptions
    });
};

/**
 * __useConstantsQuery__
 *
 * To run a query within a React component, call `useConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConstantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConstantsQuery, ConstantsQueryVariables>) {
        return ApolloReactHooks.useQuery<ConstantsQuery, ConstantsQueryVariables>(ConstantsDocument, baseOptions);
      }
export function useConstantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConstantsQuery, ConstantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConstantsQuery, ConstantsQueryVariables>(ConstantsDocument, baseOptions);
        }
export type ConstantsQueryHookResult = ReturnType<typeof useConstantsQuery>;
export type ConstantsLazyQueryHookResult = ReturnType<typeof useConstantsLazyQuery>;
export type ConstantsQueryResult = ApolloReactCommon.QueryResult<ConstantsQuery, ConstantsQueryVariables>;
export const DivisionsAttrsDocument = gql`
    query DivisionsAttrs($seasonYears: [String!]) {
  divisions(seasonYears: $seasonYears) {
    name
    position
    bannerUrl
    logoUrl
  }
}
    `;
export type DivisionsAttrsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DivisionsAttrsQuery, DivisionsAttrsQueryVariables>, 'query'>;

    export const DivisionsAttrsComponent = (props: DivisionsAttrsComponentProps) => (
      <ApolloReactComponents.Query<DivisionsAttrsQuery, DivisionsAttrsQueryVariables> query={DivisionsAttrsDocument} {...props} />
    );
    
export type DivisionsAttrsProps<TChildProps = {}> = ApolloReactHoc.DataProps<DivisionsAttrsQuery, DivisionsAttrsQueryVariables> & TChildProps;
export function withDivisionsAttrs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DivisionsAttrsQuery,
  DivisionsAttrsQueryVariables,
  DivisionsAttrsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, DivisionsAttrsQuery, DivisionsAttrsQueryVariables, DivisionsAttrsProps<TChildProps>>(DivisionsAttrsDocument, {
      alias: 'divisionsAttrs',
      ...operationOptions
    });
};

/**
 * __useDivisionsAttrsQuery__
 *
 * To run a query within a React component, call `useDivisionsAttrsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivisionsAttrsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivisionsAttrsQuery({
 *   variables: {
 *      seasonYears: // value for 'seasonYears'
 *   },
 * });
 */
export function useDivisionsAttrsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DivisionsAttrsQuery, DivisionsAttrsQueryVariables>) {
        return ApolloReactHooks.useQuery<DivisionsAttrsQuery, DivisionsAttrsQueryVariables>(DivisionsAttrsDocument, baseOptions);
      }
export function useDivisionsAttrsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DivisionsAttrsQuery, DivisionsAttrsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DivisionsAttrsQuery, DivisionsAttrsQueryVariables>(DivisionsAttrsDocument, baseOptions);
        }
export type DivisionsAttrsQueryHookResult = ReturnType<typeof useDivisionsAttrsQuery>;
export type DivisionsAttrsLazyQueryHookResult = ReturnType<typeof useDivisionsAttrsLazyQuery>;
export type DivisionsAttrsQueryResult = ApolloReactCommon.QueryResult<DivisionsAttrsQuery, DivisionsAttrsQueryVariables>;
export const HideMatchMessageDocument = gql`
    mutation hideMatchMessage($id: ID!, $type: String!) {
  hideMessage(id: $id, type: $type) {
    success
    errors {
      message
    }
  }
}
    `;
export type HideMatchMessageMutationFn = ApolloReactCommon.MutationFunction<HideMatchMessageMutation, HideMatchMessageMutationVariables>;
export type HideMatchMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<HideMatchMessageMutation, HideMatchMessageMutationVariables>, 'mutation'>;

    export const HideMatchMessageComponent = (props: HideMatchMessageComponentProps) => (
      <ApolloReactComponents.Mutation<HideMatchMessageMutation, HideMatchMessageMutationVariables> mutation={HideMatchMessageDocument} {...props} />
    );
    
export type HideMatchMessageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<HideMatchMessageMutation, HideMatchMessageMutationVariables> & TChildProps;
export function withHideMatchMessage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HideMatchMessageMutation,
  HideMatchMessageMutationVariables,
  HideMatchMessageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, HideMatchMessageMutation, HideMatchMessageMutationVariables, HideMatchMessageProps<TChildProps>>(HideMatchMessageDocument, {
      alias: 'hideMatchMessage',
      ...operationOptions
    });
};

/**
 * __useHideMatchMessageMutation__
 *
 * To run a mutation, you first call `useHideMatchMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideMatchMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideMatchMessageMutation, { data, loading, error }] = useHideMatchMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useHideMatchMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HideMatchMessageMutation, HideMatchMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<HideMatchMessageMutation, HideMatchMessageMutationVariables>(HideMatchMessageDocument, baseOptions);
      }
export type HideMatchMessageMutationHookResult = ReturnType<typeof useHideMatchMessageMutation>;
export type HideMatchMessageMutationResult = ApolloReactCommon.MutationResult<HideMatchMessageMutation>;
export type HideMatchMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<HideMatchMessageMutation, HideMatchMessageMutationVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    