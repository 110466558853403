import { Box, Text } from 'rebass'
import { isEmpty } from 'lodash'
import { oc } from 'ts-optchain'
import { RouteComponentProps } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import React, { useState, useEffect } from 'react'

import { currentUserIsAdmin } from '../../utils/admins'
import { playerDefaultSport } from '../../utils/sportUtils'
import { PlayerProfileMatchHistoryQuery } from '../../types/fragments'
import { RosterEntry } from '../../types/graphql'
import { useConstants } from '../../hooks/constants'
import Animation from '../atoms/Animation'
import ContentContainer from '../atoms/ContentContainer'
import DataFetcher from '../organisms/DataFetcher'
import MatchCarousel from '../organisms/MatchCarousel'
import PlayerAdminLinks from '../molecules/PlayerAdminLinks'
import PlayerOldTeams from '../organisms/PlayerOldTeams'
import PlayerProfileHero from '../organisms/PlayerProfileHero'
import PlayerStats from '../organisms/PlayerStats'
import PlayerTwoTables from '../organisms/PlayerTwoTables'

import {
  MatchCarouselMatchInfoFragmentDoc,
  MatchCarouselTournamentCheckinInfoFragmentDoc,
  MatchCarouselMatchInfoFragment as CarouselMatch,
  MatchCarouselTournamentCheckinInfoFragment as Tournament,
} from '../../types/graphql'

// Consider refactoring backend to return all game info with one field
const PLAYERPROFILEPAGE = gql`
  query playerProfile($id: ID!) {
    user(id: $id) {
      id
      name
      username
      university {
        id
        name
      }
      discordId
      discordUsername
      facebookUsername
      twitterUsername
      twitchUsername
      lolSummonerTier
      lolChampionName
      lolRiotImageName
      lolSummonerName
      lolAverageStats {
        id
        kills
        deaths
        assists
        totalDamageDealtToChampions
        goldPerMin
        creepsPerMin
      }
      dota2HeroImageName
      dota2SteamId
      sc2PreferredRace
      hsPreferredClass
      vgPreferredHero
      nhlPreferredTeam
      owPreferredHero
      maddenPreferredTeam
      nbaPreferredTeam
      sfvPreferredFighter
      sfvFid
      mtgaPreferredColor
      csgoEseaProfileUrl
      csgoSteamId
      rlSteamId
      valorantRiotAccount
      valorantPreferredChampion
      playedSports {
        slug
      }
      activeRosterEntries {
        id
        inGameName
        gameRole
        player {
          id
          selectedSeasonStats {
            id
            wins
            losses
          }
        }
        team {
          id
          name
          createdAt
          sportSlug
          seasonYear
          teamAwards {
            id
          }
          selectedSeason {
            id
            divisionName
          }
          selectedSeasonConferenceName
          division
          selectedSeasonRank
          selectedSeasonStats {
            id
            wins
            losses
          }
          selectedSeasonMatches {
            id
            scheduledDate
            settled
            homeTeam {
              id
              name
            }
            awayTeam {
              id
              name
            }
            games {
              id
              winner {
                id
                team {
                  id
                }
              }
            }
          }
        }
      }
      upcomingMatches {
        ...MatchCarouselMatchInfo
      }
      tournamentsToCheckinTo {
        ...MatchCarouselTournamentCheckinInfo
      }
      unreportedMatches {
        ...MatchCarouselMatchInfo
      }
      ...PlayerProfileMatchHistoryQuery
    }
  }
  ${PlayerProfileMatchHistoryQuery}
  ${MatchCarouselMatchInfoFragmentDoc}
  ${MatchCarouselTournamentCheckinInfoFragmentDoc}
`

export interface IURLParams {
  playerId?: string
}
interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}

const PlayerProfile: React.FC<IProps> = ({ match: { params } }) => {
  const playerId = oc(params).playerId('')
  const [player, setPlayer] = useState(null as any)
  const [sport, setSport] = useState('')
  const constants = useConstants()
  const currentSeasonYear = oc(constants).currentSeasonYear('')
  const [carouselMatches, setCarouselMatches] = useState<CarouselMatch[]>([])
  const [tournamentsToCheckinTo, setTournamentsToCheckinTo] = useState<Tournament[]>([])

  //const playerId = '40450'//(lol, sfv, smash) //'21507'(3 league teams)
  //'16183' (3 sports)//'17734' (1 overwatch, 1 csgo, no active roster entries!)
  //'40152' (1 lol team)//'48888' (2 csgo teams)
  const { error, loading } = useQuery(PLAYERPROFILEPAGE, {
    variables: { id: playerId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setPlayer(data.user)
      setCarouselMatches(
        [...data.user.upcomingMatches, ...data.user.unreportedMatches].sort(function(a, b) {
          return a.scheduledDate.localeCompare(b.scheduledDate)
        }),
      )
      setTournamentsToCheckinTo(data.user.tournamentsToCheckinTo)
    },
  })
  const awards =
    player &&
    player.activeRosterEntries.filter(
      (entry: RosterEntry) => entry.team && entry.team.teamAwards && entry.team.teamAwards.length,
    ).length

  const separateRosterEntries =
    player &&
    player.activeRosterEntries.reduce(
      (array: any, entry: RosterEntry) => {
        array[
          (entry.team &&
            entry.team.seasonYear &&
            entry.team.seasonYear.includes(currentSeasonYear)) ||
          dayjs().diff(entry.team.createdAt, 'year') <= 1
            ? 0
            : 1
        ].push(entry)
        return array
      },
      [[], []],
    )

  if (separateRosterEntries) var [currentEntries, oldEntries] = separateRosterEntries

  useEffect(() => {
    if (!sport && player && player.playedSports && !isEmpty(player.playedSports)) {
      setSport(playerDefaultSport(player.playedSports, currentEntries))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, player])

  const entries =
    currentEntries &&
    currentEntries.filter((entry: RosterEntry) => entry.team && entry.team.sportSlug === sport)

  //for all sports stats except LOL
  const sportInfo: any = player && {
    dota2: player.dota2HeroImageName,
    vg: player.vgPreferredHero,
    hs: player.hsPreferredClass,
    sc2: player.sc2PreferredRace,
    nhl: player.nhlPreferredTeam,
    ow: player.owPreferredHero,
    madden: player.maddenPreferredTeam,
    nba: player.nbaPreferredTeam,
    sfv: player.sfvPreferredFighter,
    sfvFid: player.sfvFid,
    mtga: player.mtgaPreferredColor,
    csgoSteamId: player.csgoSteamId,
    csgoEsea: player.csgoEseaProfileUrl,
    dota2SteamId: player.dota2SteamId,
    rlSteamId: player.rlSteamId,
    valorant: player.valorantPreferredChampion,
  }
  //for LOL stats
  const calcKda = (avgStats: any) => {
    const { kills, deaths, assists } = avgStats
    const kda =
      kills + assists === 0
        ? '0.00'
        : deaths === 0
        ? kills + assists
        : ((kills + assists) / deaths).toFixed(2)
    return kda
  }

  const LOLInfo: any = player && {
    name: player.lolSummonerName,
    tier: player.lolSummonerTier,
    champion: player.lolChampionName
      ? {
          name: player.lolChampionName,
          imageName: player.lolRiotImageName,
        }
      : null,
    kda: calcKda(player.lolAverageStats),
    dmg: player.lolAverageStats.totalDamageDealtToChampions,
    gpm: player.lolAverageStats.goldPerMin.toFixed(2),
    cpm: player.lolAverageStats.creepsPerMin.toFixed(2),
  }

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <DataFetcher error={error} loading={loading}>
      {player ? (
        <>
          <PlayerProfileHero
            username={player.username}
            userId={player.id}
            university={player.university}
            playedSports={player.playedSports}
            fb={player.facebookUsername}
            twitch={player.twitchUsername}
            twitter={player.twitterUsername}
            discordId={player.discordId}
            discordUsername={player.discordUsername}
            selectSport={sport => setSport(sport)}
            awards={awards}
            currentSport={sport}
          />

          <ContentContainer flexDirection="column" py={[6, 6, 6, 8]} px={[3, 3, 3, 3, 3, 0]}>
            {currentUserIsAdmin() && <PlayerAdminLinks playerId={playerId} />}
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              <PlayerStats
                entries={entries}
                sport={sport}
                statInfo={sport === 'lol' ? LOLInfo : sportInfo}
              />
            </Animation>

            <PlayerTwoTables
              entries={entries}
              matches={player ? player.reportedMatches : []}
              sport={sport}
            />
            {(!isEmpty(tournamentsToCheckinTo) || !isEmpty(carouselMatches)) && (
              <Box my={8}>
                <Text mb={5}>
                  <h2>Upcoming Matches & Tournaments</h2>
                </Text>
                <MatchCarousel tournaments={tournamentsToCheckinTo} matches={carouselMatches} />
              </Box>
            )}

            {/* to show older teams: */}
            {oldEntries.length ? (
              <PlayerOldTeams
                teams={oldEntries
                  .filter((entry: RosterEntry) => entry.team && entry.team.sportSlug === sport)
                  .reduce((teamArray: any, entry: RosterEntry) => {
                    teamArray.push(entry.team)
                    return teamArray
                  }, [])}
              />
            ) : null}
          </ContentContainer>
        </>
      ) : null}
    </DataFetcher>
  )
}
export default PlayerProfile
