import { useTheme } from 'emotion-theming'
import React, { useState } from 'react'
import { GiCrossedSwords } from 'react-icons/gi'
import { useModal } from 'react-modal-hook'
import { Box, Flex, Text } from 'rebass'
import { oc } from 'ts-optchain'

import { useConstants } from '../../hooks/constants'
import { styled } from '../../styles/settings/theme'
import { ClickEvent, SetState } from '../../types/aliases'
import { Team, UniversityHubQueryFragment } from '../../types/graphql'
import { allSportSlugs } from '../../utils/sportUtils'
import Alert from '../atoms/Alert'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import Card from '../atoms/Card'
import Content from '../atoms/Content'
import ModalContainer from '../atoms/ModalContainer'
import Filters from '../molecules/Filters'
import CreateTeam from '../organisms/CreateTeam'
import GamesFilter from '../organisms/GamesFilter'
import UniversityDetailHero from '../organisms/UniversityDetailHero'
import UniversityDetailTeams from '../organisms/UniversityDetailTeams'

interface IUniversityHubProps {
  university: UniversityHubQueryFragment
  setYear: SetState<string>
  year: string
}

const FILTER_KEYS = {
  ALL: 'all',
  NEW: 'new teams',
}

const ZeroState = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
`

const UniversityHub: React.FC<IUniversityHubProps> = ({ university, year, setYear }) => {
  const { colors } = useTheme()
  const constants = useConstants()
  const currentSeasonYear = oc(constants).currentSeasonYear('')
  const [filter, setFilter] = useState(FILTER_KEYS.ALL)
  const isCurrentSeason = currentSeasonYear === year

  const teamData = {
    [FILTER_KEYS.ALL]: university.seasonTeams as Team[],
    [FILTER_KEYS.NEW]: university.newTeams as Team[],
  }

  const playedSports: string[] = [...new Set(teamData[filter].map((team: Team) => team.sportSlug))]
    .sort()
    .reverse()
  const [selectedSports, setSelectedSports] = useState(playedSports)
  //sort list of all sports so that played sports are at the beginning
  const matchingSportOrder = allSportSlugs.sort(
    (a: string, b: string) => playedSports.indexOf(b) - playedSports.indexOf(a),
  )

  const [showCreateTeamModal, hideCreateTeamModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideCreateTeamModal}
        style={{
          content: { maxWidth: '50rem' },
        }}
      >
        <CreateTeam
          unplayedSports={matchingSportOrder.filter(
            sport => !university.currentUserPlayedSports.includes(sport),
          )}
          playedSports={university.currentUserPlayedSports}
        />
      </ModalContainer>
    ),
    [],
  )

  const sportClickHandler = (clickedSport: string) => {
    if (selectedSports.includes(clickedSport)) {
      setSelectedSports((currentSports: string[]) =>
        currentSports.filter(currentSport => currentSport !== clickedSport),
      )
    } else setSelectedSports((currentSports: string[]) => [...currentSports, clickedSport])
  }

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <UniversityDetailHero
        name={university.name}
        memberCount={university.activeUsers ? university.activeUsers.length : 0}
        awards={university.numberOfAwards}
        changeYear={setYear}
        selectedYear={year}
        showCreateTeamButton={!!university.currentUserCanCreateTeam}
        showCreateTeamModal={showCreateTeamModal}
        seasonToggle
        isCurrentSeason={isCurrentSeason}
        universityId={university.id}
      />

      <Content>
        <Box mb={7}>
          <GamesFilter
            sportSlugs={matchingSportOrder}
            selectedSports={selectedSports}
            unplayedSports={matchingSportOrder.filter(sport => !playedSports.includes(sport))}
            sportClickHandler={sportClickHandler}
          />
        </Box>
        {teamData[FILTER_KEYS.ALL].length ? (
          <Box>
            <Flex mb={3} alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
              >
                <Box>
                  <h2>
                    Our Teams{' '}
                    <Text color={colors.darkgray} as="span">
                      ({teamData[FILTER_KEYS.ALL].length})
                    </Text>
                  </h2>
                </Box>
              </Animation>
              {isCurrentSeason && (
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
                >
                  <Filters
                    selectedFilter={filter}
                    filters={Object.keys(teamData).filter(
                      leagueKey => teamData[leagueKey].length > 0,
                    )}
                    setSelectedFilter={(_e: ClickEvent, value: string) => setFilter(value)}
                  />
                </Animation>
              )}
            </Flex>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              <UniversityDetailTeams
                teams={teamData[filter].filter(team => selectedSports.includes(team.sportSlug))}
                currentUserTeamIds={university.currentUserTeamIds}
              />
            </Animation>
          </Box>
        ) : (
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
          >
            <ZeroState notch={true} notchSize={20} width={1}>
              <Alert borderColor={colors.sand} mx="auto" maxWidth="100%" minWidth="100%">
                <Box py={4} px={6}>
                  <Box mt={university.currentUserCanCreateTeam && isCurrentSeason ? null : 4}>
                    <GiCrossedSwords color={colors.sand} size={40} />
                  </Box>
                  <Text color={colors.black} mt={4}>
                    <h2>{isCurrentSeason ? 'No active teams' : 'No teams this season'}</h2>
                    {university.currentUserCanCreateTeam && isCurrentSeason ? (
                      <>
                        <Text maxWidth={600} mx="auto">
                          <p>
                            Looking to get things started? Create your universities first team for
                            this season, and start recruiting players today!
                          </p>
                        </Text>
                        <BaseButton variant="primary" mt={6} onClick={showCreateTeamModal}>
                          Create A Team
                        </BaseButton>
                      </>
                    ) : null}
                  </Text>
                </Box>
              </Alert>
            </ZeroState>
          </Animation>
        )}
        {/* TODO: Reactivate if we end up getting this functionality in V4
        <Box mt={5}>
          <UniversityDetailContact clubEmail="" managerEmail="" />
        </Box> */}
      </Content>
    </>
  )
}

export default UniversityHub
