import React from 'react'
import { User } from 'react-feather'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'

import HeroImage from '../../assets/images/hero__team-details.jpg'
import PlaceholderLogo from '../../assets/small_logos/animal-house-college.png'
import { Match } from '../../types/graphql'
import { currentUserIsAdmin } from '../../utils/admins'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import Awards from '../atoms/Awards'
import { BaseButton } from '../atoms/Buttons'
import CircleLogo from '../atoms/CircleLogo'
import HeroContainer from '../atoms/HeroContainer'
import SeasonsToggle from '../molecules/SeasonsToggle'

interface IProps {
  name: string
  memberCount: number
  match?: Match
  awards: number
  changeYear: (arg0: string) => void
  selectedYear: string
  showCreateTeamButton: boolean
  seasonToggle: boolean
  showCreateTeamModal: () => void
  isCurrentSeason: boolean
  universityId: string
}

const UniversityDetailHero: React.FunctionComponent<IProps> = ({
  name,
  memberCount,
  match,
  awards,
  changeYear,
  selectedYear,
  showCreateTeamButton,
  seasonToggle,
  showCreateTeamModal,
  isCurrentSeason,
  universityId,
}) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <HeroContainer
      height="18rem"
      heroImage={HeroImage}
      tint={isCurrentSeason ? '3,51,89, 0.7' : '0,0,0,0.6'}
    >
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        height="100%"
        flexWrap="wrap"
        pt={5}
      >
        <Box width={[1, 2 / 3, 2 / 3, 2 / 3, 3 / 4]}>
          <Flex mb={[3, 5]} alignItems="center">
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              <CircleLogo logoUrl={PlaceholderLogo} alt="University Logo" mr={5} />
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
            >
              <Awards major={awards} minor={0} />
            </Animation>
          </Flex>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <Text color="white">
              <h2>{name}</h2>
            </Text>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
          >
            <Flex mt={3} alignItems="center">
              <User size={24} color="white" />
              <Text color="white" ml={2}>
                <h5>{memberCount} Active Members</h5>
              </Text>
            </Flex>
          </Animation>
        </Box>
        {showCreateTeamButton && isCurrentSeason && (
          <Box ml={[0, 0, 'auto']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              <BaseButton onClick={showCreateTeamModal}>Create Team</BaseButton>
            </Animation>
          </Box>
        )}
        {seasonToggle && (
          <SeasonsToggle
            backgroundDark
            changeYear={changeYear}
            selectedYear={selectedYear}
            isCurrentSeason={isCurrentSeason}
          />
        )}

        {currentUserIsAdmin() && (
          <Link to={paths.manageUniversity(universityId)}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <BaseButton width="100%" mt={[4, 4, 4, 4]} variant="primary">
                Edit University
              </BaseButton>
            </Animation>
          </Link>
        )}
      </Flex>
    </HeroContainer>
  )
}

export default UniversityDetailHero
