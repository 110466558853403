import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Box, Flex, FlexProps, Text } from 'rebass'
import { darken } from 'polished'
import { Link } from 'react-router-dom'
import { styled, Theme } from '../../styles/settings/theme'

import { allSportSlugs, roles } from '../../utils/sportUtils'
import { handleResult, errorsFullMessage } from '../../utils/results'
import { paths } from '../../utils/Routes'
import { useCreateTeamMutation } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import { CustomInput, InputBox, FieldLabel } from '../atoms/FormPieces'
import { CustomSelect } from '../atoms/Select'
import BackButton from '../atoms/BackButton'
import GamesFilter from '../organisms/GamesFilter'
import LoadingSpinner from '../atoms/LoadingSpinner'
import SectionTitle from '../atoms/SectionTitle'
import Stepper from '../atoms/Stepper'

gql`
  mutation createTeam($name: String!, $sport: String!, $coordinatorRole: String!) {
    upsertTeam(name: $name, sport: $sport, coordinatorRole: $coordinatorRole) {
      success
      value {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

const Input = styled(CustomInput)`
  ::placeholder {
    opacity: 100%;
  }
`

const FormWrapper = styled(Flex)<FlexProps>`
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;

  small {
    font-size: 10px;
    color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
  }
`

interface ICreateTeamProps {
  playedSports: string[]
  unplayedSports: string[]
}

const CreateTeam: React.FC<ICreateTeamProps> = ({ playedSports, unplayedSports }) => {
  const [sport, setSport] = useState<string>(playedSports.length === 1 ? playedSports[0] : '')
  const [name, setName] = useState<string>('')
  const [coordinatorRole, setCoordinatorRole] = useState<string>('')
  const [step, setStep] = useState<number>(1)
  const [formErrors, setFormErrors] = useState<string[]>([])

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)

  const sportClickHandler = (clickedSport: string) => {
    setSport(clickedSport)
  }

  const [createTeam, { loading }] = useCreateTeamMutation({
    onError(error) {
      console.error(error)
    },
    onCompleted: data => {
      setFormErrors([])
      handleResult({
        result: data.upsertTeam,
        onSuccess: () => (window.location.href = paths.manageTeam(data.upsertTeam.value!.id)),
        onFailure: errors => {
          setFormErrors([errorsFullMessage(errors)])
        },
      })
    },
  })

  const playerRoles = roles(sport)

  const handleNext = () => {
    step === 2 ? createTeam({ variables: { name, sport, coordinatorRole } }) : setStep(2)
  }

  const isButtonDisabled = step === 1 ? !sport || (playerRoles && !coordinatorRole) : !name

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Flex flexDirection="column">
      {step === 2 && <BackButton onClick={() => setStep(1)} />}

      <FormWrapper>
        <>
          <SectionTitle
            text={step === 2 ? 'Tell Us About Your Team' : 'What Game Are You Playing?'}
            align="center"
            mb=".75rem"
          />
          <Text textAlign="center" mt={3}>
            {step === 2 ? (
              <p>Add a name for your team so your teammates can easily find you.</p>
            ) : (
              <p>
                Please select what game you and your team will be playing. You can only make a team
                for games already added to{' '}
                <Link to={paths.profileSettings()} target={'_blank'} rel={'noopener noreferrer'}>
                  your account.
                </Link>
              </p>
            )}
          </Text>
          <Box my={7}>
            {step === 1 && (
              <GamesFilter
                sportSlugs={allSportSlugs}
                selectedSports={[sport]}
                unplayedSports={unplayedSports}
                sportClickHandler={sportClickHandler}
                gridTemplateColumns="repeat(8,1fr)"
              />
            )}
            {step === 2 && (
              <>
                <InputBox>
                  <Input
                    type="text"
                    placeholder="team name"
                    value={name}
                    onChange={changeHandler}
                    maxLength={30}
                  />
                  {name && <FieldLabel label="Team Name" />}
                  <Text textAlign="right" mt={'-4px'} mb={'10px'}>
                    <small>Max 30 characters</small>
                  </Text>
                </InputBox>

                {formErrors.map((error: string) => (
                  <Text key={error} textAlign="center" color="red" mt={2}>
                    {error}
                  </Text>
                ))}
              </>
            )}
          </Box>
          {step === 1 && playerRoles && (
            <CustomSelect
              onChange={e => {
                setCoordinatorRole(e.target.value)
              }}
              placeholder="Select your role"
              value={coordinatorRole}
              name="coordinatorRole"
              mb={5}
            >
              <option value="" disabled selected>
                Select your role
              </option>
              {playerRoles.map((role: string) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </CustomSelect>
          )}
          <Flex mb={3}>
            <BaseButton
              variant={isButtonDisabled ? 'secondaryDisabled' : 'secondary'}
              disabled={isButtonDisabled}
              onClick={handleNext}
              mx="auto"
            >
              {step === 1 ? 'Add Team Details' : 'Create Team'}
            </BaseButton>
          </Flex>
        </>
      </FormWrapper>
      {!loading && (
        <Box width="75%" mx="auto" mt={6} mb={4}>
          <Stepper totalSteps={2} currentStep={step} background={Theme.colors.lightgray} />
        </Box>
      )}
    </Flex>
  )
}

export default CreateTeam
