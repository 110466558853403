import React, { useState } from 'react'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { Game, Map, Team } from '../../types/graphql'
import { teamScore } from '../../utils/matchUtils'
import Card from '../atoms/Card'
import LoadingSpinner from '../atoms/LoadingSpinner'
import SportLogo from '../atoms/SportLogo'
import CsgoGameBox from './GameBoxes/CsgoGameBox'
import Dota2GameBox from './GameBoxes/Dota2GameBox'
import FortniteGameBox from './GameBoxes/FortniteGameBox'
import LolGameBox from './GameBoxes/LolGameBox'
import MtgaGameBox from './GameBoxes/MtgaGameBox'
import NbaGameBox from './GameBoxes/NbaGameBox'
import NhlGameBox from './GameBoxes/NhlGameBox'
import RlGameBox from './GameBoxes/RlGameBox'
import SfvGameBox from './GameBoxes/SfvGameBox'
import OwGameBox from './GameBoxes/OwGameBox'
import MaddenGameBox from './GameBoxes/MaddenGameBox'
import SmashGameBox from './GameBoxes/SmashGameBox'
import ValorantGameBox from './GameBoxes/ValorantGameBox'

interface ReportProps {
  homeTeam: Team
  awayTeam: Team
  settled: boolean
  games: Game[]
  parentLoading: boolean
  updateLoading: (loading: boolean) => void
  report: number
  maps: [Map] | null
}

const Header = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: ${props => props.theme.colors.black};
  padding: 1.2rem 0.75rem 1rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;

  ${Mq.sm} {
    grid-template-columns: 3fr 2fr 3fr;
    grid-template-rows: 1fr;
  }
`
const VS = styled(Flex)<FlexProps>`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  text-align: center;
`
const Circle = styled(Box)<BoxProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`
const ScoreText = styled(Text)`
  font-family: DrukWide;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  ${Mq.md} {
    font-size: 50px;
    line-height: 65px;
  }
`
const scoreColor = (teamScore: number, otherTeamScore: number): 'white' | 'green' | 'red' =>
  teamScore === otherTeamScore ? 'white' : teamScore > otherTeamScore ? 'green' : 'red'

const ReportGameModal = ({
  homeTeam,
  awayTeam,
  settled,
  games,
  report,
  maps,
  parentLoading,
  updateLoading,
}: ReportProps) => {
  const [newReport, setNewReport] = useState(report)
  const [loading, setLoading] = useState(parentLoading)
  const numReported = (games: Game[]) => {
    return games.reduce((total, game) => {
      if (game.winner) total += 1
      return total
    }, 0)
  }

  const handleOpenIndex = (index: number) => {
    setNewReport(index)
  }

  const updateChildLoading = (loading: boolean) => {
    setLoading(loading)
    updateLoading(loading)
  }
  const homeTeamMatchScore = homeTeam.id ? teamScore(homeTeam.id, games) : 0
  const awayTeamMatchScore = awayTeam.id ? teamScore(awayTeam.id, games) : 0

  const renderGameBox = (sport: string | undefined | null) => {
    if (sport === 'csgo') {
      return games.map((game, i) => (
        <CsgoGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          maps={maps}
          settled={settled}
        />
      ))
    }
    if (sport === 'mtga') {
      return games.map((game, i) => (
        <MtgaGameBox
          key={game.id}
          game={game}
          settled={settled}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
        />
      ))
    }
    if (sport === 'dota2') {
      return games.map((game, i) => (
        <Dota2GameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'lol') {
      return games.map((game, i) => (
        <LolGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'rl') {
      return games.map((game, i) => (
        <RlGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'sfv') {
      return games.map((game, i) => (
        <SfvGameBox
          key={game.id}
          game={game}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
        />
      ))
    }
    if (sport === 'fortnite') {
      return games.map((game, i) => (
        <FortniteGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'valorant') {
      return games.map((game, i) => (
        <ValorantGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          maps={maps}
          settled={settled}
        />
      ))
    }
    if (sport === 'nhl') {
      return games.map((game, i) => (
        <NhlGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'nba') {
      return games.map((game, i) => (
        <NbaGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'ow') {
      return games.map((game, i) => (
        <OwGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
          maps={maps}
        />
      ))
    }
    if (sport === 'madden') {
      return games.map((game, i) => (
        <MaddenGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
        />
      ))
    }
    if (sport === 'smash') {
      return games.map((game, i) => (
        <SmashGameBox
          key={game.id}
          game={game}
          reportable={i === 0 ? true : games[i - 1].winner && !settled ? true : false}
          updateLoading={updateChildLoading}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          toOpen={handleOpenIndex}
          report={newReport}
          index={i}
          settled={settled}
          maps={maps}
        />
      ))
    }
  }

  return (
    <>
      {/* title */}
      <Flex justifyContent="space-between" alignItems={'center'} flexWrap="wrap">
        <Flex alignItems="center" mb={[2, 2, 0]}>
          <Box mr={3}>
            <SportLogo sport={homeTeam.sportSlug} width="1.75rem" height="1.75rem" />
            {parentLoading}
          </Box>
          <h2>Report Your Match</h2>
        </Flex>
        <Flex>
          <Circle bg="yellow" mr={2} />
          <Text color="darkgray">
            <h6>
              {numReported(games)}/{games.length} games reported
            </h6>
          </Text>
        </Flex>
      </Flex>

      {/* header box */}
      <Header notchSize={12} notch={true}>
        <Flex flexDirection="column" justifyContent="space-evenly" ml={4}>
          <Text color="white">
            <h3>{homeTeam.truncatedName}</h3>
          </Text>
          <Text color="white">
            <h5>
              {homeTeam.activeRosterEntries ? homeTeam.activeRosterEntries.length : 0} members
            </h5>
          </Text>
        </Flex>

        <Flex justifyContent="space-evenly" alignItems="center">
          <ScoreText color={scoreColor(homeTeamMatchScore, awayTeamMatchScore)}>
            {homeTeamMatchScore}
          </ScoreText>
          <VS justifyContent="center" alignItems="center" mt={1} mb={1}>
            <Text color="white" ml={'1px'} mt={'2px'}>
              <h6>VS</h6>
            </Text>
          </VS>
          <ScoreText color={scoreColor(awayTeamMatchScore, homeTeamMatchScore)}>
            {awayTeamMatchScore}
          </ScoreText>
        </Flex>

        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems={['flex-start', 'flex-end']}
          mr={4}
          ml={[4, 4, 0]}
        >
          <Text color="white">
            <h3>{awayTeam.truncatedName}</h3>
          </Text>
          <Text color="white">
            <h5>
              {awayTeam.activeRosterEntries ? awayTeam.activeRosterEntries.length : 0} members
            </h5>
          </Text>
        </Flex>
      </Header>

      {/* games container*/}
      <Flex flexDirection="column">
        {/* tslint:disable-next-line */}
        {loading || parentLoading ? <LoadingSpinner /> : renderGameBox(homeTeam.sportSlug)}
      </Flex>
    </>
  )
}

export default ReportGameModal
