import _ from 'lodash'
import QS from 'query-string'
import React from 'react'
import { ModalProvider } from 'react-modal-hook'
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Analytics from 'react-router-ga'

import AppWrapper from '../components/atoms/AppWrapper'
import ScrollToTop from '../components/atoms/ScrollToTop'
import ConfirmEmail from '../components/molecules/ConfirmEmail'
import CookieConsent from '../components/molecules/CookieConsent'
import ResendConfirmation from '../components/molecules/ResendConfirmation'
import Footer from '../components/organisms/Footer'
import ForgotPassword from '../components/organisms/ForgotPassword'
import LogIn from '../components/organisms/LogIn'
import LogOut from '../components/organisms/LogOut'
import NavBar from '../components/organisms/NavBar'
import OAuthCallback from '../components/organisms/OAuthCallback'
import ResetPassword from '../components/organisms/ResetPassword'
import SEO from '../components/organisms/SEO'
import About from '../components/pages/About'
import AccountSettings from '../components/pages/AccountSettings'
import CompleteProfile from '../components/pages/CompleteProfile/CompleteProfile'
import Contact from '../components/pages/Contact'
import CreateNewsAndAnnouncements from '../components/pages/CreateNewsAndAnnouncements'
import CreateSeason from '../components/pages/CreateSeason'
import CreateTournament from '../components/pages/CreateTournament'
import CreateUniversity from '../components/pages/CreateUniversity'
import EventPage from '../components/pages/Events/EventPage'
import Forfeits from '../components/pages/Forfeits'
import HomePage from '../components/pages/HomePage'
import IncompleteProfile from '../components/pages/IncompleteProfile'
import LeagueDetail from '../components/pages/LeagueDetail'
import Leagues from '../components/pages/Leagues'
import ManageNewsAndAnnouncements from '../components/pages/ManageNewsAndAnnouncements'
import ManageSeason from '../components/pages/ManageSeason'
import ManageTeam from '../components/pages/ManageTeam/ManageTeam'
import ManageTournament from '../components/pages/ManageTournament'
import ManageUniversity from '../components/pages/ManageUniversity'
import MatchDetail from '../components/pages/MatchDetail'
import MediaTools from '../components/pages/MediaTools'
import MyUniversityDetail from '../components/pages/MyUniversityDetail'
import NewsAndAnnouncements from '../components/pages/NewsAndAnnouncements'
import NewsArticle from '../components/pages/NewsArticle'
import NoMatch from '../components/pages/NoMatch'
import PlayerProfile from '../components/pages/PlayerProfile'
import PrivacyPolicy from '../components/pages/PrivacyPolicy'
import ProfileSettings from '../components/pages/ProfileSettings/ProfileSettings'
import Rules from '../components/pages/Rules'
import Support from '../components/pages/Support'
import TeamDetail from '../components/pages/TeamDetail'
import TeamSettings from '../components/pages/TeamSettings/TeamSettings'
import TermsOfService from '../components/pages/TermsOfService'
import TournamentDetail from '../components/pages/TournamentDetail'
import Tournaments from '../components/pages/Tournaments'
import Universities from '../components/pages/Universities'
import UniversityDetail from '../components/pages/UniversityDetail'
import UserDashboard from '../components/pages/UserDashboard'
import UserSearch from '../components/pages/UserSearch'
import { accountIsIncomplete, currentUserIsLoggedIn, currentUsername } from './accountUtils'
import { currentUserIsAdmin } from './admins'
import { collegeSite } from './sites'
import { camelCase } from './strings'

export function paramify(paramsObj?: object) {
  if (!paramsObj) {
    return ''
  }
  const stringified = QS.stringify(paramsObj, { arrayFormat: 'comma' })
  return _.isEmpty(stringified) ? '' : `?${stringified}`
}

// This typecast is a _little_ risky since the object
// may not include those keys or may include keys
// other than the ones specified in QueryParams.
//
// Still, the existing type for QS.parse (i.e.
// ParsedQuery<string | boolean | number>) doesn't
// really _help_ from a development perspective. So this
// is a tradeoff that lets you have code completion.
//
// Just make sure to make the values optional
// since, realistically, they are.
export function useQuery<QueryParams extends object>() {
  const location = useLocation()
  const parsed = QS.parse(location.search)
  const result = _.fromPairs(
    _.map(parsed, (v, k) => {
      return [camelCase(k), v]
    }),
  )
  return result as QueryParams
}

export function updateQueryParams<QueryParams extends object>(
  oldParams: QueryParams,
  newParams: object,
) {
  return { ...oldParams, ...newParams } as QueryParams
}

export const externalPaths = {
  acceptTermsForBrands: () => 'https://cslesports.gg/privacy-policy',
}

export const paths = {
  homepage: () => '/',
  aboutUs: () => '/about-us',
  acceptInvitation: () => '/auth/invitation/accept',
  accountSettings: (playerId?: string) =>
    playerId ? `/account-settings/${playerId}` : '/account-settings',
  completeProfile: (anchor?: string) =>
    anchor ? `/complete-profile/${anchor}` : '/complete-profile',
  confirmation: () => '/auth/confirmation',
  contact: () => '/contact-us',
  createNewsAndAnnouncements: () => '/create-news-and-announcements',
  createSeason: () => '/create-season',
  createTournament: () => '/create-tournament',
  createUniversity: () => '/create-university',
  discord: () => 'https://discord.com/invite/csl',
  editPassword: () => '/auth/password/edit',
  forfeits: () => '/forfeits',
  forgotPassword: () => '/forgot-password',
  league: ({ id }: { id: string }) => `/league/${id}`,
  leagues: () => `/leagues`,
  logIn: () => '/login',
  logOut: () => '/logout',
  manageNewsAndAnnouncements: ({ id }: { id: string }) => `/manage-news-and-announcements/${id}`,
  manageSeason: (id: string, anchor?: string) =>
    anchor ? `/manage-season/${id}/${anchor}` : `/manage-season/${id}`,
  manageTournament: (id?: string, anchor?: string) =>
    anchor ? `/manage-tournament/${id}/${anchor}` : `/manage-tournament/${id}`,
  manageUniversity: (id: string) => `/manage-university/${id}`,
  matchDetail: (id: string) => `/match-detail/${id}`,
  mediaTools: () => '/media-tools',
  myUniversity: () => `/my-university`,
  newsAndAnnouncements: ({
    newsPage,
    newsCategory,
    newsSport,
  }: {
    newsPage?: string
    newsCategory?: string
    newsSport?: string
  } = {}) => `/news${paramify({ newsPage, newsCategory, newsSport })}`,
  newsArticle: (slug: string) => `/news/${slug}`,
  player: (id: string) => `/player/${id}`,
  privacyPolicy: () => '/privacy-policy',
  profile: () => '/profile',
  profileSettings: (playerId?: string) =>
    playerId ? `/profile-settings/${playerId}` : '/profile-settings',
  resendConfirmation: () => '/resend-confirmation',
  rules: (sport: string) => `/rules/${sport}`,
  signup: () => '/signup',
  support: () => '/support',
  teamDetail: (id: string) => `/team-detail/${id}`,
  teamSettings: (playerId?: string) => (playerId ? `/team-settings/${playerId}` : '/my-teams'),
  terms: () => `/terms-of-service`,
  tournament: (id: string) => `/tournament/${id}`,
  tournaments: () => '/tournaments',
  university: (id: string) => `/university/${id}`,
  universities: () => '/universities',
  userDashboard: () => '/dashboard',
  userSearch: () => '/user-search',
  manageTeam: (id: string, anchor?: string) =>
    anchor ? `/manage-team/${id}/${anchor}` : `/manage-team/${id}`,
  oAuthCallback: (provider: string) => `/oauth-callback/${provider}`,

  // EVENT PAGES
  eventPage: () => `/event-page`,
}

const AdminRoute = ({ component, unauthComponent, ...rest }: any) => {
  const routeComponent = (props: any) => {
    const unauthenticatedComponent = () => {
      const defaultUnauthComponent = (
        <LoggedOutRoute>
          <Redirect
            to={{
              pathname: paths.logIn(),
            }}
          />
        </LoggedOutRoute>
      )

      return unauthComponent ? React.createElement(unauthComponent, props) : defaultUnauthComponent
    }

    const authenticatedComponent = () => {
      return React.createElement(component, props)
    }

    return currentUserIsAdmin() ? authenticatedComponent() : unauthenticatedComponent()
  }

  return <Route {...rest} render={routeComponent} />
}

const CollegeRoute = ({ component, cslComponent, ...rest }: any) => {
  const routeComponent = (props: any) => {
    const defaultComponent = () => {
      const defaultCSLComponent = (
        <Route>
          <Redirect
            to={{
              pathname: paths.userDashboard(),
            }}
          />
        </Route>
      )

      return cslComponent ? React.createElement(cslComponent, props) : defaultCSLComponent
    }

    const collegeComponent = () => {
      return React.createElement(component, props)
    }

    return collegeSite ? collegeComponent() : defaultComponent()
  }

  return <Route {...rest} render={routeComponent} />
}

export function Routes() {
  return (
    <Router>
      <ScrollToTop />
      <ModalProvider>
        <NavBar />
        <AppWrapper hideBg={window.location.pathname === paths.homepage()}>
          <SEO />
          <Analytics id="UA-66604182-1" debug>
            <Switch>
              <Route path={paths.teamDetail(':teamId')} exact component={TeamDetail} />
              <Route path={paths.tournament(':tournamentId')} exact component={TournamentDetail} />
              <Route path={paths.matchDetail(':matchId')} exact component={MatchDetail} />
              <Route path={paths.player(':playerId')} exact component={PlayerProfile} />
              <CollegeRoute
                path={paths.university(':universityId')}
                exact
                component={UniversityDetail}
              />
              <CollegeRoute path={paths.universities()} component={Universities} />
              <CollegeRoute path={paths.myUniversity()} exact component={MyUniversityDetail} />
              <Route path={paths.rules(':sport')} exact component={Rules} />
              <Route path={paths.accountSettings(':playerId?')} component={AccountSettings} />
              <Route path={paths.profileSettings(':playerId?')} component={ProfileSettings} />
              <Route
                path={[paths.teamSettings(':playerId?'), paths.teamSettings()]}
                component={TeamSettings}
              />
              <Route path={paths.rules(':sport')} component={Rules} />
              <Route path={paths.logOut()} component={LogOut} />
              <Route path={paths.newsArticle(':slug')} component={NewsArticle} />
              <Route path={paths.newsAndAnnouncements()} component={NewsAndAnnouncements} />
              <Route path={paths.manageTeam(':teamId', ':anchor?')} component={ManageTeam} />
              <Route path={paths.oAuthCallback(':provider')} component={OAuthCallback} />
              <Route path={paths.privacyPolicy()} exact component={PrivacyPolicy} />
              <Route path={paths.terms()} exact component={TermsOfService} />
              <Route path={paths.aboutUs()} exact component={About} />
              <Route path={paths.contact()} exact component={Contact} />
              <Route path={paths.support()} exact component={Support} />

              {/* EVENTS PAGES ––– This is the first page, use this as a template and update links above*/}
              <Route path={paths.eventPage()} exact component={EventPage} />

              <UserProfile path={paths.profile()} />

              <ValidateConfirmationToken path={paths.confirmation()} />

              <CompleteProfileRoute path={paths.completeProfile(':anchor?')} />

              <EmailConfirmationAuth path={paths.resendConfirmation()}>
                <Route render={props => <ResendConfirmation {...props} />} />
              </EmailConfirmationAuth>

              <LoggedOutRoute path={paths.logIn()}>
                <Route component={LogIn} />
              </LoggedOutRoute>

              <LoggedOutRoute path={paths.forgotPassword()}>
                <Route component={ForgotPassword} />
              </LoggedOutRoute>

              <LoggedOutRoute path={paths.editPassword()}>
                <Route render={props => <ResetPasswordRoute {...props} />} />
              </LoggedOutRoute>

              <LoggedOutRoute path={paths.acceptInvitation()}>
                <Route render={props => <InvitedUserRoute {...props} />} />
              </LoggedOutRoute>

              <LoggedOutRoute path={paths.signup()}>
                <Route render={props => <LogIn {...props} isSignUp />} />
              </LoggedOutRoute>

              <CollegeRoute path={paths.leagues()} component={Leagues} />
              <CollegeRoute path={paths.league({ id: ':leagueId' })} component={LeagueDetail} />
              <Route path={paths.tournaments()} component={Tournaments} />

              <Route path={paths.userDashboard()} component={UserDashboard} />

              <AdminRoute
                path={paths.manageTournament(':tournamentId?', ':anchor?')}
                component={ManageTournament}
              />
              <AdminRoute
                path={paths.manageSeason(':seasonId?', ':anchor?')}
                component={ManageSeason}
              />
              <AdminRoute
                path={paths.manageUniversity(':universityId')}
                component={ManageUniversity}
              />
              <AdminRoute path={paths.createTournament()} component={CreateTournament} />
              <AdminRoute path={paths.createUniversity()} component={CreateUniversity} />
              <AdminRoute path={paths.createSeason()} component={CreateSeason} />
              <AdminRoute
                path={paths.createNewsAndAnnouncements()}
                component={CreateNewsAndAnnouncements}
              />
              <AdminRoute
                path={paths.manageNewsAndAnnouncements({ id: ':id' })}
                component={ManageNewsAndAnnouncements}
              />
              <AdminRoute path={paths.mediaTools()} component={MediaTools} />
              <AdminRoute path={paths.forfeits()} component={Forfeits} />
              <AdminRoute path={paths.userSearch()} component={UserSearch} />

              <Route exact path={paths.homepage()} component={collegeSite ? HomePage : HomePage} />

              <Route component={NoMatch} />
            </Switch>
          </Analytics>
          <Footer />
        </AppWrapper>
      </ModalProvider>
      <CookieConsent />
    </Router>
  )
}

function UserProfile({ ...props }) {
  const username = currentUsername()
  const userId = props.location.state && props.location.state.userId

  return accountIsIncomplete() ? (
    <IncompleteProfile username={username || ''} />
  ) : userId ? (
    <Redirect
      to={{
        pathname: paths.player(userId),
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname: paths.homepage(),
      }}
    />
  )
}

function ValidateConfirmationToken({ ...props }) {
  const params = new URLSearchParams(props.location.search)
  const token = params.get('confirmation_token')

  return token ? (
    <ConfirmEmail token={token} />
  ) : (
    <Redirect
      to={{
        pathname: paths.homepage(),
      }}
    />
  )
}

function EmailConfirmationAuth({ ...props }) {
  return props.location.state && props.location.state.authorized ? (
    props.children
  ) : (
    <Redirect
      to={{
        pathname: paths.homepage(),
      }}
    />
  )
}

function InvitedUserRoute({ ...props }) {
  const searchParams = new URLSearchParams(props.location.search)
  const token = searchParams.get('invitation_token')

  return token ? (
    <LogIn
      invitationToken={token}
      location={props.location}
      history={props.history}
      match={props.match}
      isSignUp
    />
  ) : (
    <Redirect
      to={{
        pathname: paths.homepage(),
      }}
    />
  )
}

function CompleteProfileRoute({ ...props }) {
  return accountIsIncomplete() ? (
    <Route component={CompleteProfile} {...props} />
  ) : (
    <Redirect
      to={{
        pathname: paths.homepage(),
      }}
    />
  )
}

function ResetPasswordRoute({ ...props }) {
  const searchParams = new URLSearchParams(props.location.search)
  const token = searchParams.get('reset_password_token')

  return token ? (
    <ResetPassword token={token} />
  ) : (
    <Redirect
      to={{
        pathname: paths.logIn(),
      }}
    />
  )
}

function LoggedOutRoute({ ...props }) {
  return currentUserIsLoggedIn() ? (
    <Redirect
      to={{
        pathname: paths.homepage(),
      }}
    />
  ) : (
    props.children
  )
}

export default {
  Routes,
  paths,
}
