import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from 'rebass'

import { useHomepageRankingTournamentsQuery } from '../../types/graphql'
import { fullWithShortMonth } from '../../utils/dateTimeFormats'
import { paths } from '../../utils/Routes'
import { isActive } from '../../utils/tournament'
import { BaseButton } from '../atoms/Buttons'
import Hidden from '../atoms/Hidden'
import Table from '../atoms/Table'
import DataFetcher from './DataFetcher'
import HomeRankingsAlert from './HomeRankingsAlert'

gql`
  query HomepageRankingTournaments {
    homepageRankingTournaments {
      id
      name
      firstMatchDate
      style
      published
      registrationCloseDate
      registrationOpenDate
      decided
      platform {
        id
        title
      }
    }
  }
`

const OpenTournaments: React.FC = () => {
  const { colors } = useTheme()

  const { data, loading, error } = useHomepageRankingTournamentsQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <DataFetcher loading={loading} error={error}>
      {data && data.homepageRankingTournaments.length ? (
        <>
          <Table headerBackgroundColor={'darkgray'} lastRowBorder={true}>
            <thead>
              <tr>
                <Hidden smDown>
                  <th style={{ width: '25%' }}>
                    <Text color={colors.white}>
                      <h6>Date</h6>
                    </Text>
                  </th>
                </Hidden>
                <th style={{ width: '50%' }}>
                  <Text color={colors.white}>
                    <h6>Tournament</h6>
                  </Text>
                </th>
                <th style={{ width: '25%' }}>
                  <Text color={colors.white}>
                    <h6>Platform</h6>
                  </Text>
                </th>
              </tr>
            </thead>

            <tbody>
              {data.homepageRankingTournaments.map(tournament => (
                <tr key={tournament.id}>
                  <Hidden smDown>
                    <td>
                      <Text color={isActive(tournament) ? colors.teal : colors.darkgray}>
                        <h6 style={{ minWidth: 'max-content' }}>
                          {isActive(tournament)
                            ? 'ACTIVE'
                            : fullWithShortMonth(tournament.firstMatchDate)}
                        </h6>
                      </Text>
                    </td>
                  </Hidden>
                  <td>
                    <Link to={paths.tournament(tournament.id)}>
                      <Text color={colors.primarynavy}>
                        <h6 className="title">{tournament.name}</h6>
                      </Text>
                    </Link>
                  </td>
                  <td>
                    <Text color={colors.darkgray}>
                      <h6>{tournament.platform ? tournament.platform.title : '-'}</h6>
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Flex justifyContent="center" py={6}>
            <Link to={paths.tournaments()}>
              <BaseButton variant="secondary">View All Tournaments</BaseButton>
            </Link>
          </Flex>
        </>
      ) : (
        <HomeRankingsAlert
          title="No open tournaments"
          description="We don't currently have any active tournaments. Check back soon to see what we have
      coming up next."
          ctaText="View All Tournaments"
          ctaLink={paths.tournaments()}
        />
      )}
    </DataFetcher>
  )
}

export default OpenTournaments
