import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const LolRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="League of Legends" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        CSL League of Legends 2020-2021 Season Rulebook: <a href="https://drive.google.com/file/d/1zHSpgxFeM8AuH9GGQBi7CGv_XqZ64MlQ/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          CSL-LOL-2020-2021-Rules.pdf
          </a><br />
          Lone Star College League of Legends Intramurals Rulebook (Fall 2020): <a href="https://drive.google.com/file/d/1PuAeNNSYcB5ePsEWEl2oChujHN8ONneG/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Lone-Star-College-Intramurals-League-of-Legends-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY League of Legends RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #lol-help chat.
        </h3>
      </Text>
    </>
  )
}

export default LolRules
