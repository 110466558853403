import { useTheme } from 'emotion-theming'
import React, { useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Text } from 'rebass'

import { currentUserIsLoggedIn } from '../../utils/accountUtils'
import { currentUserIsAdmin } from '../../utils/admins'
import { paths } from '../../utils/Routes'
import { currentlyInRegistration } from '../../utils/seasonUtils'
import { SiteSwitch } from '../../utils/sites'
import WideContent from '../atoms/WideContent'
import HomePageCTA from '../molecules/HomePageCTA'
import HomePageGames from '../molecules/HomePageGames'
import HomePageSponsors from '../molecules/HomePageSponsors'
import HomePageCarousel from '../organisms/HomePageCarousel'
import HomePageNews from '../organisms/HomePageNews'
import HomePageValueProps from '../organisms/HomePageValueProps'
import HomeRankings from '../organisms/HomeRankings'

const Home: React.FC = () => {
  const { colors } = useTheme()

  useEffect(() => {
    // TODO: Paul – Change the requirements below to show or hide based on how this toast should be presented
    if (false) {
    // if (currentlyInRegistration() && !currentUserIsLoggedIn()) {
      toast.info(
        <Link to={paths.signup()}>
          <Text color={colors.white}>
            <h6>
              <SiteSwitch
                college="ENROLLMENT IS NOW OPEN FOR THE 2020-2021 SEASON &mdash; CLICK HERE TO REGISTER!"
                esports={null}
              />
            </h6>
          </Text>
        </Link>,
        {
          autoClose: false,
          hideProgressBar: true,
          className: 'CTA',
          containerId: 'permanent',
          toastId: 'home-page-notice', // unique id prevents stacking up a toast loaf
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyInRegistration(), colors.white])

  return currentUserIsLoggedIn() && !currentUserIsAdmin() ? (
    <Redirect to={paths.userDashboard()} />
  ) : (
    <WideContent>
      <SiteSwitch college={<HomePageCarousel />} esports={<HomePageCarousel />} />
      <SiteSwitch college={null} esports={<HomePageValueProps />} />
      <HomePageSponsors />
      <HomePageGames />
      <HomePageNews />
      <HomeRankings />
      <HomePageCTA />
    </WideContent>
  )
}

export default Home
