import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { Redirect, RouteComponentProps, useParams } from 'react-router-dom'
import { oc } from 'ts-optchain'

import { useConstants } from '../../hooks/constants'
import { UniversityHubQuery } from '../../types/fragments'
import { useUniversityDetailPageLazyQuery } from '../../types/graphql'
import DataFetcher from '../organisms/DataFetcher'
import UniversityHub from '../organisms/UniversityHub'

gql`
  query universityDetailPage($id: ID!, $selectedYear: String) {
    university(id: $id, selectedYear: $selectedYear) {
      ...UniversityHubQuery
    }
  }
  ${UniversityHubQuery}
`
interface IURLParams {
  universityId?: string
  page?: string
  pageSize?: string
}

interface IRouteParams {
  universityId: string
}

const UniversityDetail: React.FunctionComponent<RouteComponentProps<IURLParams>> = () => {
  const params = useParams<IRouteParams>()
  const constants = useConstants()
  const currentSeasonYear = oc(constants).currentSeasonYear('')
  const [year, setYear] = useState('')
  const universityId = params.universityId

  const [universityQuery, { data, error, loading }] = useUniversityDetailPageLazyQuery({
    variables: { id: universityId, selectedYear: year },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (currentSeasonYear && !year) {
      setYear(currentSeasonYear)
    }
  }, [year, currentSeasonYear])

  useEffect(() => {
    if (!year) {
      return
    }
    universityQuery()
  }, [year, universityQuery])

  if (data && !data.university) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <DataFetcher error={error} loading={loading || (!data && !error)}>
      {data && data.university && (
        <UniversityHub university={data.university} setYear={setYear} year={year} />
      )}
    </DataFetcher>
  )
}

export default UniversityDetail
