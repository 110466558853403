import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React from 'react'
import Slider from 'react-slick'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import { styled, Theme, MediaQueries as Mq } from '../../styles/settings/theme'

interface ICarouselProps {
  children?: any
  className?: any
  onClick?: any
}

const HeroSlider = styled(Slider)`
  & .slick-prev {
    z-index: 9;
    left: 1rem;
  }
  & .slick-next {
    z-index: 9;
    right: 1rem;
  }
  & .slick-dots {
    bottom: 2rem;
    ${Mq.md} {
      bottom: 2rem;
    }
  }
  & .slick-dots li {
    margin: 0 0.75rem;
    opacity: 0.8;
  }
  & .slick-dots li.slick-active button:before {
    color: #fff;
    font-size: 24px;
    border: 0px;
    opacity: 1;
    margin-top: 2px;
    margin-left: -2px;
  }
  & .slick-dots li button:before {
    color: rgba(0, 0, 0, 0);
    border: 2px solid #fff;
    border-radius: 20px;
    opacity: 1;
    font-size: 22px;
  }

  @media screen and (max-width: 1750px) {
    & .slick-prev {
      top: auto;
      bottom: 1.5rem;
    }
    & .slick-next {
      top: auto;
      bottom: 1.5rem;
    }
  }

  ${Mq.lg} {
    & .slick-prev {
      left: 4rem;
    }
    & .slick-next {
      right: 4rem;
    }
  }
`

const NextArrow: React.FC<ICarouselProps> = ({ className, onClick }) => {
  return (
    <FiArrowRight className={className} onClick={onClick} size={40} color={Theme.colors.white} />
  )
}

const PrevArrow: React.FC<ICarouselProps> = ({ className, onClick }) => {
  return (
    <FiArrowLeft className={className} onClick={onClick} size={40} color={Theme.colors.white} />
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  draggable: false,
  // autoplay: true,
  autoplaySpeed: 7000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const HeroCarousel: React.FC<ICarouselProps> = ({ children }) => {
  return <HeroSlider {...settings}>{children}</HeroSlider>
}

export default HeroCarousel
