import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const ValorantRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="VALORANT" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        DreamHack Beyond Valorant Collegiate Open Rulebook: <a href="https://drive.google.com/file/d/122KzmysWGGa2wriKoI6w37RyGmVTBkFF/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          DreamHack Beyond 2021 - Valorant Collegiate Open Rulebook
          </a>
      </Paragraph>
      <Paragraph>
        CSL VALORANT 2020-2021 Season Rulebook: <a href="https://drive.google.com/file/d/1uWl6GCpyXuo-rcWNkvxZeGC9WOogOv9n/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          CSL-VALORANT-2020-2021-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY VALORANT RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #valorant-help chat.
        </h3>
      </Text>
    </>
  )
}

export default ValorantRules
