import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Redirect, Link } from 'react-router-dom'
import { Box, Flex } from 'rebass'
import React, { useState } from 'react'
import { Text } from 'rebass'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { validateEmail } from '../../utils/accountUtils'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import { AuthFormContent, AuthFormBox, StyledInput } from '../atoms/AuthFormPieces'
import { BaseButton } from '../atoms/Buttons'
import LoadingSpinner from '../atoms/LoadingSpinner'
import SectionTitle from '../atoms/SectionTitle'

const SEND_PASSWORD_RESET = gql`
  mutation sendPasswordReset($email: String!) {
    sendPasswordReset(email: $email) {
      success
    }
  }
`

const CustomBox = styled(Box)`
  width: 100%;
  margin: 2rem auto 0;
  border: 2px solid ${(props) => props.theme.colors.middlegray};
  padding: 2rem;

  ${Mq.md} {
    width: 80%;
  }
`

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [formError, setFormError] = useState('')
  const [redirect, setRedirect] = useState(false)

  const handleOnFocus = () => {
    setFormError('')
  }

  const handleValidateEmail = (event: React.SyntheticEvent) => {
    const value = (event as any).target.value
    value && !validateEmail(value) ? setFormError('Not a valid Email address.') : setFormError('')
  }

  const [submitForm, { loading }] = useMutation(SEND_PASSWORD_RESET, {
    onError(error) {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        setFormError(error.graphQLErrors[0].message)
      } else if (error.message) {
        setFormError(error.message)
      } else {
        setFormError('An unexpected error has occured. Please try again.')
      }
    },
    onCompleted() {
      setRedirect(true)
    },
  })

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    submitForm({
      variables: {
        email,
      },
    })
  }

  const isButtonDisabled = loading || !!formError || email === ''

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/logIn',
          state: {
            message:
              'Reset password instructions sent. Follow the instructions in your email to reset your password.',
          },
        }}
      />
    )
  }

  const variants = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    fade: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <AuthFormContent onSubmit={handleSubmit}>
      <AuthFormBox>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
        >
          <SectionTitle text="Forgot your password?" align="center" mb=".75rem" />
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
        >
          <Text textAlign="center" maxWidth={500} margin="auto">
            <p>
              Enter your account email below, and we will send you instructions to reset your
              password.
            </p>
          </Text>
        </Animation>

        <CustomBox>
          <Animation
            initial="fade"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
          >
            <>
              <Flex>
                <Box width={1 / 2}>
                  <h5>Forgot Password</h5>
                </Box>
                <Box width={1 / 2}>
                  <Text textAlign="right">
                    <Link to={paths.logIn()}>
                      <h5>Sign In</h5>
                    </Link>
                  </Text>
                </Box>
              </Flex>
              <Flex width="auto" mt={2} justifyContent="center">
                <Box width={1} margin="auto">
                  <StyledInput
                    type="text"
                    name="email"
                    placeholder="Enter your login Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleValidateEmail}
                    onFocus={handleOnFocus}
                  />
                </Box>
              </Flex>

              {formError && (
                <Text textAlign="center" color="red" mt={2}>
                  {formError}
                </Text>
              )}
              <br />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <Flex width="auto" mt={4} justifyContent="flex-end">
                  <Box width="auto">
                    <BaseButton
                      variant={isButtonDisabled ? 'primaryDisabled' : 'primary'}
                      disabled={isButtonDisabled}
                      type="submit"
                    >
                      Send reset instructions
                    </BaseButton>
                  </Box>
                </Flex>
              )}
            </>
          </Animation>
        </CustomBox>
      </AuthFormBox>
    </AuthFormContent>
  )
}

export default ForgotPassword
