import React from 'react'
import { Box, BoxProps } from 'rebass'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

interface IHeroProps {
  height: string
  heroImage?: string
  tint?: string
  image?: string
  position?: string
}

interface IStyledBoxProps {
  image?: string
  tint?: string
  position?: string
}

const StyledWideContent = styled(WideContent)<IStyledBoxProps>`
  background-image: linear-gradient(black, black), url('${(props) => props.image}');
  box-shadow: 0 3000px rgba(${(props) => props.tint || `3, 51, 89, 0.6`}) inset;
  background-blend-mode: saturation;
  background-size: cover;
  background-position: ${(props) => props.position || 'center'};
  min-width: 100%;
  position: relative;
`

const StyledBox = styled(Box)<IStyledBoxProps & BoxProps>`
  width: 100%;
  position: relative;

  h1 {
    ${Mq.xxl} {
      min-width: 20rem;
      max-width: 75rem;
      margin-left: 0;
    }
  }
`

const HeroContainer: React.FC<IHeroProps> = ({ height, heroImage, tint, position, children }) => {
  return (
    <StyledWideContent image={heroImage} position={position} tint={tint}>
      <WideContainer>
        <StyledBox minHeight={[height]} height={height} px={[0, 0, 0, 0]} py={[3, 3, 4, 7]}>
          {children}
        </StyledBox>
      </WideContainer>
    </StyledWideContent>
  )
}

export default HeroContainer
