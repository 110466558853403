import React, { useState } from 'react'
import { Box, Flex } from 'rebass'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import HeroImage from '../../assets/images/hero__rules.jpg'
import Content from '../atoms/Content'
import CsgoRules from '../organisms/CsgoRules'
import Dota2Rules from '../organisms/Dota2Rules'
import FortniteRules from '../organisms/FortniteRules'
import GamesFilter from '../organisms/GamesFilter'
import Hero from '../molecules/Hero'
import LolRules from '../organisms/LolRules'
import MaddenRules from '../organisms/MaddenRules'
import MtgaRules from '../organisms/MtgaRules'
import NbaRules from '../organisms/NbaRules'
import OwRules from '../organisms/OwRules'
import RlRules from '../organisms/RlRules'
import SfvRules from '../organisms/SfvRules'
import SmashRules from '../organisms/SmashRules'
import ValorantRules from '../organisms/ValorantRules'

interface IURLParams {
  sport: string
}

const StyledFlex = styled(Flex)`
  padding: 1rem;
  background: ${props => props.theme.colors.white};

  --notchSize: 16px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  ${Mq.md} {
    padding: 2rem;
  }
`

const sportMap: { [key: string]: any } = {
  csgo: CsgoRules,
  dota2: Dota2Rules,
  fortnite: FortniteRules,
  lol: LolRules,
  madden: MaddenRules,
  mtga: MtgaRules,
  nba: NbaRules,
  ow: OwRules,
  rl: RlRules,
  sfv: SfvRules,
  smash: SmashRules,
  valorant: ValorantRules,
}

const Rules: React.FunctionComponent<RouteComponentProps<IURLParams>> = ({ match: { params } }) => {
  let history = useHistory()
  const sport = params.sport || Object.keys(sportMap)[0]
  const [selectedSport, setSelectedSport] = useState(sport)

  if (selectedSport !== sport) {
    history.push(paths.rules(selectedSport))
  }

  const Component = sportMap[selectedSport]

  const handleSportClick = (sport: string) => {
    history.push(paths.rules(sport))
    setSelectedSport(sport)
  }

  return (
    <>
      <Hero
        title={'Rules'}
        image={HeroImage}
        position="center 30%"
        height="18rem"
        tint={'3,51,89, 0.6'}
      ></Hero>
      <Content>
        <Box mb={7}>
          <GamesFilter
            sportSlugs={Object.keys(sportMap)}
            selectedSports={[selectedSport]}
            sportClickHandler={handleSportClick}
            unplayedSports={[]}
          />
          <h2 style={{ marginTop: '3rem' }}>season guide to csl fall 2020 - spring 2021</h2>
          <StyledFlex width="auto" flexDirection="column" margin="2rem auto 4rem">
            <Component />
          </StyledFlex>
        </Box>
      </Content>
    </>
  )
}

export default Rules
