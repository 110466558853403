import React from 'react'
import { Flex, Box, Text, Link as ExternalLink } from 'rebass'
import { useTheme } from 'emotion-theming'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import TwitchLogo from '../../assets/images/sponsor_logos/sponsor__twitch--color.png'
import Animation from '../atoms/Animation'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

const StyledBox = styled(Box)`
  h4 {
    text-align: left;
    padding-right: 1rem;
  }
`

const StyledFlex = styled(Flex)`
  padding: 3rem 0 5rem;
`

const RightBox = styled(Box)`
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 2rem;

  &:nth-child(2) {
    img {
      margin-left: -1rem;
    }
  }

  ${Mq.md} {
    text-align: right;
    margin-top: 0rem;
    margin-bottom: 0rem;
    &:nth-child(2) {
      img {
        margin-left: inherit;
      }
    }
  }
`

const StyledWideContent = styled(WideContent)`
  background: ${props => props.theme.colors.white};
`

const DividerLine = styled(Box)`
  display: none;

  ${Mq.md} {
    display: block;
    width: 100%;
    margin-right: 2rem;
    border-bottom: 2px solid ${props => props.theme.colors.primarylightblueHighlight};
  }
`

const HomePageSponsors: React.FC = () => {
  const { colors } = useTheme()
  const animations = {
    hidden: {
      opacity: 0,
      x: '1.5rem',
    },
    hiddenLeft: {
      opacity: 0,
      x: '-1.5rem',
    },
    fade: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  return (
    <StyledWideContent>
      <WideContainer>
        <StyledFlex
          width="auto"
          alignItems={['flex-start', 'flex-start', 'center']}
          flexDirection={['column', 'column', 'row']}
          pb={[4, 4, 6, 6]}
        >
          <StyledBox width="auto">
            <Animation
              initial="hiddenLeft"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              <Text color={colors.primarynavy}>
                <h4>CSL is powered by you and these amazing sponsors</h4>
              </Text>
            </Animation>
          </StyledBox>
          <DividerLine />
          <Box width="auto">
            <Flex
              width="auto"
              justifyContent={['flex-start', 'flex-start', 'flex-end']}
              mx={[-6, -6, -6, -4]}
              mt={[4, 4, 4, 0]}
            >
              <RightBox px={[6, 6, 6, 4]}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 1.2 }}
                >
                  <ExternalLink href="https://www.twitch.tv/p/students/" rel="noopener noreferrer">
                    <img src={TwitchLogo} alt="Twitch Logo" width="200px" height="auto" />
                  </ExternalLink>
                </Animation>
              </RightBox>
            </Flex>
          </Box>
        </StyledFlex>
      </WideContainer>
    </StyledWideContent>
  )
}

export default HomePageSponsors
