import dayjs from 'dayjs'
import React from 'react'
import { FiCalendar, FiClock, FiDollarSign, FiFlag, FiUser, FiUsers } from 'react-icons/fi'
import { FaNetworkWired } from 'react-icons/fa'
import { useModal } from 'react-modal-hook'
import { Link } from 'react-router-dom'
import { Box, Flex, FlexProps, Text } from 'rebass'

import { styled } from '../../styles/settings/theme'
import { RosterEntry, Team } from '../../types/graphql'
import { adjustedDayAndFullWithShortMonthAndTime } from '../../utils/dateTimeFormats'
import { playoffs, prizeAmounts, prizePool } from '../../utils/playoffs'
import { paths } from '../../utils/Routes'
import { SiteSwitch } from '../../utils/sites'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import ModalContainer from '../atoms/ModalContainer'
import PlayersTable from '../molecules/PlayersTable'
import TeamsTable, { TeamTableLike } from '../molecules/TeamsTable'
import TournamentDescription from '../molecules/TournamentDescription'
import { InfoBoxHalf, InfoRow } from './InfoBoxPieces'
import PrizePoolModal from './PrizePoolModal'

const InfoContainer = styled(Flex)<FlexProps>`
  width: 100%;
  max-width: 85rem;
`

const InfoRows = styled(Flex)<FlexProps>`
  flex-direction: column;
  border-bottom: 2px solid ${props => props.theme.colors.middlegray};
`

const animations = {
  hidden: {
    opacity: 0,
  },
  hiddenDown: {
    opacity: 0,
    y: '24px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

export interface TournamentInfoProps {
  numTeams?: string
  numPlayers?: string
  numSchools?: string
  tournamentId: string
  tournamentName: string
  teams: Team[]
  activeRosterEntries: RosterEntry[]
  sport: string
  firstMatchDate: string
  tournamentReady: boolean
  description: string
  location: string
  checkinLength: number
  registrationOpenDate: string
  registrationCloseDate: string
  bracketIds?: string[]
  containerId?: string
}

const TournamentInformation: React.FunctionComponent<TournamentInfoProps> = ({
  numTeams,
  numPlayers,
  numSchools,
  tournamentId,
  tournamentName,
  activeRosterEntries,
  teams,
  sport,
  firstMatchDate,
  tournamentReady,
  description,
  location,
  checkinLength,
  registrationOpenDate,
  registrationCloseDate,
  bracketIds,
  containerId,
}) => {
  const [showPrizeModal, hidePrizeModal] = useModal(
    () => (
      <ModalContainer hideModal={hidePrizeModal}>
        <PrizePoolModal
          sport={sport}
          prize={prizePool[tournamentId]}
          topPrizes={prizeAmounts[tournamentId].topPrizes}
          bottomPrizes={prizeAmounts[tournamentId].bottomPrizes}
        />
      </ModalContainer>
    ),
    [sport],
  )

  const [showTeamModal, hideTeamModal] = useModal(
    () => (
      <ModalContainer hideModal={hideTeamModal}>
        <h2>{tournamentName}</h2>
        <h2>Teams List</h2>
        <Box mt={6}>
          <TeamsTable teams={teams as TeamTableLike[]} coordinator={true} enroll={false} />
        </Box>
      </ModalContainer>
    ),
    [teams],
  )
  const [showPlayerModal, hidePlayerModal] = useModal(
    () => (
      <ModalContainer hideModal={hidePlayerModal}>
        <h2>{tournamentName}</h2>
        <h2>Players List</h2>

        <Box mt={6}>
          <PlayersTable
            bodyBorder={true}
            lightHeaderBackground={true}
            playerHeaderTitle="Player Name"
            coordinatorId={null}
            recordsColumn={true}
            aceScoreColumn={false}
            inGameNameColumn={true}
            rosterEntries={activeRosterEntries}
            pagination={true}
            sport={sport}
          />
        </Box>
      </ModalContainer>
    ),
    [activeRosterEntries, sport],
  )

  return (
    <InfoContainer flexDirection="column" mx={'auto'} my={8} px={[3, 3, 5, 6]}>
      <Animation
        initial="hiddenDown"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
      >
        <Text mb={3}>
          <h2>Information</h2>
        </Text>
      </Animation>

      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.6, delay: 0.4 }}
      >
        <Flex flexWrap="wrap" mx={[0, 0, 0, -2]}>
          <Box px={[0, 0, 0, 2]} mb={[5, 5, 5, 0]} width={[1, 1, 1, 1 / 2]}>
            <InfoBoxHalf
              icon={<FiCalendar color="white" size="32" />}
              title={
                playoffs[tournamentId]
                  ? 'Feb 22 - Jun 7, 2020'
                  : adjustedDayAndFullWithShortMonthAndTime(firstMatchDate)
              }
            >
              <InfoRows>
                <InfoRow
                  icon={<FiUsers size="24" />}
                  text="teams"
                  data={numTeams}
                  color="primaryblue"
                  height={'56px'}
                  showModal={showTeamModal}
                />
                <InfoRow
                  icon={<FiUser size="24" />}
                  text="players"
                  data={numPlayers}
                  color="primaryblue"
                  height={'56px'}
                  showModal={showPlayerModal}
                />
                <SiteSwitch
                  college={
                    <InfoRow
                      icon={<FiFlag size="24" />}
                      text="schools"
                      data={numSchools}
                      color="darkgray"
                      height={'56px'}
                    />
                  }
                  esports={null}
                />
                {containerId && (
                  <Link to={paths.tournament(containerId)}>
                    <InfoRow
                      icon={<FaNetworkWired size="24" />}
                      text="Containing tournament (CURTIS PLEASE COME UP WITH SOMETHING BETTER)"
                      color="primaryblue"
                      height={'56px'}
                    />
                  </Link>
                )}
                {prizePool[tournamentId] && (
                  <InfoRow
                    icon={<FiDollarSign size="24" />}
                    text="prize pool"
                    data={prizePool[tournamentId]}
                    color="primaryblue"
                    height={'56px'}
                    showModal={showPrizeModal}
                  />
                )}
                {/* Commenting out until we add a tournament statuses resolver*/}
                {/* <InfoRow
                icon={<FiCheckSquare size="24" />}
                text="status"
                data={'Hey'}
                color="darkgray"
                height={'56px'}
                currTournament={playoffs[tournamentId] ? true : false}
              /> */}
              </InfoRows>
              <Flex justifyContent="center" alignItems="center" p={4} mt={4} mx="auto">
                <Link to={paths.rules(sport)}>
                  <Animation
                    initial="hidden"
                    animate="visible"
                    variants={animations}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
                  >
                    <BaseButton variant="secondary">View Tournament Rules</BaseButton>
                  </Animation>
                </Link>
              </Flex>
            </InfoBoxHalf>
          </Box>

          <Box px={[0, 0, 0, 2]} width={[1, 1, 1, 1 / 2]}>
            {playoffs[tournamentId] ? (
              <InfoBoxHalf icon={<FiClock color="white" size="32" />} title="tournament timeline">
                {playoffs[tournamentId].map((row, i) => (
                  <InfoRow
                    icon={
                      <Flex>
                        <Box
                          bg={playoffs[tournamentId][i].colors[0]}
                          height={'20px'}
                          width={playoffs[tournamentId][i].colors[1] ? '10px' : '20px'}
                          mb={1}
                        />
                        {playoffs[tournamentId][i].colors[1] && (
                          <Box
                            bg={playoffs[tournamentId][i].colors[1]}
                            height={'20px'}
                            width={'10px'}
                            mb={1}
                          />
                        )}
                      </Flex>
                    }
                    text={playoffs[tournamentId][i].rounds}
                    data={playoffs[tournamentId][i].date}
                    color="darkgray"
                    height={'56px'}
                  />
                ))}
              </InfoBoxHalf>
            ) : (
              <InfoBoxHalf icon={<FiClock color="white" size="32" />} title="tournament timeline">
                {/* TODO: show data for dates already in tour. reg start/end, check in time, 1st match date for all tournaments. when no registration, just hide. */}
                <InfoRow
                  text="Registration opens"
                  color="darkgray"
                  height={'56px'}
                  data={adjustedDayAndFullWithShortMonthAndTime(registrationOpenDate)}
                />
                <InfoRow
                  text="Registration ends"
                  color="darkgray"
                  height={'56px'}
                  data={adjustedDayAndFullWithShortMonthAndTime(registrationCloseDate)}
                />
                <InfoRow
                  text="Tournament Check In"
                  color="darkgray"
                  height={'56px'}
                  data={
                    checkinLength === 0
                      ? 'no check-in'
                      : adjustedDayAndFullWithShortMonthAndTime(
                          dayjs(firstMatchDate).subtract(checkinLength, 'minute'),
                        )
                  }
                />
                <InfoRow
                  text="Matches Begin"
                  color="darkgray"
                  height={'56px'}
                  data={adjustedDayAndFullWithShortMonthAndTime(firstMatchDate)}
                />

                {/* TODO: set logic once tournaments are nearing completion
              <h6>This tournament is over.</h6> */}
              </InfoBoxHalf>
            )}
          </Box>
        </Flex>
      </Animation>
      {(description !== '' || location !== '') && (
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.6, delay: 0.4 }}
        >
          <Box mt={5}>
            <InfoBoxHalf title="tournament description">
              <TournamentDescription description={description} location={location} />
            </InfoBoxHalf>
            {bracketIds && bracketIds.length > 0 && (
              <Box mt={5}>
                <InfoBoxHalf title="Brackets">
                  <Text>
                    This tournament was simply too OP for a single bracket, below you will find a
                    list of this tournament's brackets.
                  </Text>
                  <br />
                  {bracketIds.map((bracket, idx) => (
                    <Link to={paths.tournament(bracket)}>
                      <Text> Bracket {idx + 1} </Text>
                    </Link>
                  ))}
                </InfoBoxHalf>
              </Box>
            )}
          </Box>
        </Animation>
      )}
    </InfoContainer>
  )
}

export default TournamentInformation
