import gql from 'graphql-tag'

export const AdminSeasonInfoQuery = gql`
  fragment AdminSeasonInfoQuery on Season {
    banner
    division1
    divisionName
    divisionNumber
    firstMatchDate
    id
    id
    isEditable
    isPremium
    maximumTeamSize
    minimumTeamSize
    name
    priceCents
    published
    regionChoice
    registrationCloseDate
    registrationOpenDate
    rosterLockDate
    scheduleTypeChoice
    seasonEndDate
    seasonYear
    sponsorImage
    matchPrototype {
      id
      gameCount
    }
    sport {
      slug
    }
  }
`

export const AdminMatchPrototypeInfo = gql`
  fragment AdminMatchPrototypeInfo on MatchPrototype {
    id
    gameCount
  }
`
export const AdminTournamentRoundInfo = gql`
  fragment AdminTournamentRoundInfo on Round {
    id
    tournamentRoundName
    tournamentBracketOrder
    matchPrototype {
      ...AdminMatchPrototypeInfo
    }
  }
  ${AdminMatchPrototypeInfo}
`
export const AdminTournamentRegistryInfo = gql`
  fragment AdminTournamentRegistryInfo on TournamentRegistry {
    team {
      id
    }
    id
    seed
  }
`

export const AdminTournamentInfo = gql`
  fragment AdminTournamentInfo on Tournament {
    name
    description
    brackets {
      id
    }
    desiredBrackets
    multibracketCapable
    containerId
    id
    firstMatchDate
    registrationCloseDate
    registrationOpenDate
    banner
    sponsorImage
    published
    minTeamSize
    defaultSeriesLength
    typeChoice
    numberOfTeams
    tournamentRegistries {
      ...AdminTournamentRegistryInfo
    }
    season {
      id
    }
    platform {
      id
      uid
      title
    }
    styleChoice
    sport
    checkinLength
    isPremium
    rounds {
      ...AdminTournamentRoundInfo
    }
  }
  ${AdminTournamentRoundInfo}
  ${AdminTournamentRegistryInfo}
`
export const AdminTournamentTeamInfo = gql`
  fragment AdminTournamentTeamInfo on Team {
    name
    id
    activePlayersCount
    university {
      abbreviation
      name
      id
      state
    }
  }
`

const AdminSeasonTeamInfo = gql`
  fragment AdminSeasonTeamInfo on Team {
    id
    name
    activePlayersCount
    university {
      id
      abbreviation
      name
      state
    }
  }
`

const AdminSeasonConferences = gql`
  fragment AdminSeasonConferences on Conference {
    id
    name
    teams {
      id
    }
  }
`

export const AdminSeasonTeamsQuery = gql`
  fragment AdminSeasonTeamsQuery on Season {
    adminSeasonTeams {
      ...AdminSeasonTeamInfo
    }
    conferences {
      ...AdminSeasonConferences
    }
  }
  ${AdminSeasonTeamInfo}
  ${AdminSeasonConferences}
`

export const UniversityHubQuery = gql`
  fragment UniversityHubQuery on University {
    id
    name
    currentUserCanCreateTeam
    currentUserPlayedSports
    currentUserTeamIds
    numberOfAwards
    activeUsers {
      id
    }
    seasonTeams {
      id
      name
      selectedSeasonConferenceName
      isRecruiting
      selectedSeason {
        id
        divisionName
        seasonYear
      }
      selectedSeasonStats {
        losses
        wins
        id
      }
      sportSlug
    }
    newTeams {
      id
      name
      selectedSeasonConferenceName
      isRecruiting
      selectedSeason {
        id
        divisionName
        seasonYear
      }
      selectedSeasonStats {
        losses
        wins
        id
      }
      sportSlug
    }
  }
`

export const TeamDetailQuery = gql`
  fragment TeamDetailQuery on Team {
    id
    name
    sportSlug
    currentUserIsCoordinator
    currentUserIsEligible
    currentUserOnTeam
    currentUserApplicationPending
    selectedSeasonConferenceName
    selectedSeasonRank
    currentSeasonEligible
    isRecruiting
    facebookUsername
    twitterUsername
    twitchUsername
    pendingLogoImage {
      name
      dataUrl
    }
    approvedLogoImage {
      name
      dataUrl
    }
    pendingBannerImage {
      name
      dataUrl
    }
    approvedBannerImage {
      name
      dataUrl
    }
    coordinator {
      id
      username
      discordId
    }
    university {
      id
      abbreviation
      name
    }
    teamAwards {
      id
    }
  }
`

export const TeamPendingInvitationsQuery = gql`
  fragment TeamPendingInvitationsQuery on Team {
    pendingInvitations {
      id
      player {
        id
        email
        username
        discordUsername
      }
      gameRole
    }
  }
`

export const TeamRosterEntriesQuery = gql`
  fragment TeamRosterEntriesQuery on Team {
    activeRosterEntries {
      id
      inGameName
      accepted
      player {
        id
        username
        discordId
        characterImageName
        university {
          name
          id
        }
      }
      roleCd
      gameRole
      staffRoleCd
    }
    pendingRosterEntries {
      id
      player {
        username
        discordUsername
      }
      gameRole
    }
  }
`
export const TeamSeasonInfoQuery = gql`
  fragment TeamSeasonInfoQuery on Team {
    selectedSeasonPlayerStats {
      id
      wins
      losses
      player {
        id
      }
    }
    selectedSeasonStats {
      id
      wins
      losses
    }
    selectedSeason {
      id
      divisionName
    }
  }
`

export const TeamSettledMatchesQuery = gql`
  fragment TeamSettledMatchesQuery on Team {
    settledMatches {
      id
      scheduledDate
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      games {
        id
        winner {
          id
          team {
            id
          }
        }
      }
    }
  }
`

export const UpcomingMatchesQuery = gql`
  fragment UpcomingMatchesQuery on Team {
    upcomingMatches {
      ...MatchCarouselMatchInfo
    }
  }
`

export const UnreportedMatchesQuery = gql`
  fragment UnreportedMatchesQuery on Team {
    unreportedMatches {
      ...MatchCarouselMatchInfo
    }
  }
`

export const TournamentsToCheckinToQuery = gql`
  fragment TournamentsToCheckinToQuery on Team {
    tournamentsToCheckinTo {
      ...MatchCarouselTournamentCheckinInfo
    }
  }
`

export const TournamentDetailTeam = gql`
  fragment TournamentDetail_Team on Team {
    id
    name
    sportSlug
    tournamentRegistries {
      id
      tournament {
        id
      }
    }
  }
`
export const TeamMessageBoard = gql`
  fragment TeamMessageBoard on Team {
    currentUserOnTeam
    teamMessages {
      id
      hidden
      user {
        displayName
        isAdmin
        id
      }
      body
      createdAt
    }
  }
`
export const TeamMessageBoardUpdates = gql`
  fragment TeamMessageBoardUpdates on Team {
    teamMessages(since: $since) {
      id
      hidden
      user {
        displayName
        isAdmin
        id
      }
      body
      createdAt
    }
  }
`

export const TournamentDetailCurrentUser = gql`
  fragment TournamentDetail_CurrentUser on CurrentUser {
    id
    coordinatingTeams {
      ...TournamentDetail_Team
    }
  }
  ${TournamentDetailTeam}
`

export const CurrentUserUniversity = gql`
  fragment CurrentUser_University on University {
    id
    name
  }
`

export const PendingInvitationTeam = gql`
  fragment PendingInvitation_Team on Team {
    id
    name
    sportSlug
    overallWins
    overallLosses
  }
`

export const CurrentUserPendingInvitations = gql`
  fragment CurrentUser_PendingInvitations on PendingInvitation {
    id
    gameRole
    team {
      ...PendingInvitation_Team
    }
  }
  ${PendingInvitationTeam}
`

export const AuthenticationAttributes = gql`
  fragment AuthenticationAttributes on UserForAuth {
    accessToken
    username
    client
    expiry
    uid
    id
    isIncompleteAccount
    isAdmin
    teamIds
  }
`
export const PlayerProfileMatchHistoryQuery = gql`
  fragment PlayerProfileMatchHistoryQuery on User {
    reportedMatches {
      id
      scheduledDate
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      games {
        id
        winner {
          id
          team {
            id
          }
        }
      }
    }
  }
`

export const MatchDetailAttributes = gql`
  fragment MatchDetailAttributes on Match {
    id
    sport
    currentUserCanReport
    currentUserCanApproveReschedule
    currentUserIsCoordinator
    currentUserIsTeamMember
    scheduledDate
    rescheduleDate
    homeTeamCheckedIn
    awayTeamCheckedIn
    rescheduleRequestingTeam {
      id
    }
    settled
    forfeit {
      id
      requestingTeamName
      opposingTeamName
      accepted
      explanation
      rejectedMessage
      proofScreenshotUrl
    }
    isBye
    playerStats {
      id
      wins
      losses
      player {
        id
      }
    }
    maps {
      id
      name
    }
    tournament {
      id
      sport
      type
      name
    }
    awayTeam {
      id
      name
      truncatedName
      sportSlug
      selectedSeasonStats {
        id
        wins
        losses
      }
      coordinator {
        id
      }
      activeRosterEntries {
        id
      }
      rosterEntries {
        gameRole
        id
        inGameName
        roleCd
        player {
          csgoEseaProfileUrl
          id
          discordId
          discordUsername
          username
          characterName
          characterImageName
        }
      }
    }
    homeTeam {
      id
      name
      truncatedName
      sportSlug
      selectedSeasonStats {
        id
        wins
        losses
      }
      coordinator {
        id
      }
      activeRosterEntries {
        id
      }
      rosterEntries {
        gameRole
        id
        inGameName
        roleCd
        player {
          id
          csgoEseaProfileUrl
          discordUsername
          discordId
          username
          characterName
          characterImageName
        }
      }
    }
    games {
      id
      map {
        id
        name
      }
      riotTournamentCodeData {
        tournamentCode
      }
      proofScreenshotUrl
      winner {
        score
        id
        lineupEntries {
          id
          player {
            id
          }
          deckListCode
          selectedColor
          role
          characterName
          characterImageName
          kills
          lolGamePlayerStats {
            kills
            deaths
            assists
          }
          smashRemainingStocks
        }
        players {
          id
        }
        team {
          id
          truncatedName
        }
      }
      loser {
        score
        id
        lineupEntries {
          id
          player {
            id
          }
          deckListCode
          selectedColor
          role
          characterName
          characterImageName
          kills
          lolGamePlayerStats {
            kills
            deaths
            assists
          }
          smashRemainingStocks
        }
        players {
          id
        }
        team {
          id
        }
      }
    }
  }
`
export const HIDE_MESSAGE = gql`
  mutation hideMatchMessage($id: ID!, $type: String!) {
    hideMessage(id: $id, type: $type) {
      success
      errors {
        message
      }
    }
  }
`